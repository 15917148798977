import React from 'react';
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
	options: Ioption[];
	value: string | boolean | number;
	onChange: (value: unknown) => void;
	label: string;
}

interface Ioption {
	label: string;
	value: any;
}

const SelectDropDown: React.FC<Props> = ({
	options,
	value,
	onChange,
	label,
}) => {
	const styles = useStyles();
	return (
		<FormControl variant="outlined" className={styles.formControl}>
			<TextField
				fullWidth
				variant="outlined"
				label={label}
				InputLabelProps={{ shrink: true }}
				select
				value={value}
				className={styles.select}
				onChange={onChange}
			>
				{options.map((option: Ioption) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
		</FormControl>
	);
};

export default SelectDropDown;
