import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { FieldsCadQuestoes } from 'components';
import EditQuestoes from './EditQuestoes';

const CadQuestoes = () => {
	const { id, desafioId } = useParams();
	if (id) {
		return <EditQuestoes desafioId={desafioId} id={id} />;
	}

	return (
		<Container fluid className="pl-3 pr-3 pt-3">
			<FieldsCadQuestoes desafioId={desafioId} />
		</Container>
	);
};

export default CadQuestoes;
