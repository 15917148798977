import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	padding: theme.spacing(2),
	marginTop: theme.spacing(2),
}));

const ItemLine: React.FC<{ label: string; value; key?: string }> = ({
	label,
	value,
	key,
}) =>
	value ? (
		<Box mb={1} key={key}>
			<Grid container direction="row" spacing={2}>
				<Grid item>
					<Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
				</Grid>
				<Grid item>
					<Typography>{value}</Typography>
				</Grid>
			</Grid>
		</Box>
	) : null;

export { Item, ItemLine };
