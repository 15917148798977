import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { getQueryVariables } from 'helpers/queries';
import { LISTAR_SIMULADOS } from '../queries';

const SimuladoContext = React.createContext();

export const SimuladoProvider = ({ children }) => {
	const [ano, setAno] = useState(0);
	const [vestibular, setVestibular] = useState(0);

	const makeVariables = () => ({
		variables: getQueryVariables({
			ano,
			vestibular,
		}),
	});

	const [listarSimulados, { loading, error, data, refetch }] = useLazyQuery(
		LISTAR_SIMULADOS,
	);

	return (
		<SimuladoContext.Provider
			value={{
				loading,
				error,
				data,
				refetch,
				ano,
				setAno,
				vestibular,
				setVestibular,
				makeVariables,
				listarSimulados,
			}}
		>
			{children}
		</SimuladoContext.Provider>
	);
};

SimuladoProvider.propTypes = {
	children: PropTypes.node,
};

export default SimuladoContext;
