import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
	Box,
	Grid,
	LinearProgress,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { FETCH_PLANOS } from 'screens/DashboardParceiros/api';
import { IPlano, IPlanoValidationField } from 'types/plano';
import { State } from 'screens/FindValidationCode/types';
import { InputBase, Divider, Paper, Button } from '@material-ui/core';

const FindValidationForm: React.FC<{
	state: State;
	handleChange: (values: any) => void;
	onSubmit: (envet: any) => void;
	onClear: () => void;
}> = ({ state, handleChange, onSubmit, onClear }) => {
	const { data, loading } = useQuery<{ planos: IPlano[] | null }>(FETCH_PLANOS);
	const plano = useMemo(
		() =>
			data?.planos?.find(
				it => it.name === state.nome && it.parceiro === state.parceiro,
			) as IPlano | null,
		[data?.planos, state.nome, state.parceiro],
	);
	const onHandlePlan = (event: any) => {
		if (plano?.name === event.target.value) return;
		if (plano?._id) onClear();
		const plan = data?.planos?.find(
			it => it.name === event.target.value,
		) as IPlano | null;
		handleChange({
			nome: event.target.value,
			parceiro: plan.parceiro,
		});
	};
	const onHandleChange = (event: any) => {
		handleChange({
			[event.target.name]: event.target.value,
		});
	};

	if (loading) {
		return <LinearProgress />;
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3} lg={2}>
					<TextField
						fullWidth
						select
						name="nome"
						variant="outlined"
						label="Plano"
						value={state.nome}
						onChange={onHandlePlan}
						size="small"
					>
						<MenuItem key="" value="">
							Planos
						</MenuItem>
						{data?.planos?.map((item: IPlano) => (
							<MenuItem key={item.name} value={item.name}>
								{item.name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
			<Box my={2}>
				<Paper component="form" onSubmit={onSubmit}>
					<Grid container spacing={2}>
						{plano ? (
							<Grid container spacing={3} className="align-items-center p-4">
								{plano.validationFields?.map((item: IPlanoValidationField) => (
									<Grid
										key={item.name}
										item
										xs={12}
										lg={8}
										className="flex-row d-flex"
									>
										<Divider orientation="vertical" />
										<InputBase
											className="pl-3 border border-gray"
											placeholder={item.label}
											fullWidth
											name={item.name}
											onChange={onHandleChange}
											inputProps={{
												'aria-label': 'O que você está procurando',
											}}
										/>
									</Grid>
								))}
								<Grid item xs={12} lg={8} className="text-center">
									<Button
										variant="contained"
										fullWidth
										color="primary"
										type="submit"
									>
										Buscar
									</Button>
								</Grid>
							</Grid>
						) : null}
					</Grid>
				</Paper>
			</Box>
		</>
	);
};

export default FindValidationForm;
