import { gql } from '@apollo/client';

export const UPDATE_CAD_USER = gql`
	mutation UpdateCadUser($_id: String!, $caduser: CadUserInput!) {
		updateCadUser(_id: $_id, caduser: $caduser) {
			_id
			username
			nome
			role
			email
			active
		}
	}
`;
