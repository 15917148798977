import { gql } from '@apollo/client';

export const ADD_QUESTOES_SIMULADO = gql`
	mutation addQuestoesSimulado(
		$idSimulado: String!
		$questoes: [String]
		$prova: Int
	) {
		addQuestoesSimulado(
			idSimulado: $idSimulado
			questoes: $questoes
			prova: $prova
		) {
			_id
		}
	}
`;

export const REMOVE_QUESTOES_SIMULADO = gql`
	mutation removeQuestoesSimulado(
		$idSimulado: String!
		$questoes: [String]
		$prova: Int
	) {
		removeQuestoesSimulado(
			idSimulado: $idSimulado
			questoes: $questoes
			prova: $prova
		) {
			_id
			provas {
				questoes {
					_id
				}
			}
		}
	}
`;

export const UPDATE_SIMULADO_FB = gql`
	mutation updateSimuladoFB($_id: String, $simulado: inputSimuladoFB) {
		updateSimuladoFB(_id: $_id, simulado: $simulado)
	}
`;
