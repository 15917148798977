import React from 'react';
import { Avatar, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
	color: string;
	number: number;
	numberCanceled: number;
}

const DoubleCard: React.FC<Props> = ({ color, number, numberCanceled }) => {
	const classes = useStyles();
	return (
		<Paper
			style={{
				borderLeft: `6px solid ${color}`,
			}}
			className={classes.cardParceiro}
		>
			<Grid className={classes.cardContent}>
				<Avatar
					variant="rounded"
					style={{
						backgroundColor: color,
					}}
					className={classes.avatar}
				>
					Subscription
				</Avatar>
				<Divider orientation="vertical" flexItem />
				<Grid className={classes.summary}>
					<Typography className={classes.title}>Pagamentos</Typography>
					<Typography className={classes.number}>{number}</Typography>
				</Grid>

				<Grid className={classes.summary}>
					<Typography className={classes.title}>Canceled</Typography>
					<Typography className={classes.number}>{numberCanceled}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default DoubleCard;
