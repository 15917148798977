import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Container,
	Typography,
	Box,
	CircularProgress,
	MenuItem,
	Select,
} from '@material-ui/core';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { NavHeader, SearchBox } from 'components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { FIND_APP_USER, FIND_USER_VALIDATION_CODE } from './api/query';

import { IAppUser } from './types';
import DetailsUserApp from './details-user';

const FindUsersApp: React.FC = () => {
	const statusErrorRef = useRef<string>('');
	const params = useParams();
	const [searchOption, setSearchOption] = useState('email');
	const [
		findUser,
		{ loading, data, error, networkStatus, called, refetch },
	] = useLazyQuery<
		{
			findUser?: {
				user: IAppUser | null;
				found?: boolean;
			};
		},
		{ searchTerm: string }
	>(FIND_APP_USER);
	const [
		findUserValidationCode,
		{
			data: dataValidation,
			error: errorValidation,
			called: calledValidation,
			loading: loadingValidation,
			networkStatus: networkStatusValidation,
		},
	] = useLazyQuery<
		{
			findUserValidationCode?: {
				user: IAppUser | null;
				found?: boolean;
			};
		},
		{ validationCode: string }
	>(FIND_USER_VALIDATION_CODE);

	const handleChange = async (event: any) => {
		setSearchOption(event.target.value);
	};
	const onSearch = (
		event: React.FormEvent<HTMLDivElement>,
		selectOption: string,
	) => {
		event.preventDefault();
		const input = (event.target as HTMLFormElement).elements?.namedItem(
			'search',
		) as HTMLInputElement;

		if (!input.value) return;
		statusErrorRef.current = 'called';
		if (selectOption === 'email') {
			findUser({
				variables: {
					searchTerm: input.value,
				},
			});
		} else {
			findUserValidationCode({
				variables: {
					validationCode: input.value,
				},
			});
		}
	};
	const isLoading = useMemo(
		() => loading || networkStatus === NetworkStatus.refetch,
		[loading, networkStatus],
	);

	const isLoadingValidation = useMemo(
		() =>
			loadingValidation || networkStatusValidation === NetworkStatus.refetch,
		[loadingValidation, networkStatusValidation],
	);

	useEffect(() => {
		if (error && statusErrorRef.current === 'called') {
			toast.error('Error ao tentar buscar usuário!');
			statusErrorRef.current = 'showed';
		} else if (
			statusErrorRef.current === 'called' &&
			called &&
			data &&
			!data.findUser?.found
		) {
			toast.info('Usuário não encontrado');
			statusErrorRef.current = 'showed';
		}
	}, [error, called, data?.findUser?.found, data]);

	useEffect(() => {
		if (errorValidation && statusErrorRef.current === 'called') {
			toast.error('Error ao tentar buscar usuário!');
			statusErrorRef.current = 'showed';
		} else if (
			statusErrorRef.current === 'called' &&
			calledValidation &&
			dataValidation &&
			!dataValidation.findUserValidationCode?.found
		) {
			toast.info('Usuário não encontrado');
			statusErrorRef.current = 'showed';
		}
	}, [
		errorValidation,
		calledValidation,
		dataValidation?.findUserValidationCode?.found,
		dataValidation,
	]);

	useEffect(() => {
		if (params.userId) {
			findUser({
				variables: {
					searchTerm: params.userId,
				},
			});
		}
	}, [findUser, params.userId]);

	return (
		<>
			<NavHeader />
			<Container>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Buscar usuário
					</Typography>
				</Box>
				<Box my={3} marginLeft={5}>
					<Select
						value={searchOption}
						labelWidth={115}
						onChange={handleChange}
						style={{ width: 140 }}
					>
						<MenuItem value="email">Email</MenuItem>
						<MenuItem value="validationCode">Validation Code</MenuItem>
					</Select>
				</Box>

				<SearchBox
					onSubmit={event => {
						onSearch(event, searchOption);
					}}
					placeholder={
						searchOption === 'email'
							? 'Buscar por email ou uid'
							: 'Buscar por validation code'
					}
					btnText="Buscar"
					defaultValue={params?.userId}
				/>
				<Box my={4}>
					{isLoading || isLoadingValidation ? (
						<h5>
							Carregando
							<CircularProgress />
						</h5>
					) : null}
				</Box>
				<Box my={4}>
					<DetailsUserApp
						user={
							searchOption === 'email'
								? data?.findUser?.user
								: dataValidation?.findUserValidationCode.user
						}
						refetch={refetch}
					/>
				</Box>
			</Container>
		</>
	);
};

export default FindUsersApp;
