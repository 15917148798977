import { gql } from 'apollo-boost';

import { createModel } from '@rematch/core';
import api from '../../../services/api';
import { GET_QUESTOES } from '../../../services/queries';
import { getItemsPerPage, getMaxPages } from '../../../helpers';
import { RootModel } from '..';

export const INITIAL_STATE = {
	questoes: [],
	questoesPagina: [],
	loading: false,
	currentPage: 0,
	maxPages: 0,
	palavrasChave: [],
	assuntos: [],
	materias: [],
	vestibulares: [],
	fontes: [],
	palavraChave: null,
	assunto: null,
	materia: null,
	vestibular: null,
	fonte: null,
};

export const questoesModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		changeState: (state, props) => ({
			...state,
			...props,
		}),
		startLoading: state => ({ ...state, loading: true }),
		stopLoading: state => ({ ...state, loading: false }),
		clearFields: () => ({ ...INITIAL_STATE }),
	},
	effects: dispatch => ({
		fetchQuestoes: async currentPage => {
			try {
				dispatch.questoesModel.startLoading();

				const { data } = await api.query({ query: GET_QUESTOES });
				const questoesPagina = getItemsPerPage(data.questoes, currentPage, 10);
				const maxPages = getMaxPages(data.questoes, 10);

				dispatch.questoesModel.changeState({
					questoes: data.questoes,
					loading: false,
					questoesPagina,
					maxPages,
				});
			} catch (error: any) {
				dispatch.questoesModel.stopLoading();
			}
		},
		changePage: ({ newPage, questoes }) => {
			const questoesPagina = getItemsPerPage(questoes, newPage, 10);
			dispatch.questoesModel.changeState({
				questoesPagina,
				currentPage: newPage,
			});
		},
		fetchOpcoesFiltro: async () => {
			try {
				dispatch.questoesModel.startLoading();
				const query = gql`
					{
						materias {
							_id
							nome
						}
						vestibulares {
							_id
							nome
						}
						fontes {
							_id
							nome
						}
					}
				`;
				const { data } = await api.query({ query });

				dispatch.questoesModel.changeState({
					materias: data.materias.map((materia: any) => ({
						value: materia._id,
						label: materia.nome,
					})),
					vestibulares: data.vestibulares.map((vest: any) => ({
						value: vest._id,
						label: vest.nome,
					})),
					fontes: data.fontes.map((fonte: any) => ({
						value: fonte._id,
						label: fonte.nome,
					})),
					loading: false,
				});
			} catch (error: any) {
				dispatch.questoesModel.stopLoading();
			}
		},
		filtrarQuestoes: async ({
			fonte,
			vestibular,
			palavraChave,
			materia,
			assunto,
		}) => {
			try {
				const variables = {
					palavraChave: palavraChave ? palavraChave.label : null,
					fonte: fonte ? fonte.label : null,
					vestibular: vestibular ? vestibular.label : null,
					materia: materia ? materia.label : null,
					assunto: assunto ? assunto.label : null,
				};

				const { data } = await api.query({ query: GET_QUESTOES, variables });

				const questoesPagina = getItemsPerPage(data.questoes, 0, 10);
				const maxPages = getMaxPages(data.questoes, 10);
				dispatch.questoesModel.changeState({
					questoes: data.questoes,
					questoesPagina,
					maxPages,
					currentPage: 0,
				});
			} catch (error: any) {
				console.error(error);
			}
		},
		fetchAssuntos: async materia => {
			try {
				const query = gql`
					query Assuntos($materia: String!) {
						assuntos(materia: $materia) {
							_id
							nome
							materia
						}
					}
				`;
				const { data } = await api.query({ query, variables: { materia } });

				dispatch.questoesModel.changeState({
					assuntos: data.assuntos.map((assunto: any) => ({
						value: assunto._id,
						label: assunto.nome,
					})),
				});
			} catch (error: any) {
				console.error(error);
			}
		},
		fetchPalavrasChave: async () => {
			try {
				const query = gql`
					{
						palavrasChave {
							_id
							nome
						}
					}
				`;

				const { data } = await api.query({ query });

				dispatch.questoesModel.changeState({
					palavrasChave: data.palavrasChave.map((pc: any) => ({
						value: pc._id,
						label: pc.nome,
					})),
				});
			} catch (error: any) {
				console.error(error);
			}
		},
		updateFiltroValue: (value, _, name) => {
			dispatch.questoesModel.changeState({ [name]: value });
		},
		clearFilters: () => {
			dispatch.questoesModel.changeState({
				palavraChave: null,
				assunto: null,
				materia: null,
				vestibular: null,
				fonte: null,
			});
		},
	}),
});
