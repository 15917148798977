import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Container, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { FieldsCadQuestoes } from 'components';
import { useStyles } from './styles';

const FETCH_QUESTAO_BY_ID = gql`
	query getQuestaoById($id: ID!) {
		questao(_id: $id) {
			_id
			ano
			areaConhecimento {
				_id
				nome
				competencias
				habilidades
			}
			vestibular
			codigo
			alternativas {
				letra
				texto
				correta
			}
			enunciado
			fonte
			numeroQuestao
			materia
			palavraChave
			assuntos {
				_id
				nome
				materia
			}
			resolucao
			ano
			video
			disponivel
			review
			reviewReason
			erros
		}
	}
`;
const EditQuestoes = ({ id, desafioId }) => {
	const styles = useStyles();
	const { loading, data } = useQuery(FETCH_QUESTAO_BY_ID, {
		variables: { id },
	});

	if (loading)
		return (
			<div className={styles.spinner}>
				<Spinner size="lg" color="primary" />
			</div>
		);
	return (
		<Container fluid className="pl-3 pr-3 pt-3">
			<FieldsCadQuestoes desafioId={desafioId} questao={data?.questao} />
		</Container>
	);
};

EditQuestoes.propTypes = {
	id: PropTypes.string,
};

export default EditQuestoes;
