import React from 'react';
import { Box, InputBase, Divider, Paper, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { grey } from '@material-ui/core/colors';
import { AdminCadUsersContextProps } from '../../../screens/AdminCadUsers/types';

interface Props {
	onSubmit?: AdminCadUsersContextProps['onSearch'];
	placeholder?: string;
	btnText?: string;
	defaultValue?: string;
}

const SearchBox: React.FC<Props> = ({
	onSubmit,
	placeholder = 'Buscar por nome, email ou username',
	btnText = 'Filtrar',
	defaultValue,
}) => (
	<Paper component="form" onSubmit={onSubmit}>
		<Grid container spacing={3} className="align-items-center">
			<Grid item xs={12} lg={8} className="flex-row d-flex">
				<Box p={1} ml={1} className="bg-light">
					<SearchIcon style={{ color: grey[800] }} />
				</Box>
				<Divider orientation="vertical" />
				<InputBase
					defaultValue={defaultValue}
					className="pl-3 border border-gray"
					placeholder={placeholder}
					fullWidth
					name="search"
					inputProps={{ 'aria-label': 'O que você está procurando' }}
				/>
			</Grid>
			<Grid item xs={12} lg={4} className="text-center">
				<Button variant="contained" fullWidth color="primary" type="submit">
					{btnText}
				</Button>
			</Grid>
		</Grid>
	</Paper>
);

export default SearchBox;
