import React, { useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Navigate } from 'react-router-dom';
import {
	oraColor,
	tixColor,
	primaryGreen,
	lightOraColor,
} from 'constants/colors';
import { UsersContext } from 'screens/DashboardFinanceiro/provider';
import { Grid, LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';
import {
	formattedFinancialReport,
	sortDate,
	formatArray,
	formattedPriceReport,
	formattedPriceReportUSD,
} from '../../helpers';

const FinancialChart = () => {
	const classes = useStyles();
	const {
		users,
		loadingUsers,
		loadingGraph,
		loadingReport,
		usersError,
		errorGraph,
		errorReport,
		dataPrice,
	} = useContext(UsersContext);

	if (loadingUsers || loadingGraph || loadingReport) return <LinearProgress />;
	if (usersError || errorGraph || errorReport) return <Navigate to="/" />;

	const {
		subscriptionCanceledApple,
		subscriptionCanceledGoogle,
		subscriptionRenewedGoogle,
		subscriptionRenewedApple,
		subscriptionsExpired,
	} = users.getFinancialReport;

	const {
		applePriceBRL,
		googlePriceBRL,
		applePriceUSD,
		googlePriceUSD,
	} = dataPrice?.getReportPrice;

	const priceAppleGoogleBRL = formattedPriceReport([
		...applePriceBRL,
		...googlePriceBRL,
	]);

	const priceAppleGoogleUSD = formattedPriceReportUSD([
		...applePriceUSD,
		...googlePriceUSD,
	]);

	const appleGooglePriceGraph = [
		...sortDate(priceAppleGoogleUSD),
		...sortDate(priceAppleGoogleBRL),
	];

	const renewedAppleGoogle = formattedFinancialReport([
		...subscriptionRenewedApple,
		...subscriptionRenewedGoogle,
	]);
	const renewedAppleGoogleSorted = sortDate(renewedAppleGoogle);

	const canceledAppleGoogle = formattedFinancialReport([
		...subscriptionCanceledApple,
		...subscriptionCanceledGoogle,
	]);
	const canceledAppleGoogleSorted = sortDate(canceledAppleGoogle);
	const formatSubscriptionExpired = formattedFinancialReport(
		subscriptionsExpired,
	);
	const canceledExpired = [
		...canceledAppleGoogleSorted,
		...formatSubscriptionExpired,
	];

	return (
		<div>
			{users ? (
				<Grid
					container
					justify="space-between"
					spacing={2}
					className={classes.chartContainer}
				>
					<Grid item lg={6}>
						<h5>Subscriptions Renovadas</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={formatArray(renewedAppleGoogleSorted)}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<YAxis />
							<Tooltip />
							<Bar
								dataKey="totalSubscriptionsRenewedApple"
								stackId="apple"
								fill={tixColor}
								name="Apple"
							/>
							<Bar
								dataKey="totalSubscriptionsRenewedGoogle"
								stackId="google"
								fill={primaryGreen}
								name="Google"
							/>
						</BarChart>
					</Grid>
					<Grid item lg={6}>
						<h5>Subscriptions Expiradas</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={formatArray(sortDate(canceledExpired))}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<YAxis />
							<Tooltip />
							<Bar
								dataKey="totalSubscriptionsExpired"
								fill={oraColor}
								name="Subscriptions expiradas"
							/>
							<Bar
								dataKey="totalSubscriptionsCanceledApple"
								stackId="apple"
								fill={lightOraColor}
								name="Apple"
							/>
							<Bar
								dataKey="totalSubscriptionsCanceledGoogle"
								stackId="google"
								fill={lightOraColor}
								name="Google"
							/>
						</BarChart>
					</Grid>
					<Grid item lg={6}>
						<h5>Financeiro</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={formatArray(appleGooglePriceGraph)}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<YAxis />
							<Tooltip
								formatter={(value: number) =>
									new Intl.NumberFormat('id', {
										minimumFractionDigits: 2,
									}).format(value)
								}
							/>
							<Bar
								dataKey="totalPriceApple"
								stackId="appleBRL"
								fill={tixColor}
								name="Apple R$"
							/>
							<Bar
								dataKey="totalPriceAppleUSD"
								stackId="appleUSD"
								fill={tixColor}
								name="Apple U$"
							/>
							<Bar
								dataKey="totalPriceGoogle"
								stackId="googleBRL"
								fill={primaryGreen}
								name="Google R$"
							/>
							<Bar
								dataKey="totalPriceGoogleUSD"
								stackId="googleUSD"
								fill={primaryGreen}
								name="Google U$"
							/>
						</BarChart>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
};

export default FinancialChart;
