import { gql } from '@apollo/client';

export const MUTATION_CREATE_FEED_NOTIFICATION = gql`
	mutation CreateNotificationFeed($notification: NotificationFeedInput) {
		createNotificationFeed(notification: $notification)
	}
`;
export const MUTATION_REMOVE_FEED_NOTIFICATION = gql`
	mutation RemoveNotification($notificationId: String) {
		removeNotification(notificationId: $notificationId)
	}
`;

export const MUTATION_SEND_FEED_NOTIFICATION_AS_PUSH = gql`
	mutation SendFeedNotificationAsPush(
		$notificationId: String!
		$platform: [String]
		$useDeeplink: Boolean
		$sendAfter: String
	) {
		sendFeedNotificationAsPush(
			notificationId: $notificationId
			platform: $platform
			useDeeplink: $useDeeplink
			sendAfter: $sendAfter
		) {
			id
			recipients
		}
	}
`;
