import React from 'react';
import { ListQuestionItem } from '../ListQuestionItem/index';
import { Iquestao } from '../../Types';

interface Props {
	questoes: Iquestao[];
	reportadas: boolean;
}

export const ListQuestion: React.FC<Props> = ({ questoes, reportadas }) => (
	<>
		{questoes.map((questao: Iquestao) => (
			<ListQuestionItem
				key={questao._id}
				questao={questao}
				reportadas={reportadas}
			/>
		))}
	</>
);
