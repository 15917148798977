import { makeStyles } from '@material-ui/core';
import { primaryBlue, primary } from 'constants/colors';

const CIRCLE_IMAGE_SIZE = 32;
export const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 'bold',
		color: primaryBlue,
	},
	headerTitle: {
		paddingTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	backButton: {
		color: primaryBlue,
	},
	row: {
		marginBottom: theme.spacing(5),
	},
	container: {
		backgroundColor: '#F2F2F2',
		height: '100%',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		paddingBottom: theme.spacing(5),
	},
	cardSection: {
		padding: 8,
		paddingBottom: 16,
		paddingTop: 16,
		backgroundColor: 'white',
	},
	// notification item

	badge: {
		backgroundColor: primary,
		borderRadius: 8,
		height: 8,
		left: -4,
		position: 'absolute',
		top: CIRCLE_IMAGE_SIZE / 2,
		width: 8,
	},
	btnBottomSheet: {
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 8,
	},
	button: {
		flexDirection: 'row',
		flex: 1,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	actions: {
		flex: 0,
		display: 'flex',
		flexDirection: 'row',
		marginTop: 8,
	},
	description: {
		color: '#4a5359',
		lineHeight: 16,
		paddingRight: 8,
	},
	leftColumn: {
		flex: 0,
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-start',
		marginRight: 8,
	},
	main: {
		flex: 1,
		display: 'flex',
	},
	mainRow: {
		flex: 1,
		display: 'flex',
		marginRight: 12,
	},
	rightColumn: {
		display: 'flex',
		alignItems: 'flex-end',
		flex: 0,
	},
	newNotification: {
		backgroundColor: 'rgba(118,253,182, 0.12)',
	},
	titleNotification: {
		color: '#4a5359',
		fontWeight: 'bold',
	},
	image: {
		borderRadius: 24,
		height: 24,
		width: 24,
	},
	imageCircle: {
		flex: 1,
		display: 'flex',
		borderRadius: CIRCLE_IMAGE_SIZE,
		height: CIRCLE_IMAGE_SIZE,
		width: CIRCLE_IMAGE_SIZE,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.6)',
		borderColor: '#000',
		borderWidth: 1,
		border: 'solid',
	},

	// actions
	actionButton: {
		display: 'inline-block',
		alignItems: 'center',
		backgroundColor: '#fff',
		borderColor: '#3B7D5A',
		borderRadius: 16,
		borderStyle: 'solid',
		borderWidth: 1,
		justifyContent: 'center',
		paddingRight: 8,
		paddingLeft: 8,
		paddingTop: 4,
		paddingBottom: 4,
		marginRight: 8,
	},
	actionLabel: {
		color: '#3B7D5A',
		fontWeight: 'bold',
		fontSize: 13,
		lineHeight: 0.8,
		letterSpacing: 0.61,
		textAlign: 'center',
	},
}));
