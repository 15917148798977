import { dateToStringDate } from 'helpers/data';
import moment from 'moment';
import { useState } from 'react';
import { ParceirosFilters } from '../types';

const INITIAL_FILTERS: ParceirosFilters = {
	nome: '',
	email: '',
	plataforma: '',
	parceiro: '',
	plano: '',
	inicio: dateToStringDate(moment().add(-1, 'days')),
	fim: dateToStringDate(moment()),
	provider: '',
	skip: 0,
};

export const useParceiroFilters = () => {
	const [filters, setFilters] = useState(INITIAL_FILTERS);

	const handleChange = (event: any) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[`${event.target.name}`]: event.target.value,
		}));
	};
	const setPage = (page: number) => {
		setFilters(prevFilters => ({
			...prevFilters,
			skip: page,
		}));
	};
	return {
		filters,
		handleChange,
		setPage,
	};
};
