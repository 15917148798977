import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdminCadUsers from 'screens/AdminCadUsers';
import { AdminCadUsersProvider } from 'screens/AdminCadUsers/shared/admin-caduser-provider';
import AdminCadUsersPartner from 'screens/AdminCadUsersPartner';
import { AdminCadUsersProviderPartner } from 'screens/AdminCadUsersPartner/shared/admin-caduser-provider';
import { ADMIN_CAD_USERS_SCREEN_PARTNER } from 'screens/AdminCadUsersPartner/routes';
import LoginPage from 'screens/LoginPage';
import { LOGIN_SCREEN } from 'screens/LoginPage/routes';
import FindUsersApp from 'screens/FindUsersApp';
import { DesafioProvider } from 'screens/ListarDesafios/providers/DesafiosProvider';
import FeedNotification from 'screens/FeedNotification/screens/SendNotification';
import FeedNotificationByIdScreen from 'screens/FeedNotification/screens/NotificationById';
import ListFeedNotifications from 'screens/FeedNotification/screens/List';
import { ListNotificationsProvider } from 'screens/FeedNotification/provider/notifications.provider';
import FindValidationCode from 'screens/FindValidationCode';
import RevisarSimulados from 'screens/RevisarSimulados';
import {
	ListarQuestoes,
	CadastrarUsuario,
	AlterarSenha,
	ListarSimulados,
	CadastrarSimulado,
	CadastrarDesafio,
	ListarDesafios,
	DashboardParceiros,
	DashboardFinanceiro,
	ObjetivosAlunos,
	QuestoesReportadas,
} from '../../screens';

import Unauthorized from '../erros/Unauthorized';
import CadQuestoes from '../CadQuestoes/CadQuestoes';
import { QuestionProvider } from '../../screens/ListarQuestoes/providers/QuestionProvider';
import { QuestionReportedProvider } from '../../screens/QuestoesReportadas/providers/QuestionReportedProvider';
import api from '../../services/api';
import PrivateRoute from './PrivateRoute';
import UserRouteWrapper from '../../screens/wrappers/UserRouteWrapper';
import UserRoles from '../../types/user';

import {
	LISTAR_QUESTOES,
	QUESTOES_REPORTADAS,
	CADASTRAR_QUESTOES,
	EDITAR_QUESTAO,
	LISTAR_SIMULADOS,
	UNAUTHORIZED,
	CADASTRAR_USUARIO,
	CHANGE_PASSWORD,
	CADASTRAR_DESAFIO_QUESTAO,
	DASHBOARD_PARCEIROS,
	DASHBOARD_FINANCEIRO,
	BUSCAR_USUARIO_APP,
	OBJETIVOS_ALUNOS,
	FEED_NOTIFICATION,
	FEED_BY_ID_NOTIFICATION,
	FEED_NOTIFICATION_LIST,
	BUSCAR_VALIDATION_CODE_APP,
	REVISAR_SIMULADOS,
} from '../../constants';

import PrivateContextRoute from './PrivateContextRoute';

moment.locale('pt-br');

const App = () => {
	const { COLABORADOR, ADMINISTRADOR, AUTOR, REVISOR } = UserRoles;
	return (
		<ApolloProvider client={api}>
			<BrowserRouter>
				<ToastContainer />
				<Routes>
					<Route path="/">
						<Route
							index
							element={
								<UserRouteWrapper
									route="simulados"
									userRole={COLABORADOR}
									to={LISTAR_QUESTOES}
								/>
							}
						/>

						<Route
							path="users"
							element={
								<PrivateContextRoute roles={[ADMINISTRADOR]}>
									<AdminCadUsersProvider>
										<AdminCadUsers />
									</AdminCadUsersProvider>
								</PrivateContextRoute>
							}
						/>
					</Route>

					<Route
						path={LISTAR_QUESTOES}
						element={
							<PrivateContextRoute roles={[AUTOR, REVISOR, ADMINISTRADOR]}>
								<QuestionProvider>
									<ListarQuestoes />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					/>

					<Route
						path={QUESTOES_REPORTADAS}
						element={
							<PrivateContextRoute roles={[AUTOR, REVISOR, ADMINISTRADOR]}>
								<QuestionReportedProvider>
									<QuestoesReportadas />
								</QuestionReportedProvider>
							</PrivateContextRoute>
						}
					/>

					<Route
						path={ADMIN_CAD_USERS_SCREEN_PARTNER}
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR]}>
								<AdminCadUsersProviderPartner>
									<AdminCadUsersPartner />
								</AdminCadUsersProviderPartner>
							</PrivateContextRoute>
						}
					/>

					<Route
						path={CADASTRAR_QUESTOES}
						element={
							<PrivateContextRoute roles={[AUTOR, REVISOR, ADMINISTRADOR]}>
								<QuestionProvider>
									<CadQuestoes />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					/>

					<Route path={LOGIN_SCREEN} element={<LoginPage />} />
					<Route path={UNAUTHORIZED} element={<Unauthorized />} />
					<Route
						path={CADASTRAR_USUARIO}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={CADASTRAR_USUARIO}
								component={CadastrarUsuario}
							/>
						}
					/>

					<Route
						path={DASHBOARD_PARCEIROS}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={DASHBOARD_PARCEIROS}
								component={DashboardParceiros}
							/>
						}
					/>

					<Route
						path={BUSCAR_USUARIO_APP}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={BUSCAR_USUARIO_APP}
								component={FindUsersApp}
							/>
						}
					>
						<Route path=":userId" element={null} />
					</Route>
					<Route
						path={BUSCAR_VALIDATION_CODE_APP}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={BUSCAR_VALIDATION_CODE_APP}
								component={FindValidationCode}
							/>
						}
					>
						<Route path=":validationCode" element={null} />
					</Route>
					<Route
						path={FEED_BY_ID_NOTIFICATION}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={FEED_BY_ID_NOTIFICATION}
								component={FeedNotificationByIdScreen}
							/>
						}
					/>
					<Route
						path={FEED_NOTIFICATION}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={FEED_NOTIFICATION}
								component={FeedNotification}
							/>
						}
					/>
					<Route
						path={FEED_NOTIFICATION_LIST}
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR]}>
								<ListNotificationsProvider>
									<ListFeedNotifications />
								</ListNotificationsProvider>
							</PrivateContextRoute>
						}
					/>
					<Route
						path={OBJETIVOS_ALUNOS}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={OBJETIVOS_ALUNOS}
								component={ObjetivosAlunos}
							/>
						}
					/>

					<Route
						path={DASHBOARD_FINANCEIRO}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR]}
								path={DASHBOARD_FINANCEIRO}
								component={DashboardFinanceiro}
							/>
						}
					/>

					<Route
						path={LISTAR_SIMULADOS}
						element={
							<PrivateRoute
								roles={[ADMINISTRADOR, COLABORADOR]}
								path={LISTAR_SIMULADOS}
								component={ListarSimulados}
							/>
						}
					/>

					<Route path={CHANGE_PASSWORD} element={<AlterarSenha />} />
					<Route
						path="desafios"
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR, COLABORADOR]}>
								<DesafioProvider>
									<QuestionProvider>
										<ListarDesafios />
									</QuestionProvider>
								</DesafioProvider>
							</PrivateContextRoute>
						}
					/>
					<Route
						path="cadastrar-desafio"
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR, COLABORADOR]}>
								<DesafioProvider>
									<QuestionProvider>
										<CadastrarDesafio />
									</QuestionProvider>
								</DesafioProvider>
							</PrivateContextRoute>
						}
					>
						<Route path=":desafioId" element={null} />
					</Route>
					<Route
						path={CADASTRAR_DESAFIO_QUESTAO}
						element={
							<PrivateContextRoute
								roles={[AUTOR, REVISOR, ADMINISTRADOR, COLABORADOR]}
							>
								<QuestionProvider>
									<CadQuestoes />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					>
						<Route path=":id" element={null} />
					</Route>
					<Route
						path={EDITAR_QUESTAO}
						element={
							<PrivateContextRoute
								roles={[AUTOR, REVISOR, ADMINISTRADOR, COLABORADOR]}
							>
								<QuestionProvider>
									<CadQuestoes />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					/>

					<Route
						path="cadastrar-simulado"
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR, COLABORADOR]}>
								<QuestionProvider>
									<CadastrarSimulado />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					/>
					<Route
						path={REVISAR_SIMULADOS}
						element={
							<PrivateContextRoute roles={[ADMINISTRADOR, COLABORADOR]}>
								<QuestionProvider>
									<RevisarSimulados />
								</QuestionProvider>
							</PrivateContextRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</ApolloProvider>
	);
};

export default App;
