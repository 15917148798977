import { NetworkStatus, useQuery } from '@apollo/client';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GET_FEED_NOTIFICATIONS } from '../api/query';
import { ListNotificationsContextProps, NotificationFeedData } from '../types';

const DEFAULT_VARIABLES = {
	page: 0,
	skip: 0,
	limit: 10,
	sentFrom: 'admin',
};

export const ListNotificationsContext = React.createContext<ListNotificationsContextProps>(
	{
		...((DEFAULT_VARIABLES as unknown) as ListNotificationsContextProps),
	},
);

export const ListNotificationsProvider: React.FC = ({ children }) => {
	const [variables, setVariables] = useState(DEFAULT_VARIABLES);

	const { loading, data, error, refetch, networkStatus } = useQuery<
		{
			notifications: null | Partial<NotificationFeedData>[];
			countNotifications: null | number;
		},
		{
			limit?: number;
			skip?: number;
			sentFrom?: string;
		}
	>(GET_FEED_NOTIFICATIONS, {
		variables,
	});

	const isLoading = useMemo(
		() => loading || networkStatus === NetworkStatus.refetch,
		[loading, networkStatus],
	);

	const onChangeSentFrom = (sentFrom: string | null) => {
		refetch({ ...DEFAULT_VARIABLES, sentFrom });
		setVariables({ ...DEFAULT_VARIABLES, sentFrom });
	};

	const onChangePage = useCallback(
		(page: number) => {
			const skip = page * variables.limit;
			refetch({ skip, limit: variables.limit });
			setVariables(vals => ({ ...vals, skip, page }));
		},
		[refetch, variables.limit],
	);

	const onChangeLimit = useCallback(
		(limit: number) => {
			const newVariables = {
				...variables,
				skip: DEFAULT_VARIABLES.skip,
				limit,
			};
			refetch(newVariables);
			setVariables(newVariables);
		},
		[refetch, variables],
	);

	return (
		<ListNotificationsContext.Provider
			value={{
				loading: isLoading,
				notifications: data?.notifications,
				totalNotifications: data?.countNotifications || 0,
				error,
				refetch,
				onChangePage,
				onChangeLimit,
				onChangeSentFrom,
				...variables,
			}}
		>
			{children}
		</ListNotificationsContext.Provider>
	);
};

export const useListNotificationFeed = () => {
	const context = useContext(ListNotificationsContext);

	return {
		...context,
	};
};
