import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Container,
	Grid,
	Typography,
	Button,
	LinearProgress,
	Chip,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { NavHeader, TabelaQuestoes } from 'components';
import UserWrapper from 'screens/wrappers/UserWrapper';
import UserRoles from 'types/user';
import DesafioConfirmClone from 'screens/ListarDesafios/DesafioConfirmClone';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
	CADASTRAR_DESAFIO_ROUTE,
	LISTAR_DESAFIOS_ROUTE,
} from '../../constants';
import { DesafioDialog, DesafioForm } from './components';
import { useStyles } from './styles';
import { useCustomDialog, useDialog, useLazyDesafio } from './hooks';
import CheckQuestionProvider from './provider/CheckQuestionProvider';
import CampanhaDialog from './components/CampanhaDialog';
import { CLONE_DESAFIO } from './api';

const CadastrarDesafio: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const params = useParams();
	const [selecionadas, setSelecionadas] = useState(0);
	const {
		data,
		loading,
		error,
		called,
		handlePublish,
		refetch,
		planos,
		dataCountdesafio,
	} = useLazyDesafio(params?.desafioId as string);
	const { open, handleClose, handleOpen } = useDialog();
	const [
		isCampanhaOpen,
		openCampanhaDialog,
		closeCampanhaDialog,
	] = useCustomDialog();
	const [openDialog, setDialog] = useState(false);
	const [clonarDesafio] = useMutation(CLONE_DESAFIO);

	const onDialogChange = React.useCallback(() => {
		setDialog(values => !values);
	}, []);

	const handleSubmit = async () => {
		const desafioId = data?.desafio?._id;
		try {
			const cloned = await clonarDesafio({
				variables: {
					desafioId,
				},
			});
			onDialogChange();
			navigate(`${CADASTRAR_DESAFIO_ROUTE}/${cloned.data.desafio._id}`);
		} catch {
			toast.error('Não foi possivel clonar o desafio');
		}
	};

	if (called && loading) {
		return (
			<>
				<NavHeader headerStyle={{ padding: '20px 5.5% 10px 5.5%' }} />
				<LinearProgress />
			</>
		);
	}
	if (error) {
		return <div>Error</div>;
	}

	return (
		<>
			<NavHeader headerStyle={{ padding: '20px 5.5% 10px 5.5%' }} />
			<Container maxWidth="xl" className={classes.container}>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
					className={classes.headerTitle}
				>
					<Button
						className={classes.backButton}
						onClick={() => {
							navigate(LISTAR_DESAFIOS_ROUTE);
						}}
						startIcon={<ArrowBack />}
					>
						Voltar
					</Button>

					<Typography variant="h4" className={classes.title}>
						Cadastrar Desafio
					</Typography>
					<Chip
						label={data?.desafio.disponivel ? 'Disponível' : 'Indisponível'}
						color={data?.desafio.disponivel ? 'primary' : 'secondary'}
					/>
				</Grid>

				<DesafioForm
					desafio={data?.desafio ? data.desafio : {}}
					checkDesafioResponse={dataCountdesafio?.isDesafioUpdateLocked}
					onClickCampanha={openCampanhaDialog}
					planos={planos}
				/>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
					className={classes.row}
				>
					<Typography variant="h4" className={classes.title}>
						Questões do Desafio
					</Typography>
					<Button
						data-testid="salvar-button"
						variant="contained"
						className={classes.button}
						size="large"
						startIcon={<QuestionAnswerIcon />}
						type="submit"
						disabled={
							!params.desafioId || dataCountdesafio?.isDesafioUpdateLocked
						}
						onClick={handleOpen}
					>
						Selecionar do Banco
					</Button>
					<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
						<DesafioConfirmClone
							handleClose={onDialogChange}
							open={openDialog}
							handleSubmit={handleSubmit}
						/>
						<Button
							data-testid="salvar-button"
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<FileCopyIcon />}
							type="submit"
							onClick={() => setDialog(true)}
						>
							Clonar Desafio
						</Button>
					</UserWrapper>
					<Button
						data-testid="salvar-button"
						variant="contained"
						className={classes.button}
						size="large"
						startIcon={<AddIcon />}
						type="submit"
						disabled={
							!params.desafioId || dataCountdesafio?.isDesafioUpdateLocked
						}
						onClick={() =>
							navigate(`${CADASTRAR_DESAFIO_ROUTE}/${params.desafioId}/questao`)
						}
					>
						Adicionar Questão
					</Button>
					<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
						<Button
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<PublishIcon />}
							type="submit"
							disabled={
								!params.desafioId || dataCountdesafio?.isDesafioUpdateLocked
							}
							onClick={() => handlePublish(data?.desafio.disponivel)}
						>
							{data?.desafio.disponivel
								? 'Despublicar Desafio'
								: 'Publicar Desafio'}
						</Button>
					</UserWrapper>
				</Grid>
				<CampanhaDialog
					open={isCampanhaOpen}
					handleClose={closeCampanhaDialog}
					desafio={data?.desafio ? data.desafio : {}}
				/>
				<CheckQuestionProvider
					desafioQuestoes={data ? data.desafio.questoes : []}
					setSelecionadas={setSelecionadas}
					selecionadas={selecionadas}
				>
					<>
						<DesafioDialog
							open={open}
							desafioId={params.desafioId as string}
							refetch={refetch}
							handleClose={handleClose}
						/>
						{!params.desafioId ? null : (
							<TabelaQuestoes
								refetch={refetch}
								desafioId={params.desafioId}
								questoes={data ? data.desafio.questoes : []}
							/>
						)}
					</>
				</CheckQuestionProvider>
			</Container>
		</>
	);
};

export default CadastrarDesafio;
