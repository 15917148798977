import client from './api';
import { REFRESH_TOKEN } from './mutations';
import { getLocalStorage } from '../helpers';
import store from '../store/store';

const ERROR_TOKEN_NOT_FOUND = 'not found storage';

export const getRefreshToken = () =>
	new Promise(async (resolve, reject) => {
		try {
			await store.dispatch.auth.setFetchRefreshToken();
			const loginCadUser = getLocalStorage(
				process.env.REACT_APP_USER_LOGIN_STORAGE_KEY,
			);
			if (!loginCadUser) {
				throw new Error(ERROR_TOKEN_NOT_FOUND);
			}
			const {
				data: { refreshToken },
			} = await client.query({
				query: REFRESH_TOKEN,
				variables: { token: loginCadUser.token },
				fetchPolicy: 'no-cache',
			});
			localStorage.setItem(
				process.env.REACT_APP_USER_LOGIN_STORAGE_KEY,
				JSON.stringify({
					...(loginCadUser || {}),
					token: refreshToken,
				}),
			);
			await store.dispatch.auth.setUser({
				token: refreshToken,
			});
			resolve(refreshToken);
		} catch (error) {
			if (error) await store.dispatch.auth.setTokenInvalid();
			else await store.dispatch.auth.finalyFetchRefreshToken();
			reject(error);
		}
	});
