import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { gql } from 'apollo-boost';
import { FetchResult } from '@apollo/client';
import { RootModel } from '..';
import api from '../../../services/api';
import { IChangeFormPayload } from './types';

export const INITIAL_STATE = {
	loading: false,
};

export const assuntosForm = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		changeFormProps: (state, props: IChangeFormPayload) => ({
			...state,
			...props,
		}),
		clearFields: () => ({ ...INITIAL_STATE }),
	},
	effects: dispatch => ({
		postAssunto: async assunto => {
			try {
				dispatch.assuntosForm.changeFormProps({ loading: true });

				if (assunto._id) {
					// Atualizar assunto por _id
					const mutation = gql`
						mutation updateAssunto($_id: ID!, $assunto: InputAssunto!) {
							updateAssunto(_id: $_id, assunto: $assunto) {
								_id
							}
						}
					`;

					const variables = {
						assunto: { nome: assunto.name, materia: assunto.materia.label },
						_id: assunto._id,
					};

					await api.mutate({ mutation, variables });
				} else {
					// Criar novo assunto
					const mutation = gql`
						mutation createAssunto($nome: String!, $materia: String!) {
							createAssunto(nome: $nome, materia: $materia) {
								_id
								nome
								materia
							}
						}
					`;

					const variables = {
						nome: assunto.name,
						materia: assunto.materia.label,
					};

					const {
						data,
					}: FetchResult<{
						createAssunto: {
							_id: string;
							materia: string;
							nome: string;
						};
					}> = await api.mutate({
						mutation,
						variables,
					});

					return {
						_id: data?.createAssunto._id,
						materia: data?.createAssunto.materia,
						nome: data?.createAssunto.nome,
					};
				}

				window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				return true;
			} catch (error: any) {
				if (error.graphQLErrors && error.graphQLErrors.length > 0) {
					toast.error(error.graphQLErrors[0].message);
				} else {
					toast.error('Houve um erro ao tentar salvar Assunto');
				}
				return false;
			} finally {
				dispatch.assuntosForm.changeFormProps({ loading: false });
			}
		},
	}),
});
