import React, { useState, useEffect } from 'react';
import {
	Alert,
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiPlus, mdiCloseCircle } from '@mdi/js';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Select from 'react-select';

import { FiltroQuestao, ItemQuestao } from 'components';
import api from 'services/api';
import { GET_QUESTOES } from 'services/queries';

const HeaderRow = styled(Row)`
	margin: 1px;
	margin-bottom: 3px;
	font-size: 17px;
	font-weight: bold;
`;

const HeaderCol = styled(Col)`
	padding-bottom: 10px;
	padding-top: 10px;
`;

const FilterButton = styled(Button)`
	margin-top: 37px;
`;

const ListaQuestoes = () => {
	const [perPage, setPerPage] = useState(20);

	const perPageOptions = [
		{ label: '20', value: 20 },
		{ label: '40', value: 40 },
		{ label: '60', value: 60 },
	];

	const [filterOptions, setFilterOptions] = useState({
		fontes: [],
		vestibulares: [],
		materias: [],
		assuntos: [],
		palavrasChave: [],
	});

	const initialFilterValues = {
		fonte: null,
		vestibular: null,
		materia: null,
		assunto: null,
		palavraChave: null,
	};
	const [filterValues, setFilterValues] = useState(initialFilterValues);

	useEffect(() => {
		const updateOptions = async () => {
			/**
			 * Atualizar opções de Filtros
			 */
			const query = gql`
				{
					fontes {
						_id
						nome
					}
					palavrasChave {
						_id
						nome
					}
					vestibulares {
						_id
						nome
					}
					materias {
						_id
						nome
					}
				}
			`;
			const { data } = await api.query({ query });

			// Formatar dados de opções de acordo com o formato do React-Select
			await setFilterOptions({
				fontes: data.fontes.map(fonte => ({
					label: fonte.nome,
					value: fonte._id,
					fonte,
				})),
				palavrasChave: data.palavrasChave.map(palavraChave => ({
					label: palavraChave.nome,
					value: palavraChave._id,
					palavraChave,
				})),
				vestibulares: data.vestibulares.map(vestibular => ({
					label: vestibular.nome,
					value: vestibular._id,
					vestibular,
				})),
				materias: data.materias.map(materia => ({
					label: materia.nome,
					value: materia._id,
					materia,
				})),
			});
		};
		updateOptions();
	}, []);

	useEffect(() => {
		const updateAssuntos = async () => {
			/**
			 * Atualizar opções de Assuntos sempre que o valor do
			 * Filtro de Matéria for atualizado
			 */
			const query = gql`
				query getAssuntos($materia: String!) {
					assuntos(materia: $materia) {
						_id
						nome
					}
				}
			`;

			const variables = { materia: filterValues.materia.materia.nome };

			const { data } = await api.query({ query, variables });

			setFilterOptions(oldOptions => ({
				...oldOptions,
				assuntos: data.assuntos.map(assunto => ({
					label: assunto.nome,
					value: assunto._id,
					assunto,
				})),
			}));
		};

		if (filterValues.materia) {
			updateAssuntos();
		} else {
			// Limpar opções de assuntos caso não haja matéria selecionada
			setFilterOptions(oldOptions => ({ ...oldOptions, assuntos: [] }));
		}
	}, [filterValues.materia]);

	const onChangeFiltro = (value, name) => {
		/**
		 * Atualizar Valores de Filtros de Pesquisa
		 */
		setFilterValues(oldFilterValues => ({ ...oldFilterValues, [name]: value }));
	};

	const clearFilterValues = () => {
		/**
		 * Limpar todos os Valores de Filtros de Pesquisa
		 */
		setFilterValues(initialFilterValues);
	};

	const { vestibular, palavraChave, fonte, materia, assunto } = filterValues;
	const variables = {
		skip: 0,
		limit: perPage,
		vestibular: vestibular ? vestibular.vestibular.nome : null,
		palavraChave: palavraChave ? palavraChave.palavraChave.nome : null,
		fonte: fonte ? fonte.fonte.nome : null,
		materia: materia ? materia.materia.nome : null,
		assunto: assunto ? assunto.assunto.nome : null,
	};

	const { data, fetchMore } = useQuery(GET_QUESTOES, {
		variables,
		fetchPolicy: 'cache-and-network',
	});

	const carregarMaisQuestoes = () => {
		/**
		 * Carregar mais Questões na Lista
		 */

		const updateQuery = (previousData, { fetchMoreResult }) => {
			/**
			 * Atualizar os dados de `data.questoes` do resultado da Query com
			 * as novas Questões carregadas, mas também manter as antigas
			 *
			 * https://www.apollographql.com/docs/react/data/pagination/#offset-based
			 */
			if (!fetchMoreResult) return previousData;

			return {
				...previousData,
				questoes: [...previousData.questoes, ...fetchMoreResult.questoes],
			};
		};

		fetchMore({
			variables: { ...variables, skip: data.questoes.length },
			updateQuery,
		});
	};

	const updatePerPage = value =>
		/**
		 * Atualizar quantidade de Questões adicionadas ao clicar
		 * no Botão 'Mais Questões'
		 */
		setPerPage(value.value);

	const hasQuestions = data ? data.questoes.length > 0 : false;

	return (
		<Card className="mb-2">
			<CardHeader className="font-weight-bold">
				Questões
				<Button
					tag={Link}
					to="/nova-questao"
					className="float-right"
					size="sm"
					color="success"
				>
					Nova Questão
					<Icon className="ml-2" path={mdiPlus} size={0.8} color="white" />
				</Button>
			</CardHeader>

			<CardBody>
				<>
					<Row className="mb-3">
						<Col xs="12" sm="6" md="4">
							<FiltroQuestao
								label="Vestibular"
								name="vestibular"
								options={filterOptions.vestibulares}
								value={filterValues.vestibular}
								onChange={onChangeFiltro}
							/>
						</Col>
						<Col xs="12" sm="6" md="4">
							<FiltroQuestao
								label="Palavra Chave"
								name="palavraChave"
								noData="Selecione um Vestibular primeiro"
								options={filterOptions.palavrasChave}
								value={filterValues.palavraChave}
								onChange={onChangeFiltro}
							/>
						</Col>
						<Col xs="12" sm="6" md="4">
							<FiltroQuestao
								label="Fonte"
								name="fonte"
								options={filterOptions.fontes}
								value={filterValues.fonte}
								onChange={onChangeFiltro}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col xs="12" sm="4">
							<FiltroQuestao
								label="Matéria"
								name="materia"
								options={filterOptions.materias}
								value={filterValues.materia}
								onChange={onChangeFiltro}
							/>
						</Col>
						<Col xs="12" sm="4">
							<FiltroQuestao
								label="Assunto"
								name="assunto"
								options={filterOptions.assuntos}
								value={filterValues.assunto}
								noData="Selecione uma Matéria primeiro"
								onChange={onChangeFiltro}
							/>
						</Col>
						<Col xl="4" lg="12" sm="12" md="12" xs="12">
							<FilterButton block onClick={clearFilterValues}>
								Limpar Campos
								<Icon
									className="ml-2"
									path={mdiCloseCircle}
									size={0.8}
									color="white"
								/>
							</FilterButton>
						</Col>
					</Row>
				</>

				<hr />

				{hasQuestions || (
					<Alert color="danger">Nenhuma questão encontrada</Alert>
				)}
				{hasQuestions && (
					<>
						<HeaderRow>
							<HeaderCol lg="1" md="1" sm="1" xs="1">
								#
							</HeaderCol>
							<HeaderCol lg="3" md="3" sm="3" xs="3">
								Palavra Chave
							</HeaderCol>
							<HeaderCol lg="2" md="3" sm="3" xs="3">
								Matéria
							</HeaderCol>
							<HeaderCol lg="2" md="2" sm="2" className="d-none d-sm-block">
								Fonte
							</HeaderCol>
							<HeaderCol lg="2" className="d-none d-lg-block">
								Ano
							</HeaderCol>
							<HeaderCol lg="2" md="3" sm="3" xs="5">
								Ações
							</HeaderCol>
						</HeaderRow>
						{data.questoes.map((questao, index) => (
							<ItemQuestao index={index} questao={questao} key={questao._id} />
						))}
					</>
				)}
			</CardBody>

			{hasQuestions && (
				<CardFooter>
					<Row>
						<Col lg="10" sm="6">
							<Button
								block
								size="lg"
								color="primary"
								onClick={carregarMaisQuestoes}
							>
								Mais Questões ({perPage})
								<Icon
									className="ml-2"
									path={mdiPlus}
									size={1.2}
									color="white"
								/>
							</Button>
						</Col>
						<Col lg="2" sm="6">
							<Select
								className="mt-1"
								menuPlacement="auto"
								options={perPageOptions}
								onChange={updatePerPage}
								value={{ label: `Carregar mais: ${perPage}`, value: perPage }}
							/>
						</Col>
					</Row>
				</CardFooter>
			)}
		</Card>
	);
};

export default ListaQuestoes;
