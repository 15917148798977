import React from 'react';
import { withStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import TableCell from '@material-ui/core/TableCell';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

export const TableHeader: React.FC = () => (
	<TableHead>
		<TableRow>
			<StyledTableCell align="center">
				<CheckBoxIcon />
			</StyledTableCell>
			<StyledTableCell>Vestibular</StyledTableCell>
			<StyledTableCell align="center">Ano</StyledTableCell>
			<StyledTableCell align="center">Fonte</StyledTableCell>
			<StyledTableCell align="center">Materia</StyledTableCell>
			<StyledTableCell align="center">Numero da Questão</StyledTableCell>
			<StyledTableCell align="center">Assertividade</StyledTableCell>
			<StyledTableCell align="center">Assuntos</StyledTableCell>
			<StyledTableCell align="center">Data de Criação</StyledTableCell>
			<StyledTableCell align="center">
				<ArrowDropDownCircleIcon />
			</StyledTableCell>
		</TableRow>
	</TableHead>
);
