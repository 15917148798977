import { MenuProps } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuOptionsProps: Partial<MenuProps> = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	placeholder: 'Selecione uma permissão',
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'center',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
	variant: 'menu',
};

export const options = [
	{
		description: 'Premium',
		level: 2,
	},
	{
		description: 'Freemium',
		level: 1,
	},
];
