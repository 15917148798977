import { makeStyles } from '@material-ui/core/styles';
import { primaryBlue } from 'constants/colors';

export const useStyles = makeStyles({
	header: {
		backgroundColor: primaryBlue,
		height: '80px',
		padding: '20px 10% 10px 10%',
	},
	headerItens: { backgorundColor: 'pink' },
	menuIten: {
		padding: '10px',
		color: 'white',
		borderRadius: '3px',
		textDecoration: 'none',
		fontSize: 16,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#7C8AA0',
			textDecoration: 'none',
			color: 'white',
		},
	},
});
