import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

export const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);
