import { ALL_VESTIBULARES } from 'helpers/vestibular';

export const CampanhaObjetivosItemsOptions = {
	vestibular: {
		key: 'vestibular',
		title: 'Vestibulares',
		items: ALL_VESTIBULARES,
	},
	materia: {
		key: 'materia',
		title: 'Matérias',
		items: [
			'Matemática',
			'Português',
			'História',
			'Física',
			'Biologia',
			'Química',
			'Geografia',
			'Filosofia',
			'Sociologia',
			'Inglês',
			'Espanhol',
		],
	},
};

export const generateObjetivosLabel = () => {
	const materias = CampanhaObjetivosItemsOptions.materia.items.map(text => ({
		label: text,
		value: text,
	}));
	const vestibulares = CampanhaObjetivosItemsOptions.vestibular.items.map(
		text => ({
			label: text,
			value: text,
		}),
	);

	return {
		materias,
		vestibulares,
	};
};
