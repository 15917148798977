import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	chip: {
		backgroundColor: '#4DA35F',
		color: 'white',
	},
	button: {
		// margin: theme.spacing(1),
		fontSize: theme.typography.pxToRem(13),
		backgroundColor: '#363636',
		border: 0,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#262626',
			textDecoration: 'none',
		},
	},
	card: {
		flexDirection: 'column',
		marginBottom: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
	},
	cardData: {
		display: 'flex',
		justifyContent: 'space-evenly',
		alignSelf: 'center',
	},
	cardBtn: {
		justifyContent: 'flex-end',
		alignSelf: 'center',
	},
	vestibular: {
		fontWeight: 500,
	},
	item: {
		color: '#8190A7',
	},
	scoreBaixo: {
		color: '#f44336',
		fontWeight: 'bold',
	},
	scoreMedio: {
		color: '#ff9800',
		fontWeight: 'bold',
	},
	scoreAlto: {
		color: '#4caf50',
		fontWeight: 'bold',
	},
	reportRow: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: 20,
	},
	reportLabel: {
		fontSize: 12,
		fontWeight: 'lighter',
		marginRight: 10,
	},
	reportText: {
		fontSize: 12,
		marginRight: 10,
	},
}));
