import React, { useCallback } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { NotificationFeedAction } from '../types';
import ActionItem from './action-item';

const ActionsNotification: React.FC<{
	actions: NotificationFeedAction[];
	onChange: (values: NotificationFeedAction[]) => void;
}> = ({ actions, onChange }) => {
	const handleOnAdd = useCallback(() => {
		onChange([
			{
				label: 'Action Button',
				link: '',
			},
			...actions,
		]);
	}, [actions, onChange]);

	const handleOnChange = useCallback(
		({
			index,
			action,
			name,
			value,
		}: {
			index: number;
			name: string;
			value: string;
			action: NotificationFeedAction;
		}) => {
			onChange([
				...actions?.slice(0, index),
				{
					...action,
					[name]: value,
				},
				...actions?.slice(index + 1, actions.length),
			]);
		},
		[actions, onChange],
	);

	const handleOnRemove = useCallback(
		(index: number) => {
			onChange([
				...actions?.slice(0, index),
				...actions?.slice(index + 1, actions.length),
			]);
		},
		[actions, onChange],
	);
	return (
		<>
			<Grid container spacing={1} alignItems="center">
				<Grid item spacing={2} xs="auto">
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h6"
						component="h5"
						color="initial"
					>
						Actions
					</Typography>
				</Grid>
				<Grid item spacing={1} xs="auto">
					<IconButton onClick={handleOnAdd}>
						<AddIcon />
					</IconButton>
				</Grid>
			</Grid>
			{actions.map((action, index) => (
				<ActionItem
					key={`action-${index}`}
					action={action}
					index={index}
					handleOnChange={handleOnChange}
					onRemove={handleOnRemove}
				/>
			))}
		</>
	);
};

export default ActionsNotification;
