import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormControl, TextField } from '@material-ui/core';

const FormCompletePurchaseDialog: React.FC<{
	open: boolean;
	handleClose: () => void;
	handleSubmit: (sku: string, purchaseToken: string) => void;
}> = ({ handleSubmit, handleClose, open }) => {
	const [{ sku, purchaseToken }, setValues] = useState({
		sku: '',
		purchaseToken: '',
	});

	const onSubmit = () => {
		if (!purchaseToken || !sku) return;
		handleSubmit(sku, purchaseToken);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Você tem certeza que deseja continuar ?
					</DialogContentText>
				</DialogContent>
				<FormControl variant="outlined" fullWidth>
					<TextField
						id="sku"
						type="text"
						label="SKU - br_fbvest_premium_monthly"
						variant="outlined"
						placeholder="Digite o SKU (Produto ID)"
						value={sku}
						onChange={({ target: { value } }) => {
							setValues(values => ({
								...values,
								sku: value,
							}));
						}}
					/>
				</FormControl>
				<FormControl variant="outlined" fullWidth>
					<TextField
						id="purchaseToken"
						type="text"
						label="Purchase Token"
						variant="outlined"
						placeholder="Digite o purchaseToken"
						value={purchaseToken}
						onChange={({ target: { value } }) => {
							setValues(values => ({
								...values,
								purchaseToken: value,
							}));
						}}
					/>
				</FormControl>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={onSubmit}
						variant="contained"
						color="primary"
						autoFocus
						disabled={sku.length < 5 || purchaseToken.length < 10}
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default FormCompletePurchaseDialog;
