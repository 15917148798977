import React, { Component } from 'react';
import { Row, Col, Button, ListGroupItem } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiPencil, mdiDelete } from '@mdi/js';
import PropTypes from 'prop-types';

class ListaCadAssuntosItem extends Component {
	render() {
		const { assunto, onEditClick, onDeleteClick } = this.props;

		return (
			<ListGroupItem>
				<Row>
					<Col xs="5" sm="8" md="8">
						{assunto.nome}
					</Col>
					<Col xs="7" sm="4" md="4">
						<Button
							color="danger"
							className="ml-2 float-right"
							onClick={onDeleteClick}
						>
							<Icon path={mdiDelete} size={0.8} color="white" />
						</Button>
						<Button
							color="primary"
							className="float-right"
							onClick={onEditClick}
						>
							<Icon path={mdiPencil} size={0.8} color="white" />
						</Button>
					</Col>
				</Row>
			</ListGroupItem>
		);
	}
}

ListaCadAssuntosItem.propTypes = {
	assunto: PropTypes.shape({
		_id: PropTypes.string,
		assuntoId: PropTypes.number,
		assunto: PropTypes.string,
		materia: PropTypes.string,
	}),
	onEditClick: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func,
};

export default ListaCadAssuntosItem;
