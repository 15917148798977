import React, { useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Navigate } from 'react-router-dom';
import { UsersContext } from 'screens/DashboardParceiros/provider';
import { useQuery } from '@apollo/client';
import { FETCH_PLANOS } from 'screens/DashboardParceiros/api/queries';
import {
	primaryGreen,
	primaryBlue,
	oraColor,
	tixColor,
	primaryOrange,
} from 'constants/colors';
import { useStyles } from './styles';
import { UsersCard } from '..';
import { countPlano } from '../../helpers';

const CardRow: React.FC = () => {
	const classes = useStyles();
	const { users, loadingUsers, usersError } = useContext(UsersContext);
	const { data, loading, error } = useQuery(FETCH_PLANOS);
	if (loadingUsers) return <LinearProgress />;
	if (loading) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;
	if (error) return <Navigate to="/" />;

	const { plano } = users.usersStats.lastDay;

	const renderCards = () => {
		let colors = [primaryBlue, primaryGreen, oraColor, tixColor, primaryOrange];
		const cards = data.planos.map((planoN: any, n: number) => {
			let color = colors[n % 5];
			let nomePlano = planoN.name;
			let index = nomePlano.indexOf(' ') > 0 ? nomePlano.indexOf(' ') : 0;
			index =
				nomePlano.indexOf('&') > 0 && index === 0
					? nomePlano.indexOf('&')
					: index;
			index = index || 8;
			nomePlano = nomePlano.substring(0, index);
			return (
				<Grid item lg={3}>
					<UsersCard
						avatarTitle={nomePlano}
						color={color}
						title={planoN.name}
						number={countPlano(plano, planoN.name)}
					/>
				</Grid>
			);
		});

		return cards;
	};
	return (
		<Grid className={classes.container} container spacing={2}>
			{renderCards()}
		</Grid>
	);
};

export default CardRow;
