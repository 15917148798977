export const anoOptions = [
	{ label: 'ANO', value: 0 },
	{ label: '2021', value: 2021 },
	{ label: '2020', value: 2020 },
	{ label: '2019', value: 2019 },
	{ label: '2018', value: 2018 },
	{ label: '2017', value: 2017 },
	{ label: '2016', value: 2016 },
	{ label: '2015', value: 2015 },
	{ label: '2014', value: 2014 },
	{ label: '2013', value: 2013 },
	{ label: '2012', value: 2012 },
];

export const ascOptions = [
	{ label: 'ORDENAR', value: 0 },
	{ label: 'CRESCENTE', value: true },
	{ label: 'DECRESCENTE', value: false },
];

export const getMateriaOptions = ({ materias }) => [
	{ label: 'MATERIAS', value: 0 },
	...materias.map(materia => ({
		label: materia.nome,
		value: materia.nome,
	})),
];

export const getVestibularOptions = ({ vestibulares }) => [
	{ label: 'VESTIBULARES', value: 0 },
	...vestibulares.map(vestibular => ({
		label: vestibular.nome,
		value: vestibular.nome,
	})),
];

export const getFontesOptions = ({ fontes }) => [
	{ label: 'FONTES', value: 0 },
	...fontes.map(fonte => ({
		label: fonte.nome,
		value: fonte.nome,
	})),
];
