import { gql } from '@apollo/client';

export const FETCH_PLANOS = gql`
	{
		planos {
			_id
			name
			parceiro
			validationFields {
				name
				label
				description
				placeholder
			}
			active
		}
	}
`;

export const LIST_USERS = gql`
	query listUsers($filters: listUsersFilters) {
		listUsers(filters: $filters) {
			_id
			displayName
			email
			phoneNumber
			createdAt
			providers {
				providerId
			}
			customData {
				deviceInfo {
					platform
				}
			}
			customClaims {
				level
				subscriptions {
					parceiro
					nome
				}
			}
		}
		usersStats(filters: $filters) {
			ativos
			premium
			plano {
				nome
				valor
			}
			parceiro {
				nome
				valor
			}
			plataforma {
				nome
				valor
			}
			login {
				nome
				valor
			}
			lastDay {
				ativos
				premium
				plano {
					nome
					valor
				}
				parceiro {
					nome
					valor
				}
				plataforma {
					nome
					valor
				}
				login {
					nome
					valor
				}
			}
		}
	}
`;
