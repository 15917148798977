import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

function SimuladoDropdown({ options, value, onChange }) {
	const classes = useStyles();
	return (
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				className={classes.select}
				value={value}
				onChange={event => onChange(event.target.value)}
			>
				{options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

SimuladoDropdown.propTypes = {
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SimuladoDropdown;
