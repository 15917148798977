import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function AlertDialog({ handleSubmit, handleClose, open }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Você tem certeza que deseja finalizar a revisão?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={handleSubmit}
						variant="contained"
						color="primary"
						autoFocus
					>
						Concluir
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
