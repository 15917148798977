import { formatDate } from './formatDate';

const GeneralInfo = {
	createdAt: 'Cadastro em:',
	updateAt: 'Atualizado em:',
	startedAt: 'Inciado em:',
	expireAt: 'Expirado em:',
	canceledAt: 'Cancelado em:',
	lastLoginAt: 'Último login em:',
	lastAction: 'Última ação em:',
};

export const formatGeneralValues = (defatulValues, renderLine) =>
	Object.keys(GeneralInfo).reduce((values, key) => {
		if (defatulValues[key]) {
			return [
				...values,
				renderLine(GeneralInfo[key], formatDate(defatulValues[key]), key),
			];
		}
		return values;
	}, []);
