import React, { useState } from 'react';

import { Container } from '@material-ui/core';

import { NavHeader } from 'components';
import { useStyles } from './styles';
import { CardRow, Filters, UsersChart, UserTable } from './components';
import UsersProvider from './provider';
import { ParceirosFilters } from './types';

const DashboardParceiros: React.FC = () => {
	const classes = useStyles();
	const [filtersOut, setFilters] = useState<ParceirosFilters>();
	return (
		<Container className={classes.container} maxWidth="xl" disableGutters>
			<NavHeader headerStyle={{ paddingRight: '4.7%', paddingLeft: '4.2%' }} />
			<Container className={classes.body}>
				<UsersProvider setFilters={setFilters}>
					<>
						<h3>Ativações nas últimas 24 Horas </h3>
						<hr />
						<CardRow />

						<h3>Pesquisa </h3>
						<hr />
						<Filters />
						<hr />
						<h3>Resultados </h3>
						<hr />
						<UsersChart />
					</>
				</UsersProvider>
				<UsersProvider filtersOut={filtersOut} setFilters={setFilters} update>
					<>
						<UserTable />
					</>
				</UsersProvider>
			</Container>
		</Container>
	);
};

export default DashboardParceiros;
