import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	List,
	CircularProgress,
	Typography,
	Grid,
	Button,
	Card,
	Container,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import FilterListIcon from '@material-ui/icons/FilterList';
import { AddCircleOutline, ArrowBack } from '@material-ui/icons';
import UserWrapper from 'screens/wrappers/UserWrapper';
import UserRoles from 'types/user';
import { NavHeader } from 'components';
import SimuladoCard from '../SimuladoCard';
import SimuladoDropdown from '../SimuladoDropdown';
import SimuladoContext from '../../providers/SimuladoProvider';
import { anoOptions, vestibularOptions } from '../../constants';
import { useStyles } from './styles';
import { ISimulado } from '../../types';
import { CADASTRAR_SIMULADO } from '../../../../constants';

export default function LitarSimulados() {
	const { ADMINISTRADOR } = UserRoles;
	const navigate = useNavigate();
	const classes = useStyles();
	const {
		data,
		loading,
		error,
		ano,
		setAno,
		vestibular,
		setVestibular,
		listarSimulados,
		makeVariables,
	} = useContext(SimuladoContext);

	if (loading) {
		return <CircularProgress />;
	}
	if (error) {
		return <div>Error</div>;
	}

	return (
		<Container className={classes.container} maxWidth="xl" disableGutters>
			<NavHeader />
			<div className={classes.body}>
				<Grid
					className={classes.titleRow}
					container
					direction="row"
					alignItems="flex-end"
				>
					<UserWrapper roles={[ADMINISTRADOR]}>
						<Grid item className={classes.backButtonGrid}>
							<Button
								onClick={() => {
									navigate(-1);
								}}
								startIcon={<ArrowBack />}
							>
								Voltar
							</Button>
						</Grid>
					</UserWrapper>
					<Grid item className={classes.titleContainer}>
						<Typography className={classes.title} variant="h4">
							Lista de Simulados
						</Typography>
					</Grid>
				</Grid>

				<Card className={classes.registerCard}>
					<Grid container>
						<Grid className={classes.dropdownRow} item lg={6}>
							<SimuladoDropdown
								value={ano}
								onChange={setAno}
								options={anoOptions}
							/>

							<SimuladoDropdown
								value={vestibular}
								onChange={setVestibular}
								options={vestibularOptions}
							/>

							<Button
								variant="contained"
								color="primary"
								className={classes.filterButton}
								startIcon={<FilterListIcon />}
								onClick={() => {
									const variables = makeVariables();
									listarSimulados(variables);
								}}
							>
								Filtrar
							</Button>
						</Grid>
						<Grid className={classes.simuladoButtonRow} item lg={6}>
							<Button
								className={classes.registerButton}
								variant="contained"
								startIcon={<AddCircleOutline />}
								size="large"
								onClick={() => navigate(CADASTRAR_SIMULADO)}
							>
								SIMULADO
							</Button>
						</Grid>
					</Grid>
				</Card>

				<List className={classes.list}>
					{data ? (
						data.simuladosAdmin.map((simulado: ISimulado) => (
							<SimuladoCard key={simulado._id} simulado={simulado} />
						))
					) : (
						<Grid container direction="row" justify="center">
							<Typography variant="h6" gutterBottom>
								Faça uma busca para localizar simulados!
							</Typography>
						</Grid>
					)}
				</List>

				<Pagination
					count={data ? Math.ceil(data.simuladosAdmin.length / 20) : 1}
					onChange={(event, value) => console.log(value)}
					variant="outlined"
					color="primary"
					size="small"
				/>
			</div>
		</Container>
	);
}
