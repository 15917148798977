import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHeader from './TableHeader';
import Row from './Row';

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	button: { color: 'white' },
});

interface Props {
	questoes: any[];
	desafioId: string;
	refetch: any;
	deleteCheckbox?: boolean;
	prova?: number;
	selectQuestion?: (selected: any) => void;
}

export default function CustomizedTables({
	questoes,
	desafioId,
	refetch,
	deleteCheckbox,
	selectQuestion,
	prova,
}: Props) {
	const classes = useStyles();
	const [selectedQuestion, setSelectedQuestion] = useState([]);

	useEffect(() => {
		selectQuestion?.(selectedQuestion);
	}, [selectedQuestion, selectQuestion]);

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
				<TableHeader />
				<TableBody>
					{questoes?.map(questao => (
						<Row
							prova={prova}
							selectedQuestion={setSelectedQuestion}
							deleteCheckbox={deleteCheckbox}
							key={questao._id}
							refetch={refetch}
							questao={questao}
							desafioId={desafioId}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
