import moment from 'moment';
import { gql } from 'apollo-boost';

import { MATERIAS_DB, VESTIBULARES, AREA_DB, FONTE_DB } from './data';
import { ASSUNTOS_DB } from './assuntos';
import api from '../services/api';

export const isEmptyObject = object => {
	if (Object.entries(object).length === 0) {
		return true;
	}
	return false;
};
// React select functions
export const generateAnosToSelect = (year = moment().year()) => {
	// const anosAcima = [year, ...new Array(10)].reduce((il, it, index) => ([...il, it ? it + 1: il[index - 1] + 1]), []);
	const anosAbaixo = [year, ...new Array(20)].reduce(
		(il, it, index) => [...il, it ? it - 1 : il[index - 1] - 1],
		[],
	);
	const anos = [year, ...anosAbaixo].reduce(
		(il, it) => [...il, { value: it, label: it }],
		[],
	);
	return anos;
};

export const getMateriasToSelect = () => {
	const materias = MATERIAS_DB.map(it => ({
		label: it.materia,
		value: it._id,
	}));
	return materias;
};

export const getAssuntosByMateria = materia => {
	const assuntos = ASSUNTOS_DB.filter(it => it.materia === materia).map(it => ({
		label: it.assunto,
		value: it._id,
	}));
	return assuntos;
};

export const getAreasSelect = () =>
	AREA_DB.map(it => ({
		label: it.areaConhecimento,
		value: it.areaConhecimento,
		area: it,
	}));

export const getVestibularesOptions = () =>
	VESTIBULARES.map(it => ({ label: it.vestibular, value: it._id }));

export const getFontesOptions = () =>
	FONTE_DB.map(fonte => ({
		label: fonte.descricao,
		value: fonte.fonte,
		fonte,
	}));

export const getPalavrasChave = vestibular =>
	/**
	 * Retorna um array com os nomes únicos das Palavras Chaves de um Vestibular
	 */
	[...new Set(vestibular.palavrasChave.map(pc => pc.palavraChave))];

export const normalizarString = item =>
	/**
	 * Transforma string em lowercase e troca letras com acentos
	 * para letras sem acentos
	 *
	 * Ex.:
	 *  C -> c
	 *  Á -> a
	 *  ç -> c
	 *  õ -> o
	 *  RaÇãO -> racao
	 */
	item
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');

export const getAssuntosPorMateria = materia =>
	new Promise((resolve, reject) => {
		try {
			const query = gql`
				query assuntos($materia: [String]) {
					assuntos(materia: $materia) {
						assuntos {
							_id
							nome
							materia
						}
						materia
					}
				}
			`;

			const variables = {
				materia: [materia],
			};

			api.query({ query, variables }).then(response => {
				resolve(response.data.assuntos.assuntos);
			});
		} catch (err) {
			reject(err);
		}
	});

export const getAssuntosPorSearch = (assuntos, search) =>
	new Promise((resolve, reject) => {
		try {
			resolve(
				assuntos.filter(assunto =>
					normalizarString(assunto.assunto).includes(normalizarString(search)),
				),
			);
		} catch (err) {
			reject(err);
		}
	});

export const getAssuntosPorPage = (assuntos, currentPage = 0) =>
	new Promise((resolve, reject) => {
		try {
			resolve({
				assuntosPagina: assuntos.slice(
					currentPage * 20,
					(currentPage + 1) * 20,
				),
				length: assuntos.length,
			});
		} catch (err) {
			reject(err);
		}
	});

export const getItemsPerPage = (items, page, perPage = 10) =>
	/**
	 * Retorna slice de um array de itens dependendo da página atual e
	 * número de items por página
	 */
	items.slice(page * perPage, (page + 1) * perPage);

export const getMaxPages = (items, perPage = 10) =>
	/**
	 * Retorna o número máximo de páginas de um array de itens
	 * dependendo do seu tamanho e o número por página
	 */
	Math.ceil(items.length / perPage) - 1;

export const range = (from, to, step = 1) => {
	let i = from;
	const _range = [];

	while (i <= to) {
		_range.push(i);
		i += step;
	}

	return _range;
};

export const numberToLetter = (n, upper = true) => {
	/**
	 * Converte números para letras, começando de 0
	 *
	 * Ex.:
	 * 0 -> a (upper = false)
	 * 1 -> B (upper = true)
	 * 2 -> c (upper = false)
	 */
	const char = String.fromCharCode(97 + n);
	if (upper) {
		return char.toUpperCase();
	}
	return char;
};

export const mapQuestaoValues = values =>
	/**
	 * Reformata valores de uma Form de questão para o formato
	 * utilizado na API
	 */
	({
		ano: parseInt(values.ano.value, 10),
		areaConhecimento: values.areaConhecimento
			? {
					_id: values.areaConhecimento.area._id,
					nome: values.areaConhecimento.area.nome,
					competencias: values?.competenciasValues?.map(
						competencia => competencia.numero,
					),
					habilidades: values?.habilidadesValues?.map(
						habilidade => habilidade.numero,
					),
			  }
			: null,
		codigo: values.codigo,
		enunciado: values.enunciado,
		fonte: values.fonte.label,
		numeroQuestao: parseInt(values.numeroQuestao, 10),
		palavraChave: values?.palavraChave?.label || null,
		resolucao: values.resolucao,
		vestibular: values.vestibular.label,
		video: values.video ? values.video : null,
		materia: values.materia.label,
		alternativas: values.alternativas.map((alternativa, index) => ({
			letra: numberToLetter(index),
			texto: alternativa.texto,
			correta: alternativa.correta,
			_id: alternativa._id,
		})),
		assuntos: values.assuntos?.map(assunto => ({
			_id: assunto.assunto._id,
			nome: assunto.assunto.nome,
			materia: assunto.assunto.materia,
		})),
		disponivel: !!values.disponivel,
	});

export const indexCompetencias = area => {
	/**
	 * Reformatar o campo de competências em uma área, do formato abaixo:
	 * ```json
	 * "competencias": [
	 *   {
	 *     "numero": 1,
	 *     "nome": "C-01",
	 *     "descricao": "Aplicar (...)",
	 *     "habilidades": [
	 *       {
	 *         "numero": 1,
	 *         "nome": "H-01",
	 *         "descricao": "Identificar (...)"
	 *       }
	 *     ]
	 *   }
	 * ]
	 * ```
	 *
	 * Para o formato a seguir:
	 * ```json
	 * "competencias": {
	 *   1: {
	 *     "numero": 1,
	 *     "nome": "C-01",
	 *     "descricao": "Aplicar (...)",
	 *     "habilidades": {
	 *       1: {
	 *         "numero": 1,
	 *         "nome": "H-01",
	 *         "descricao": "Identificar (...)"
	 *       }
	 *     }
	 *   }
	 * }
	 * ```
	 *
	 * Para poder fazer uso de indexes como:
	 *
	 * ```js
	 * // Pegar a Habilidade 3 da Competência 1 em `area`
	 * area.competencias[1].habilidades[3]
	 * ```
	 */
	const competencias = area.competencias.reduce(
		(obj, comp) => ({
			...obj,
			[comp.numero]: {
				...comp,
				habilidades: comp.habilidades.reduce(
					(_obj, hab) => ({ ..._obj, [hab.numero]: hab }),
					{},
				),
			},
		}),
		{},
	);
	return competencias;
};

export const selectStyles = {
	/**
	 * https://github.com/JedWatson/react-select/issues/3030#issuecomment-494471427
	 */
	container: (base, state) => ({
		...base,
		zIndex: state.isFocused ? '999' : '1', // Only when current state focused
	}),
};

export const zeroPad = (number, length = 2) =>
	/**
	 * Coloca zero-padding nos números
	 * Com length padrão (2), formata número como abaixo:
	 *
	 * 5 -> '05'
	 * 1 -> '01'
	 * 10 -> '10'
	 * 15 -> '15'
	 */
	number.toString().padStart(length, '0');

export const formatCompetenciasHabilidades = (items, letter, separator = '/') =>
	/**
	 * Formata Array de Competências ou Habilidades em uma string separada por '/', zeroPadded e com letras na frente
	 *
	 * Exemplos:
	 *
	 * formatCompetenciasHabilidades([1, 2, 3], 'C') => 'C-01/C-02/C-03'
	 * formatCompetenciasHabilidades([12, 14], 'H') => 'H-12/H-14'
	 * formatCompetenciasHabilidades([15, 19], 'H', ', ') => 'H-15, H-19'
	 */
	items.map(item => `${letter}-${zeroPad(item)}`).join(separator);

export const getLocalStorage = key => {
	try {
		const storageKey = localStorage.getItem(key);
		if (!storageKey) throw new Error('Invalid key');
		return JSON.parse(storageKey);
	} catch (error) {
		return null;
	}
};

export const mapOrderByArray = (values, order, key) => {
	try {
		const array = values;
		const map = new Map();
		let index = 0;
		let tmp;
		if (!array || !order || array.length !== order.length) return array;
		array.forEach(it => {
			map.set(it[key], index);
			index += 1;
		});

		order.forEach(it => {
			if (map.get(it) === undefined) throw array;
		});
		index -= 1;
		for (; index >= 0; index -= 1) {
			if (array[index][key] !== order[index]) {
				tmp = array[index];
				array[index] = array[map.get(order[index])];
				array[map.get(order[index])] = tmp;
				map.set(tmp[key], map.get(order[index]));
			}
		}
		return array;
	} catch (error) {
		return values;
	}
};

export const createObjectFromArray = (list: string[], keys: string[]) => {
	const newItem = list.map(value => {
		const item = keys.reduce(
			(itemKeys, key) => ({ ...itemKeys, [key]: value }),
			{},
		);
		return item;
	});
	return newItem;
};
