import { gql } from '@apollo/client';

export const GET_ALL_CAD_USERS = gql`
	query GetAllCadUsers(
		$limit: Int
		$skip: Int
		$nome: String
		$email: String
		$username: String
		$role: String
	) {
		cadUsers(
			limit: $limit
			skip: $skip
			email: $email
			username: $username
			nome: $nome
			role: $role
		) {
			_id
			username
			nome
			role
			email
			active
		}
		countCadUsers
	}
`;
