/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { object, string, array } from 'yup';
import { toast } from 'react-toastify';
import { gql } from 'apollo-boost';
import moment from 'moment';
import {
	GET_QUESTOES_VESTIBULARES,
	COUNT_QUESTOES_HEADER,
} from 'screens/ListarQuestoes/queries';
import api from 'services/api';
import {
	generateAnosToSelect,
	indexCompetencias,
	zeroPad,
	numberToLetter,
	mapQuestaoValues,
} from 'helpers';
import {
	questoesNotEnemValidator,
	questoesEnemValidator,
} from 'components/questoes/FormCadQuestoes/FormCadQuestoes';
import QuestionContext from 'screens/ListarQuestoes/providers/QuestionProvider';
import {
	CREATE_QUESTAO_DESAFIO,
	QUESTAO_BY_ID,
	CREATE_QUESTAO_SIMULADO,
} from 'screens/CadastrarDesafio/api';
import { CADASTRAR_DESAFIO_ROUTE, LISTAR_SIMULADOS } from '../constants';

const areaConhecimentoFields = `
  _id
  nome
  materias {
    nome
  }
  competencias {
    numero
    nome
    descricao
    habilidades {
      numero
      nome
      descricao
    }
  }
`;

const GET_AREA_CONHECIMENTO = gql`
  query areaConhecimento($_id: ID!) {
    areaConhecimento(_id: $_id) {
      ${areaConhecimentoFields}
    }
  }
`;
const ALTERNATIVAS_INICIAIS = [
	{
		id: new Date().getTime(),
		texto: '',
		correta: false,
		letra: numberToLetter(0),
	},
	{
		id: new Date().getTime() + 1,
		texto: '',
		correta: false,
		letra: numberToLetter(1),
	},
	{
		id: new Date().getTime() + 2,
		texto: '',
		correta: false,
		letra: numberToLetter(2),
	},
	{
		id: new Date().getTime() + 3,
		texto: '',
		correta: false,
		letra: numberToLetter(3),
	},
];

export const useQuestaoForm = ({
	questao,
	isOpen,
	method,
	clearOnSubmit = false,
	toggle,
	desafioId,
}) => {
	const {
		materia,
		fonte,
		vestibular,
		palavraChave,
		codigo,
		assuntos,
		ano,
		areaConhecimento,
		resolucao,
		enunciado,
		alternativas,
		numeroQuestao,
		video,
		disponivel,
	} = questao;
	const navigate = useNavigate();
	const { refetch } = useContext(QuestionContext);
	const createMutation = gql`
		mutation createQuestao($questao: QuestaoInput!) {
			createQuestao(questao: $questao) {
				_id
			}
		}
	`;
	const mutation = gql`
		mutation updateQuestao($_id: ID!, $questao: AtualizarQuestaoInput!) {
			updateQuestao(_id: $_id, questao: $questao) {
				_id
			}
		}
	`;
	const REVIEW_QUESTAO = gql`
		mutation reviewQuestao($_id: String) {
			reviewQuestao(_id: $_id)
		}
	`;
	const [createQuestaoSimulado] = useMutation(CREATE_QUESTAO_SIMULADO);
	const [createQuestaoDesafio] = useMutation(CREATE_QUESTAO_DESAFIO);
	const [createQuestao] = useMutation(createMutation);
	const [reviewQuestao] = useMutation(REVIEW_QUESTAO);
	const [updateQuestao] = useMutation(mutation, {
		refetchQueries: [
			{ query: COUNT_QUESTOES_HEADER },
			{
				query: GET_QUESTOES_VESTIBULARES,
			},
		],
		onCompleted: refetch,
	});
	// Configurar valores iniciais para 'values', vindo da questão passada, se existir
	const initialValues = {
		...questao,
		areaConhecimento: areaConhecimento
			? {
					area: areaConhecimento,
					value: areaConhecimento._id,
					label: areaConhecimento.nome,
			  }
			: null,
		materia: materia ? { value: materia, label: materia } : null,
		fonte: fonte ? { value: fonte, label: fonte } : null,
		vestibular: vestibular ? { value: vestibular, label: vestibular } : null,
		palavraChave: palavraChave
			? { value: palavraChave, label: palavraChave }
			: null,
		ano: ano
			? { value: ano, label: ano }
			: { value: moment().year(), label: moment().year() },
		codigo: codigo || '',
		assuntos: assuntos
			? assuntos.map(assunto => ({
					assunto,
					value: assunto._id,
					label: assunto.nome,
			  }))
			: null,
		resolucao: resolucao || '',
		enunciado: enunciado || '',
		numeroQuestao: numeroQuestao || '',
		alternativas: alternativas || ALTERNATIVAS_INICIAIS,
		video: video || '',
		competenciasValues: questao.areaConhecimento
			? questao.areaConhecimento.competencias.map(competencia => ({
					numero: competencia,
					value: `C-${zeroPad(competencia)}`,
					label: `C-${zeroPad(competencia)}`,
			  }))
			: null,
		habilidadesValues: questao.areaConhecimento
			? questao.areaConhecimento.habilidades.map(habilidade => ({
					numero: habilidade,
					label: `H-${zeroPad(habilidade)}`,
					value: `H-${zeroPad(habilidade)}`,
			  }))
			: null,
		disponivel: !!disponivel,
	};

	const [values, setValues] = useState(initialValues);
	const [options, setOptions] = useState({});

	const [currentArea, setCurrentArea] = useState({});

	const getAssuntos = useCallback(async () => {
		/**
		 * Atualizar opções de assuntos ao trocar Matéria
		 */
		try {
			if (values.materia) {
				const query = gql`
					query assuntos($materia: [String]) {
						assuntos(materia: $materia) {
							assuntos {
								_id
								nome
								materia
							}
							materia
						}
					}
				`;

				const variables = {
					materia: [values.materia.label],
				};

				const { data } = await api.query({ query, variables });

				setOptions(opts => ({
					...opts,
					assuntosOptions: data?.assuntos[0]?.assuntos?.map(assunto => ({
						assunto,
						value: assunto._id,
						label: assunto.nome,
					})),
				}));
			}
		} catch (error) {
			toast.error('Erro ao tentar atualizar opções de Assuntos');
		}
	}, [values.materia]);

	const getCompetencias = useCallback(async () => {
		/**
		 * Atualiza opções de Competências baseado na Área de Conhecimento
		 */
		try {
			const query = GET_AREA_CONHECIMENTO;
			const variables = {
				_id: values.areaConhecimento.area._id,
			};

			const { data } = await api.query({ query, variables });

			// Atualiza estado da área de conhecimento atual, para pegar informações
			// de competências/habilidades etc.
			setCurrentArea(data.areaConhecimento);

			setOptions(opts => ({
				...opts,
				competencias: data.areaConhecimento.competencias.map(comp => ({
					competencia: comp,
					numero: comp.numero,
					value: comp.nome,
					label: comp.nome,
				})),
			}));
		} catch (error) {
			toast.error('Erro ao tentar atualizar opções de Competências');
		}
	}, [values.areaConhecimento]);

	useEffect(() => {
		/**
		 * Pegar valores de opções da API e inicializar valores padrões
		 * de acordo com os dados já existentes da questão.
		 * Também pega valores mais intuítivos com nome e descrição descrição para os campos
		 * de Competência e Habilidade da API
		 * (De: 'Competência: 1' para: 'Competência: C-01: Aplicar as tecnologias (...)')
		 *
		 * Só roda quando o Modal é renderizado
		 */
		async function getOptions() {
			try {
				const query = gql`
          {
            fontes {
              _id
              nome
            }
            vestibulares {
              _id
              nome
            }
            materias {
              _id
              nome
            }
            areasConhecimento {
              ${areaConhecimentoFields}
            }
          }
        `;

				const { data } = await api.query({ query });
				setOptions(opts => ({
					...opts,
					fontes: data.fontes.map(it => ({
						label: it.nome,
						value: it._id,
						fonte: it,
					})),
					vestibulares: data.vestibulares.map(it => ({
						label: it.nome,
						value: it._id,
						vestibular: it,
					})),
					materias: data.materias.map(it => ({
						label: it.nome,
						value: it._id,
						materia: it,
					})),
					areasConhecimento: data.areasConhecimento.map(it => ({
						label: it.nome,
						value: it._id,
						area: it,
						competencias: indexCompetencias(it),
					})),
					anos: generateAnosToSelect(),
				}));
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções para seleção');
			}
		}

		if (isOpen) {
			getOptions();
			if (areaConhecimento) {
				getCompetencias();
			}
		}
	}, [areaConhecimento, getCompetencias, values.vestibular, isOpen]);

	useEffect(() => {
		const fetchMateriasByArea = async () => {
			const queryMaterias = gql`
				query getMateriasByArea($id: ID!) {
					areaConhecimento(_id: $id) {
						_id
						nome
						materias {
							_id
							nome
						}
					}
				}
			`;
			const variables = {
				id: values.areaConhecimento.area._id,
			};
			const { data } = await api.query({ query: queryMaterias, variables });
			setOptions(prevOptions => ({
				...prevOptions,
				materias: data.areaConhecimento.materias.map(areaMateria => ({
					label: areaMateria.nome,
					value: areaMateria._id,
					materia: areaMateria,
				})),
			}));
		};

		if (values.areaConhecimento) {
			fetchMateriasByArea();
		}
	}, [values.areaConhecimento, isOpen]);

	useEffect(() => {
		/**
		 * Atualizar opções de palavras chaves
		 */
		async function getPcs() {
			try {
				const query = gql`
					{
						palavrasChave {
							_id
							nome
						}
					}
				`;
				const { data } = await api.query({ query });

				if (data.palavrasChave.length > 0) {
					setOptions(opts => ({
						...opts,
						palavrasChave: data.palavrasChave.map(pc => ({
							palavraChave: pc,
							value: pc.nome,
							label: pc.nome,
						})),
					}));
				}
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções de Palavras Chave');
			}
		}
		if (isOpen) getPcs();
	}, [isOpen]);

	useEffect(() => {
		if (values.materia) getAssuntos();
	}, [values.materia, getAssuntos]);

	useEffect(() => {
		/**
		 * Atualizar opções de Competências
		 * sempre que o campo de Área de Conhecimento for alterado
		 */
		if (values.areaConhecimento) getCompetencias();
	}, [values.areaConhecimento, getCompetencias]);

	useEffect(() => {
		async function getHabilidades() {
			try {
				// Pegar todos os números das competências selecionadas em um array
				// {'label': 'C-01', value: 1} => [1]
				const competenciaNumeros = values.competenciasValues.map(
					comp => comp.numero,
				);

				// Filtrar competências a serem procuradas habilidades pelas competências
				// atualmente selecionadas
				const competencias = currentArea.competencias.filter(competencia =>
					competenciaNumeros.some(numero => numero === competencia.numero),
				);

				// Pegar todas as habilidades dessas competências filtradas
				const habilidades = [];

				for (let i = 0; i < competencias.length; i += 1) {
					for (let j = 0; j < competencias[i].habilidades.length; j += 1) {
						habilidades.push({
							...competencias[i].habilidades[j],
						});
					}
				}

				const habilidadesOptions = habilidades.map(habilidade => ({
					habilidade,
					numero: habilidade.numero,
					value: habilidade.nome,
					label: habilidade.nome,
				}));

				setOptions(opts => ({
					...opts,
					habilidades: habilidadesOptions,
				}));
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções de Habilidades');
			}
		}
		// Não tentar pegar opções de Habilidades caso:
		// 1- Não haja nenhuma competência selecionada
		// 2- Não haja currentArea.competencias na API
		if (isOpen && values.competenciasValues && currentArea.competencias)
			getHabilidades();
	}, [values.competenciasValues, isOpen, currentArea]);

	const handleSelectChange = (value, action) => {
		if (value && values[action.name]) {
			// Não alterar nada caso não haja mudanças entre o novo valor
			// do campo alterado e o atual
			if (values[action.name] === value) return;
		}

		if (action.name === 'vestibular') {
			// Limpar campo de palavra chave e areaConhecimento ao alterar Vestibular
			setValues(vals => ({
				...vals,
				palavraChave: null,
				areaConhecimento: null,
				[action.name]: value,
			}));
		} else if (action.name === 'materia') {
			// Limpar campo de assuntos ao alterar Matéria
			setValues(vals => ({ ...vals, assuntos: null, [action.name]: value }));
		} else if (action.name === 'competencia') {
			// Limpar campo de habilidades ao alterar Competência
			setValues(vals => ({
				...vals,
				habilidadesValues: null,
				competenciasValues: value,
			}));
		} else if (action.name === 'habilidade') {
			setValues(vals => ({
				...vals,
				habilidadesValues: value,
			}));
		} else if (action.name === 'areaConhecimento') {
			// Limpar campo de competência e habilidade ao alterar Área de Conhecimento
			setValues(vals => ({
				...vals,
				competenciasValues: null,
				habilidadesValues: null,
				[action.name]: value,
			}));
		} else {
			setValues(vals => ({ ...vals, [action.name]: value }));
		}
	};

	const handleChange = e => {
		const { name, value, checked } = e.target;

		switch (name) {
			case 'numeroQuestao':
				// Apenas permitir números no campo numeroQuestao
				setValues(vals => ({ ...vals, [name]: value.replace(/\D/g, '') }));
				break;
			case 'disponivel':
				setValues(vals => ({ ...vals, disponivel: checked }));
				break;
			default:
				setValues(vals => ({ ...vals, [name]: value }));
		}
	};

	const handleEditorChange = useCallback(
		value => setValues(vals => ({ ...vals, ...value })),
		[],
	);

	const handleAddAlternativa = event => {
		/**
		 * Adicionar nova alternativa, incrementando sua letra
		 */
		event.preventDefault();

		const nextLetter = numberToLetter(values.alternativas.length);

		const newAlt = {
			id: new Date().getTime(),
			texto: '',
			correta: false,
			letra: nextLetter,
		};

		setValues(vals => ({
			...vals,
			alternativas: [...vals.alternativas, newAlt],
		}));
	};

	const handleRemoveAllAlternativas = () =>
		setValues(vals => ({ ...vals, alternativas: ALTERNATIVAS_INICIAIS }));

	const clearForm = (keepRepeatable = false) => {
		/**
		 * Limpa a Form, exceto por campos repetíveis, como Fonte, Vestibular, Ano etc.
		 * caso o usuário queira cadastrar várias questões da mesma prova, por exemplo
		 *
		 */
		if (keepRepeatable === true) {
			setValues(vals => ({
				...initialValues,
				numeroQuestao: (parseInt(vals.numeroQuestao, 10) + 1).toString(),
				fonte: vals.fonte,
				vestibular: vals.vestibular,
				ano: vals.ano,
				palavraChave: vals.palavraChave,
				competencia: null,
				habilidade: null,
			}));
		} else {
			setValues({ ...initialValues });
		}
	};

	async function submitQuestao(finishUpdating = false, prova) {
		try {
			// Validar campos da Questão pelo Yup
			if (values.vestibular?.label === 'ENEM') {
				await questoesEnemValidator.validate(values);
			} else {
				await questoesNotEnemValidator.validate(values);
			}
			try {
				const variables = {
					_id: questao ? questao._id : null,
					questao: mapQuestaoValues(values),
				};

				if (method === 'post') {
					// Criar nova Questão
					if (desafioId !== '') {
						const query = QUESTAO_BY_ID;
						const idDesafio = {
							_id: desafioId,
						};
						const { data } = await api.query({
							query,
							variables: idDesafio,
						});
						if (data.questao) {
							await createQuestaoDesafio({
								variables: {
									idDesafio: desafioId,
									questao: {
										...variables.questao,
										review: true,
									},
								},
							});
							// TODO: ADDQUESTAO DESAFIO
							toast.success('Questão salva no desafio com sucesso!');
							navigate(`${CADASTRAR_DESAFIO_ROUTE}/${desafioId}`);
						} else {
							await createQuestaoSimulado({
								variables: {
									idSimulado: desafioId,
									prova,
									questao: {
										...variables.questao,
										review: true,
									},
								},
							});
							toast.success('Questão salva no simulado com sucesso!');
							navigate(`${LISTAR_SIMULADOS}/${desafioId}`);
						}
					} else {
						const { data: dataCreate } = await createQuestao({
							variables: {
								...variables,
								questao: {
									...variables.questao,
									review: true,
								},
							},
						});
						toast.success('Questão salva com sucesso!');
						if (variables.questao?.disponivel) navigate('/');
						else navigate(`/editar/${dataCreate?.createQuestao?._id}`);
					}
				} else if (method === 'put') {
					// Atualizar Questão existente por ID
					if (finishUpdating) {
						await updateQuestao({
							variables: {
								...variables,
								questao: {
									...variables.questao,
									disponivel: !!values?.disponivel,
								},
							},
						});
						await reviewQuestao({
							variables: {
								_id: variables._id,
							},
						});
						if (desafioId !== '') {
							// TODO: UPDATE QUESTAO DESAFIO
							toast.success('Questão atualizada no desafio com sucesso!');
							navigate(`${CADASTRAR_DESAFIO_ROUTE}/${desafioId}`);
						} else {
							// TODO: UPDATE QUESTAO DESAFIO
							toast.success('Questão atualizada com sucesso!');
							navigate(0);
						}
					} else {
						await updateQuestao({
							variables: {
								...variables,
								questao: {
									...variables.questao,
								},
							},
						});
						if (desafioId !== '') {
							// TODO: UPDATE QUESTAO DESAFIO
							toast.success('Questão atualizada no desafio com sucesso!');
							navigate(`${CADASTRAR_DESAFIO_ROUTE}/${desafioId}`, {
								replace: true,
							});
						} else {
							// TODO: UPDATE QUESTAO DESAFIO
							toast.success('Questão atualizada com sucesso!');
							navigate(0);
						}
					}
					if (!variables.questao?.disponivel)
						toast.info('Lembrete: Questão está está habilitada!');
				}

				if (toggle) {
					// Fechar Modal se existir
					toggle();
				}
				if (clearOnSubmit) {
					// Limpar Form ao enviar
					clearForm(true);
				}
			} catch (error) {
				if (error.graphQLErrors && error.graphQLErrors.length > 0) {
					// Mensagem de erro da API
					toast.error(error.graphQLErrors[0].message);
				} else {
					console.log('Error', error);
					toast.error('Houve um Erro ao tentar salvar questão');
				}
			}
		} catch (error) {
			if (error.errors) {
				// Mensagem de Erro do Schema do Yup
				toast.error(error.errors[0]);
			} else {
				toast.error('Houve um Erro ao tentar salvar questão');
			}
		}
	}

	const afterCreateAssunto = useCallback(
		async assuntoValue => {
			try {
				const item = {
					assunto: assuntoValue,
					value: assuntoValue._id,
					label: assuntoValue.nome,
				};

				setOptions(opts => ({
					...opts,
					assuntosOptions: [...opts.assuntosOptions, item],
				}));

				setValues(vals => ({
					...vals,
					assuntos: [...vals.assuntos, item],
				}));

				const assuntosUpdate = values.assuntos
					.map(assunto => ({
						_id: assunto.assunto._id,
						nome: assunto.assunto.nome,
						materia: assunto.assunto.materia,
					}))
					.concat(assuntoValue);
				await updateQuestao({
					variables: {
						_id: questao ? questao._id : null,
						questao: {
							assuntos: assuntosUpdate,
						},
					},
				});
				toast.success('Assunto salvo com sucesso!');
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções de Assuntos');
			}
		},
		[values.assuntos, questao],
	);

	const isValid = async () => {
		/**
		 * Verificar se a Questão está válida de acordo com o
		 * Schema do Yup
		 */
		try {
			if (values.vestibular.label === 'ENEM') {
				await questoesEnemValidator.validate(values);
			} else {
				await questoesNotEnemValidator.validate(values);
			}
			return true;
		} catch (error) {
			return false;
		}
	};

	return {
		handleRemoveAllAlternativas,
		handleAddAlternativa,
		handleEditorChange,
		handleSelectChange,
		handleChange,
		values,
		options,
		submitQuestao,
		clearForm,
		isValid,
		getAssuntos,
		afterCreateAssunto,
	};
};

export const useFormPalavraChave = ({
	isOpen,
	palavraChave = {},
	vestibular = null,
	toggle,
	onSave,
}) => {
	const { palavraChave: pc, ano, codigo } = palavraChave;
	const initialValues = useMemo(
		() => ({
			palavraChave: pc || '',
			ano: ano
				? { value: ano, label: ano }
				: { value: moment().year(), label: moment().year() },
			codigo: codigo || '',
			vestibular: vestibular
				? { value: vestibular, label: vestibular.vestibular }
				: null,
		}),
		[ano, codigo, pc, vestibular],
	);
	const [values, setValues] = useState(initialValues);
	const [options, setOptions] = useState({});

	useEffect(() => {
		if (!isOpen) setValues(initialValues);
	}, [initialValues, isOpen]);

	useEffect(() => {
		async function getOptions() {
			try {
				const { data } = await api.get('vestibulares');
				setOptions({
					anos: generateAnosToSelect(),
					vestibulares: data.map(it => ({ value: it, label: it.vestibular })),
				});
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções de Vestibulares');
			}
		}
		getOptions();
	}, []);

	const palavraChaveScheme = object({
		palavraChave: string().required('Palavra Chave precisa ter um Nome'),
		ano: object()
			.required('Você precisa selecionar um Ano')
			.typeError('Você precisa selecionar um Ano'),
		codigo: object().nullable(),
		vestibular: object()
			.required('Você precisa selecionar um Vestibular')
			.typeError('Você precisa selecionar um Vestibular'),
	});

	const handleChange = ({ target: { name, value } }) =>
		setValues(vals => ({ ...vals, [name]: value }));

	const handleSelectChange = (value, action) => {
		// Não alterar nada caso não haja mudanças entre o novo valor
		// do campo alterado e o atual
		if (value && values[action.name] && values[action.name] === value) return;
		setValues(vals => ({ ...vals, [action.name]: value }));
	};

	const submitPalavraChave = async () => {
		try {
			await palavraChaveScheme.validate(values);
			const { data } = await api.get(
				`vestibulares/${values.vestibular.value._id}`,
			);
			const palavrasChave = [
				...data.palavrasChave,
				{
					palavraChave: values.palavraChave,
					ano: values.ano.value,
					codigo: values.codigo,
				},
			];
			await api.put(`vestibulares/${values.vestibular.value._id}`, {
				...data,
				palavrasChave,
			});
			toast.success('Palavra Chave salva com sucesso!');
			toggle();
			if (onSave) {
				onSave();
			}
		} catch (error) {
			if (error.errors) {
				toast.error(error.errors[0]);
			} else {
				toast.error('Houve um Erro ao tentar salvar Palavra Chave');
			}
		}
	};

	return {
		values,
		options,
		handleChange,
		handleSelectChange,
		submitPalavraChave,
	};
};

export const useFormAreaConhecimento = ({
	areaConhecimento = null,
	isOpen,
	method,
	toggle,
	onSave,
}) => {
	const initialValues = useMemo(
		() => ({
			nome: areaConhecimento ? areaConhecimento.areaConhecimento : '',
			materia: areaConhecimento
				? areaConhecimento.materias.map(mat => ({
						value: mat,
						label: mat,
				  }))
				: [],
			vestibular: areaConhecimento
				? areaConhecimento.vestibulares.map(vest => ({
						value: vest,
						label: vest,
				  }))
				: [],
			competencias: areaConhecimento ? areaConhecimento.competencias : [],
		}),
		[areaConhecimento],
	);
	const [values, setValues] = useState(initialValues);
	const [options, setOptions] = useState({});

	const areaConhecimentoSchema = object({
		nome: string().required('Área de Conhecimento precisa ter um nome'),
		materia: array()
			.min(1, 'Área de Conhecimento precisa de pelo menos 1 Matéria')
			.typeError('Área de Conhecimento precisa de pelo menos 1 Matéria'),
		vestibular: array()
			.min(1, 'Área de Conhecimento precisa de pelo menos 1 Vestibular')
			.typeError('Área de Conhecimento precisa de pelo menos 1 Vestibular'),
		competencias: array()
			.min(1, 'Você precisa ter pelo menos 1 Competência')
			.test(
				'competencia-repetida',
				'Você não pode ter 2 ou mais Competências com o mesmo número',
				competencias => {
					/**
					 * Impede que 2 Competências tenham o mesmo número
					 * https://stackoverflow.com/a/30735838
					 */

					// Faz um array com apenas os números de todas as competências
					const numeros = competencias.map(competencia => competencia.numero);

					// Verifica se algum dos números é repetido
					const hasDuplicate = numeros.some(
						(numero, idx) => numeros.indexOf(numero) !== idx,
					);

					return !hasDuplicate;
				},
			)
			.test(
				'habilidade-repetida',
				'Você não pode ter 2 ou mais Habilidades com o mesmo número',
				competencias => {
					/**
					 * Impede que 2 Habilidades tenham o mesmo número
					 * https://stackoverflow.com/a/30735838
					 */
					if (competencias) {
						const habilidades = [].concat(
							...competencias.map(competencia => competencia.habilidades),
						);
						const numeros = habilidades.map(habilidade => habilidade.numero);
						const hasDuplicate = numeros.some(
							(numero, idx) => numeros.indexOf(numero) !== idx,
						);
						return !hasDuplicate;
					}
					return true;
				},
			),
	});

	const handleChange = ({ target: { name, value } }) =>
		setValues(vals => ({ ...vals, [name]: value }));

	const handleSelectChange = (value, action) => {
		// Não alterar nada caso não haja mudanças entre o novo valor
		// do campo alterado e o atual
		if (value && values[action.name] && values[action.name] === value) return;
		setValues(vals => ({ ...vals, [action.name]: value }));
	};

	useEffect(() => {
		if (!isOpen) setValues(initialValues);
	}, [initialValues, isOpen]);

	useEffect(() => {
		/**
		 * Pega informações das matérias nos valores iniciais da Área, usando suas id's
		 *
		 * 5c3f30337bff7b7e921f7831 -> História, etc.
		 */
		async function getValores() {
			try {
				if (areaConhecimento && areaConhecimento.materias) {
					setValues(vals => ({ ...vals, materia: [] }));
					areaConhecimento.materias.forEach(async materia => {
						const { data } = await api.get(`materias/${materia}`);
						setValues(vals => ({
							...vals,
							materia: [
								...vals.materia,
								{ label: data.materia, value: data._id, materia: data },
							],
						}));
					});
				}
			} catch (error) {
				console.error(error);
				toast.error('Erro ao tentar atualizar valores da Área de Conhecimento');
			}
		}
		if (isOpen) getValores();
	}, [areaConhecimento, isOpen]);

	useEffect(() => {
		/**
		 * Atualiza opções de Seleção para Vestibulares e Matérias
		 */
		async function getOptions() {
			try {
				const { data: materias } = await api.get('materias');
				const { data: vestibulares } = await api.get('vestibulares');
				setOptions(opts => ({
					...opts,
					materias: materias.map(mat => ({
						value: mat.materia,
						label: mat.materia,
						materia: mat,
					})),
					vestibulares: vestibulares.map(vest => ({
						value: vest.vestibular,
						label: vest.vestibular,
						vestibular: vest,
					})),
				}));
			} catch (error) {
				toast.error('Erro ao tentar atualizar opções de seleção');
			}
		}
		if (isOpen) getOptions();
	}, [isOpen]);

	const handleAddCompetencia = () => {
		setValues(vals => ({
			...vals,
			competencias: [
				...vals.competencias,
				{ descricao: '', numero: '0', habilidades: [] },
			],
		}));
	};

	const handleRemoveCompetencia = event => {
		const compIndex = parseInt(event.currentTarget.dataset.index, 10);
		setValues(vals => ({
			...vals,
			competencias: vals.competencias.filter((_, index) => index !== compIndex),
		}));
	};

	const handleRemoveHabilidade = (compIndex, habIndex) => {
		/**
		 * Remove habilidade de index 'habIndex' de dentro da competência
		 * de index 'compIndex'
		 */
		setValues(vals => ({
			...vals,
			competencias: vals.competencias.map((comp, compI) => {
				if (compI === compIndex) {
					return {
						...comp,
						habilidades: comp.habilidades.filter(
							(_, habI) => habI !== habIndex,
						),
					};
				}
				return comp;
			}),
		}));
	};

	const handleAddHabilidade = event => {
		const index = parseInt(event.currentTarget.dataset.index, 10);
		setValues(vals => ({
			...vals,
			competencias: vals.competencias.map((comp, _index) => {
				if (_index === index) {
					return {
						...comp,
						habilidades: [...comp.habilidades, { descricao: '', numero: '0' }],
					};
				}
				return comp;
			}),
		}));
	};

	const handleHabilidadeChange = (compIndex, habIndex, event) => {
		const { name, value } = event.target;

		// Procurar Competência por compIndex, e em seguida a habilidade
		// dentro da competência, por habIndex, alterar dentro de habilidade
		// o campo alterado
		setValues(vals => ({
			...vals,
			competencias: vals.competencias.map((comp, compI) => {
				if (compI === compIndex) {
					return {
						...comp,
						habilidades: comp.habilidades.map((hab, habI) => {
							if (habI === habIndex) {
								if (name === 'numero') {
									return { ...hab, [name]: value.replace(/\D/g, '') };
								}
								return { ...hab, [name]: value };
							}
							return hab;
						}),
					};
				}
				return comp;
			}),
		}));
	};

	const handleCompetenciaChange = event => {
		const { name, value } = event.target;
		const index = parseInt(event.currentTarget.dataset.index, 10);

		setValues(vals => ({
			...vals,
			competencias: vals.competencias.map((comp, i) => {
				if (i === index) {
					if (name === 'numero') {
						return { ...comp, [name]: value.replace(/\D/g, '') };
					}
					return { ...comp, [name]: value };
				}
				return comp;
			}),
		}));
	};

	const mapAreaValues = area => {
		const { nome, competencias, vestibular, materia } = area;
		return {
			areaConhecimento: nome,
			materias: materia.map(mat => mat.materia._id),
			vestibulares: vestibular.map(vest => vest.label),
			competencias: competencias.map(comp => ({
				...comp,
				numero: parseInt(comp.numero, 10),
				nome: `C-${zeroPad(comp.numero)}`,
				habilidades: comp.habilidades.map(habilidade => ({
					...habilidade,
					numero: parseInt(habilidade.numero, 10),
					nome: `C-${zeroPad(habilidade.numero)}`,
				})),
			})),
		};
	};

	const submitArea = async () => {
		try {
			await areaConhecimentoSchema.validate(values);
			await api({
				method,
				url: method === 'post' ? 'areas' : `areas/${areaConhecimento._id}`,
				data: {
					...mapAreaValues(values),
					_id: areaConhecimento ? areaConhecimento._id : null,
				},
			});
			toast.success('Área de Conhecimento salva com sucesso!');
			if (toggle) {
				toggle();
			}
			if (onSave) {
				onSave();
			}
		} catch (error) {
			if (error.errors) {
				toast.error(error.errors[0]);
			} else {
				toast.error('Houve um Erro ao tentar salvar Área de Conhecimento');
			}
		}
	};

	return {
		values,
		options,
		submitArea,
		handleAddCompetencia,
		handleHabilidadeChange,
		handleCompetenciaChange,
		handleChange,
		handleSelectChange,
		handleRemoveCompetencia,
		handleRemoveHabilidade,
		handleAddHabilidade,
	};
};

export const usePagination = ({ data, perPage }) => {
	/**
	 * Paginação de lista de valores (data) a cada X páginas (perPage)
	 */
	const [currentPage, setCurrentPage] = useState(0);
	const [paginated, setPaginated] = useState([]);

	const totalPages = Math.ceil(data.length / perPage) - 1;

	useEffect(() => {
		const from = currentPage * perPage;
		const to = (currentPage + 1) * perPage;
		setPaginated(data.slice(from, to));
	}, [currentPage, data, perPage]);

	const setFirstPage = () => setCurrentPage(0);
	const setLastPage = () => setCurrentPage(totalPages);
	const handlePageChange = page => setCurrentPage(page);

	return {
		handlePageChange,
		setFirstPage,
		setLastPage,
		paginated,
		totalPages,
		currentPage,
		setCurrentPage,
	};
};

export const useDialog = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return {
		open,
		handleOpen,
		handleClose,
	};
};
