import React, { useContext } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Cell,
} from 'recharts';
import { Navigate } from 'react-router-dom';
import {
	oraColor,
	primaryBlue,
	primaryGreen,
	primaryOrange,
	tixColor,
} from 'constants/colors';
import { UsersContext } from 'screens/DashboardParceiros/provider';
import { Grid, LinearProgress } from '@material-ui/core';
import { UsersCard } from '..';
import { useStyles } from './styles';

const freemiumUsers = (active: number, premium: number) => active - premium;

const UsersChart = () => {
	const classes = useStyles();
	const { users, loadingUsers, usersError } = useContext(UsersContext);

	if (loadingUsers) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;

	const {
		login,
		parceiro,
		plano,
		plataforma,
		ativos,
		premium,
	} = users.usersStats;

	return (
		<div>
			{users ? (
				<Grid
					container
					justify="space-between"
					spacing={2}
					className={classes.chartContainer}
				>
					<Grid item lg={4} className={classes.cardContainer}>
						<UsersCard
							avatarTitle="ATV"
							title="ATIVOS"
							color={primaryGreen}
							number={ativos}
						/>
					</Grid>
					<Grid item lg={4} className={classes.cardContainer}>
						<UsersCard
							avatarTitle="FRE"
							title="FREEMIUM"
							color={primaryOrange}
							number={freemiumUsers(ativos, premium)}
						/>
					</Grid>
					<Grid item lg={4} className={classes.cardContainer}>
						<UsersCard
							avatarTitle="PRE"
							title="PREMIUM"
							color={primaryBlue}
							number={premium}
						/>
					</Grid>
					<Grid item lg={6}>
						<h5>Forma de Login</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={login}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="nome" />
							<YAxis />
							<Tooltip />
							<Bar dataKey="valor" fill={primaryBlue} />
						</BarChart>
					</Grid>
					<Grid item lg={6}>
						<h5>Parceiros</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={parceiro}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="nome" />
							<YAxis />
							<Tooltip />
							<Bar dataKey="valor" fill={primaryGreen} />
						</BarChart>
					</Grid>

					<Grid item lg={6}>
						<h5>Planos</h5>
						<BarChart
							barCategoryGap="30%"
							width={500}
							height={400}
							data={plano}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="nome" />
							<YAxis />
							<Tooltip />
							<Bar dataKey="valor" fill={oraColor} />
						</BarChart>
					</Grid>
					<Grid item lg={6}>
						<h5>Plataformas</h5>
						<BarChart
							barCategoryGap="20%"
							width={500}
							height={400}
							data={plataforma}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="nome" />
							<YAxis dataKey="valor" />
							<Tooltip />
							<Bar dataKey="valor" fill={tixColor}>
								{plataforma.map((index: number) => (
									<Cell key={`cell-${index}`} />
								))}
							</Bar>
						</BarChart>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
};

export default UsersChart;
