import { CreateNotificationFeedInput } from '../types';

export const validateNotificationInput = async (
	notification: CreateNotificationFeedInput,
) => {
	if (!notification.message?.title && !notification.message?.description) {
		return Promise.reject(new Error('Titúlo ou Descrição são obrigatório.'));
	}

	if (
		(notification.message?.iconType && !notification.message?.icon) ||
		(!notification.message?.iconType && notification.message?.icon)
	) {
		return Promise.reject(new Error('Ícone inválido.'));
	}

	const actionsValid = notification?.message?.actions.every(
		it => !!it.label && !!it.link && it.link?.includes('fbvest://'),
	);

	if (!actionsValid) {
		return Promise.reject(
			new Error('Actions devem ter Label e Deeplink válido.'),
		);
	}

	if (!notification?.audience && !notification?.receiver.length) {
		return Promise.reject(
			new Error('Defina audiencia ou usuários para enviar a notificação!'),
		);
	}

	return Promise.resolve();
};
