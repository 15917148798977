import { gql } from 'apollo-boost';

export const GET_QUESTOES = gql`
	query questoes(
		$fonte: String
		$vestibular: String
		$assunto: String
		$materia: String
		$palavraChave: String
		$limit: Int
		$skip: Int
	) {
		questoes(
			fonte: $fonte
			vestibular: $vestibular
			assunto: $assunto
			materia: $materia
			palavraChave: $palavraChave
			limit: $limit
			skip: $skip
		) {
			_id
			ano
			areaConhecimento {
				_id
				nome
				competencias
				habilidades
			}
			codigo
			enunciado
			fonte
			numeroQuestao
			palavraChave
			resolucao
			vestibular
			video
			materia
			alternativas {
				letra
				texto
				correta
			}
			assuntos {
				_id
				nome
			}
			review
			reviewReason
			assertividade {
				erros
				acertos
			}
		}
	}
`;

export const GET_CAD_USERS = gql`
	query GetCadUsers($email: String) {
		cadUsers(email: $email) {
			_id
			role
			nome
		}
	}
`;
