import React from 'react';
import { NavHeader } from 'components';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	CircularProgress,
	Divider,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { NotificationFeedData } from 'screens/FeedNotification/types';
import { GET_FEED_NOTIFICATION_BY_ID } from '../../api/query';
import { useStyles } from '../../styles';
import MessageNotificationById from './message';
import PushNotificationAction from './push-notification';

const FeedNotificationByIdScreen: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const params = useParams();
	const { loading, data, error, refetch, client } = useQuery<{
		notification: NotificationFeedData | null;
	}>(GET_FEED_NOTIFICATION_BY_ID, {
		variables: {
			notificationId: params?.notificationId,
		},
	});

	const onClearStore = () => {
		client?.clearStore();
	};

	return (
		<>
			<NavHeader />
			<Container>
				<Grid container className={classes.headerTitle}>
					<Button
						className={classes.backButton}
						onClick={() => {
							navigate(-1);
						}}
						startIcon={<ArrowBack />}
					>
						Voltar
					</Button>
				</Grid>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Notificação
					</Typography>
					<Typography component="p" color="initial">
						{loading
							? 'Buscando notificação...'
							: 'Abaixo tem os detalhes da notificação enviada e algumas ações.'}
					</Typography>
				</Box>
				{loading && <CircularProgress size={50} />}
				{error && (
					<>
						<Typography variant="h6" component="h6">
							Notificação não encontrada
						</Typography>
						<Button onClick={() => refetch()} variant="contained">
							Tentar novamente
						</Button>
					</>
				)}
				<MessageNotificationById
					data={data?.notification}
					onClearStore={onClearStore}
				/>
				<Divider style={{ marginTop: 8 }} />
				<PushNotificationAction data={data?.notification} refetch={refetch} />
			</Container>
		</>
	);
};

export default FeedNotificationByIdScreen;
