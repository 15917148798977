import { gql } from 'apollo-boost';

export const REMOVE_QUESTAO_DESAFIO = gql`
	mutation removeQuestoesDesafio($idDesafio: String!, $questoes: [String]) {
		desafio: removeQuestoesDesafio(idDesafio: $idDesafio, questoes: $questoes) {
			_id
			questoes
		}
	}
`;
