import { gql } from '@apollo/client';

export const ADMIN_FIND_VALIDATION_CODE = gql`
	query AdminFindValidationCode(
		$validationCode: String
		$nome: String
		$email: String
		$password: String
		$parceiro: String
	) {
		adminFindValidationCode(
			validationCode: $validationCode
			nome: $nome
			email: $email
			password: $password
			parceiro: $parceiro
		) {
			authorized
			isActive
			found
			existingUser {
				uid
				displayName
				email
				createdAt
				lastLoginAt
				lastAction
				customData {
					deviceInfo {
						platform
						version
					}
				}
				customClaims {
					level
					completeRegister
					subscriptions {
						planId
						parceiro
						validationCode
						nome
						status
						externalParceiro
						createdAt
						startedAt
						expireAt
						canceledAt
					}
				}
			}
		}
	}
`;
