import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
	placeholder: string;
	value: string;
	onKeyDown: any;
	myWhidth: string;
	myMargin: string;
}

const InputTextField: React.FC<Props> = ({
	placeholder,
	value,
	onKeyDown,
	myWhidth,
	myMargin,
}) => {
	const [currentValue, setCurrentValue] = useState(value);

	return (
		<TextField
			variant="outlined"
			value={currentValue}
			onChange={event => setCurrentValue(event.target.value)}
			onKeyDown={event => onKeyDown(event, currentValue)}
			type="text"
			placeholder={placeholder}
			style={{ width: myWhidth, marginRight: myMargin }}
		/>
	);
};

export default InputTextField;
