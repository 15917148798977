import React, { useContext, useMemo } from 'react';
import { CheckQuestionContext } from 'screens/CadastrarDesafio/provider/CheckQuestionProvider';
import { IQuestao } from 'types/questao';

export const useRowState = (questao: IQuestao) => {
	const { selectedQuestions, selectQuestion, unselectQuestion } = useContext(
		CheckQuestionContext,
	);
	const [open, setOpen] = React.useState(false);

	const foundQuestion = useMemo(
		() =>
			selectedQuestions.find(
				(selectedQuestion: string) => selectedQuestion === questao._id,
			),
		[selectedQuestions, questao._id],
	);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			selectQuestion(questao._id);
		} else {
			unselectQuestion(questao._id);
		}
	};

	return {
		isChecked: !!foundQuestion,
		handleChange,
		open,
		setOpen,
	};
};
