import { gql } from 'apollo-boost';

export const UPDATE_DESAFIO = gql`
	mutation updateDesafio($_id: String!, $desafio: FilterDesafio) {
		updateDesafio(_id: $_id, desafio: $desafio) {
			_id
			questoes
		}
	}
`;

export const PUBLISH_DESAFIO = gql`
	mutation publishDesafio($_id: [String]!, $dataFinal: String) {
		publishDesafio(_id: $_id, dataFinal: $dataFinal)
	}
`;
