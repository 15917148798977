import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DesafioConfirmClone: React.FC<{
	open: boolean;
	handleClose: () => void;
	handleSubmit: () => void;
}> = ({ handleSubmit, handleClose, open }) => (
	<div>
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Você tem certeza que deseja clonar esse desafio?
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button onClick={handleClose} variant="outlined" color="secondary">
					Cancelar
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					autoFocus
				>
					Clonar Desafio
				</Button>
			</DialogActions>
		</Dialog>
	</div>
);

export default DesafioConfirmClone;
