import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { SIMULADO_DISPONIVEL } from '../components/SimuladoCard/api';
import SimuladoContext from '../providers/SimuladoProvider';

export const SimuladoDisponivel = ({ simulado, classes }) => {
	const { refetch } = useContext(SimuladoContext);

	const [simuladoChangeDisponivel] = useMutation(SIMULADO_DISPONIVEL);

	const simuladoDisponivel = async () => {
		try {
			await simuladoChangeDisponivel({
				variables: {
					_id: simulado._id,
					disponivel: !simulado.disponivel,
				},
			});
			refetch();
			toast.success('Simulado atualizado com sucesso!');
		} catch (error) {
			toast.error('Erro ao atualizar o simulado.');
		}
	};
	return (
		<Button
			variant="contained"
			className={classes.button}
			style={{
				backgroundColor: simulado.disponivel ? '#E74133' : '#53AF50',
				color: 'white',
				minWidth: '20%',
			}}
			onClick={simuladoDisponivel}
		>
			{simulado?.disponivel ? 'Desativar' : 'Ativar'}
		</Button>
	);
};

export default SimuladoDisponivel;
