import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
	Grid,
	LinearProgress,
	MenuItem,
	TextField,
	Card,
	Button,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { toast } from 'react-toastify';
import {
	anoOptions,
	getVestibularOptions,
	getFontesOptions,
} from '../../../helpers/options';
import QuestionContext from '../../ListarQuestoes/providers/QuestionProvider';
import { useStyles } from '../styles';
import { parseCredentials, simuladoSchema } from './formValidator';
import { SALVAR_SIMULADO } from '../api';

const initialCredentials = {
	nome: '',
	vestibular: '',
	ano: 0,
	fonte: '',
	titulo: '',
	descricao: '',
	disponivel: false,
};

const initialErrorMessages = {
	nome: undefined,
	vestibular: undefined,
	ano: undefined,
	fonte: undefined,
};

const Form: React.FC = () => {
	const classes = useStyles();
	const { data, loading } = useContext(QuestionContext);
	const [formCredentials, setFormCredentials] = useState(initialCredentials);
	const [errorMessages, setErrorMessages] = useState(initialErrorMessages);
	const [salvarSimulado] = useMutation(SALVAR_SIMULADO);

	const handleChange = (name: string, value: string) => {
		setFormCredentials(prevState => ({
			...prevState,
			[name]: value,
		}));
		setErrorMessages(prevState => ({
			...prevState,
			[name]: undefined,
		}));
	};

	const handleErrors = (errorFields: string[], yupErrors: string[]) => {
		errorFields.forEach(field => {
			const error = yupErrors.find((errorMessage: string) =>
				errorMessage.includes(field),
			);
			setErrorMessages(prevState => ({
				...prevState,
				[field]: error,
			}));
		});
	};

	const salvarRascunho = async () => {
		try {
			const yupCredentials = parseCredentials(formCredentials);
			await simuladoSchema.validate(yupCredentials, {
				abortEarly: false,
				strict: false,
			});
			await salvarSimulado({
				variables: { simulado: formCredentials },
			});
			toast.success('O simulado foi corretamente salvado.');
		} catch (error: any) {
			if (error.errors) {
				const errorFields = Object.keys(error.value);
				const yupErrors = error.errors;
				handleErrors(errorFields, yupErrors);
			} else {
				toast.error(
					'Erro interno, tente novamente.Cheque se os dados do simulado foram enviados corretamente.',
				);
			}
		}
	};

	if (loading)
		return (
			<Card className={classes.form}>
				<LinearProgress />
			</Card>
		);

	return (
		<Card className={classes.form}>
			<Grid container direction="row" spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={5}>
					<TextField
						data-testid="nome-text-field"
						name="nome"
						error={!!errorMessages.nome}
						helperText={errorMessages.nome ? errorMessages.nome : ''}
						value={formCredentials.nome}
						style={{ display: 'flex', flex: 1 }}
						required
						label="Nome"
						placeholder="Digite o nome do simulado"
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					/>
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3}>
					<TextField
						name="vestibular"
						data-testid="vestibular-text-field"
						error={!!errorMessages.vestibular}
						helperText={
							errorMessages.vestibular ? errorMessages.vestibular : ''
						}
						value={
							formCredentials.vestibular === '' ? 0 : formCredentials.vestibular
						}
						style={{ display: 'flex', flex: 1, fontWeight: 100 }}
						select
						label="Vestibular"
						placeholder="Escolha o vetibular do simulado"
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					>
						<MenuItem value={0}>Escolha o Vestibular</MenuItem>
						{getVestibularOptions(data)
							.slice(1)
							.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
					</TextField>
				</Grid>

				<Grid item xs={12} sm={4} md={4} lg={2}>
					<TextField
						name="ano"
						data-testid="ano-text-field"
						error={!!errorMessages.ano}
						helperText={errorMessages.ano ? errorMessages.ano : ''}
						value={formCredentials.ano}
						style={{ display: 'flex', flex: 1 }}
						select
						label="Ano"
						placeholder="Escolha o ano"
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					>
						<MenuItem value={0}>Escolha o ano</MenuItem>
						{anoOptions.slice(1).map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={2}>
					<TextField
						name="fonte"
						data-testid="fonte-text-field"
						error={!!errorMessages.fonte}
						helperText={errorMessages.fonte ? errorMessages.fonte : ''}
						style={{ display: 'flex', flex: 1 }}
						select
						label="Fonte"
						value={formCredentials.fonte === '' ? 0 : formCredentials.fonte}
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					>
						<MenuItem value={0}>Escolha a fonte</MenuItem>
						{getFontesOptions(data).map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12}>
					<TextField
						name="titulo"
						data-testid="titulo-text-field"
						label="Título"
						placeholder="Digite o título do simulado"
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						value={formCredentials.titulo}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12}>
					<TextField
						name="descricao"
						data-testid="descricao-text-field"
						multiline
						rows={8}
						label="Descrição"
						placeholder="Descrição do simulado"
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						value={formCredentials.descricao}
						variant="outlined"
						onChange={({ target: { name, value } }) =>
							handleChange(name, value)
						}
					/>
				</Grid>
			</Grid>
			<Grid className={classes.btnContainer}>
				<Button
					data-testid="salvar-button"
					variant="contained"
					color="primary"
					size="large"
					startIcon={<SaveIcon />}
					onClick={salvarRascunho}
				>
					SALVAR RASCUNHO
				</Button>
			</Grid>
		</Card>
	);
};

export default Form;
