import { gql } from '@apollo/client';

export const PUBLISH_SIMULADO_FB = gql`
	mutation publishSimuladoFB($_id: String!) {
		publishSimuladoFB(_id: $_id)
	}
`;

export const UNPUBLISH_SIMULADO_FB = gql`
	mutation unpublishSimuladoFB($_id: String!) {
		unpublishSimuladoFB(_id: $_id)
	}
`;

export const SIMULADO_DISPONIVEL = gql`
	mutation simuladoDisponivel($_id: String, $disponivel: Boolean) {
		simuladoDisponivel(_id: $_id, disponivel: $disponivel)
	}
`;
