import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	IconButton,
	Collapse,
	Switch,
	FormGroup,
	FormControlLabel,
	Button,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ChildDetailsUserProps } from './types';
import { FBVestClaimsSubscription, IAppUser } from '../types';
import { formatGeneralValues } from '../utils';
import { Item, ItemLine, renderLine } from './utils';
import MarkUserAsPremium from './mark-user-as-premium';
import { UPDATE_NOTIFICATION_PREFERENCES } from '../api/mutation';
import { UserDeleteRevoke } from '../utils/buttonDeleteRevoke';
import CompltePurchaseAndroid from './complete-purchase-android';

const renderTopic = (subject: string, user: IAppUser) => {
	const topicSubjects = user?.historicoAproveitamento?.materias.filter(
		materia => materia.nome === subject,
	);

	return topicSubjects.map(topicSubject =>
		topicSubject.assuntos.map(assunto => (
			<TableRow>
				<TableCell style={{ borderBottom: 'none' }} align="left">
					{assunto?.nome}
				</TableCell>
				<TableCell style={{ borderBottom: 'none' }} align="center">
					{assunto?.acertos}
				</TableCell>
				<TableCell style={{ borderBottom: 'none' }} align="center">
					{assunto?.erros}
				</TableCell>
			</TableRow>
		)),
	);
};

const renderSubscriptionLine = (
	subscription: FBVestClaimsSubscription,
	index: number,
) => (
	<Box mb={1} key={subscription?.validationCode}>
		<Item>
			<Typography variant="caption">({index + 1} - subscription)</Typography>
			<ItemLine label="Plano ID" value={subscription?.planId} />
			<ItemLine label="Plano" value={subscription?.nome} />
			<ItemLine label="Parceiro" value={subscription?.parceiro} />
			<ItemLine label="Status" value={subscription?.status} />
			<ItemLine label="ValidationCode" value={subscription?.validationCode} />
			<ItemLine
				label="ExternalParceiro?"
				value={subscription?.externalParceiro}
			/>
			{formatGeneralValues(subscription, renderLine)}
		</Item>
	</Box>
);

const GeneralInfoAppUser: React.FC<ChildDetailsUserProps> = ({
	user,
	refetch,
}) => {
	const generalItems = useMemo(() => formatGeneralValues(user, renderLine), [
		user,
	]);
	const [open, setOpen] = useState({});

	const [checkedEmail, setCheckedEmail] = useState(
		user?.notification?.desafio?.email ?? true,
	);
	const [checkedPush, setCheckedPush] = useState(
		user?.notification?.desafio?.push ?? true,
	);
	const [salvarNotification] = useMutation(UPDATE_NOTIFICATION_PREFERENCES);

	const switchHandlerEmail = event => {
		setCheckedEmail(event.target.checked);
	};
	const switchHandlerPush = event => {
		setCheckedPush(event.target.checked);
	};

	const salvarNotificationPreference = useCallback(
		async (
			email: boolean,
			push: boolean,
			uid: string,
			notification: string,
		) => {
			try {
				await salvarNotification({
					variables: { email, push, uid, notification },
				});
				toast.success('Notificações alteradas com sucesso.');
			} catch (error: any) {
				toast.error('Erro interno, tente novamente.');
			}
		},
		[salvarNotification],
	);

	const renderBlockGeneral = useMemo(
		() => (
			<Item>
				<Box mb={3}>
					<Typography variant="h5" style={{ fontWeight: 'bold' }}>
						Informações Gerais
					</Typography>
				</Box>
				<ItemLine label="UID" value={user?.uid} />
				<ItemLine label="E-MAIL" value={user?.email} />
				{generalItems}
				<ItemLine
					label="Device Platform"
					value={user?.customData?.deviceInfo?.platform}
				/>
				<ItemLine
					label="Registro Completo?"
					value={user?.customClaims?.completeRegister ? 'SIM' : 'NÃO'}
				/>
				<ItemLine
					label="Plano"
					value={user?.customClaims?.level === 2 ? 'PREMIUM' : 'FREEMIUM'}
				/>
				<UserDeleteRevoke uid={user?.uid} />
			</Item>
		),
		[
			generalItems,
			user?.customClaims?.completeRegister,
			user?.customClaims?.level,
			user?.email,
			user?.uid,
			user?.customData?.deviceInfo?.platform,
		],
	);

	const renderCustomClaimsBlock = useMemo(
		() =>
			user?.customClaims?.subscriptions?.length ? (
				<Item>
					<Box mb={3}>
						<Typography variant="h5" style={{ fontWeight: 'bold' }}>
							Subscriptions
						</Typography>
					</Box>
					{user?.customClaims?.subscriptions?.map(renderSubscriptionLine)}
				</Item>
			) : null,
		[user?.customClaims?.subscriptions],
	);
	const renderObjetivosBlock = useMemo(
		() => (
			<Item>
				<Box mb={3}>
					<Typography variant="h5" style={{ fontWeight: 'bold' }}>
						Objetivos ({user?.objetivos ? 'SIM' : 'NÃO'})
					</Typography>
				</Box>
				<ItemLine
					label="Materias"
					value={user?.objetivos?.materia?.join(', ')}
				/>
				<ItemLine
					label="Vestibulares"
					value={user?.objetivos?.vestibular?.join(', ')}
				/>
			</Item>
		),
		[user?.objetivos],
	);

	const renderAssuntosBlock = useMemo(
		() => (
			<Item>
				<Box mb={3}>
					<Typography variant="h5" style={{ fontWeight: 'bold' }}>
						Histórico de Aproveitamento (
						{user?.historicoAproveitamento?.materias ? 'SIM' : 'NÃO'})
					</Typography>
				</Box>
				{user?.historicoAproveitamento?.materias.length ? (
					<Table style={{ minWidth: 500 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<TableCell component="th" scope="row" align="left" />
								<TableCell component="th" scope="row" align="left">
									Matérias
								</TableCell>
								<TableCell component="th" scope="row" align="center">
									Acertos
								</TableCell>
								<TableCell component="th" scope="row" align="center">
									Erros
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{user?.historicoAproveitamento?.materias.map(materia =>
								materia.assuntos.length > 0 ? (
									<>
										<TableRow>
											<TableCell
												style={{
													padding: '0px 0px 0px 0px',
													width: '40px',
													borderBottom: 'none',
												}}
											>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={() =>
														setOpen(prev => ({
															...prev,
															[materia.nome]: !prev[materia.nome],
														}))
													}
												>
													{open[materia.nome] ? (
														<KeyboardArrowUpIcon />
													) : (
														<KeyboardArrowDownIcon />
													)}
												</IconButton>
											</TableCell>
											<TableCell style={{ borderBottom: 'none' }}>
												{materia?.nome}
											</TableCell>
											<TableCell
												align="center"
												style={{ borderBottom: 'none' }}
											>
												{materia?.acertos}
											</TableCell>
											<TableCell
												align="center"
												style={{ borderBottom: 'none' }}
											>
												{materia?.erros}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{
													padding: 0,
													borderBottomColor: '#616161',
												}}
												colSpan={6}
											>
												<Collapse
													in={open[materia.nome]}
													timeout="auto"
													unmountOnExit
												>
													{open[materia.nome] && (
														<Box style={{ paddingLeft: '50px' }}>
															<Table
																style={{ minWidth: 500 }}
																aria-label="customized table"
															>
																<TableHead>
																	<TableRow>
																		<TableCell
																			align="left"
																			style={{ width: '42%' }}
																		>
																			Assunto
																		</TableCell>
																		<TableCell align="center">
																			Acertos
																		</TableCell>
																		<TableCell align="center">Erros</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{renderTopic(materia.nome, user)}
																</TableBody>
															</Table>
														</Box>
													)}
												</Collapse>
											</TableCell>
										</TableRow>
									</>
								) : null,
							)}
						</TableBody>
					</Table>
				) : null}
			</Item>
		),
		[user, open],
	);

	const renderNotificationBlock = useMemo(
		() => (
			<Item>
				<Box mb={3}>
					<Typography variant="h5" style={{ fontWeight: 'bold' }}>
						Notificação
					</Typography>
				</Box>

				<>
					<Typography variant="h6">Desafio</Typography>
					<FormGroup
						style={{
							paddingLeft: '15px',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
						row
					>
						<FormControlLabel
							control={
								<Switch
									defaultChecked={checkedEmail}
									color="primary"
									onClick={switchHandlerEmail}
								/>
							}
							label={
								<Typography style={{ width: '45px' }} variant="body1">
									Email
								</Typography>
							}
							labelPlacement="start"
						/>
						<FormControlLabel
							control={
								<Switch
									defaultChecked={checkedPush}
									color="primary"
									onClick={switchHandlerPush}
								/>
							}
							label={
								<Typography style={{ width: '45px' }} variant="body1">
									Push
								</Typography>
							}
							labelPlacement="start"
						/>
					</FormGroup>
				</>

				<Box style={{ paddingTop: '20px' }}>
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => {
							salvarNotificationPreference(
								checkedEmail,
								checkedPush,
								user?.uid,
								'desafio',
							);
						}}
					>
						Salvar alterações
					</Button>
				</Box>
			</Item>
		),
		[checkedEmail, checkedPush, salvarNotificationPreference, user?.uid],
	);

	return (
		<>
			{renderBlockGeneral}
			{renderObjetivosBlock}
			{renderAssuntosBlock}
			{renderNotificationBlock}
			<MarkUserAsPremium user={user} refetch={refetch} />
			<CompltePurchaseAndroid user={user} refetch={refetch} />
			{renderCustomClaimsBlock}
		</>
	);
};

export default GeneralInfoAppUser;
