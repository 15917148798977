export const NOME_ERROR = 'Por favor, preencha o nome do desafio.';
export const MATERIA_ERROR = 'Por favor, escolha a materia do desafio.';
export const TEMPO_ERROR = 'Por favor, preencha o tempo máximo do desafio.';
export const QUANTIDADE_ERROR =
	'Por favor, indique a quantidade de questões que terá o desafio.';

export const PONTUACAO_ERROR = 'Por favor, escolha a pontuacao do desafio.';
export const VESTIBULAR_ERROR = 'Por favor, escolha o vestibular do desafio.';
export const DATA_ERROR = 'Por favor, indique a data final do desafio.';
export const PREMISSAO_LEVEL =
	'Por favor, indique quais usuários tem permissão de responder esse desafio.';
export const CRIADO_ERROR = 'Por favor, indique por quem foi criado o desafio.';

export const NOME_PLACEHOLDER = 'Nome do desafio';
export const MATERIA_PLACEHOLDER = 'Materia do desafio';
export const TEMPO_PLACEHOLDER = 'Tempo máximo em minutos';
export const QUANTIDADE_PLACEHOLDER = 'Quantidade de questões do desafio';
export const PONTUACAO_PLACEHOLDER = 'Pontuação do desafio';
export const VESTIBULAR_PLACEHOLDER = 'Vestibular do desafio';
export const DATA_PLACEHOLDER = 'Data final do desafio';
export const CRIADO_PLACEHOLDER = 'Nome do criador do desafio';
