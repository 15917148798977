export enum CampaignAudience {
	All = 'all', // toda a base
	Premium = 'premium', // usuários level 2
	Freemium = 'freemium', // usuários level 1
	User = 'user', // usuário especifico pelo o ID
	Custom = 'custom', // envio customizado com nome e email
}

export const AudienceOptions = [
	{
		label: 'Todos',
		value: CampaignAudience.All,
	},
	{
		label: 'Premium',
		value: CampaignAudience.Premium,
	},
	{
		label: 'Freemium',
		value: CampaignAudience.Freemium,
	},
	{
		label: 'Customizado',
		value: CampaignAudience.Custom,
	},
];

export enum CampaignType {
	Email = 'email',
	Push = 'push',
}

export enum CampaignModel {
	Desafio = 'desafio',
	Simulado = 'simulado',
	QuestoesAvulsa = 'questoes-avulsa',
}

export interface CampaignInput {
	type: 'email' | 'push';
	model: 'desafio' | 'simulado' | 'questoes-avulsa';
	modelId?: string;
	subject?: string;
	url?: string;
	audience: string | 'all' | 'premium' | 'freemium' | 'user' | 'custom';
	displayName?: string;
	vestibulares?: string[];
	materias?: string[];
	email?: string;
}

export const DefaultSubject = `Olá {{displayName}}, chegou desafio no FB Vest!`;
