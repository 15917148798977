import { dateToStringDate } from 'helpers/data';
import moment from 'moment';
import React from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import { useParceiroFilters } from '../hooks';
import { LIST_USERS } from '../api';
import { ParceirosFilters } from '../types';

interface Context {
	filters: any;
	handleChange: any;
	setPage: any;
	users: any;
	loadingUsers: boolean;
	usersError: ApolloError | undefined;
}

export const UsersContext = React.createContext<Context>({
	filters: {},
	handleChange: () => {},
	setPage: () => {},
	users: {},
	loadingUsers: false,
	usersError: undefined,
});

interface Props {
	children: React.ReactElement;
	filtersOut?: ParceirosFilters;
	setFilters?: (filters: ParceirosFilters) => void;
	update?: boolean;
}
let auxFilters: ParceirosFilters = {
	nome: '',
	email: '',
	plataforma: '',
	parceiro: '',
	plano: '',
	inicio: dateToStringDate(moment().add(-1, 'days')),
	fim: dateToStringDate(moment()),
	provider: '',
	skip: 0,
};
const UsersProvider: React.FC<Props> = ({
	children,
	filtersOut,
	setFilters,
	update = false,
}) => {
	const { filters, handleChange, setPage } = useParceiroFilters();

	if (filtersOut && filtersOut !== auxFilters && update) {
		auxFilters = filtersOut;
	}
	if (!update) {
		auxFilters = filters;
		if (setFilters) setFilters(filters || ({} as ParceirosFilters));
	}
	// if (update) {
	// 	console.log(auxFilters, filters, filtersOut === auxFilters);
	// }
	const variables = {
		filters: { ...auxFilters, skip: filters.skip },
		inicio: filters.inicio,
		fim: filters.fim,
	};
	const { data, loading, error } = useQuery(LIST_USERS, {
		variables,
		fetchPolicy: 'no-cache',
	});
	return (
		<UsersContext.Provider
			value={{
				filters,
				handleChange,
				setPage,
				users: data,
				loadingUsers: loading,
				usersError: error,
			}}
		>
			{children}
		</UsersContext.Provider>
	);
};

export default UsersProvider;
