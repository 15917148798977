import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useListNotificationFeed } from 'screens/FeedNotification/provider/notifications.provider';

const OptionsSentFrom = [
	{ label: 'Todos', value: null },
	{ label: 'Admin', value: 'admin' },
];

const SelectSentFrom: React.FC = () => {
	const {
		loading,
		error,
		onChangeSentFrom,
		sentFrom,
	} = useListNotificationFeed();

	const onChange = (event: any) => {
		onChangeSentFrom?.(event.target.value);
	};
	if (loading) return null;

	return (
		<FormControl variant="outlined" error={!!error} className="mr-2">
			<p className="font-weight-bold m-0 mb-1">Local de Envio</p>
			<Select
				name="sentFrom"
				displayEmpty
				value={sentFrom}
				onChange={onChange}
				disabled={loading}
			>
				<MenuItem value="" disabled>
					Selecione uma opção
				</MenuItem>
				{OptionsSentFrom.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectSentFrom;
