import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: '#F2F2F2',
		minHeight: '100vh',
	},
	body: {
		paddingTop: theme.spacing(5),
	},
	tableContainer: { height: 400, width: '100%', marginTop: theme.spacing(5) },
}));
