import React, { useState } from 'react';
import { Grid, Typography, Button, Box, TextField } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Item } from '../utils';
import { useStyles } from '../styles';
import SelectDropDown from '../SelectDropDown';
import {
	getFontesOptions,
	getVestibularOptions,
} from '../SelectDropDown/options';
import { UPDATE_SIMULADO_FB } from '../api';

export const GeneralInfo = ({ data }) => {
	const classes = useStyles();
	const [info, setInfo] = useState(data);
	const [ano, setAno] = useState(info?.revisarSimulado?.ano);

	const handleChangeAno = event => {
		setAno(event.target.value);
	};

	const [updateSimuladoFB] = useMutation(UPDATE_SIMULADO_FB);

	const updateSimulado = async () => {
		try {
			await updateSimuladoFB({
				variables: {
					_id: info?.revisarSimulado?._id,
					simulado: {
						ano: Number(ano),
						fonte: info?.revisarSimulado.fonte,
						vestibular: info?.revisarSimulado.vestibular,
					},
				},
			});
			toast.success('Simulado atualizado com sucesso');
		} catch {
			toast.error('Não foi possivel atualizar o simulado');
		}
	};

	return (
		<Item>
			<Grid
				container
				spacing={3}
				direction="column"
				className={classes.containerEdit}
			>
				<Box mb={3}>
					<Typography variant="h5" style={{ fontWeight: 'bold' }}>
						Informações Gerais
					</Typography>
				</Box>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						label="Nome"
						disabled
						value={info?.revisarSimulado.nome}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<SelectDropDown
						label="Vestibular"
						options={getVestibularOptions()}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setInfo(curr => ({
								...curr,
								revisarSimulado: {
									...curr.revisarSimulado,
									vestibular: e.target.value,
								},
							}));
						}}
						value={info?.revisarSimulado.vestibular}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						label="Ano"
						value={ano}
						onChange={handleChangeAno}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<SelectDropDown
						label="Fonte"
						options={getFontesOptions()}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setInfo(curr => ({
								...curr,
								revisarSimulado: {
									...curr.revisarSimulado,
									fonte: e.target.value,
								},
							}));
						}}
						value={info?.revisarSimulado.fonte}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						disabled
						label="Total de questões"
						value={info?.revisarSimulado.totalQuestoes}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						disabled
						label="Data de publicação"
						value={
							info?.revisarSimulado?.pubDate
								? moment(Number(info?.revisarSimulado?.pubDate)).format(
										'DD/MM/YYYY',
								  )
								: ''
						}
					/>
				</Grid>
				<Button
					variant="contained"
					className={classes.editButton}
					startIcon={<Save />}
					fullWidth={false}
					onClick={updateSimulado}
				>
					Salvar
				</Button>
			</Grid>
		</Item>
	);
};
