import React from 'react';
import Icon from '@mdi/react';
import styled from 'styled-components';
import { Button, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { mdiPlus, mdiFormatListBulleted } from '@mdi/js';
import { Link } from 'react-router-dom';

const Header = styled(CardHeader)`
	font-size: 25px;
`;

const Dashboard = () => (
	<Card className="mb-2 text-center">
		<CardBody>
			<Row>
				<Col className="mt-4" xs={12} sm={12} md={6} lg={4}>
					<Card>
						<Header className="font-weight-bold">Questões</Header>
						<CardBody>
							<Button color="info" block size="lg" tag={Link} to="/questoes">
								Listar Questões
								<Icon
									className="ml-2"
									path={mdiFormatListBulleted}
									size={1}
									color="white"
								/>
							</Button>
							<Button
								color="success"
								block
								size="lg"
								tag={Link}
								to="/nova-questao"
							>
								Cadastrar Questões
								<Icon className="ml-2" path={mdiPlus} size={1} color="white" />
							</Button>
						</CardBody>
					</Card>
				</Col>
				<Col className="mt-4" xs={12} sm={12} md={6} lg={4}>
					<Card>
						<Header className="font-weight-bold">Assuntos</Header>
						<CardBody>
							<Button color="primary" block size="lg" tag={Link} to="/assuntos">
								Listar e Cadastrar Assuntos
								<Icon
									className="ml-2"
									path={mdiFormatListBulleted}
									size={1}
									color="white"
								/>
							</Button>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</CardBody>
	</Card>
);

export default Dashboard;
