import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
	mutation LoginCadUser($email: String!, $password: String) {
		loginCadUser(email: $email, password: $password) {
			_id
			token
			role
			firstLogin
		}
	}
`;

export const UPDATE_PASSWORD = gql`
	mutation UpdatePassword($_id: String!, $caduser: CadUserInput!) {
		updateCadUser(_id: $_id, caduser: $caduser) {
			_id
			email
		}
	}
`;

export const REFRESH_TOKEN = gql`
	mutation RefreshToken($token: String!) {
		refreshToken(token: $token)
	}
`;

export const REGISTER_USER = gql`
	mutation RegisterUser(
		$username: String!
		$password: String!
		$email: String!
		$role: String
		$nome: String
	) {
		user: createCadUser(
			username: $username
			password: $password
			email: $email
			role: $role
			nome: $nome
		) {
			email
			nome
		}
	}
`;
