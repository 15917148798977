import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Grid, ListItem, Button } from '@material-ui/core';
import { getCollor } from './helper';
import { useStyles } from './styles';
import { Iquestao } from '../../Types';

interface Props {
	questao: Iquestao;
	reportadas: boolean;
}

export const ListQuestionItem: React.FC<Props> = ({ questao, reportadas }) => {
	const { vestibular, ano, fonte, materia, erros, _id } = questao;

	const color = getCollor(vestibular);
	const classes = useStyles();

	return (
		<ListItem
			style={{
				borderLeft: `4px solid ${color}`,
				padding: '10px',
			}}
			className={classes.card}
			key={_id}
		>
			<Grid
				container
				direction="row"
				justify="space-between"
				style={{ display: 'flex', width: '100%', padding: '0px 0px 0px 20px' }}
			>
				<Grid className={classes.infoVestibular}>
					<Typography style={{ color }} className={classes.vestibular}>
						{vestibular}
					</Typography>
					<Typography className={classes.item}>{ano}</Typography>
					<Typography className={classes.item}>{fonte}</Typography>
					<Typography className={classes.item}>{materia}</Typography>
				</Grid>

				<Grid item xs={1} className={classes.cardBtn}>
					<Link to={`/editar/${_id}`} className={classes.button}>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
						>
							REVISAR
						</Button>
					</Link>
				</Grid>
			</Grid>
			{reportadas ? (
				<Grid
					container
					direction="row"
					style={{ borderTop: '0.5px solid #8190A7' }}
				>
					<Grid className={classes.reportRow}>
						<Typography className={`${classes.reportLabel} ${classes.error}`}>
							<span>Motivo: </span>
							<span>Categoria: </span>
							<span>Data: </span>
							<span>Tipo Simulado: </span>
							<span>Versão App: </span>
							<span>Info: </span>
							<span>User: </span>
							{erros.map(erro => (
								<>
									<Typography className={`${classes.reportText}`}>
										{erro.mensagem ?? '----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										{erro.categoria ?? '----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										{moment(Number(erro.createdAt)).format('DD/MM/YY') ??
											'----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										{erro.tipoSimulado ?? '----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										{erro?.info?.versionNumber ?? '----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										{erro?.info
											? `${erro?.info?.os} ${erro?.info?.version}`
											: '----------------'}
									</Typography>
									<Typography className={`${classes.reportText}`}>
										<Link
											to={`/buscar-usuario/${erro?.user}`}
											target="_blank"
											style={{ color: 'inherit' }}
										>
											{erro?.user ?? '----------------'}
										</Link>
									</Typography>
								</>
							))}
						</Typography>
					</Grid>
				</Grid>
			) : null}
		</ListItem>
	);
};
