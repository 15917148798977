import React, { useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Navigate } from 'react-router-dom';
import { UsersContext } from 'screens/DashboardFinanceiro/provider';
import { useStyles } from './styles';
import { DoubleFinancialCard } from '..';

interface Props {
	loading: boolean;
	error: any;
	cards: any;
}

interface Card {
	color: string;
	label: string;
	total: number;
}

const filterGoogleApple = (
	data: Array<any>,
	subscriptionType: 'Google' | 'Apple',
) => data.filter(card => card.label.includes(subscriptionType));

const DoubleCardRow: React.FC<Props> = ({ loading, error, cards }) => {
	const classes = useStyles();
	const { loadingUsers, usersError } = useContext(UsersContext);

	if (loadingUsers) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;
	if (loading) return <LinearProgress />;
	if (error) return <Navigate to="/" />;

	const appleArray = filterGoogleApple(cards, 'Apple');
	const googleArray = filterGoogleApple(cards, 'Google');
	const cardsArray = [googleArray, appleArray];

	const renderCards = () => {
		const cardsMap = cardsArray.map((card: Card[]) => (
			<Grid item lg={3}>
				{card[0].label.split(' ')[1] === 'Google' ? (
					<h5>Google</h5>
				) : (
					<h5>Apple</h5>
				)}
				<DoubleFinancialCard
					color={card[0].color}
					number={card[0].total}
					numberCanceled={card[1].total}
				/>
			</Grid>
		));
		return cardsMap;
	};
	return (
		<Grid className={classes.container} container spacing={2}>
			{renderCards()}
		</Grid>
	);
};

export default DoubleCardRow;
