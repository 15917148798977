import { createObjectFromArray } from 'helpers';
import { ALL_VESTIBULARES } from 'helpers/vestibular';

export const getFontesOptions = () => [
	{ label: 'Vestibulares Anteriores', value: 'Vestibulares Anteriores' },
	{ label: 'Adaptada', value: 'Adaptada' },
	{ label: 'FB Online', value: 'FB Online' },
];

export const getVestibularOptions = () =>
	createObjectFromArray(ALL_VESTIBULARES, ['label', 'value']);
