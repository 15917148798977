import React from 'react';
import {
	Grid,
	Box,
	InputLabel,
	Select,
	MenuItem,
	ListItemText,
	TextField,
	Link,
	IconButton,
} from '@material-ui/core';
import { InsertLink } from '@material-ui/icons';
import {
	NotificationFeedInput,
	IconTypes,
	NotificationFeedAction,
} from '../types';
import DeeplinkDialogOptions from './deeplink-dialog-options';
import ActionsNotification from './notification-actions';

const MessageInputsSection: React.FC<{
	state: NotificationFeedInput;
	handleOnSetState: (values: Partial<NotificationFeedInput>) => void;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ state, handleOnSetState, handleOnChange }) => {
	const [openDeeplinkHelp, setOpenDeeplinkHelp] = React.useState(false);

	const handleOnChangeActions = React.useCallback(
		(actions: NotificationFeedAction[]) => {
			handleOnSetState({
				actions,
			});
		},
		[handleOnSetState],
	);
	return (
		<>
			{/* Icone */}
			<Grid container spacing={3} style={{ marginBottom: 4 }}>
				<Grid item spacing={1} xs="auto">
					<InputLabel id="iconType-select">Tipo do ícone</InputLabel>
					<Select
						name="iconType"
						labelId="iconType-select"
						variant="outlined"
						displayEmpty
						defaultValue={state.iconType}
						onChange={event => {
							handleOnSetState({
								iconType: event.target.value as string,
							});
						}}
						style={{ minWidth: 150 }}
						placeholder="Tipo do ícone"
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value="" disabled>
							<em>Selecione uma tipo</em>
						</MenuItem>
						{IconTypes.map(option => (
							<MenuItem key={option.value} value={option.value}>
								<ListItemText primary={option.label} />
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item spacing={1} xs="auto">
					<Box>
						<Link
							href="https://oblador.github.io/react-native-vector-icons/"
							target="_blank"
						>
							Acesse a biblioteca de ícones
						</Link>
					</Box>
					<TextField
						id="icon"
						name="icon"
						label="Ícone ou URL"
						onChange={handleOnChange}
						placeholder="URL ou nome do ícone"
						value={state.icon}
						multiline
						variant="outlined"
					/>
				</Grid>
			</Grid>
			{/* Inputs */}
			<Grid container spacing={1}>
				<Grid item spacing={1} xs={12}>
					<TextField
						id="title"
						value={state.title}
						name="title"
						fullWidth
						onChange={handleOnChange}
						label="Title"
						multiline
						variant="outlined"
					/>
				</Grid>
				<Grid item spacing={1} xs={12}>
					<TextField
						id="description"
						name="description"
						fullWidth
						label="Descrição"
						multiline
						onChange={handleOnChange}
						value={state.description}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} direction="row">
					<TextField
						id="link"
						name="link"
						style={{ width: '80%' }}
						label="Deeplink"
						placeholder="fbvest://"
						onChange={handleOnChange}
						value={state.link}
						variant="outlined"
					/>
					<IconButton onClick={() => setOpenDeeplinkHelp(true)}>
						<InsertLink />
					</IconButton>
					<DeeplinkDialogOptions
						id="deeplink-menu"
						keepMounted
						open={openDeeplinkHelp}
						onClose={value => {
							setOpenDeeplinkHelp(false);
							if (value) {
								handleOnSetState({
									link: value,
								});
							}
						}}
						value={state.link}
					/>
				</Grid>
			</Grid>
			{/* Actions */}
			<ActionsNotification
				actions={state.actions}
				onChange={handleOnChangeActions}
			/>
		</>
	);
};

export default MessageInputsSection;
