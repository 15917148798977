import React, { useMemo, useState } from 'react';
import {
	Grid,
	Typography,
	Card,
	Divider,
	Box,
	IconButton,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { NotificationFeedData } from 'screens/FeedNotification/types';
import { MUTATION_SEND_FEED_NOTIFICATION_AS_PUSH } from 'screens/FeedNotification/api/mutations';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ConfirmAlertDialog } from 'components';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ClearIcon from '@mui/icons-material/Clear';

import dayjs, { Dayjs } from 'dayjs';
import { useStyles } from '../../styles';

const getDate = (date: any) => {
	// @ts-ignore
	if (moment(date)._d.toString() === 'Invalid Date') {
		return moment(parseInt(date, 10)).format('DD/MM/YYYY hh:mm:ss A');
	}
	return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY hh:mm:ss A');
};

type MSendMessageAsPushNotification = {
	notificationId: string;
	platform?: string[];
	useDeeplink?: boolean;
	sendAfter?: string;
};

const PushNotificationAction: React.FC<{
	data: NotificationFeedData | null;
	refetch?: () => void;
}> = ({ data, refetch }) => {
	const [sendAfter, setSendAfter] = useState<null | Dayjs>(null);
	const classes = useStyles();
	const [openDialog, setDialog] = useState(false);
	const [
		sendMessageAsPushNotification,
		{ loading, data: response },
	] = useMutation<
		{
			sendMessageAsPushNotification: {
				id?: string;
				recipients?: number;
				sendAfter?: string;
			};
		},
		MSendMessageAsPushNotification
	>(MUTATION_SEND_FEED_NOTIFICATION_AS_PUSH);

	const handleSendPushNotification = async () => {
		try {
			const variables: MSendMessageAsPushNotification = {
				notificationId: data._id,
			};
			if (sendAfter) {
				variables.sendAfter = sendAfter.toISOString();
			}
			await sendMessageAsPushNotification({
				variables,
			});
			toast.success('Notificação enviada com sucesso!');
			refetch?.();
		} catch (error) {
			toast.error('Falhou ao tentar enviar a notificação.');
		}
	};

	const onDialogChange = React.useCallback(() => {
		setDialog(values => !values);
	}, []);

	const handleSubmit = () => {
		if (!loading) handleSendPushNotification();

		onDialogChange();
	};

	const renderDetailsPush = useMemo(() => {
		if (
			!data?.sentAsPushNotification &&
			!response?.sendMessageAsPushNotification?.id
		)
			return null;

		return (
			<>
				<Grid item spacing={1} xs={12}>
					<p>
						<strong>Onesignal ID:</strong>{' '}
						{data?.pushNotificationDetails?.id ||
							response?.sendMessageAsPushNotification?.id ||
							'N/A'}
					</p>
					<a
						href={`https://dashboard.onesignal.com/apps/a47d48f0-0983-4f72-8bf0-33d97d7c43d3/notifications/${
							data?.pushNotificationDetails?.id ||
							response?.sendMessageAsPushNotification?.id
						}#outcomes=os__click__count,os__confirmed_delivery__count`}
						target="_blank"
						rel="noreferrer"
					>
						Abrir Mensagem
					</a>
				</Grid>
				<Grid item spacing={1} xs={12}>
					<strong>Recipients: </strong>
					{data?.pushNotificationDetails?.recipients ||
						response?.sendMessageAsPushNotification?.recipients ||
						'N/A'}
				</Grid>
				<Grid item spacing={1} xs={12}>
					<strong>Enviado em:</strong> {getDate(data?.sentPushNotificationAt)}
				</Grid>
				{data?.pushNotificationDetails?.sendAfter ? (
					<Grid item spacing={1} xs={12}>
						<strong>Agendado para em:</strong>{' '}
						{getDate(data?.pushNotificationDetails?.sendAfter)}
					</Grid>
				) : null}
			</>
		);
	}, [
		data?.sentAsPushNotification,
		data?.pushNotificationDetails?.id,
		data?.pushNotificationDetails?.recipients,
		data?.sentPushNotificationAt,
		response?.sendMessageAsPushNotification?.id,
		response?.sendMessageAsPushNotification?.recipients,
		data?.pushNotificationDetails?.sendAfter,
	]);

	if (!data) return null;
	return (
		<Card className={classes.cardSection}>
			<ConfirmAlertDialog
				open={openDialog}
				handleClose={onDialogChange}
				handleSubmit={handleSubmit}
				sendAfter={sendAfter ? getDate(sendAfter.toDate()) : null}
			/>
			<Grid container spacing={3}>
				{/* Coluna A */}
				<Grid item spacing={1} xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item spacing={1} xs={12}>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								component="p"
								color="initial"
							>
								Push Notification
							</Typography>
						</Grid>
						{renderDetailsPush}
					</Grid>
					<Divider className={classes.divider} />
					{!data?.sentAsPushNotification ||
					!!response?.sendMessageAsPushNotification ? (
						<>
							<Box mb={3}>
								<Typography
									style={{ fontWeight: 'bold', marginBottom: 4 }}
									variant="h6"
									component="h6"
									color="initial"
								>
									Agendar o envio
								</Typography>
								<Typography
									style={{ marginBottom: 12 }}
									variant="body1"
									component="p"
									color="initial"
								>
									Preencha o data para agendar a notificação.
								</Typography>
								<Box display="flex" alignItems="center">
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker
											renderInput={props => <TextField {...props} />}
											label="Escolha data"
											value={sendAfter}
											minDate={dayjs(new Date())}
											onChange={newValue => {
												setSendAfter(newValue as any);
											}}
											disableMaskedInput
											rifmFormatter={value =>
												value
													? moment(value)
															.format('DD/MM/YYYY hh:MM A')
															.toString()
													: value
											}
											ignoreInvalidInputs
											closeOnSelect
										/>
									</LocalizationProvider>
									{sendAfter ? (
										<IconButton
											aria-label="clear date"
											onClick={() => {
												setSendAfter(null);
											}}
										>
											<ClearIcon />
										</IconButton>
									) : null}
								</Box>
							</Box>
							<LoadingButton
								variant="contained"
								disableElevation
								color="primary"
								loading={loading}
								onClick={onDialogChange}
								endIcon={<Delete />}
							>
								Enviar Push Notification
							</LoadingButton>
						</>
					) : null}
				</Grid>
			</Grid>
		</Card>
	);
};

export default PushNotificationAction;
