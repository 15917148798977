import React from 'react';
import {
	FormControl,
	FormHelperText,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { getTagsOptions } from 'screens/CadastrarDesafio/api/utils';

interface Props {
	name: string;
	value: string;
	onChange: any;
	onChangeStatus?: any;
	onChangeVestibular?: any;
	error?: boolean | undefined;
	helperText?: string | false | undefined;
	disabled?: boolean;
	variables?: any;
}

const SelectTag: React.FC<Props> = ({
	name,
	value,
	onChange,
	error,
	helperText,
	disabled = false,
}) => (
	<FormControl
		variant="outlined"
		error={error}
		className="mr-2"
		style={{ minWidth: '60%', maxWidth: '100%' }}
	>
		{onChange && (
			<TextField
				fullWidth
				variant="outlined"
				label="Escolha a Tag"
				InputLabelProps={{ shrink: true }}
				name={name}
				select
				value={value}
				onChange={onChange}
				disabled={disabled}
			>
				{getTagsOptions.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
		)}
		<FormHelperText>{helperText}</FormHelperText>
	</FormControl>
);

export default SelectTag;
