import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	card: {
		padding: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	container: {
		marginBottom: theme.spacing(3),
	},
	button: { backgroundColor: '#53AF50', color: theme.palette.common.white },
}));
