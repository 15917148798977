export const getQueryVariables = filters => {
	const newFilters = { ...filters };
	Object.keys(newFilters).forEach(filter => {
		if (
			newFilters[filter] === null ||
			newFilters[filter] === 0 ||
			newFilters[filter] === ''
		) {
			delete newFilters[filter];
		}
	});
	return newFilters;
};
