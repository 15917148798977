import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Item } from '../utils';
import { useStyles } from '../styles';
import { UPDATE_SIMULADO_FB } from '../api';

export const ProvaInfo = ({ prova, simuladoId, indexProva }) => {
	const classes = useStyles();
	const [provaMinutos, setProvaMinutos] = useState(prova.tempoDeProva);

	const handleChangeProva = event => {
		setProvaMinutos(event.target.value);
	};

	const [updateTempoProva] = useMutation(UPDATE_SIMULADO_FB);

	const updateTempo = async () => {
		try {
			const objProva =
				indexProva === 0
					? [{ tempoDeProva: Number(provaMinutos) }]
					: [{}, { tempoDeProva: Number(provaMinutos) }];
			await updateTempoProva({
				variables: {
					_id: simuladoId,
					simulado: {
						provas: objProva,
					},
				},
			});
			toast.success('Simulado atualizado com sucesso');
		} catch (e) {
			toast.error('Não foi possivel atualizar o simulado');
		}
	};

	return (
		<Item>
			<Grid
				container
				spacing={3}
				direction="column"
				className={classes.containerEdit}
			>
				<Box mb={3}>
					<Typography variant="h6" style={{ fontWeight: 'bold' }}>
						{prova.nome}
					</Typography>
				</Box>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						label="Quantidade de questões"
						value={prova.totalQuestoes}
						disabled
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<TextField
						fullWidth
						variant="outlined"
						label="Duração em minutos"
						value={provaMinutos}
						onChange={handleChangeProva}
					/>
				</Grid>
				<Button
					variant="contained"
					className={classes.editButton}
					startIcon={<Save />}
					fullWidth={false}
					onClick={updateTempo}
				>
					Salvar
				</Button>
			</Grid>
		</Item>
	);
};
