import { gql } from '@apollo/client';

export const GET_FEED_NOTIFICATIONS = gql`
	query Notifications($sentFrom: String, $limit: Int, $skip: Int) {
		notifications(sentFrom: $sentFrom, limit: $limit, skip: $skip) {
			_id
			audience
			message {
				title
				description
				link
				actions {
					label
					link
				}
			}
			receiver
			sentBy
			sentName
			sentFrom
			createdAt
		}
		countNotifications(sentFrom: $sentFrom)
	}
`;
export const GET_FEED_NOTIFICATION_BY_ID = gql`
	query Notification($notificationId: String) {
		notification(notificationId: $notificationId) {
			_id
			audience
			seen {
				userId
			}
			hide {
				userId
			}
			interacted {
				userId
			}
			interact {
				userId
			}
			message {
				title
				description
				link
				icon
				iconType
				actions {
					label
					link
				}
			}
			receiver
			sentName
			sentFrom
			createdAt
			sentAsPushNotification
			sentPushNotificationAt
			pushNotificationDetails {
				id
				recipients
				sendAfter
			}
		}
	}
`;
