import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	button: {
		border: 0,
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'none',
		},
	},
});
