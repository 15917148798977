import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { StyledTableCell } from './StyledTableCell';

const TableHeader: React.FC = () => (
	<TableHead>
		<TableRow>
			<StyledTableCell>Vestibular</StyledTableCell>
			<StyledTableCell align="center">Ano</StyledTableCell>
			<StyledTableCell align="center">Fonte</StyledTableCell>
			<StyledTableCell align="center">Materia</StyledTableCell>
			<StyledTableCell align="center">Numero da Questão</StyledTableCell>
			<StyledTableCell align="center"> Área do Conhecimento</StyledTableCell>
			<StyledTableCell align="center"> Assertividade</StyledTableCell>
			<StyledTableCell align="center">Data de Criação</StyledTableCell>
			<StyledTableCell align="center">
				<EditIcon />
			</StyledTableCell>
			<StyledTableCell align="center">
				<ArrowDropDownCircleIcon />
			</StyledTableCell>
			<StyledTableCell align="center">
				<DeleteForeverIcon />
			</StyledTableCell>
		</TableRow>
	</TableHead>
);

export default TableHeader;
