import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardHeader,
	Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiPlus, mdiArrowLeft } from '@mdi/js';

import { Typography, Grid, makeStyles } from '@material-ui/core';
import {
	EditorHTML,
	FormAlternativas,
	FormAssuntoModal,
	SelectField,
} from 'components';
import { useQuestaoForm } from 'helpers/hooks';
import { isEmptyObject } from 'helpers';
import { materiasIMEITA } from 'helpers/questoes';
import { toast } from 'react-toastify';
import UserWrapper from '../../../screens/wrappers/UserWrapper';
import ReviewWrapper from './ReviewWrapper';
import FinalizarDialog from './FinalizarDialog';
import UserRoles from '../../../types/user';

const useStyles = makeStyles(theme => ({
	reviewContainer: {
		marginBottom: theme.spacing(2),
	},
	row: {
		display: 'block',
	},
	errosMessage: {
		color: 'red',
	},
	errosTitle: {
		fontWeight: 'bold',
		marginRight: 10,
	},
}));

const FieldsCadQuestoes = ({ questao = {}, desafioId = '' }) => {
	const classes = useStyles();
	const isReported =
		(!isEmptyObject(questao) && questao.reviewReason !== '') || questao.erros
			? questao.erros.length > 0
			: false;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const location = useLocation();
	const prova = location?.state?.prova;

	const [assuntoModal, setAssuntoModal] = useState(false);
	const [acumularQuestao, setAcumularQuestao] = useState(true);
	const navigate = useNavigate();
	const handleAcumuladorToggle = React.useCallback(
		() => setAcumularQuestao(vals => !vals),
		[],
	);
	const toggleAssuntoModal = React.useCallback(() => {
		if (questao?._id) setAssuntoModal(vals => !vals);
		else toast.info('Salve a questão para criar novos assuntos.');
	}, [questao]);
	const {
		values: {
			alternativas,
			enunciado,
			fonte,
			numeroQuestao,
			codigo,
			materia,
			vestibular,
			palavraChave,
			assuntos,
			resolucao,
			ano,
			areaConhecimento,
			competenciasValues,
			habilidadesValues,
			video,
			disponivel,
		},
		handleAddAlternativa,
		options,
		handleChange,
		handleEditorChange,
		handleSelectChange,
		handleRemoveAllAlternativas,
		submitQuestao,
		clearForm,
		afterCreateAssunto,
	} = useQuestaoForm({
		questao,
		isOpen: true,
		method: isEmptyObject(questao) ? 'post' : 'put',
		clearOnSubmit: acumularQuestao,
		desafioId,
	});

	const {
		areasConhecimento,
		fontes,
		anos,
		materias,
		vestibulares,
		palavrasChave,
		assuntosOptions,
		habilidades,
		competencias,
	} = options;

	return (
		<>
			<FormAssuntoModal
				isOpen={assuntoModal}
				materia={materia}
				toggle={toggleAssuntoModal}
				onSave={afterCreateAssunto}
			/>
			<Card>
				<CardHeader className="font-weight-bold">
					<Button onClick={() => navigate(-1)} className="mr-2" size="sm">
						<Icon
							className="mr-2"
							path={mdiArrowLeft}
							size={0.8}
							color="white"
						/>
						Voltar
					</Button>
					Cadastrar Questão
				</CardHeader>
				<CardBody>
					<ReviewWrapper questao={questao} erros={questao.erros}>
						<Grid container className={classes.reviewContainer}>
							{questao.erros ? (
								<Grid item lg={6} className={classes.row}>
									<Typography className={classes.errosTitle}>
										Razão da Revisão:
									</Typography>
									{questao.erros.map(erro => (
										<Typography className={classes.errosMessage}>
											{erro}
										</Typography>
									))}
								</Grid>
							) : null}
						</Grid>
					</ReviewWrapper>
					<UserWrapper
						roles={[
							UserRoles.ADMINISTRADOR,
							UserRoles.REVISOR,
							UserRoles.AUTOR,
						]}
					>
						<Row className="mb-3">
							<Col
								className="d-flex flex-row justify-content-start align-items-center"
								xs="12"
							>
								<p className="font-weight-bold m-0 mr-2 mb-1">
									Questão disponível
								</p>
								<input
									checked={disponivel}
									name="disponivel"
									onChange={handleChange}
									type="checkbox"
								/>
							</Col>
						</Row>
						<UserWrapper
							roles={[
								UserRoles.ADMINISTRADOR,
								UserRoles.REVISOR,
								UserRoles.AUTOR,
							]}
						>
							<Row className="mb-3">
								<Col
									className="d-flex flex-row justify-content-start align-items-center"
									xs="12"
								>
									<p className="font-weight-bold m-0 mr-2 mb-1">
										Abrir no App{' - '}
										<a href={`fbvest://questao/review/${questao?._id}`}>
											(deeplink)
										</a>
									</p>
								</Col>
							</Row>
						</UserWrapper>
					</UserWrapper>
					<Row className="justify-content-center align-items-center">
						<SelectField
							xs={12}
							sm={4}
							label="Fonte"
							name="fonte"
							onChange={handleSelectChange}
							options={fontes}
							value={fonte}
						/>
						<SelectField
							xs={12}
							sm={4}
							label="Vestibular"
							name="vestibular"
							onChange={handleSelectChange}
							options={vestibulares}
							value={vestibular}
						/>
						<SelectField
							xs={12}
							sm={4}
							name="ano"
							label="Ano"
							onChange={handleSelectChange}
							value={ano}
							options={anos}
						/>
					</Row>
					{vestibular && vestibular.label === 'ENEM' && (
						<Row>
							<SelectField
								xs={12}
								sm={6}
								label="Área de Conhecimento"
								name="areaConhecimento"
								onChange={handleSelectChange}
								options={areasConhecimento}
								value={areaConhecimento}
							/>
							<SelectField
								xs={12}
								sm={3}
								label="Competência"
								name="competencia"
								optionalLabel="(opcional)"
								noOptions="Selecione uma Área primeiro"
								onChange={handleSelectChange}
								options={competencias}
								value={competenciasValues}
								isMulti
							/>
							<SelectField
								xs={12}
								sm={3}
								label="Habilidade"
								name="habilidade"
								optionalLabel="(opcional)"
								noOptions="Selecione uma Competência primeiro"
								onChange={handleSelectChange}
								options={habilidades}
								value={habilidadesValues}
								isMulti
							/>
						</Row>
					)}

					<Row className="mt-2 justify-content-center align-items-center">
						<SelectField
							style={{ marginTop: '6px' }}
							xs={12}
							sm={6}
							md={4}
							lg={5}
							label="Palavra Chave"
							name="palavraChave"
							optionalLabel="(opcional)"
							noOptions="Selecione um Vestibular primeiro"
							onChange={handleSelectChange}
							options={palavrasChave}
							value={palavraChave}
						/>
						<Col xs={12} sm={6} md={2} lg={2}>
							<p className="font-weight-bold m-0 mb-1">Código</p>
							<Input name="codigo" value={codigo} onChange={handleChange} />
						</Col>

						<Col xs={12} sm={12} md={6} lg={5}>
							<Row className="align-items-center">
								<Col xs={7} lg={9}>
									<p className="font-weight-bold m-0 mb-1">Número da Questão</p>
								</Col>
								<Col xs={5} lg={3} className="text-right">
									<Input
										name="acumularQuestao"
										type="checkbox"
										checked={acumularQuestao}
										onChange={handleAcumuladorToggle}
									/>
									<span>Acumular</span>
								</Col>
								<Col xs={12}>
									<Input
										name="numeroQuestao"
										value={numeroQuestao}
										onChange={handleChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<SelectField
							className="mt-3"
							xs={12}
							lg={5}
							label="Matéria"
							name="materia"
							onChange={handleSelectChange}
							options={
								vestibular?.label === 'ITA' || vestibular?.label === 'IME'
									? materiasIMEITA
									: materias
							}
							value={materia}
						/>
						<SelectField
							className="mt-3"
							xs={12}
							sm={8}
							lg={5}
							isMulti
							label="Assuntos"
							name="assuntos"
							noOptions="Selecione uma Matéria primeiro"
							onChange={handleSelectChange}
							options={assuntosOptions}
							value={assuntos}
						/>
						<Col xs={12} sm={4} lg={2}>
							<Button
								color="success"
								size="sm"
								className="ml-2 mt-5"
								onClick={toggleAssuntoModal}
							>
								Assunto
								<Icon
									className="ml-2"
									path={mdiPlus}
									size={0.8}
									color="white"
								/>
							</Button>
						</Col>
					</Row>

					<Row className="mt-4">
						<Col xs={12} className="d-inline-flex justify-content-end">
							<Button
								outline
								size="small"
								color="secondary"
								onClick={clearForm}
							>
								Limpar Formulário
							</Button>
						</Col>
					</Row>

					<Row className="justify-content-center">
						<Col xs="12">
							<Card className="mt-5 mb-2">
								<CardHeader className="font-weight-bold">Enunciado</CardHeader>
								<CardBody>
									<EditorHTML
										onChangeState={handleEditorChange}
										name="enunciado"
										htmlRaw={enunciado}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col xs="12">
							<FormAlternativas
								alternativas={alternativas}
								onChangeState={handleEditorChange}
								addAlternativa={handleAddAlternativa}
								removeAllAlternativas={handleRemoveAllAlternativas}
							/>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col xs="12">
							<Card className="mt-5 mb-2">
								<CardHeader className="font-weight-bold">Resolução</CardHeader>
								<CardBody>
									<EditorHTML
										onChangeState={handleEditorChange}
										height="250px"
										name="resolucao"
										htmlRaw={resolucao}
									/>
								</CardBody>
							</Card>
						</Col>
						<Col xs="12" className="mb-2">
							<p className="font-weight-bold m-0 mb-1">
								Vídeo <small>(opcional)</small>
							</p>
							<Input
								name="video"
								value={video}
								onChange={handleChange}
								placeholder="http://youtube.com/opcional"
							/>
						</Col>
					</Row>

					<Row className="pt-5">
						<Col
							xs={12}
							md={{
								size: 3,
								order: 2,
								offset:
									!isEmptyObject(questao) && (questao.review || isReported)
										? 6
										: 9,
							}}
						>
							<button
								onClick={event => {
									event.preventDefault();
									submitQuestao(false, prova);
								}}
								type="submit"
								className="btn btn-primary btn-lg mb-2 float-right text-white font-weight-bold ls-15 px-5 w-100 w-lg-null mb-4"
							>
								Salvar
							</button>
						</Col>
						{!isEmptyObject(questao) && (questao.review || isReported) ? (
							<>
								<Col xs={12} md={{ size: 3, order: 2 }}>
									<button
										onClick={handleClickOpen}
										type="button"
										className="btn btn-primary btn-lg mb-2 float-right text-white font-weight-bold ls-15 px-5 w-100 w-lg-null mb-4"
									>
										Finalizar Revisão
									</button>
								</Col>
								<FinalizarDialog
									open={open}
									handleClose={handleClose}
									handleSubmit={() => submitQuestao(true)}
								/>{' '}
							</>
						) : null}
					</Row>
				</CardBody>
			</Card>
		</>
	);
};

FieldsCadQuestoes.propTypes = {
	questao: PropTypes.shape({
		ano: PropTypes.number,
		areaConhecimento: PropTypes.shape({
			nome: PropTypes.string,
		}),
		createdAt: PropTypes.string,
		fonte: PropTypes.string,
		numeroQuestao: PropTypes.number,
		vestibular: PropTypes.string,
		_id: PropTypes.string,
	}),
};

export default React.memo(FieldsCadQuestoes);
