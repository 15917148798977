import React, { useState } from 'react';

export const usePage = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const onChangePage = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		newPage: number,
	) => setPage(newPage);

	const onChangeRowsPerPage = (
		rowPerPage: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		setRowsPerPage(Number(rowPerPage.target.value));
	};
	return {
		page,
		onChangePage,
		rowsPerPage,
		onChangeRowsPerPage,
	};
};
