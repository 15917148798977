import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		height: '50vh',
		marginTop: '10%',
	},
});
