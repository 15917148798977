import { gql } from '@apollo/client';

export const FETCH_SIMULADO = gql`
	query simulado($_id: ID!) {
		revisarSimulado(_id: $_id) {
			_id
			nome
			vestibular
			disponivel
			totalQuestoes
			ano
			fonte
			pubDate
			provas {
				nome
				tempoDeProva
				totalQuestoes
				questoes {
					_id
					vestibular
					ano
					enunciado
					resolucao
					fonte
					materia
					numeroQuestao
					areaConhecimento {
						nome
					}
					createdAt
				}
			}
		}
	}
`;
