import React from 'react';
import { Grid, Typography, Card, Avatar, Box } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useStyles } from '../styles';
import { NotificationFeedInput } from '../types';
import { formatDateFrom } from '../utils/formatDateFrom';

const NotificationItemExample: React.FC<{
	state: NotificationFeedInput | null;
	createdAt?: string;
}> = ({ state, createdAt }) => {
	const classes = useStyles();
	const sentDate = React.useMemo(() => formatDateFrom(createdAt), [createdAt]);
	if (!state) return null;
	return (
		<>
			<Typography component="span" variant="h5">
				Notificação
			</Typography>
			<Card
				style={{
					marginTop: 16,
					maxWidth: 500,
					minHeight: 50,
					height: 'auto',
					paddingTop: 16,
					paddingBottom: 16,
					paddingRight: 24,
					paddingLeft: 24,
					backgroundColor: 'rgba(118,253,182, 0.12)',
					alignSelf: 'center',
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={1} style={{ position: 'relative' }}>
						<div className={classes.badge} />
						<div className={classes.imageCircle}>
							{state.iconType === 'url' ? (
								<Avatar src={state.icon} className={classes.image} />
							) : (
								<div className={classes.image}>
									<InfoOutlined
										style={{
											color: '#495057',
											fontSize: 24,
											margin: 0,
											padding: 0,
											top: -1,
											position: 'relative',
										}}
									/>
								</div>
							)}
						</div>
					</Grid>
					<Grid item xs={9}>
						{state.title ? (
							<Typography
								component="p"
								display="inline"
								className={classes.titleNotification}
							>
								{state.title}{' '}
							</Typography>
						) : null}
						<Typography
							display="inline"
							component="p"
							style={{ overflowWrap: 'anywhere' }}
						>
							{state.description}
						</Typography>
						{state?.actions?.length ? (
							<Box marginTop={1}>
								{state?.actions?.map(action => (
									<div className={classes.actionButton}>
										<span className={classes.actionLabel}>{action?.label}</span>
									</div>
								))}
							</Box>
						) : null}
					</Grid>

					<Grid
						item
						xs={2}
						style={{
							alignItems: 'flex-end',
							flexDirection: 'column',
							alignSelf: 'flex-start',
							textAlign: 'right',
						}}
					>
						<Typography component="p" align="right">
							{sentDate ?? '1h'}
						</Typography>

						<MoreHoriz style={{ marginTop: 8 }} />
					</Grid>
				</Grid>
			</Card>
		</>
	);
};

export default NotificationItemExample;
