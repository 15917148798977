import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { makeStyles, LinearProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableRows from './TableBody';
import TableHeader from './TableHeader';
import { LISTAR_DESAFIOS } from './queries';

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});

interface Props {
	pagination?: boolean;
	checkable?: boolean;
	collapsable?: boolean;
	variables: any;
	pageConfig: {
		page: number;
		onChangePage: (
			event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
			newPage: number,
		) => void;
		rowsPerPage: number;
		onChangeRowsPerPage: (
			rowPerPage: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => void;
	};
}
let loaded = false;
const TabelaDesafios: React.FC<Props> = ({
	pagination,
	checkable,
	collapsable,
	variables,
	pageConfig: { page, onChangePage, rowsPerPage, onChangeRowsPerPage },
}) => {
	const classes = useStyles();
	const [desafioInfo, setdesafioInfo] = useState([]);

	const { data, loading, refetch, networkStatus } = useQuery(LISTAR_DESAFIOS, {
		variables,
	});

	const isLoading = useMemo(
		() => loading || networkStatus === NetworkStatus.refetch,
		[loading, networkStatus],
	);

	useEffect(() => {
		if (loaded) {
			loaded = false;
			refetch();
		}
		loaded = true;
		return () => {
			loaded = true;
		};
	}, [page, refetch]);

	if (isLoading) {
		return <LinearProgress />;
	}

	return (
		<Paper>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHeader
						checkable={checkable}
						collapsable={collapsable}
						desafios={data}
						desafioInfo={desafioInfo}
					/>
					<TableRows
						refetch={refetch}
						desafios={data?.desafios}
						desafioInfo={desafioInfo}
						setdesafioInfo={setdesafioInfo}
					/>
				</Table>
			</TableContainer>
			{pagination ? (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
					colSpan={3}
					count={data?.desafiosCountFilter ?? 0}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
				/>
			) : null}
		</Paper>
	);
};

export default TabelaDesafios;
