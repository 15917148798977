import React, { useMemo, useState } from 'react';
import { IQuestao } from 'types/questao';

interface Context {
	selectedQuestions: string[];
	setSelectedQuestions: any;
	selectQuestion: (questionId: string) => void;
	unselectQuestion: (questionId: string) => void;
	desafioQuestoes: IQuestao[];
	selecionadas: number;
}

export const CheckQuestionContext = React.createContext<Context>({
	selectedQuestions: [],
	setSelectedQuestions: () => {},
	selectQuestion: () => {},
	unselectQuestion: () => {},
	desafioQuestoes: [],
	selecionadas: 0,
});

interface Props {
	children: React.ReactElement;
	desafioQuestoes: IQuestao[];
	setSelecionadas: (arg: number) => void;
	selecionadas: number;
}

const CheckQuestionProvider: React.FC<Props> = ({
	children,
	desafioQuestoes,
	setSelecionadas,
	selecionadas,
}) => {
	const initialQuestions = useMemo(
		() => desafioQuestoes?.map(desafioQuestao => desafioQuestao._id),
		[desafioQuestoes],
	);
	const [selectedQuestions, setSelectedQuestions] = useState(initialQuestions);
	const selectQuestion = (questionId: string) => {
		setSelectedQuestions(prevQuestions => {
			setSelecionadas([...prevQuestions, questionId].length);
			return [...prevQuestions, questionId];
		});
	};
	const unselectQuestion = (questionId: string) => {
		setSelectedQuestions(prevQuestions => {
			const filteredQuestions = prevQuestions.filter(
				question => question !== questionId,
			);

			setSelecionadas(filteredQuestions.length);
			return [...filteredQuestions];
		});
	};

	return (
		<CheckQuestionContext.Provider
			value={{
				selectedQuestions,
				selectQuestion,
				setSelectedQuestions,
				unselectQuestion,
				desafioQuestoes,
				selecionadas,
			}}
		>
			{children}
		</CheckQuestionContext.Provider>
	);
};

export default CheckQuestionProvider;
