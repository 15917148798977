import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsFillCaretDownFill } from 'react-icons/bs';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Grid } from '@material-ui/core';
import { ADMIN_CAD_USERS_SCREEN } from 'screens/AdminCadUsers/routes';
import UserRoles from 'types/user';
import UserWrapper from 'screens/wrappers/UserWrapper';
import {
	LISTAR_DESAFIOS_ROUTE,
	CADASTRAR_USUARIO,
	CHANGE_PASSWORD,
	LISTAR_SIMULADOS,
} from 'constants/routes';
import { Dispatch, RootState } from 'store/store';
import { useStyles } from './styles';

export default function MenuPopupState() {
	const navigate = useNavigate();
	const dispatch = useDispatch<Dispatch>();
	const loggedUser = useSelector((state: RootState) => state.auth.user);
	const styles = useStyles();

	function sair() {
		localStorage.clear();
		dispatch.auth.logout();
		navigate('/login');
	}

	return (
		<div className={styles.dropdownContainer}>
			<PopupState variant="popover" popupId="demo-popup-menu">
				{(popupState: any) => (
					<>
						<Grid {...bindTrigger(popupState)}>
							<span className={styles.dropdownTitle}>{loggedUser.nome}</span>
							<img src="avatar_image.png" alt="Avatar" width="25" height="25" />
							<BsFillCaretDownFill
								color="white"
								className={styles.dropdownIcon}
								size={10}
							/>
						</Grid>
						<Menu {...bindMenu(popupState)}>
							<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
								<MenuItem onClick={() => navigate(ADMIN_CAD_USERS_SCREEN)}>
									Usuários
								</MenuItem>
							</UserWrapper>
							<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
								<MenuItem onClick={() => navigate(CADASTRAR_USUARIO)}>
									Cadastrar Usuário
								</MenuItem>
							</UserWrapper>

							<MenuItem onClick={() => navigate(CHANGE_PASSWORD)}>
								Alterar Senha
							</MenuItem>
							<UserWrapper
								roles={[UserRoles.ADMINISTRADOR, UserRoles.COLABORADOR]}
							>
								<MenuItem onClick={() => navigate(LISTAR_SIMULADOS)}>
									Listar Simulados
								</MenuItem>
							</UserWrapper>
							<UserWrapper
								roles={[UserRoles.ADMINISTRADOR, UserRoles.COLABORADOR]}
							>
								<MenuItem onClick={() => navigate(LISTAR_DESAFIOS_ROUTE)}>
									Listar Desafios
								</MenuItem>
							</UserWrapper>
							<MenuItem onClick={() => sair()}>Sair</MenuItem>
						</Menu>
					</>
				)}
			</PopupState>
		</div>
	);
}
