import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiPencil, mdiDelete, mdiEye } from '@mdi/js';

import { DetalhesQuestao, QuestaoModal } from 'components';

const TableRow = styled(Row)`
	border: 1px lightgray solid;
	border-radius: 5px;
	margin: 1px;
	margin-bottom: 3px;
	font-size: 15px;
`;

const TableCol = styled(Col)`
	padding-bottom: 5px;
	padding-top: 5px;
`;

const HeaderCol = styled(TableCol)`
	font-weight: bold;
	padding-bottom: 5px;
	padding-top: 5px;
	font-size: 17px;
	text-justify: center;
`;

const ItemQuestao = ({ index, questao }) => {
	const [isInfoOpen, setInfo] = useState(false);
	const [isEditOpen, setEdit] = useState(false);

	const toggleInfo = () => setInfo(!isInfoOpen);
	const toggleEdit = () => setEdit(!isEditOpen);

	return (
		<>
			<DetalhesQuestao
				isOpen={isInfoOpen}
				toggle={toggleInfo}
				questao={questao}
			/>
			<QuestaoModal isOpen={isEditOpen} toggle={toggleEdit} questao={questao} />
			<TableRow>
				<HeaderCol lg="1" md="1" sm="1" xs="1">
					{index + 1}
				</HeaderCol>
				<TableCol lg="3" md="3" sm="3" xs="3">
					{questao.palavraChave}
				</TableCol>
				<TableCol lg="2" md="3" sm="3" xs="3">
					{questao.materia}
				</TableCol>
				<TableCol lg="2" md="2" sm="2" xs="2" className="d-none d-sm-block">
					{questao.fonte}
				</TableCol>
				<TableCol lg="2" className="d-none d-lg-block">
					{questao.ano}
				</TableCol>
				<TableCol lg="2" md="3" sm="3" xs="5">
					<Button
						size="sm"
						color="secondary"
						className="ml-1 mb-1"
						onClick={toggleInfo}
					>
						<Icon path={mdiEye} size={0.8} color="white" />
					</Button>
					<Button
						size="sm"
						color="primary"
						className="ml-1 mb-1"
						onClick={toggleEdit}
					>
						<Icon path={mdiPencil} size={0.8} color="white" />
					</Button>
					<Button size="sm" color="danger" className="ml-1 mb-1">
						<Icon path={mdiDelete} size={0.8} color="white" />
					</Button>
				</TableCol>
			</TableRow>
		</>
	);
};

ItemQuestao.propTypes = {
	index: PropTypes.number.isRequired,
	questao: PropTypes.instanceOf(Object),
};

export default memo(ItemQuestao);
