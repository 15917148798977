import { object, string, number, array } from 'yup';

export const questoesEnemValidator = object({
	ano: object({
		label: string().required('Você precisa selecionar um Ano'),
		value: string().required('Você precisa selecionar um Ano'),
	}).typeError('Você precisa selecionar um Ano'),
	vestibular: object({
		label: string().required('Você precisa selecionar um Vestibular'),
		value: string().required('Você precisa selecionar um Vestibular'),
	}).typeError('Você precisa selecionar um Vestibular'),
	fonte: object({
		label: string().required('Você precisa selecionar uma Fonte'),
		value: string().required('Você precisa selecionar uma Fonte'),
	}).typeError('Você precisa selecionar uma Fonte'),
	assuntos: array().when('disponivel', {
		is: true,
		then: array()
			.required('Você precisa selecionar ao menos 1 assunto')
			.typeError('Você precisa selecionar ao menos 1 assunto'),
		otherwise: array().nullable(),
	}),
	codigo: string().nullable(),
	numeroQuestao: number()
		.typeError('Número da Questão é obrigatório')
		.min(1)
		.required('Número da Questão é obrigatório'),
	areaConhecimento: object().when('vestibular.label', {
		is: 'ENEM',
		then: object({
			label: string().required(
				'Você precisa selecionar uma Área de Conhecimento',
			),
			value: string().required(
				'Você precisa selecionar uma Área de Conhecimento',
			),
		}).typeError('Você precisa selecionar uma Área de Conhecimento'),
		otherwise: object().nullable(),
	}),
	// Valores opcionais!
	// palavraChave: object({
	// 	label: string().required('Você precisa selecionar uma Palavra Chave'),
	// 	value: string().required('Você precisa selecionar uma Palavra Chave'),
	// }).typeError('Você precisa selecionar uma Palavra Chave'),
	// competenciasValues: array().when('vestibular.label', {
	// 	is: 'ENEM',
	// 	then: array(
	// 		object({
	// 			label: string().required('Você precisa selecionar uma Competência'),
	// 			value: string().required('Você precisa selecionar uma Competência'),
	// 		}),
	// 	).typeError('Você precisa selecionar uma Competência'),
	// 	otherwise: object().nullable(),
	// }),
	// habilidadesValues: array().when('vestibular.label', {
	// 	is: 'ENEM',
	// 	then: array(
	// 		object({
	// 			label: string().required('Você precisa selecionar uma Habilidade'),
	// 			value: string().required('Você precisa selecionar uma Habilidade'),
	// 		}),
	// 	).typeError('Você precisa selecionar uma Habilidade'),
	// 	otherwise: object().nullable(),
	// }),
	materia: object({
		label: string().required('Você precisa selecionar uma Matéria'),
		value: string().required('Você precisa selecionar uma Matéria'),
	}).typeError('Você precisa selecionar uma Matéria'),
	enunciado: string().min(9, 'O Enunciado da Questão não pode ficar em branco'),
	alternativas: array(
		object({
			texto: string().min(
				1,
				'Descrição das alternativas não pode ficar em branco',
			),
		}),
	)
		.min(1, 'Você precisa de pelo menos 1 alternativa')
		.test('alternativas', 'Selecione uma Alternativa correta', objs => {
			const isOneCorreta = objs.find(it => it.correta);
			if (isOneCorreta) {
				return true;
			}
			return false;
		}),
});

export const questoesNotEnemValidator = object({
	ano: object({
		label: string().required('Você precisa selecionar um Ano'),
		value: string().required('Você precisa selecionar um Ano'),
	}).typeError('Você precisa selecionar um Ano'),
	vestibular: object({
		label: string().required('Você precisa selecionar um Vestibular'),
		value: string().required('Você precisa selecionar um Vestibular'),
	}).typeError('Você precisa selecionar um Vestibular'),
	fonte: object({
		label: string().required('Você precisa selecionar uma Fonte'),
		value: string().required('Você precisa selecionar uma Fonte'),
	}).typeError('Você precisa selecionar uma Fonte'),
	assuntos: array().when('disponivel', {
		is: true,
		then: array()
			.required('Você precisa selecionar ao menos 1 assunto')
			.typeError('Você precisa selecionar ao menos 1 assunto'),
		otherwise: array().nullable(),
	}),
	codigo: string().nullable(),
	// Valor opcional!
	// palavraChave: object({
	// 	label: string().required('Você precisa selecionar uma Palavra Chave'),
	// 	value: string().required('Você precisa selecionar uma Palavra Chave'),
	// }).typeError('Você precisa selecionar uma Palavra Chave'),
	numeroQuestao: number()
		.typeError('Número da Questão é obrigatório')
		.min(1)
		.required('Número da Questão é obrigatório'),
	areaConhecimento: object().when('vestibular.label', {
		is: 'ENEM',
		then: object({
			label: string().required(
				'Você precisa selecionar uma Área de Conhecimento',
			),
			value: string().required(
				'Você precisa selecionar uma Área de Conhecimento',
			),
		}).typeError('Você precisa selecionar uma Área de Conhecimento'),
		otherwise: object().nullable(),
	}),
	materia: object({
		label: string().required('Você precisa selecionar uma Matéria'),
		value: string().required('Você precisa selecionar uma Matéria'),
	}).typeError('Você precisa selecionar uma Matéria'),
	enunciado: string().min(9, 'O Enunciado da Questão não pode ficar em branco'),
	alternativas: array(
		object({
			texto: string().min(
				9,
				'Descrição das alternativas não pode ficar em branco',
			),
		}),
	)
		.min(1, 'Você precisa de pelo menos 1 alternativa')
		.test('alternativas', 'Selecione uma Alternativa correta', objs => {
			const isOneCorreta = objs.find(it => it.correta);
			if (isOneCorreta) {
				return true;
			}
			return false;
		}),
});
