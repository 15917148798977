import React, { useEffect } from 'react';
import { Button, Box } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
	ADMIN_USER_REQUEST_DELETE_ACCOUNT,
	ADMIN_USER_REVOKE_REQUEST_DELETE_ACCOUNT,
} from '../api/mutation';
import { GET_ONE_REQUEST_DELETE_ACCOUNT } from '../api/query';

let loaded = false;

export const UserDeleteRevoke = ({ uid }) => {
	const { data, refetch } = useQuery(GET_ONE_REQUEST_DELETE_ACCOUNT, {
		variables: { uid },
	});

	useEffect(() => {
		if (loaded) {
			loaded = false;
			refetch();
		}
		loaded = true;
		return () => {
			loaded = true;
		};
	}, [uid, refetch]);

	const [userDeleteAccount] = useMutation(ADMIN_USER_REQUEST_DELETE_ACCOUNT);
	const [userRevokeDeleteAccount] = useMutation(
		ADMIN_USER_REVOKE_REQUEST_DELETE_ACCOUNT,
	);

	const UserDeleteAccount = async () => {
		try {
			await userDeleteAccount({
				variables: {
					uid,
				},
			});
			toast.success('Conta deletada com sucesso!');
		} catch (e) {
			toast.error('Erro ao deletar a conta.');
		}
	};

	const UserRevokeDeleteAccount = async () => {
		try {
			await userRevokeDeleteAccount({
				variables: {
					uid,
				},
			});
			toast.success('Conta resgatada com sucesso!');
		} catch (e) {
			toast.error('Erro ao resgatar a conta.');
		}
	};

	return (
		<Box style={{ paddingTop: '20px' }}>
			<Button
				size="small"
				variant="contained"
				color={data?.getOneRequestDeleteAccount ? 'primary' : 'secondary'}
				onClick={
					data?.getOneRequestDeleteAccount
						? UserRevokeDeleteAccount
						: UserDeleteAccount
				}
			>
				{data?.getOneRequestDeleteAccount ? 'Resgatar conta' : 'Deletar conta'}
			</Button>
		</Box>
	);
};

export default UserDeleteRevoke;
