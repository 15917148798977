import { makeStyles } from '@material-ui/core/styles';
import { primaryBlue } from 'constants/colors';

export const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 'bold',
		color: primaryBlue,
	},
	titleRow: {
		marginBottom: theme.spacing(10),
		justifyContent: 'space-between',
	},
	titleContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	backButton: {
		marginRight: theme.spacing(5),
	},
	button: {
		color: primaryBlue,
	},
	backButtonGrid: {
		position: 'absolute',
	},
	container: {
		backgroundColor: '#F2F2F2',
		minHeight: '100vh',
	},
	body: {
		height: '100%',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		paddingBottom: theme.spacing(5),
	},
	filterHeader: {
		marginBottom: theme.spacing(5),
	},
}));
