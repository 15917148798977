import moment from 'moment';

moment.updateLocale('pt-br', {
	relativeTime: {
		future: 'em %s',
		past: '%s atrás',
		s: 'agora',
		ss: '%d s',
		m: '1 m',
		mm: '%d m',
		h: '1 h',
		hh: '%d h',
		d: '1 d',
		dd: '%d d',
		M: '1 m',
		MM: '%d m',
		y: '1 a',
		yy: '%d a',
	},
});

export const formatDateFrom = (date: string) =>
	date ? moment(new Date(+date)).fromNow(true) : undefined;
