import { createModel } from '@rematch/core';
import { RootModel } from '..';

export const INITIAL_STATE = {
	materias: null,
	isFechting: false,
	error: false,
};

export const materiasModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		changeState: (state, props) => ({
			...state,
			...props,
		}),
		requestMaterias: state => ({ ...state, isFechting: true, error: false }),
		requestSuccess: (state, payload) => ({
			...state,
			isFechting: false,
			...payload,
		}),
		requestFailure: state => ({ ...state, isFechting: false, error: true }),
		clearFields: () => ({ ...INITIAL_STATE }),
	},
	effects: () => ({
		async fetchGetMaterias() {
			/**
			 * Carregar Materias
			 */
			// try {
			//   // type: materias/REQUEST
			//   dispatch.materiasModel.requestMaterias();
			//   // const { data: materias } = await api.get(`/materias`);
			//   dispatch.materiasModel.requestSuccess({ materias });
			// } catch (err) {
			//   dispatch.assuntosModel.stopLoading();
			// }
		},
	}),
});
