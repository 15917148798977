export const createAnoOptions = anoInicial => {
	const anoFinal = new Date().getFullYear();
	const totalElementos = anoFinal - anoInicial + 1;
	return Array.from({ length: totalElementos }, (_, index) => ({
		label: `${anoInicial + index}`,
		value: anoInicial + index,
	})).reverse();
};

export const anoOptions = [
	{ label: 'ANO', value: 0 },
	...createAnoOptions(2009),
];

export const ascOptions = [
	{ label: 'ORDENAR', value: 0 },
	{ label: 'CRESCENTE', value: true },
	{ label: 'DECRESCENTE', value: false },
];
export const orderOptions = [
	{ label: 'CAMPO', value: 0 },
	{ label: 'Nº QUESTÃO', value: false },
	{ label: 'ASSERTIVIDADE', value: true },
];

export const getPalavrasChaveOptions = ({ palavrasChave }) => [
	{ label: 'PALAVRA CHAVE', value: 0 },
	...palavrasChave.map(palavraChave => ({
		label: palavraChave.nome,
		value: palavraChave.nome,
	})),
];

export const getMateriaOptions = ({ materias }) => [
	{ label: 'MATERIAS', value: 0 },
	...(materias || [])?.map(materia => ({
		label: materia.nome,
		value: materia.nome,
	})),
];

export const getVestibularOptions = ({ vestibulares }) => [
	{ label: 'VESTIBULARES', value: 0 },
	...vestibulares.map(vestibular => ({
		label: vestibular.nome,
		value: vestibular.nome,
	})),
];

export const getFontesOptions = ({ fontes }) => [
	...fontes.map(fonte => ({
		label: fonte.nome,
		value: fonte.nome,
	})),
];

export const status = [
	{ label: 'Indisponivel', value: 'indisponivel' },
	{ label: 'Disponivel', value: 'disponivel' },
	{ label: 'Vencido', value: 'vencido' },
];

export const permissao = [
	{ label: 'Premium', value: 'premium' },
	{ label: 'Freemium', value: 'freemium' },
];

export const tipo = [
	{ label: 'Autor', value: 'autor' },
	{ label: 'Revisor', value: 'revisor' },
	{ label: 'Admin', value: 'admin' },
	{ label: 'Collaborator', value: 'collaborator' },
	{ label: 'Partner', value: 'partner' },
];

export const publicado = [
	{ label: 'Ordem crescente', value: 'ascending' },
	{ label: 'Ordem decrescente', value: 'descending' },
];

export const dataFinal = [
	{ label: 'Ordem crescente', value: 'ascending' },
	{ label: 'Ordem decrescente', value: 'descending' },
];

export const totalRespondido = [
	{ label: 'Ordem crescente', value: 'ascending' },
	{ label: 'Ordem decrescente', value: 'descending' },
];
