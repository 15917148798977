import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {
	NavHeader,
	SelectMateriaListar,
	TabelaDesafios,
	SelectVestibularListar,
	SelectDisponivel,
	SelectPermissao,
	SelectPublicado,
	SelectDataFinal,
	SelectTotalRespondido,
} from 'components';
import { CADASTRAR_DESAFIO_ROUTE } from 'constants/routes';
import { getQueryVariables } from 'helpers/queries';

import { usePage } from 'components/TabelaDesafios/usePage';
import DesafiosContext from 'screens/ListarDesafios/providers/DesafiosProvider';
import { useStyles } from './styles';

const ListarDesafios: React.FC = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { page, onChangePage, rowsPerPage, onChangeRowsPerPage } = usePage();

	const {
		materia,
		selectMateria,
		selectStatus,
		selectVestibular,
		selectPermissao,
		selectPublicado,
		selectDataFinal,
		selectTotalRespondido,
		owner,
		disponivel,
		vestibular,
		permissao,
		expired,
		publicado,
		dataFinal,
		totalRespondido,
	} = useContext(DesafiosContext);

	const isDisponivel = (status: string) => {
		switch (status) {
			case 'disponivel':
				return true;
			case 'indisponivel':
				return false;
			default:
				return '';
		}
	};

	const isPremium = (permission: string) => {
		switch (permission) {
			case 'premium':
				return 2;
			case 'freemium':
				return 1;
			default:
				return undefined;
		}
	};

	return (
		<Container className={classes.container} maxWidth="xl" disableGutters>
			<NavHeader headerStyle={{ padding: '20px 5.75% 10px 5.75%' }} />
			<div className={classes.body}>
				<Grid
					className={classes.titleRow}
					container
					direction="row"
					alignItems="flex-end"
				>
					<Grid item>
						<Button
							className={classes.button}
							onClick={() => navigate(-1)}
							startIcon={<ArrowBack />}
						>
							Voltar
						</Button>
					</Grid>
					<Grid item className={classes.titleContainer}>
						<Typography className={classes.title} variant="h4">
							Lista de Desafios
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							className={classes.button}
							size="small"
							startIcon={<AddIcon />}
							onClick={() => navigate(CADASTRAR_DESAFIO_ROUTE)}
						>
							Cadastrar
						</Button>
					</Grid>
				</Grid>

				<Grid container className={classes.filterHeader}>
					<Grid item xs={8} justify="space-between">
						<SelectMateriaListar
							name="materia"
							value={materia}
							onChange={selectMateria}
						/>
						<SelectVestibularListar
							name="vestibular"
							value={vestibular}
							onChange={selectVestibular}
						/>
						<SelectDisponivel
							name="disponivel"
							value={disponivel}
							onChange={selectStatus}
						/>
						<SelectPermissao
							name="permissao"
							value={permissao}
							onChange={selectPermissao}
						/>
						<SelectPublicado
							name="publicado"
							value={publicado}
							onChange={selectPublicado}
						/>
						<SelectDataFinal
							name="dataFinal"
							value={dataFinal}
							onChange={selectDataFinal}
						/>
						<SelectTotalRespondido
							name="totalRespondido"
							value={totalRespondido}
							onChange={selectTotalRespondido}
						/>
					</Grid>
				</Grid>
				<TabelaDesafios
					variables={{
						desafioFilters: getQueryVariables({
							materia,
							owner,
							disponivel: isDisponivel(disponivel),
							vestibular,
							permissionLevel: isPremium(permissao),
						}),
						expired,
						pubDateFilter: publicado,
						dataFinalFilter: dataFinal,
						totalRespFilter: totalRespondido,
						limit: rowsPerPage,
						skip: page * rowsPerPage,
					}}
					pagination
					pageConfig={{ page, onChangePage, rowsPerPage, onChangeRowsPerPage }}
				/>
			</div>
		</Container>
	);
};

export default ListarDesafios;
