import { createStyles, TableCell, withStyles, Theme } from '@material-ui/core';
import { primaryGreen } from 'constants/colors';

export const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			fontSize: 16,
			backgroundColor: primaryGreen,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 12,
		},
	}),
)(TableCell);
