import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
	Grid,
	Typography,
	Box,
	TextField,
	Select,
	MenuItem,
	ListItemText,
	InputLabel,
	Card,
	Button,
	LinearProgress,
	ListItemIcon,
	Checkbox,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { IDesafio } from 'types/desafio';
import { Alert } from '@material-ui/lab';
import { useMemo } from 'react';
import { useDialog } from 'screens/CadastrarDesafio/hooks';
import { useMutation } from '@apollo/client';
import { SEND_CAMPANHA_DESAFIO } from 'screens/CadastrarDesafio/api';
import { mapOrderByArray } from 'helpers';
import {
	AudienceOptions,
	CampaignAudience,
	CampaignInput,
	CampaignModel,
	CampaignType,
	DefaultSubject,
} from './types';
import CampanhaHistorico from './historico';
import AlertDialog from './confirm-dialog';
import { generateObjetivosLabel } from './utils';

interface Props {
	open: boolean;
	handleClose?: () => void;
	desafio?: IDesafio;
	refetch?: any;
}

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: '#53AF50',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	button: { color: theme.palette.common.white },
	container: { backgroundColor: '#FFFFFF' },
}));

const CampanhaDialog: React.FC<Props> = ({ open, handleClose, desafio }) => {
	const classes = useStyles();
	const {
		open: openAlertDilag,
		handleClose: handleAlertClose,
		handleOpen,
	} = useDialog();

	const [enviarCampanha, { loading }] = useMutation(SEND_CAMPANHA_DESAFIO);
	const [state, setState] = useState<CampaignInput>({
		type: CampaignType.Email,
		model: CampaignModel.Desafio,
		modelId: desafio?._id,
		audience: '',
		subject: '',
		url: '',
		displayName: '',
		email: '',
	});

	const handleOnSetState = useCallback((values: Partial<CampaignInput>) => {
		setState(vals => ({ ...vals, ...values }));
	}, []);

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setState(vals => ({
				...vals,
				[event.target.name]: event.target.value,
			}));
		},
		[],
	);

	const renderAlertAudiencia = useMemo(
		() => (
			<Grid item xs={12}>
				{state?.audience === CampaignAudience.Custom && (
					<Alert severity="info">
						<Typography
							variant="body1"
							component="p"
							color="initial"
							style={{ fontWeight: 'bold' }}
						>
							Audiência Customizada
						</Typography>
						<Typography variant="body2" component="p" color="initial">
							Esse forma é para enviar um e-mail por usuário. Pode ser utilizado
							para testes e não tem limite de envio.
						</Typography>
					</Alert>
				)}
				{state?.audience && state?.audience !== CampaignAudience.Custom && (
					<Alert severity="info">
						<Typography
							variant="body1"
							component="p"
							color="initial"
							style={{ fontWeight: 'bold' }}
						>
							Audiência Todos, PREMIUM e FREEMIUM
						</Typography>
						<Typography variant="body2" component="p" color="initial">
							Só é permitido envio de uma campanha por audiência;
						</Typography>
						<Typography variant="body2" component="p" color="initial">
							O E-mail do lançamento do desafio será enviado para toda a base do
							banco com cadastro confirmado.
						</Typography>
					</Alert>
				)}
			</Grid>
		),
		[state.audience],
	);

	const hanldeOnSubmit = async () => {
		handleAlertClose();
		const campaign = Object.keys(state).reduce((acc, key) => {
			// @ts-ignore
			if (state[key]) {
				return {
					...acc,
					// @ts-ignore
					[key]: state[key],
				};
			}
			return acc;
		}, {});
		// console.log(campaign);
		await enviarCampanha({
			variables: {
				campaign,
			},
		})
			.then(() => {
				toast.success('Campanha enviada com sucesso!');
			})
			.catch(err => {
				console.log(err);
				if (err?.message) toast.error(err?.message);
			});
	};

	const objetivosOptions = useMemo(() => generateObjetivosLabel(), []);

	const renderMultpleSelect = useCallback(
		name => (
			<Select
				name={name}
				labelId={`${name}-select`}
				multiple
				onChange={event => {
					handleOnSetState({
						[name]: mapOrderByArray(
							event.target.value as string[],
							objetivosOptions[name],
							'nome',
						),
					});
				}}
				value={state?.[name] || []}
				renderValue={selected => {
					const values = (selected as string[])?.join(', ');
					if (!values) return 'Selecione as opções';
					return values;
				}}
				displayEmpty
				variant="outlined"
				inputProps={{ 'aria-label': 'Without label' }}
			>
				<MenuItem value="" disabled>
					<em>Selecione os {name}</em>
				</MenuItem>
				{objetivosOptions?.[name]?.map(option => (
					<MenuItem key={option.value} value={option.value}>
						<ListItemIcon>
							<Checkbox checked={state?.[name]?.includes(option.value)} />
						</ListItemIcon>
						<ListItemText primary={option.label} />
					</MenuItem>
				))}
			</Select>
		),
		[objetivosOptions, state, handleOnSetState],
	);

	return (
		<div className={classes.container}>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AlertDialog
					campanha={state}
					open={openAlertDilag}
					handleClose={handleAlertClose}
					handleSubmit={hanldeOnSubmit}
				/>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							E-mail Marketing
						</Typography>
					</Toolbar>
				</AppBar>
				<Box padding={3} component="div" flex={1}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6" color="initial">
								{desafio?.nome} | Vestibular {desafio?.vestibular} |{' '}
								{desafio?.totalQuestoes} Questões
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<InputLabel id="audience-select">Audiência</InputLabel>
							<Select
								name="audience"
								labelId="audience-select"
								variant="outlined"
								displayEmpty
								defaultValue=""
								onChange={event => {
									handleOnSetState({
										audience: event.target.value as string,
									});
								}}
								style={{ minWidth: 150 }}
								placeholder="Audiências"
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value="" disabled>
									<em>Selecione uma audiência</em>
								</MenuItem>
								{AudienceOptions.map(option => (
									<MenuItem key={option.value} value={option.value}>
										<ListItemText primary={option.label} />
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} lg="auto">
							<InputLabel id="vestibulares-select">Vestibulares</InputLabel>
							{renderMultpleSelect('vestibulares')}
						</Grid>

						<Grid item xs={12} lg="auto">
							<InputLabel id="materias-select">Materias</InputLabel>
							{renderMultpleSelect('materias')}
						</Grid>

						{renderAlertAudiencia}
					</Grid>
					<Card style={{ marginTop: 8 }}>
						<Box padding={2} component="div" flex={1}>
							{loading && <LinearProgress />}
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Alert severity="warning">
										<Typography
											variant="body1"
											component="p"
											color="initial"
											style={{ fontWeight: 'bold' }}
										>
											IMPORTANTE
										</Typography>
										<Typography
											variant="body2"
											component="p"
											color="initial"
											style={{ fontWeight: 'bold' }}
										>
											Subject
										</Typography>

										<Typography variant="body2" component="p" color="initial">
											- Existe uma mensagem padrão para o usuário, caso queira
											costumizar use o campo abaixo
										</Typography>
										<Typography variant="body2" component="p" color="initial">
											- Na mensagem customizada conter o nome do usuário,
											adicione
											{` {{displayName}}`} na string.
										</Typography>
										<Typography
											variant="body2"
											component="p"
											color="initial"
											style={{ fontWeight: 'bold' }}
										>
											Link
										</Typography>

										<Typography variant="body2" component="p" color="initial">
											- CTA padrão do e-mail é abrir a tela com listagem de
											todos os desafios.
										</Typography>
										<Typography variant="body2" component="p" color="initial">
											- Use o campo abaixo para alterar o link.
										</Typography>
									</Alert>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{ marginTop: 8 }}>
								<Grid item xs={12} md={6} lg={3}>
									<TextField
										fullWidth
										name="subject"
										label="Subject do e-mail"
										placeholder={DefaultSubject}
										variant="outlined"
										onChange={handleOnChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<TextField
										fullWidth
										name="url"
										label="Link do e-mail"
										placeholder="Aqui será o link do e-mail do usuário para abrir o desafio ou desafios"
										variant="outlined"
										onChange={handleOnChange}
									/>
								</Grid>
								{state?.audience === CampaignAudience.Custom && (
									<>
										<Grid item xs={12} md={6} lg={3}>
											<TextField
												fullWidth
												name="displayName"
												label="Nome do Usuário"
												placeholder="Digite o nome do usuário"
												variant="outlined"
												onChange={handleOnChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={3}>
											<TextField
												fullWidth
												name="email"
												label="E-mail"
												type="email"
												placeholder="Digite e-mail"
												variant="outlined"
												onChange={handleOnChange}
											/>
										</Grid>
									</>
								)}
							</Grid>
							<Grid container spacing={2} style={{ marginTop: 8 }}>
								<Grid item xs={12}>
									<Button
										type="button"
										variant="contained"
										color="primary"
										onClick={handleOpen}
										disabled={
											!state?.audience ||
											(state?.audience === CampaignAudience.Custom &&
												(!state.displayName || !state.email))
										}
									>
										Enviar campanha
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Card>
					{desafio?._id && <CampanhaHistorico modelId={desafio?._id} />}
				</Box>
			</Dialog>
		</div>
	);
};

export default CampanhaDialog;
