import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DeeplinksOptions } from '../types';

const DeeplinkDialogOptions: React.FC<{
	id: string;
	keepMounted: boolean;
	open: boolean;
	value: string;
	onClose?: (value?: string) => void;
}> = ({ open, value: valueProp, onClose, ...other }) => {
	const [value, setValue] = React.useState(valueProp);
	const radioGroupRef = React.useRef<HTMLElement>(null);

	React.useEffect(() => {
		if (!open) {
			setValue(valueProp);
		}
	}, [valueProp, open]);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose?.();
	};

	const handleOk = () => {
		onClose?.(value);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth="xs"
			TransitionProps={{ onEntering: handleEntering }}
			open={open}
			{...other}
		>
			<DialogTitle>Lista de Deeplink</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					aria-label="ringtone"
					name="ringtone"
					value={value}
					onChange={handleChange}
				>
					{DeeplinksOptions.map(option => (
						<FormControlLabel
							value={`fbvest://${option.link}`}
							key={option.link}
							control={<Radio />}
							label={option.title}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel}>
					Fechar
				</Button>
				<Button onClick={handleOk}>Inserir</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeeplinkDialogOptions;
