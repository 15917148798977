import { makeStyles } from '@material-ui/core';
import { primaryBlue } from 'constants/colors';

export const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 'bold',
		color: primaryBlue,
	},
	headerTitle: {
		marginBottom: theme.spacing(5),
	},
	backButton: {
		color: primaryBlue,
	},
	row: {
		marginBottom: theme.spacing(5),
	},
	container: {
		backgroundColor: '#F2F2F2',
		height: '100%',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		paddingBottom: theme.spacing(5),
	},
	containerEdit: {
		padding: 8,
	},
	button: { backgroundColor: '#53AF50', color: theme.palette.common.white },
	editButton: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
		width: '50%',
	},
}));
