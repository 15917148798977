import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import { LISTAR_SIMULADOS } from 'constants/routes';
import { BancoQuestoes } from 'screens/CadastrarDesafio/components/BancoQuestoes';
import { CheckQuestionContext } from 'screens/CadastrarDesafio/provider/CheckQuestionProvider';
import { ADD_QUESTOES_SIMULADO } from '../api/mutations';

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: '#53AF50',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	button: { color: theme.palette.common.white },
	container: { backgroundColor: '#F2F2F2' },
}));

interface Props {
	open: boolean;
	handleClose: () => void;
	idSimulado: string;
	refetch: any;
	prova: number;
}

const SimuladoDialog: React.FC<Props> = ({
	open,
	idSimulado,
	handleClose,
	refetch,
	prova,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const [addQuestoesSimulado] = useMutation(ADD_QUESTOES_SIMULADO, {
		onCompleted: refetch,
	});
	const { selectedQuestions } = useContext(CheckQuestionContext);

	const saveQuestion = async () => {
		try {
			await addQuestoesSimulado({
				variables: {
					idSimulado,
					questoes: selectedQuestions,
					prova,
				},
			});
			navigate(`${LISTAR_SIMULADOS}/${idSimulado}`);
			handleClose();
		} catch (error: any) {
			toast.error('Erro ao salvar questões.');
		}
	};
	return (
		<div className={classes.container}>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							Banco de Questões
						</Typography>
						<Button
							data-testid="salvar-button"
							variant="contained"
							className={classes.button}
							size="small"
							color="primary"
							startIcon={<AddIcon />}
							type="submit"
							onClick={saveQuestion}
						>
							Salvar Questões
						</Button>
					</Toolbar>
				</AppBar>
				<BancoQuestoes />
			</Dialog>
		</div>
	);
};

export default SimuladoDialog;
