import { gql } from '@apollo/client';

export const CREATE_DESAFIO = gql`
	mutation createDesafio($desafio: InputDesafio) {
		desafio: createDesafio(desafio: $desafio) {
			_id
			nome
		}
	}
`;

export const UPDATE_DESAFIO = gql`
	mutation updateDesafio($_id: String!, $desafio: FilterDesafio) {
		desafio: updateDesafio(_id: $_id, desafio: $desafio) {
			_id
			nome
			questoes
		}
	}
`;

export const CLONE_DESAFIO = gql`
	mutation cloneDesafio($desafioId: String!) {
		desafio: cloneDesafio(desafioId: $desafioId) {
			_id
			nome
		}
	}
`;

export const UPDATE_MANY_DESAFIOS = gql`
	mutation updateManyDesafio($_id: [String]!, $desafio: FilterDesafio) {
		updateManyDesafio(_id: $_id, desafio: $desafio) {
			dataFinal
			pontuacao
			tempoMaximo
			tag
			permissionLevel
			desafiosAlterados {
				_id
				totalQuestoes
				nome
				materia
				tempoMaximo
				pontuacao
				pubDate
				dataFinal
				active
				permissionLevel
				ownerName
			}
			desafiosErros
		}
	}
`;

export const PUBLISH_DESAFIO = gql`
	mutation publishDesafio($_id: String!) {
		publishDesafio(_id: $_id)
	}
`;

export const UNPUBLISH_DESAFIO = gql`
	mutation unpublishDesafio($_id: String!) {
		unpublishDesafio(_id: $_id)
	}
`;

export const PUBLISH_MANY_DESAFIO = gql`
	mutation publishDesafio($_id: [String]!) {
		publishManyDesafio(_id: $_id) {
			status
			id
		}
	}
`;

export const UNPUBLISH_MANY_DESAFIO = gql`
	mutation unpublishDesafio($_id: [String]!) {
		unpublishManyDesafio(_id: $_id) {
			status
			id
		}
	}
`;

export const CREATE_QUESTAO_DESAFIO = gql`
	mutation createQuestaoDesafio($idDesafio: String!, $questao: QuestaoInput) {
		createQuestaoDesafio(idDesafio: $idDesafio, questao: $questao) {
			_id
		}
	}
`;

export const ADD_QUESTOES_DESAFIO = gql`
	mutation addQuestoesDesafio($idDesafio: String!, $questoes: [String]) {
		addQuestoesDesafio(idDesafio: $idDesafio, questoes: $questoes) {
			_id
		}
	}
`;

export const SEND_CAMPANHA_DESAFIO = gql`
	mutation sendCampanhaEmailDesafio($campaign: CampaignInput) {
		isSendCampanha: sendCampanhaEmailDesafio(campaign: $campaign)
	}
`;

export const CREATE_QUESTAO_SIMULADO = gql`
	mutation createQuestaoSimulado(
		$idSimulado: String!
		$questao: QuestaoInput
		$prova: Int!
	) {
		createQuestaoSimulado(
			idSimulado: $idSimulado
			questao: $questao
			prova: $prova
		) {
			_id
		}
	}
`;
