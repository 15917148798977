import { gql } from '@apollo/client';

export const FIND_APP_USER = gql`
	query FindUser($searchTerm: String) {
		findUser(searchTerm: $searchTerm) {
			found
			user {
				uid
				displayName
				email
				createdAt
				photoURL
				lastLoginAt
				lastAction
				providers {
					providerId
				}
				customData {
					deviceInfo {
						platform
						version
					}
				}
				extraUserInfo {
					playerId
				}
				objetivos {
					materia
					vestibular
					updatedAt
					createdAt
				}
				customClaims {
					level
					completeRegister
					subscriptions {
						planId
						parceiro
						validationCode
						nome
						status
						externalParceiro
						createdAt
						startedAt
						expireAt
						canceledAt
					}
				}
				historicoAproveitamento {
					materias {
						nome
						total
						acertos
						erros
						aproveitamento
						assuntos {
							nome
							acertos
							erros
						}
					}
					total
					acertos
					erros
					aproveitamento
				}
				notification {
					desafio {
						push
						email
					}
				}
			}
		}
	}
`;

export const GET_ONE_REQUEST_DELETE_ACCOUNT = gql`
	query getOneRequestDeleteAccount($uid: String!) {
		getOneRequestDeleteAccount(uid: $uid) {
			uid
			email
			displayName
		}
	}
`;

export const FIND_USER_VALIDATION_CODE = gql`
	query findUserValidationCode($validationCode: String!) {
		findUserValidationCode(validationCode: $validationCode) {
			found
			user {
				uid
				displayName
				email
				createdAt
				photoURL
				lastLoginAt
				lastAction
				providers {
					providerId
				}
				customData {
					deviceInfo {
						platform
						version
					}
				}
				extraUserInfo {
					playerId
				}
				objetivos {
					materia
					vestibular
					updatedAt
					createdAt
				}
				customClaims {
					level
					completeRegister
					subscriptions {
						planId
						parceiro
						validationCode
						nome
						status
						externalParceiro
						createdAt
						startedAt
						expireAt
						canceledAt
					}
				}
				historicoAproveitamento {
					materias {
						nome
						total
						acertos
						erros
						aproveitamento
						assuntos {
							nome
							acertos
							erros
						}
					}
					total
					acertos
					erros
					aproveitamento
				}
				notification {
					desafio {
						push
						email
					}
				}
			}
		}
	}
`;
