import React from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { selectStyles } from 'helpers';

const SelectField = ({
	label,
	optionalLabel,
	name,
	onChange,
	options,
	value,
	noOptions,
	isMulti,
	...props
}) => (
	<Col xs={12} className="mb-2" {...props}>
		<p className="font-weight-bold m-0 mb-1">
			{label} <p style={{ fontSize: '10px' }}>{optionalLabel}</p>
		</p>
		<Select
			isMulti={isMulti}
			name={name}
			classNamePrefix="select"
			noOptionsMessage={() => noOptions}
			onChange={onChange}
			options={options}
			value={value}
			styles={selectStyles}
			placeholder="Selecionar..."
		/>
	</Col>
);

SelectField.defaultProps = {
	noOptions: 'Sem Dados',
	isMulti: false,
};

SelectField.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	value: PropTypes.oneOfType([
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.instanceOf(Object),
			]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
					PropTypes.instanceOf(Object),
				]),
				label: PropTypes.string,
			}),
		),
	]),
	noOptions: PropTypes.string,
	isMulti: PropTypes.bool,
};

export default SelectField;
