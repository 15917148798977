import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Container,
	Grid,
	Typography,
	Button,
	LinearProgress,
	Chip,
	Box,
	Select,
	MenuItem,
} from '@material-ui/core';
import { ArrowBack, Add } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { NavHeader, TabelaQuestoes } from 'components';

import UserWrapper from 'screens/wrappers/UserWrapper';
import UserRoles from 'types/user';
import { useMutation } from '@apollo/client';
import CheckQuestionProvider from '../CadastrarDesafio/provider/CheckQuestionProvider';
import { CADASTRAR_DESAFIO_ROUTE, LISTAR_SIMULADOS } from '../../constants';
import { REMOVE_QUESTOES_SIMULADO } from './api';
import { useStyles } from './styles';
import { useLazySimulado, useDialog } from './hooks';
import { SimuladoDialog } from './components/index';
import { GeneralInfo } from './GeneralInfo';
import { ProvaInfo } from './ProvaInfo';
import SimuladoConfirmDialog from './SimuladoDialog/simuladoConfirmDialog';
import SimuladoExcludeDialog from './SimuladoDialog/simuladoConfirmExclude';

const RevisarSimulados: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const params = useParams();
	const [selectedProva, setSelectedProva] = useState(0);
	const {
		data,
		loading,
		error,
		called,
		refetch,
		handlePublish,
	} = useLazySimulado(params?.simuladoId);
	const { open, handleClose, handleOpen } = useDialog();
	const [selecionadas, setSelecionadas] = useState(0);
	const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState([]);
	const [openDialog, setDialog] = useState(false);
	const [openDialogExclude, setDialogExclude] = useState(false);
	const [removeQuestaoSimulado] = useMutation(REMOVE_QUESTOES_SIMULADO, {
		onCompleted: refetch,
	});

	const onDialogChange = React.useCallback(() => {
		setDialog(values => !values);
	}, []);

	const onDialogChangeExclude = React.useCallback(() => {
		setDialogExclude(values => !values);
	}, []);

	const handleSubmit = () => {
		handlePublish(data?.revisarSimulado?.disponivel);
		onDialogChange();
	};

	const handleDelete = async () => {
		await removeQuestaoSimulado({
			variables: {
				idSimulado: params.simuladoId,
				prova: selectedProva,
				questoes: selectedQuestion.map(({ id }) => id),
			},
		});
		onDialogChangeExclude();
	};

	if (called && loading) {
		return (
			<>
				<NavHeader headerStyle={{ padding: '20px 5.5% 10px 5.5%' }} />
				<LinearProgress />
			</>
		);
	}
	if (error) {
		return <div>Error</div>;
	}

	const handleChange = async (event: any) => {
		setSelectedProva(event.target.value);
	};

	const handleToggleExcluir = async () => {
		if (isCheckboxVisible && selectedQuestion.length > 0) {
			setDialogExclude(true);
		}
		setIsCheckboxVisible(curr => !curr);
	};

	return (
		<>
			<NavHeader headerStyle={{ padding: '20px 5.5% 10px 5.5%' }} />
			<Container maxWidth="xl" className={classes.container}>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
					className={classes.headerTitle}
				>
					<Button
						className={classes.backButton}
						onClick={() => {
							navigate(LISTAR_SIMULADOS);
						}}
						startIcon={<ArrowBack />}
					>
						Voltar
					</Button>

					<Typography variant="h4" className={classes.title}>
						Revisar Simulado
					</Typography>
					<Chip
						label={
							data?.revisarSimulado.disponivel ? 'Disponível' : 'Indisponível'
						}
						color={data?.revisarSimulado.disponivel ? 'primary' : 'secondary'}
					/>
				</Grid>

				<GeneralInfo data={data} />
				{data?.revisarSimulado?.provas.map((prova, index) => (
					<ProvaInfo
						prova={prova}
						simuladoId={params.simuladoId}
						indexProva={index}
					/>
				))}
				<Box my={3}>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						className={classes.row}
					>
						<Typography variant="h4" className={classes.title}>
							Questões do Simulado
						</Typography>
						<Button
							data-testid="salvar-button"
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<QuestionAnswerIcon />}
							type="submit"
							disabled={!params.simuladoId}
							onClick={handleOpen}
						>
							Selecionar do Banco
						</Button>
						<Button
							data-testid="salvar-button"
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<Add />}
							type="submit"
							disabled={!params.simuladoId}
							onClick={() =>
								navigate(
									`${CADASTRAR_DESAFIO_ROUTE}/${params.simuladoId}/questao`,
									{
										state: {
											prova: selectedProva,
										},
									},
								)
							}
						>
							Adicionar Questão
						</Button>
						<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
							<SimuladoExcludeDialog
								handleClose={onDialogChangeExclude}
								open={openDialogExclude}
								handleSubmit={handleDelete}
							/>
							<SimuladoConfirmDialog
								handleClose={onDialogChange}
								open={openDialog}
								handleSubmit={handleSubmit}
							/>
							<Button
								variant="contained"
								className={classes.button}
								size="large"
								startIcon={<PublishIcon />}
								type="submit"
								disabled={!params.simuladoId}
								onClick={() => setDialog(true)}
							>
								{data?.revisarSimulado?.disponivel
									? 'Despublicar Simulado'
									: 'Publicar Simulado'}
							</Button>
						</UserWrapper>
					</Grid>
					<CheckQuestionProvider
						desafioQuestoes={
							data ? data?.revisarSimulado?.provas[selectedProva]?.questoes : []
						}
						setSelecionadas={setSelecionadas}
						selecionadas={selecionadas}
					>
						<>
							<SimuladoDialog
								open={open}
								idSimulado={params.simuladoId as string}
								refetch={refetch}
								handleClose={handleClose}
								prova={selectedProva}
							/>
							{!params.desafioId ? null : (
								<TabelaQuestoes
									refetch={refetch}
									desafioId={params.desafioId}
									questoes={data ? data.desafio.questoes : []}
								/>
							)}
						</>
					</CheckQuestionProvider>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						className={classes.row}
					>
						<Select
							value={selectedProva}
							labelWidth={300}
							onChange={handleChange}
							style={{ width: 400 }}
						>
							{data?.revisarSimulado?.provas.map((prova, index) => (
								<MenuItem value={index}>{prova.nome}</MenuItem>
							))}
						</Select>
						<Button
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<DeleteForeverIcon />}
							type="submit"
							disabled={!params.simuladoId}
							onClick={handleToggleExcluir}
						>
							{isCheckboxVisible ? 'Excluir' : 'Selecionar Questões'}
						</Button>
					</Grid>
				</Box>
				{!params.simuladoId ? null : (
					<TabelaQuestoes
						prova={selectedProva}
						selectQuestion={setSelectedQuestion}
						deleteCheckbox={isCheckboxVisible}
						refetch={refetch}
						desafioId={params.simuladoId}
						questoes={
							data?.revisarSimulado?.provas
								? data?.revisarSimulado?.provas[selectedProva]?.questoes
								: []
						}
					/>
				)}
			</Container>
		</>
	);
};

export default RevisarSimulados;
