import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { range } from 'helpers';

class ItemPagination extends Component {
	render() {
		const {
			currentPage,
			totalPages,
			className,
			neighborSize,
			onChange,
			fetchMore,
		} = this.props;
		const startPage = Math.max(0, currentPage - neighborSize);
		const endPage = Math.min(totalPages, currentPage + neighborSize);

		const pages = range(startPage, endPage);

		return (
			<Pagination className={className}>
				<PaginationItem className="ml-1 mr-1" disabled={currentPage === 0}>
					<PaginationLink onClick={() => onChange(0)}>«</PaginationLink>
				</PaginationItem>

				<PaginationItem className="ml-1 mr-1" disabled={currentPage === 0}>
					<PaginationLink onClick={() => onChange(currentPage - 1)}>
						‹
					</PaginationLink>
				</PaginationItem>

				{pages.map(number => (
					<PaginationItem
						key={number}
						active={currentPage === number}
						className="ml-1 mr-1"
					>
						<PaginationLink
							onClick={() => {
								onChange(number);
								fetchMore({
									variables: {
										skip: number * 20,
									},
								});
							}}
						>
							{number + 1}
						</PaginationLink>
					</PaginationItem>
				))}
				<PaginationItem
					className="ml-1 mr-1"
					disabled={currentPage === totalPages}
				>
					<PaginationLink onClick={() => onChange(currentPage + 1)}>
						›
					</PaginationLink>
				</PaginationItem>
				<PaginationItem
					className="ml-1 mr-1"
					disabled={currentPage === totalPages}
				>
					<PaginationLink onClick={() => onChange(totalPages)}>
						»
					</PaginationLink>
				</PaginationItem>
			</Pagination>
		);
	}
}

ItemPagination.defaultProps = {
	neighborSize: 2,
};

ItemPagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	neighborSize: PropTypes.number,
	className: PropTypes.string,
	fetchMore: PropTypes.func,
};

export default ItemPagination;
