import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Navbar, NavbarToggler, Nav, NavItem, Collapse } from 'reactstrap';
import styled from 'styled-components';

const LinkElem = styled(NavLink)`
	font-size: 16px;
	&.active {
		border-bottom: 2px solid black;
	}
	&.active:hover {
		border-bottom: 2px solid black;
	}
	:hover {
		border-bottom: 2px solid lightgray;
	}
	:focus {
		border-bottom: 2px solid black;
	}
`;

const Header = () => {
	const location = useLocation();

	const [isOpen, setOpen] = useState(false);
	const toggleOpen = () => setOpen(!isOpen);

	if (
		location.pathname === '/' ||
		location.pathname === '/login' ||
		location.pathname === '/listar'
	) {
		/**
		 * Não mostrar Header na página inicial (Dashboard)
		 */
		return null;
	}

	const items = [
		{ name: 'Ínicio', to: '/' },
		{ name: 'Questões', to: '/questoes' },
		{ name: 'Assuntos', to: '/assuntos' },
	];

	return (
		<Navbar color="light" light expand="md" className="shadow">
			<NavbarToggler onClick={toggleOpen} />
			<Collapse isOpen={isOpen} navbar>
				<Nav navbar>
					{items.map(item => (
						<NavItem
							key={item.to}
							className="nav-link"
							to={item.to}
							tag={LinkElem}
						>
							{item.name}
						</NavItem>
					))}
				</Nav>
			</Collapse>
		</Navbar>
	);
};

export default Header;
