import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ChildDetailsUserProps } from './types';
import { Item } from './utils';
import * as Mutations from '../api/mutation';
import FormCompletePurchaseDialog from './form-purchase-android-dialog';

const CompltePurchaseAndroid: React.FC<ChildDetailsUserProps> = ({
	user,
	refetch,
}) => {
	const [openDialog, setDialog] = useState(false);
	const [adminCompletePurchase, { loading }] = useMutation<
		{
			adminCompletePurchase: boolean;
		},
		{
			purchase: {
				userId: string;
				sku: string;
				price?: number;
				externalParceiro: string;
				purchaseToken?: string;
				transactionId?: string;
			};
		}
	>(Mutations.MUTATION_ANDROID_COMPLETE_PURCHASE);

	const handleCompletePurchase = useCallback(
		async (sku: string, purchaseToken: string) => {
			try {
				await adminCompletePurchase({
					variables: {
						purchase: {
							sku,
							purchaseToken,
							userId: user?.uid,
							externalParceiro: 'google',
						},
					},
				});
				toast.success('Assintura Validada - Usuário agora é Premium');
				refetch?.();
			} catch (error) {
				toast.error(error?.message || 'Não possível validar compra.');
			}
		},
		[adminCompletePurchase, refetch, user?.uid],
	);

	const onDialogChange = useCallback(() => {
		setDialog(values => !values);
	}, []);

	const handleSubmit = (sku: string, purchaseToken: string) => {
		handleCompletePurchase(sku, purchaseToken);
		onDialogChange();
	};

	if (user?.customClaims.level === 2) return null;

	return (
		<Box>
			<FormCompletePurchaseDialog
				open={openDialog}
				handleSubmit={handleSubmit}
				handleClose={onDialogChange}
			/>
			<Item>
				<Box mb={3}>
					<Alert
						severity={user?.customClaims?.level === 1 ? 'warning' : 'error'}
					>
						Atenção! Casos de error de validação do recibo para compra de
						assinatura na PLay Store
					</Alert>
				</Box>
				<LoadingButton
					variant="contained"
					disableElevation
					color="secondary"
					loading={loading}
					onClick={onDialogChange}
				>
					Android - Complete Purchase
				</LoadingButton>
			</Item>
		</Box>
	);
};

export default CompltePurchaseAndroid;
