import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { NavHeader } from 'components';
import QuestionReportedContext from '../../providers/QuestionReportedProvider';
import { useStyles } from './styles';

const materialUiStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

export const Header = () => {
	const { setReported, setReview } = useContext(QuestionReportedContext);

	const styles = useStyles({ selected: false });
	const classes = materialUiStyles();

	setReported(true);
	setReview(true);

	return (
		<>
			<NavHeader />
			<div className={styles.header}>
				<div className={styles.headerTabs}>
					<Paper className={classes.root} />
				</div>
			</div>
		</>
	);
};
