import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { UsersContext } from 'screens/DashboardParceiros/provider';
import { FETCH_PLANOS } from '../../api';

const plataformas = [
	{ label: 'Android', value: 'android' },
	{ label: 'iOS', value: 'iOS' },
];

const login = [
	{ label: 'Facebook', value: 'Facebook' },
	{ label: 'Apple', value: 'Apple' },
	{ label: 'Google', value: 'Google' },
	{ label: 'Password', value: 'Password' },
];

const parceiros = [
	{ label: 'FB Online', value: 'FB Online' },
	{ label: 'ORA', value: 'ORA' },
	{ label: 'FB VEST', value: 'FB Vest' },
	{ label: 'MOB', value: 'MOB' },
	{ label: 'Farias Brito', value: 'Farias Brito' },
];

const Filters: React.FC = () => {
	const { filters, handleChange } = useContext(UsersContext);
	const { data, loading } = useQuery(FETCH_PLANOS);

	if (loading) {
		return <LinearProgress />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg={2}>
				<TextField
					fullWidth
					name="inicio"
					type="date"
					value={filters.inicio}
					variant="outlined"
					onChange={handleChange}
					size="small"
				/>
			</Grid>
			<Grid item xs={12} lg={2}>
				<TextField
					fullWidth
					name="fim"
					type="date"
					value={filters.fim}
					variant="outlined"
					onChange={handleChange}
					size="small"
				/>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="plataforma"
					variant="outlined"
					label="Plataformas"
					value={filters.plataforma}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Plataformas" value="">
						Plataformas
					</MenuItem>
					{plataformas.map(plataforma => (
						<MenuItem key={plataforma.value} value={plataforma.value}>
							{plataforma.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="parceiro"
					variant="outlined"
					label="Parceiros"
					value={filters.parceiro}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Parceiros" value="">
						Parceiros
					</MenuItem>
					{parceiros.map(parceiro => (
						<MenuItem key={parceiro.value} value={parceiro.value}>
							{parceiro.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="provider"
					variant="outlined"
					label="Formas Login"
					value={filters.provider}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Formas Login" value="">
						Formas Login
					</MenuItem>
					{login.map(formaLogin => (
						<MenuItem key={formaLogin.value} value={formaLogin.value}>
							{formaLogin.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="plano"
					variant="outlined"
					label="Planos"
					value={filters.plano}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Planos" value="">
						Planos
					</MenuItem>
					{data.planos.map((plano: any) => (
						<MenuItem key={plano.name} value={plano.name}>
							{plano.name}
						</MenuItem>
					))}
				</TextField>
			</Grid>
		</Grid>
	);
};

export default Filters;
