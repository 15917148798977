import React, { useState, useCallback, useRef } from 'react';
import {
	Button,
	TableCell,
	TableRow,
	Box,
	Typography,
	Collapse,
	InputBase,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import PasswordGenerator from 'generate-password';
import { Cached, Close, FileCopy } from '@material-ui/icons';
import { Select, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useValidCadUserToken } from 'store/models/auth/shared/user-valid-caduser-token';
import { ButtonColor } from './styles';
import { useAdminCadUsers } from '../../hooks/useAdminCadUsers';
import { ICadUser, ICadUserUpdate } from '../../types';
import { UPDATE_CAD_USER } from '../../api/mutation';

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(4n+1)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	}),
)(TableRow);
const RowCadUser: React.FC<{ user: ICadUser }> = ({ user }) => {
	const { refetch } = useAdminCadUsers();
	const { user: cadUser } = useValidCadUserToken();
	const [updateCadUser, { loading }] = useMutation(UPDATE_CAD_USER);
	const [isOpen, setCollapse] = useState(false);
	const [password, setPassword] = useState('');
	const [role, setRole] = useState(user.role);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const onUpdateCadUser = (id: string, caduser: ICadUserUpdate) =>
		updateCadUser({
			variables: {
				_id: id,
				caduser,
			},
		});

	const onSalvarPassword = async () => {
		if (!password || password.length < 6) return;
		await onUpdateCadUser?.(user._id, {
			password,
		});
		toast.success('Senha alterada!');
	};

	const onChangeStatusActive = async () => {
		if (cadUser._id === user._id) return;
		await onUpdateCadUser?.(user._id, {
			active: !user.active,
		});
		refetch();
	};

	const generatePassword = useCallback(() => {
		const newPassword = PasswordGenerator.generate({
			length: 10,
			numbers: true,
		});
		setPassword(newPassword);
	}, []);

	const handleChange = async (event: any) => {
		setRole(event.target.value);
		await onUpdateCadUser?.(user._id, {
			role: event.target.value,
		});
		toast.success('Tipo alterado!');
	};

	const copyToClipboard = () => {
		inputRef.current?.select();
		document.execCommand('copy');
		toast.info('Senha copiada!');
	};

	return (
		<>
			<StyledTableRow key={user._id}>
				<StyledTableCell component="th" scope="row">
					{user.nome || 'N/A'}
				</StyledTableCell>
				<StyledTableCell align="left">{user.email}</StyledTableCell>
				<StyledTableCell align="left">
					{' '}
					<Select
						value={role}
						onChange={handleChange}
						disabled={role === 'partner'}
						labelWidth={115}
					>
						<MenuItem value="autor">autor</MenuItem>
						<MenuItem value="revisor">revisor</MenuItem>
						<MenuItem value="admin">admin</MenuItem>
						<MenuItem value="collaborator">collaborator</MenuItem>
						<MenuItem value="partner">partner</MenuItem>
					</Select>
				</StyledTableCell>
				<StyledTableCell align="left">
					<Box
						alignItems="center"
						component="span"
						p={0.5}
						bgcolor={user.active ? 'success.main' : 'error.main'}
						borderRadius={10}
					>
						<Typography
							style={{ color: '#fff', fontSize: 12 }}
							component="span"
						>
							{user.active ? 'Ativado' : 'Desativado'}
						</Typography>
					</Box>
				</StyledTableCell>
				<StyledTableCell align="center">
					<Button
						size="small"
						variant="outlined"
						component="span"
						color="secondary"
						onClick={() => setCollapse(!isOpen)}
					>
						<Typography paragraph={false} style={{ fontSize: 12 }}>
							Resetar senha
						</Typography>
					</Button>
				</StyledTableCell>
				<StyledTableCell align="center">
					<ButtonColor
						size="small"
						variant="contained"
						color="primary"
						disabled={loading || cadUser._id === user._id}
						active={user.active}
						onClick={onChangeStatusActive}
					>
						<Typography paragraph={false} style={{ fontSize: 12 }}>
							{user.active ? 'Desativar' : 'Ativar'}
						</Typography>
					</ButtonColor>
				</StyledTableCell>
			</StyledTableRow>
			<TableRow>
				<TableCell colSpan={6} style={{ padding: 0 }}>
					<Collapse in={isOpen} timeout="auto" unmountOnExit className="w-100">
						<Box p={2} textAlign="center" flexDirection="row">
							<Box
								flexDirection="row"
								justifyContent="center"
								display="flex"
								alignItems="center"
							>
								<Box display="inline-flex" pl={4}>
									<Typography component="h6" variant="h6">
										Gerar nova senha
									</Typography>
								</Box>
								<Box display="inline-flex">
									<Tooltip title="Fechar">
										<IconButton onClick={() => setCollapse(!isOpen)}>
											<Close />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
							<Box mt={1} textAlign="center" flexDirection="row">
								<InputBase
									inputRef={inputRef}
									className="pl-3 border border-gray text-center"
									placeholder="Gere uma nova senha"
									value={password}
									onChange={event => setPassword(event.currentTarget.value)}
								/>
							</Box>
							<Box p={2} textAlign="center" flexDirection="row">
								<IconButton onClick={generatePassword}>
									<Cached color="secondary" />
								</IconButton>
								<IconButton onClick={copyToClipboard}>
									<FileCopy />
								</IconButton>
								<Button
									variant="contained"
									color="primary"
									disabled={!password || password.length < 6}
									onClick={onSalvarPassword}
								>
									Salvar
								</Button>
							</Box>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default RowCadUser;
