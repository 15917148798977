import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	chip: {
		backgroundColor: '#4DA35F',
		color: 'white',
	},
	button: {
		margin: theme.spacing(1),
		fontSize: theme.typography.pxToRem(13),
	},
	card: {
		flexDirection: 'row',
		marginBottom: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
	},
	row: { display: 'flex', flexDirection: 'row' },
	nomeOriginal: {
		fontSize: theme.typography.pxToRem(16),
	},
	nome: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(12),
	},
	vestibular: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: theme.spacing(1),
	},
	fonte: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: theme.spacing(1),
	},
	totalQuestoes: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: theme.spacing(1),
	},
	descricao: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: theme.spacing(1),
	},
}));
