import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';

export const useValidCadUserToken = (roles?: string[]) => {
	const { user, fetchRefreshToken, invalidToken } = useSelector(
		(state: RootState) => state.auth,
	);

	const isValidToken = useMemo(
		() => !invalidToken && !!user && !!user.token && !!user.expDate,
		[user, invalidToken],
	);

	const isUnauthorized = useMemo(
		() =>
			isValidToken &&
			user &&
			Date.now() < user.expDate &&
			roles &&
			!roles.find(role => role === user.role),
		[isValidToken, user, roles],
	);

	return {
		user,
		fetchRefreshToken,
		invalidToken,
		isValidToken,
		isUnauthorized,
	};
};
