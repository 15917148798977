import React from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import UserRoles from 'types/user';
import { useStyles } from '../../styles';
import {
	BUSCAR_USUARIO_APP,
	FEED_NOTIFICATION,
	FEED_NOTIFICATION_LIST,
	OBJETIVOS_ALUNOS,
	BUSCAR_VALIDATION_CODE_APP,
} from '../../../../../constants';
import UserWrapper from '../../../../../screens/wrappers/UserWrapper';

export default function FBVestMenu() {
	const styles = useStyles();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
			<Button
				aria-controls="fbvest-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{ color: 'white', fontWeight: 400, padding: '10px' }}
				className={styles.menuIten}
			>
				FB Vest
			</Button>
			<Menu
				id="fbvest-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => navigate(BUSCAR_USUARIO_APP)}>
					Buscar usuários
				</MenuItem>
				<MenuItem onClick={() => navigate(BUSCAR_VALIDATION_CODE_APP)}>
					Buscar Validation Code
				</MenuItem>
				<MenuItem onClick={() => navigate(OBJETIVOS_ALUNOS)}>
					Painel de Objetivos
				</MenuItem>
				<MenuItem onClick={() => navigate(FEED_NOTIFICATION)}>
					Enviar Notificação
				</MenuItem>
				<MenuItem onClick={() => navigate(FEED_NOTIFICATION_LIST)}>
					Lista de Notificações
				</MenuItem>
			</Menu>
		</UserWrapper>
	);
}
