import React, { useMemo } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import {
	LinearProgress,
	Typography,
	Box,
	Card,
	Button,
	Grid,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { FETCH_CAMPANHA_BY_DESAFIO_ID } from 'screens/CadastrarDesafio/api';
import moment from 'moment';

const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', type: 'number', width: 50 },
	{ field: 'audience', headerName: 'Audiência', width: 110 },
	{
		field: 'createdAt',
		headerName: 'Data',
		sortable: false,
		width: 200,
		valueGetter: (params: GridValueGetterParams) =>
			moment(new Date(+params.row.createdAt)).format(
				'DD/MM/YYYY [as] hh:mm:ss',
			),
	},
	{
		field: 'subject',
		headerName: 'Subject',
		type: 'string',
		minWidth: 150,
	},
	{
		field: 'totalSent',
		headerName: 'Enviados',
		type: 'number',
		width: 100,
	},
	{
		field: 'delivered',
		headerName: 'Recebidos',
		type: 'number',
		width: 100,
	},
	{
		field: 'totalErrors',
		headerName: 'Erros',
		type: 'number',
		width: 80,
	},
	{
		field: 'url',
		headerName: 'Link',
		type: 'string',
		width: 150,
	},
	{
		field: 'displayName',
		headerName: 'DisplayName',
		type: 'string',
		width: 150,
	},
	{
		field: 'email',
		headerName: 'Email',
		type: 'string',
		width: 100,
	},
];

const CampanhaHistorico: React.FC<{ modelId: string }> = ({ modelId }) => {
	const [
		fetchHistorico,
		{ called, data, loading, error, refetch },
	] = useLazyQuery(FETCH_CAMPANHA_BY_DESAFIO_ID, {
		variables: {
			modelId,
		},
		fetchPolicy: 'no-cache',
	});

	const historico = useMemo(
		() =>
			data?.historico.map((it: any, index: number) => ({
				...it,
				id: index + 1,
			})),
		[data?.historico],
	);

	return (
		<Card style={{ marginTop: 8 }}>
			<Box padding={2} component="div" flex={1}>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h6">Histórico</Typography>
						{loading && <LinearProgress />}
						{error && (
							<>
								<Typography variant="h3">Ocorreu um erro inesperado</Typography>
								<Button
									type="button"
									variant="contained"
									onClick={() => refetch?.()}
								>
									Tente novamente
								</Button>
							</>
						)}
						{!error && !loading && (
							<Button
								type="button"
								variant="contained"
								onClick={() => {
									if (!called) fetchHistorico();
									else refetch?.();
								}}
							>
								Buscar histórico
							</Button>
						)}
						{historico && (
							<div style={{ height: 300, maxHeight: 500, width: '100%' }}>
								<DataGridPro
									rows={historico}
									columns={columns}
									pageSize={5}
									rowsPerPageOptions={[5]}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
};

export default CampanhaHistorico;
