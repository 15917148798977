import React from 'react';
import {
	Card,
	CardHeader,
	Button,
	Row,
	Col,
	Badge,
	Collapse,
	CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';

import { EditorHTML } from 'components';

const AlternativaItem = props => {
	const {
		onChangeAlternativa,
		onCollapse,
		handleOnRemove,
		index,
		al,
		collapses,
	} = props;

	function handleOnCollapse() {
		onCollapse(index);
	}

	function handleOnChangeAlternativa({ value: val }) {
		onChangeAlternativa(val, index);
	}

	return (
		<Card>
			<CardHeader>
				<Row className="d-flex justify-content-between align-items-center">
					<Col xs={8} onClick={handleOnCollapse} style={{ cursor: 'pointer' }}>
						<span className="font-weight-bold">Alternativa {al.letra})</span>{' '}
						{al.correta && <Badge color="success">Correta</Badge>}
					</Col>
					<Col className="text-right">
						<Button
							size="sm"
							color="danger"
							data-index={index}
							onClick={handleOnRemove}
						>
							Remover
						</Button>
					</Col>
				</Row>
			</CardHeader>
			{collapses[index] && (
				<Collapse isOpen={collapses[index].isOpen}>
					<CardBody>
						<EditorHTML
							isAlternativas
							errors={{}}
							onChangeState={handleOnChangeAlternativa}
							height="230px"
							name="value"
							index={index}
							htmlRaw={al.texto}
						/>
					</CardBody>
				</Collapse>
			)}
		</Card>
	);
};

AlternativaItem.propTypes = {
	al: PropTypes.instanceOf(Object),
	collapses: PropTypes.instanceOf(Array),
	handleOnRemove: PropTypes.func.isRequired,
	index: PropTypes.number,
	onChangeAlternativa: PropTypes.func.isRequired,
	onCollapse: PropTypes.func.isRequired,
};

export default AlternativaItem;
