import { Box } from '@material-ui/core';
import React, { useMemo, useCallback, useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ChildDetailsUserProps } from './types';
import { Item } from './utils';
import * as Mutations from '../api/mutation';
import PremiumAlertDialog from './premium-confirm-dialog';

const MarkUserAsPremium: React.FC<ChildDetailsUserProps> = ({
	user,
	refetch,
}) => {
	const [openDialog, setDialog] = useState(false);
	const [adminRemoveUserPremiumById, { loading: removeLoading }] = useMutation<
		{
			adminRemoveUserPremiumById: boolean;
		},
		{ userId: string }
	>(Mutations.MUTATION_REMOVE_USER_PREMIUM_BY_ID);

	const [
		adminSetUserAsPremiumById,
		{ loading: setPremiumLoading },
	] = useMutation<
		{
			adminSetUserAsPremiumById: { validationCode: string };
		},
		{ userId: string }
	>(Mutations.MUTATION_SET_USER_AS_PREMIUM_BY_ID);

	const handleSetUserAsPremium = useCallback(async () => {
		try {
			await adminSetUserAsPremiumById({
				variables: {
					userId: user?.uid,
				},
			});
			toast.success('Usuário agora é Premium');
			refetch?.();
		} catch (error) {
			toast.error(error?.message || 'Não foi da acesso premium!');
		}
	}, [adminSetUserAsPremiumById, refetch, user?.uid]);

	const handleRemoveUserAsPremium = useCallback(async () => {
		try {
			await adminRemoveUserPremiumById({
				variables: {
					userId: user?.uid,
				},
			});
			toast.success('Removido acesso premium!');
			refetch?.();
		} catch (error) {
			toast.error(error?.message || 'Não foi possível remove acesso premium.');
		}
	}, [adminRemoveUserPremiumById, user?.uid, refetch]);

	const onDialogChange = useCallback(() => {
		setDialog(values => !values);
	}, []);

	const handleSubmit = () => {
		// tornar premium
		if (user?.customClaims.level === 1) handleSetUserAsPremium();
		// remover acesso premium
		else handleRemoveUserAsPremium();

		onDialogChange();
	};

	const isB2CPremium = useMemo(
		() =>
			user?.customClaims?.subscriptions?.find(
				it => !!it.externalParceiro && it?.status === 'active',
			),
		[user],
	);

	if (isB2CPremium) return null;

	return (
		<Box>
			<PremiumAlertDialog
				open={openDialog}
				handleSubmit={handleSubmit}
				handleClose={onDialogChange}
			/>
			<Item>
				<Box mb={3}>
					<Alert
						severity={user?.customClaims?.level === 1 ? 'warning' : 'error'}
					>
						Atenção!{' '}
						{user?.customClaims?.level === 1
							? 'Ao clicar no botão o usuário terá acesso Premium com plano Farias Brito.'
							: 'Usuário irá perder o acesso Premium e essa ação não pode ser desfeita.'}
					</Alert>
				</Box>
				{user?.customClaims.level === 1 ? (
					<LoadingButton
						variant="contained"
						disableElevation
						color="secondary"
						loading={setPremiumLoading}
						onClick={onDialogChange}
					>
						Tornar usuário Premium.
					</LoadingButton>
				) : (
					<LoadingButton
						variant="contained"
						disableElevation
						color="error"
						loading={removeLoading}
						onClick={onDialogChange}
					>
						Remove acesso Premium.
					</LoadingButton>
				)}
			</Item>
		</Box>
	);
};

export default MarkUserAsPremium;
