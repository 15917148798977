import React from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IPlano } from 'types/plano';

interface Props {
	planos?: IPlano[] | null;
	formik: any;
}

const PlansSection: React.FC<Props> = ({ planos, formik }) => {
	if (!planos) return null;

	return (
		<Grid item xs={12} md="auto">
			<InputLabel id="plansSection-select">Categoria especial:</InputLabel>
			<Select
				name="plansSection"
				labelId="plansSection-select"
				multiple
				error={
					formik.touched.plansSection && Boolean(formik.errors.plansSection)
				}
				onChange={formik.handleChange}
				value={formik.values.plansSection}
				renderValue={selected => {
					const values = (selected as string[])
						// .map((it: number) => AppLevels[it])
						?.join(', ');
					if (!values) return 'Selecione os planos';
					return values;
				}}
				variant="outlined"
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}
			>
				<MenuItem value="" disabled>
					<em>Selecione os planos</em>
				</MenuItem>
				{planos.map(option => (
					<MenuItem key={option._id} value={option.name}>
						<ListItemIcon>
							<Checkbox
								checked={formik.values.plansSection?.includes(option.name)}
							/>
						</ListItemIcon>
						<ListItemText primary={option.name} />
					</MenuItem>
				))}
			</Select>
			<FormHelperText>
				{formik.touched.plansSection && formik.errors.plansSection}
			</FormHelperText>
		</Grid>
	);
};

export default PlansSection;
