import React from 'react';
import { SimuladoProvider } from './providers/SimuladoProvider';
import { ListaSimulados } from './components';

function ListarSimulados() {
	return (
		<SimuladoProvider>
			<ListaSimulados />
		</SimuladoProvider>
	);
}

export default ListarSimulados;
