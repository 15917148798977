import React, { Fragment, PureComponent } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiContentSave, mdiCloseCircle } from '@mdi/js';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
	Form,
	Row,
	Col,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	ModalFooter,
} from 'reactstrap';
import { gql } from 'apollo-boost';

import api from 'services/api';

class AssuntoModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			assunto: {},
			materias: [],
		};

		this.handleSave = this.handleSave.bind(this);
		this.handleOnChangeMateria = this.handleOnChangeMateria.bind(this);
		this.propsValidation = this.propsValidation.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
	}

	async componentDidMount() {
		const query = gql`
			{
				materias {
					_id
					nome
				}
			}
		`;
		const { data } = await api.query({ query });
		this.setState({
			materias: data.materias.map(it => ({
				label: it.nome,
				value: it._id,
			})),
		});
	}

	static getDerivedStateFromProps(props, state) {
		const {
			setValues,
			values,
			assunto,
			isOpen,
			values: { name, materia },
		} = props;

		const { assunto: currentAssunto } = state;

		if (isOpen && assunto && !name && !materia) {
			setValues({
				...values,
				_id: assunto.value,
				materia: assunto.materia,
				name: assunto.label,
			});
			return { assunto };
		}
		if (isOpen && assunto && currentAssunto !== assunto) {
			setValues({
				...values,
				_id: assunto.value,
				materia: assunto.materia,
				name: assunto.label,
			});
			return { assunto };
		}
		return null;
	}

	propsValidation(field, isSelect = true, classNames = 'basic-single') {
		const { errors, touched } = this.props;

		if (isSelect) {
			return classnames(classNames, {
				'rounded border border-danger': errors[field] && touched[field],
			});
		}

		return classnames({ 'is-invalid': errors[field] && touched[field] });
	}

	handleSave(e) {
		e.preventDefault();
		const { submitForm } = this.props;
		submitForm(e);
	}

	handleNameChange(e) {
		const { values, setValues, setFieldTouched, touched } = this.props;
		if (!touched.name) {
			setFieldTouched('name', true);
		}
		setValues({ ...values, name: e.target.value });
	}

	handleOnChangeMateria(materia) {
		const { values, setValues, setFieldTouched, touched } = this.props;
		if (!touched.materia) {
			setFieldTouched('materia', true);
		}
		setValues({ ...values, materia });
	}

	handleToggle() {
		const { resetForm, toggle } = this.props;
		toggle();
		// Delay para o usuário não ver a form vazia antes do modal fechar
		setTimeout(() => resetForm(), 500);
	}

	render() {
		const {
			values: { name, materia },
			isOpen,
			className,
		} = this.props;

		const { materias } = this.state;

		return (
			<>
				<Modal
					size="lg"
					isOpen={isOpen}
					toggle={this.handleToggle}
					className={className}
				>
					<ModalHeader toggle={this.handleToggle}>Assunto</ModalHeader>
					<Form onSubmit={this.handleSave}>
						<ModalBody>
							<>
								<Row>
									<Col xs="12">
										<p className="font-weight-bold ml-3 m-0 mb-1 mt-2">Nome</p>
										<Input
											className={this.propsValidation('name', false)}
											name="name"
											value={name}
											onChange={this.handleNameChange}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs="12">
										<p className="font-weight-bold ml-3 m-0 mb-1 mt-2">
											Matéria
										</p>
										<Select
											classNamePrefix="select"
											name="materia"
											isDisabled
											className={this.propsValidation('materia')}
											isSearchable
											noOptionsMessage={() => 'Nenhuma matéria encontrada'}
											onChange={this.handleOnChangeMateria}
											options={materias}
											value={materia}
										/>
									</Col>
								</Row>
							</>
						</ModalBody>
						<ModalFooter>
							<Button color="danger" onClick={this.handleToggle}>
								Cancelar
								<Icon
									className="ml-2"
									path={mdiCloseCircle}
									size={0.8}
									color="white"
								/>
							</Button>{' '}
							<Button color="success" type="submit">
								Salvar
								<Icon
									className="ml-2"
									path={mdiContentSave}
									size={0.8}
									color="white"
								/>
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
			</>
		);
	}
}

AssuntoModal.defaultProps = {
	isOpen: false,
};

AssuntoModal.propTypes = {
	assunto: PropTypes.shape({
		_id: PropTypes.string,
		assuntoId: PropTypes.number,
		assunto: PropTypes.string,
		materia: PropTypes.string,
	}),
	errors: PropTypes.instanceOf(Object).isRequired,
	touched: PropTypes.instanceOf(Object).isRequired,
	setValues: PropTypes.func.isRequired,
	values: PropTypes.shape({
		name: PropTypes.string,
		materia: PropTypes.instanceOf(Object),
	}),
	resetForm: PropTypes.func.isRequired,
	submitForm: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	className: PropTypes.string,
};

export default AssuntoModal;
