import React, { useEffect } from 'react';
import {
	Box,
	Container,
	Paper,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Typography,
	Grid,
	Checkbox,
	FormControlLabel,
	Button,
	TextField,
	FormHelperText,
	CircularProgress,
} from '@material-ui/core';
import logo from 'assets/logo_fb.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useValidCadUserToken } from 'store/models/auth/shared/user-valid-caduser-token';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store/store';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from 'services/mutations';
import { getLoginRoute } from './utils/getLoginRoute';
import { SchemaLogin } from './schema';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			background: '#222',
			minHeight: '100vh',
		},
		paper: {
			padding: theme.spacing(1),
			paddingBottom: theme.spacing(3),
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			margin: 'auto',
			maxWidth: 500,
		},
	}),
);

interface State {
	username: string;
	password: string;
	showPassword: boolean;
	keepConnected: boolean;
}

const LoginScreen = () => {
	const {
		fetchRefreshToken,
		invalidToken,
		isValidToken,
	} = useValidCadUserToken();

	const navigate = useNavigate();
	const dispatch = useDispatch<Dispatch>();
	const location = useLocation();
	const classes = useStyles();
	const [signIn, { loading }] = useMutation(LOGIN_USER);
	const {
		values,
		setValues,
		handleChange,
		handleSubmit,
		touched,
		errors,
	} = useFormik<State>({
		initialValues: {
			username: '',
			password: '',
			showPassword: false,
			keepConnected: true,
		},
		validationSchema: SchemaLogin,
		onSubmit: async credentials => {
			try {
				const {
					data: { loginCadUser },
				} = await signIn({
					variables: {
						email: credentials.username,
						password: credentials.password,
					},
				});

				if (loginCadUser && loginCadUser.token) {
					const expDate = Date.now() + 24 * 60 * 60 * 1000;
					const loggedUser = {
						...loginCadUser,
						expDate,
						email: credentials.username,
						keepConected: credentials.keepConnected,
					};
					localStorage.setItem(
						process.env.REACT_APP_USER_LOGIN_STORAGE_KEY as string,
						JSON.stringify(loggedUser),
					);
					await dispatch.auth.logUser(loggedUser);
					await dispatch.firstUse.setIsFirstUse({
						isFirstUse: loginCadUser.firstLogin,
					});

					navigate(getLoginRoute(loginCadUser.role, loginCadUser.firstLogin));
				} else {
					throw new Error('Nome do usuário ou senha incorreto.');
				}
			} catch (error: any) {
				if (error.errors) {
					toast.error(error?.errors[0]);
				} else {
					const message =
						error?.networkError?.statusCode === 500
							? 'Usuário não encontrado.'
							: error?.message || 'Houve um erro inesperado, tente novamente!';
					toast.error(message);
				}
			}
		},
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleClickKeepConnected = () => {
		setValues({ ...values, keepConnected: !values.keepConnected });
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	useEffect(() => {
		// @ts-ignore
		if (location && location.state && location.state.invalidToken) {
			toast.error('Por favor, logue novamente. Sessão expirada!');
		}
	}, [location]);

	if (isValidToken && !fetchRefreshToken && !invalidToken)
		return <Navigate to="/" />;
	return (
		<div className={classes.root}>
			<Container>
				<Box pt={8}>
					<Paper
						component="form"
						className={classes.paper}
						onSubmit={event => {
							event.preventDefault();
							handleSubmit();
						}}
					>
						<Box textAlign="center">
							<img src={logo} alt="logo" />
						</Box>
						<Box textAlign="center" my={3}>
							<Typography
								component="h5"
								variant="h5"
								className="font-weight-bold"
							>
								Login
							</Typography>
						</Box>
						<Grid container alignItems="center" justify="center" spacing={3}>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<TextField
										id="username"
										type="text"
										label="E-mail"
										variant="outlined"
										placeholder="username ou e-mail"
										value={values.username}
										onChange={handleChange('username')}
										error={touched?.username && !!errors?.username}
										helperText={touched?.username && errors?.username}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="password">Password</InputLabel>
									<OutlinedInput
										id="password"
										type={values.showPassword ? 'text' : 'password'}
										value={values.password}
										onChange={handleChange('password')}
										error={touched.password && Boolean(errors.password)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{values.showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
										labelWidth={70}
									/>
									<FormHelperText
										error={touched.password && Boolean(errors.password)}
									>
										{touched.password && errors.password}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<FormControlLabel
									control={
										<Checkbox
											checked={values.keepConnected}
											onChange={handleClickKeepConnected}
											name="keepConnected"
											color="primary"
										/>
									}
									label="Mantenha-me conectado"
								/>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									size="large"
									color="primary"
									className="py-3"
								>
									{!loading ? (
										'Entrar'
									) : (
										<CircularProgress disableShrink color="inherit" size={25} />
									)}
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			</Container>
		</div>
	);
};

export default LoginScreen;
