import { gql } from '@apollo/client';

export const MUTATION_SET_USER_AS_PREMIUM_BY_ID = gql`
	mutation adminSetUserAsPremiumById($userId: String!) {
		adminSetUserAsPremiumById(userId: $userId) {
			validationCode
		}
	}
`;

export const MUTATION_ANDROID_COMPLETE_PURCHASE = gql`
	mutation adminCompletePurchase($purchase: AdminCompletePurchaseInput!) {
		adminCompletePurchase(purchase: $purchase)
	}
`;
export const MUTATION_REMOVE_USER_PREMIUM_BY_ID = gql`
	mutation AdminRemoveUserPremiumById($userId: String!) {
		adminRemoveUserPremiumById(userId: $userId)
	}
`;

export const UPDATE_NOTIFICATION_PREFERENCES = gql`
	mutation updateNotificationPreferences(
		$notification: String!
		$push: Boolean!
		$email: Boolean!
		$uid: String!
	) {
		updateNotificationPreferences(
			notification: $notification
			push: $push
			email: $email
			uid: $uid
		)
	}
`;

export const ADMIN_USER_REQUEST_DELETE_ACCOUNT = gql`
	mutation adminUserRequestDeleteAccount($uid: String!) {
		adminUserRequestDeleteAccount(uid: $uid)
	}
`;

export const ADMIN_USER_REVOKE_REQUEST_DELETE_ACCOUNT = gql`
	mutation adminUserRevokeRequestDeleteAccount($uid: String!) {
		adminUserRevokeRequestDeleteAccount(uid: $uid)
	}
`;
