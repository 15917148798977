import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import QuestionContext from 'screens/ListarQuestoes/providers/QuestionProvider';
import { IQuestao } from 'types/questao';
import Row from './TableRow';
import { TableHeader } from './TableHeader';

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
});

interface Props {
	questoes: IQuestao[];
	countQuestoes: number;
}

const DesafioPagination: React.FC<Props> = ({ countQuestoes, questoes }) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const { fetchMore } = useContext(QuestionContext);

	const handleChangePage = async (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		newPage: number,
	) => {
		fetchMore({
			variables: {
				skip: newPage * 20,
			},
		});
		setPage(newPage);
	};
	const rows = questoes.map((questao: IQuestao) => (
		<Row key={questao._id} questao={questao} />
	));
	return (
		<Paper className={classes.root}>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table">
					<TableHeader />
					<TableBody>{rows}</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20]}
				component="div"
				count={countQuestoes}
				rowsPerPage={20}
				page={page}
				onChangePage={handleChangePage}
			/>
		</Paper>
	);
};

export default DesafioPagination;
