import React, { useContext } from 'react';
import {
	CircularProgress,
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
} from '@material-ui/core';
import { getMateriaOptions } from 'helpers/options';
import QuestionContext from '../../screens/ListarQuestoes/providers/QuestionProvider';

interface Props {
	name: string;
	value: string;
	onChange: any;
	onChangeStatus?: any;
	onChangeVestibular?: any;
	error?: boolean | undefined;
	helperText?: string | false | undefined;
	disabled?: boolean;
	variables?: any;
}

const SelectMateriaListar: React.FC<Props> = ({
	name,
	value,
	onChange,
	error,
	helperText,
	disabled = false,
}) => {
	const { data, loading } = useContext(QuestionContext);

	if (loading) {
		return <CircularProgress />;
	}

	return (
		<FormControl variant="outlined" error={error} className="mr-2">
			<Select
				name={name}
				displayEmpty
				value={value}
				onChange={onChange}
				disabled={disabled}
			>
				<MenuItem value="">Escolha a Materia</MenuItem>
				{getMateriaOptions(data)
					.slice(1)
					.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default SelectMateriaListar;
