import React, { useContext } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	PieChart,
	Pie,
	ResponsiveContainer,
	Cell,
	Legend,
	LabelList,
} from 'recharts';
import { tixColor } from 'constants/colors';
import { ObjectiveContext } from 'screens/ObjetivosAlunos/provider';
import { Grid, LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';
import {
	sortTotalAscending,
	renderCustomizedLabel,
	colors,
	sortTotalDescending,
	renderCustomizedLabelGraph,
} from '../../helpers';

const ObjectiveChart = () => {
	const classes = useStyles();
	const { objective, loadingObjective } = useContext(ObjectiveContext);
	const {
		materias,
		vestibulares,
		vestibularFilter,
	} = objective?.getReportObjectives ?? {
		materias: [],
		vestibulares: [],
	};
	const sortedMaterias =
		vestibularFilter?.length > 0
			? sortTotalDescending(vestibularFilter)
			: sortTotalDescending(materias);
	const sortedVestibulares = sortTotalAscending(vestibulares);

	if (loadingObjective) return <LinearProgress />;

	return (
		<div>
			{objective ? (
				<Grid
					container
					justify="space-between"
					spacing={2}
					className={classes.chartContainer}
				>
					<Grid item lg={6}>
						<h5>Materias Escolhidas</h5>
						<BarChart
							barCategoryGap="20%"
							width={600}
							height={450}
							data={sortedMaterias}
							layout="vertical"
							margin={{ left: 35, right: 15 }}
						>
							<CartesianGrid strokeDasharray="1 3" />
							<YAxis type="category" dataKey="_id" />
							<XAxis type="number" allowDecimals={false} />
							<Tooltip />
							<Bar dataKey="total" fill={tixColor} name="Quantidade">
								<LabelList
									dataKey="total"
									content={renderCustomizedLabelGraph}
									position="insideRight"
									style={{ fill: 'white' }}
								/>
							</Bar>
						</BarChart>
					</Grid>
					<Grid item lg={6}>
						<h5>Vestibulares Escolhidos</h5>
						<ResponsiveContainer width={700} height="80%">
							<PieChart width={500} height={400}>
								<Legend layout="vertical" verticalAlign="top" align="left" />
								<Tooltip />
								<Pie
									data={sortedVestibulares}
									dataKey="total"
									nameKey="_id"
									cx="50%"
									cy="50%"
									outerRadius={150}
									fill="#8884d8"
									label={renderCustomizedLabel}
									labelLine={false}
								>
									{vestibulares.map((_item, index) => (
										<Cell
											key={`cell-${index}`}
											fill={colors[index % colors.length]}
										/>
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
};

export default ObjectiveChart;
