import path from 'path';

export const getFullDate = () => {
	const date = new Date();

	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	const hour = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();

	const fullDate =
		year.toString() +
		month.toString() +
		day.toString() +
		hour.toString() +
		minutes.toString() +
		seconds.toString();

	return fullDate;
};

export const normalizeName = originalFile => {
	const originalFileName = path.extname(originalFile.name);
	const extension = path.basename(originalFile.name);
	const fullDate = getFullDate();
	const newFileName = `${fullDate}_${originalFileName
		.normalize('NFD')
		.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')}.${extension}`;
	return newFileName;
};

export const renameFile = originalFile => {
	const newFileName = normalizeName(originalFile);
	const renamedFile = new File([originalFile], newFileName, {
		type: originalFile.type,
		lastModified: originalFile.lastModified,
	});

	return renamedFile;
};
