import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import { IAssunto } from 'types/assunto';
import { useStyles } from '../../styles';
import TableCollapse from './TableCollapse';
import { useRowState } from './useRow';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const getDate = (date: any) => {
	// @ts-ignore
	if (moment(date)._d.toString() === 'Invalid Date') {
		return moment(parseInt(date, 10)).fromNow();
	}
	return moment(date, 'YYYY-MM-DD').fromNow();
};

export default function Row(props: any) {
	const { questao } = props;
	const { isChecked, handleChange, open, setOpen } = useRowState(questao);
	let assertividade = 0;
	if (questao.assertividade) {
		assertividade =
			questao.assertividade?.erros + questao.assertividade?.acertos;
		assertividade = questao.assertividade?.acertos / assertividade;
		assertividade = Math.floor(assertividade * 100);
	}
	const classes = useStyles();
	const assertividadeLabel = questao.assertividade
		? `${(assertividade || 0).toString()}%`
		: '-';
	let classAssertividade = classes.scoreBaixo;
	if (assertividade > 69) {
		classAssertividade = classes.scoreAlto;
	} else if (assertividade > 29 && assertividade < 70) {
		classAssertividade = classes.scoreMedio;
	}
	return (
		<>
			<StyledTableRow key={questao._id}>
				<TableCell>
					<Checkbox
						checked={isChecked}
						color="primary"
						onChange={handleChange}
					/>
				</TableCell>

				<StyledTableCell component="th" scope="row">
					{questao.vestibular}
				</StyledTableCell>
				<StyledTableCell align="center">{questao.ano}</StyledTableCell>
				<StyledTableCell align="center">{questao.fonte}</StyledTableCell>
				<StyledTableCell align="center">{questao.materia}</StyledTableCell>
				<StyledTableCell align="center">
					{questao.numeroQuestao}
				</StyledTableCell>
				<StyledTableCell align="center">
					<span className={classAssertividade}>{assertividadeLabel}</span>
				</StyledTableCell>
				<StyledTableCell align="center">
					{((questao.assuntos as [IAssunto]).map(x => x.nome) as []).join(', ')}
				</StyledTableCell>
				<StyledTableCell align="center">
					{getDate(questao.createdAt)}
				</StyledTableCell>

				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</StyledTableRow>
			<TableCollapse
				open={open}
				enunciado={questao?.enunciado ? questao.enunciado : ''}
				alternativas={questao?.alternativas ? questao.alternativas : []}
			/>
		</>
	);
}
