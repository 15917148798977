import { createModel } from '@rematch/core';
import {
	getAssuntosPorPage,
	getAssuntosPorMateria,
	getAssuntosPorSearch,
} from '../../../helpers';

import { RootModel } from '../index';

export const INITIAL_STATE = {
	materia: 'Biologia',
	assuntosMateria: [],
	assuntosPesquisa: [],
	assuntosPagina: [],
	loading: false,
	search: '',
	currentPage: 0,
	totalPages: 1,
};

export const assuntosModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		changeState: (state, props) => ({
			...state,
			...props,
		}),
		startLoading: state => ({ ...state, loading: true }),
		stopLoading: state => ({ ...state, loading: false }),
		clearFields: () => ({ ...INITIAL_STATE }),
	},
	effects: dispatch => ({
		async fetchUpdateMateria({ materia, search }) {
			/**
			 * Atualiza as páginas vísiveis ao mudar a matéria selecionada
			 * Volta para a primeira página
			 */
			try {
				dispatch.assuntosModel.startLoading();
				const assuntosMateria = await getAssuntosPorMateria(materia);
				const assuntosSearch = await getAssuntosPorSearch(
					assuntosMateria,
					search,
				);
				const { assuntosPagina, length } = await getAssuntosPorPage(
					assuntosSearch,
					0,
				);
				const totalPages = Math.ceil(length / 20) - 1;
				dispatch.assuntosModel.changeState({
					assuntosMateria,
					assuntosSearch,
					assuntosPagina,
					materia,
					totalPages,
					currentPage: 0,
					loading: false,
				});
			} catch (err) {
				dispatch.assuntosModel.stopLoading();
			}
		},
		fetchUpdateSearch({ search }) {
			dispatch.assuntosModel.changeState({ search });
		},
		async fetchUpdateSearchAssuntos({ assuntosMateria, search }) {
			/**
			 * Atualiza assuntos vísiveis de acordo com a Pesquisa alterada e a página atual
			 * Retorna para a primeira página caso a atual não exista após a pesquisa
			 */
			try {
				dispatch.assuntosModel.startLoading();
				const assuntosSearch = await getAssuntosPorSearch(
					assuntosMateria,
					search,
				);
				const { assuntosPagina, length } = await getAssuntosPorPage(
					assuntosSearch,
					0,
				);
				const totalPages = Math.ceil(length / 20) - 1;

				dispatch.assuntosModel.changeState({
					assuntosSearch,
					assuntosPagina,
					totalPages,
					search,
					currentPage: 0,
					loading: false,
				});
			} catch (err) {
				dispatch.assuntosModel.stopLoading();
			}
		},
		async fetchUpdatePage({ assuntosSearch, page }) {
			/**
			 * Atualiza os assuntos vísiveis em uma mudança de página
			 */
			try {
				dispatch.assuntosModel.startLoading();

				const { assuntosPagina } = await getAssuntosPorPage(
					assuntosSearch,
					page,
				);

				dispatch.assuntosModel.changeState({
					assuntosPagina,
					currentPage: page,
					loading: false,
				});
			} catch (err) {
				dispatch.assuntosModel.stopLoading();
			}
		},
		async fetchAddAssunto({ assuntosMateria, assunto }) {
			dispatch.assuntosModel.changeState({
				assuntosMateria: [...assuntosMateria, assunto],
			});
		},
	}),
});
