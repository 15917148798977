import React, { Component } from 'react';
import { Row, Col, Button, ListGroupItem } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiPencil, mdiTrashCan, mdiEye } from '@mdi/js';
import PropTypes from 'prop-types';

class ListarVestibularItem extends Component {
	render() {
		const { item } = this.props;
		return (
			<ListGroupItem className="align-items-center">
				<Row>
					<Col>{item}</Col>
					<Button color="primary" className="float-right mr-2">
						<Icon path={mdiEye} size={0.8} color="white" />
					</Button>
					<Button color="success" className="float-right mr-2">
						<Icon path={mdiPencil} size={0.8} color="white" />
					</Button>
					<Button color="danger" className="float-right mr-2">
						<Icon path={mdiTrashCan} size={0.8} color="white" />
					</Button>
				</Row>
			</ListGroupItem>
		);
	}
}

ListarVestibularItem.propTypes = {
	item: PropTypes.string,
};

export default ListarVestibularItem;
