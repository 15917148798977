import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	TextField,
	Select,
	MenuItem,
	ListItemText,
	InputLabel,
	Card,
	Button,
	ListItemIcon,
	Checkbox,
	FormHelperText,
	TableHead,
	TableRow,
	TableBody,
	Chip,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import { IDesafio } from 'types/desafio';
import { useDesafioFormUpdate } from 'screens/CadastrarDesafio/hooks';
import moment from 'moment';
import {
	DATA_PLACEHOLDER,
	PONTUACAO_PLACEHOLDER,
	TEMPO_PLACEHOLDER,
} from 'screens/CadastrarDesafio/constants';
import UserRoles, { AppLevels } from 'types/user';
import UserWrapper from 'screens/wrappers/UserWrapper';
import { getDate, timestampToDate } from 'helpers/data';
import { StyledTableRow } from '../../../../components/TabelaDesafios/StyledTableRow';
import { StyledTableCell } from '../../../../components/TabelaDesafios/StyledTableCell';
import SelectTag from '../SelectTag';
import SelectPublicar from '../SelectPublicar';
import { options } from '../DesafioForm/material-utils';

interface Props {
	open: boolean;
	handleClose?: () => void;
	desafio?: IDesafio;
	refetch?: any;
	arrayDesafioId?: Record<string, unknown>[];
}

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: '#53AF50',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	button: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	container: { backgroundColor: '#FFFFFF' },
	card: {
		padding: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	grid: {
		padding: '20px 80px 20px 80px',
	},
}));

const EditarDialog: React.FC<Props> = ({
	open,
	handleClose,
	desafio,
	arrayDesafioId,
}) => {
	const classes = useStyles();
	const { formik, updateDesafio } = useDesafioFormUpdate(
		desafio,
		arrayDesafioId,
	);

	const [show, toggleShow] = React.useState(false);

	const checkDesafio = (desafioUpdate: IDesafio) => {
		const today = new Date().getTime();
		return (
			today > Number(desafioUpdate.dataFinal) &&
			desafioUpdate.disponivel === true
		);
	};

	const checkDesafioDisponivel = (
		desafioUpdate: IDesafio,
		desafioDisponivel: boolean,
	) => {
		if (checkDesafio(desafioUpdate)) {
			return 'Vencido';
		}
		if (desafioDisponivel) return 'Disponível';
		return 'Indisponível';
	};

	return (
		<div className={classes.container}>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => {
								handleClose();
								toggleShow(false);
							}}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							Editar desafio
						</Typography>
					</Toolbar>
				</AppBar>
				<Card className={classes.card} style={{ overflow: 'visible' }}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={3} className={classes.container}>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<TextField
									fullWidth
									name="dataFinal"
									InputProps={{
										inputProps: {
											min: moment(new Date()).format('YYYY-MM-DD'),
										},
									}}
									InputLabelProps={{ shrink: true }}
									type="date"
									label={DATA_PLACEHOLDER}
									variant="outlined"
									value={formik.values.dataFinal}
									onChange={formik.handleChange}
									error={
										formik.touched.dataFinal && Boolean(formik.errors.dataFinal)
									}
									helperText={
										formik.touched.dataFinal && formik.errors.dataFinal
									}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={3}>
								<TextField
									fullWidth
									name="tempoMaximo"
									InputLabelProps={{ shrink: true }}
									inputProps={{
										step: 1,
										min: 1,
										max: 120,
										'data-testid': TEMPO_PLACEHOLDER,
									}}
									type="number"
									label={TEMPO_PLACEHOLDER}
									variant="outlined"
									value={formik.values.tempoMaximo}
									onChange={formik.handleChange}
									error={
										formik.touched.tempoMaximo &&
										Boolean(formik.errors.tempoMaximo)
									}
									helperText={
										formik.touched.tempoMaximo && formik.errors.tempoMaximo
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<TextField
									fullWidth
									name="pontuacao"
									type="number"
									InputLabelProps={{ shrink: true }}
									inputProps={{
										step: 1,
										min: 1000,
										'data-testid': PONTUACAO_PLACEHOLDER,
									}}
									label="Pontuação"
									variant="outlined"
									value={1000}
									onChange={formik.handleChange}
									error={
										formik.touched.pontuacao && Boolean(formik.errors.pontuacao)
									}
									helperText={
										formik.touched.pontuacao && formik.errors.pontuacao
									}
									disabled
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<SelectTag
									name="tag"
									value={formik.values.tag}
									onChange={formik.handleChange}
									error={formik.touched.tag && Boolean(formik.errors.tag)}
									helperText={formik.touched.tag && formik.errors.tag}
								/>
							</Grid>
						</Grid>

						<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
							<Grid
								container
								spacing={3}
								className={classes.container}
								alignItems="center"
							>
								<Grid item xs={12} md="auto">
									<InputLabel id="permissionLevel-select">
										Disponível para usuários:
									</InputLabel>
									<Select
										name="permissionLevel"
										labelId="permissionLevel-select"
										multiple
										error={
											formik.touched.permissionLevel &&
											Boolean(formik.errors.permissionLevel)
										}
										onChange={formik.handleChange}
										value={formik.values.permissionLevel}
										renderValue={selected => {
											const values = (selected as number[])
												.map((it: number) => AppLevels[it])
												.join(', ');
											if (!values) return 'Selecione uma opção';
											return values;
										}}
										variant="outlined"
										displayEmpty
										inputProps={{ 'aria-label': 'Without label' }}
									>
										<MenuItem value="" disabled>
											<em>Selecione as permissões</em>
										</MenuItem>
										{options.map(option => (
											<MenuItem key={option.level} value={option.level}>
												<ListItemIcon>
													<Checkbox
														checked={formik.values.permissionLevel.includes(
															option.level,
														)}
													/>
												</ListItemIcon>
												<ListItemText primary={option.description} />
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										{formik.touched.permissionLevel &&
											formik.errors.permissionLevel}
									</FormHelperText>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									<SelectPublicar
										name="publicar"
										value={formik.values.publicar}
										onChange={formik.handleChange}
										error={
											formik.touched.publicar && Boolean(formik.errors.publicar)
										}
										helperText={
											formik.touched.publicar && formik.errors.publicar
										}
									/>
								</Grid>
							</Grid>
						</UserWrapper>
						<Button
							data-testid="salvar-button"
							variant="contained"
							className={classes.button}
							size="large"
							startIcon={<SaveIcon />}
							type="submit"
							onClick={() => toggleShow(true)}
						>
							SALVAR
						</Button>
					</form>
				</Card>
				{show ? (
					<>
						{' '}
						<Grid className={classes.grid}>
							<Typography variant="h6" className={classes.title}>
								Desafios atualizados
							</Typography>
							<Paper>
								<TableContainer component={Paper}>
									<Table
										style={{ minWidth: 700 }}
										aria-label="customized table"
									>
										<TableHead>
											<TableRow>
												<StyledTableCell align="center">Status</StyledTableCell>
												<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
													<StyledTableCell align="center">
														Permissões
													</StyledTableCell>
												</UserWrapper>
												<StyledTableCell align="center">Nome</StyledTableCell>
												<StyledTableCell align="center">
													Criador
												</StyledTableCell>
												<StyledTableCell align="left">Materia</StyledTableCell>
												<StyledTableCell align="center">
													Tempo Máximo(min)
												</StyledTableCell>
												<StyledTableCell align="center">
													Total Questoes
												</StyledTableCell>

												<StyledTableCell align="center">Pontos</StyledTableCell>
												<StyledTableCell align="center">
													Publicado
												</StyledTableCell>
												<StyledTableCell align="center">
													Data Final
												</StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{updateDesafio?.data?.updateManyDesafio?.desafiosAlterados?.map(
												desafioUpdate => (
													<StyledTableRow key={desafioUpdate?._id}>
														<StyledTableCell align="center">
															<Chip
																size="small"
																variant="outlined"
																label={checkDesafioDisponivel(
																	desafio,
																	desafio?.disponivel,
																)}
																color={
																	desafio?.disponivel ? 'primary' : 'secondary'
																}
																style={
																	checkDesafio(desafio)
																		? {
																				border: '1px solid #eed202',
																				color: '#eed202',
																		  }
																		: {}
																}
															/>
														</StyledTableCell>
														<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
															<StyledTableCell align="center">
																<b>
																	{desafioUpdate?.permissionLevel
																		?.map((it: number) => AppLevels[it])
																		.join(', ')}
																</b>
															</StyledTableCell>
														</UserWrapper>
														<StyledTableCell align="center">
															{desafioUpdate?.nome}
														</StyledTableCell>
														<StyledTableCell align="center">
															{desafioUpdate?.ownerName}
														</StyledTableCell>
														<StyledTableCell align="left">
															{desafioUpdate?.materia}
														</StyledTableCell>
														<StyledTableCell align="center">
															{desafioUpdate.tempoMaximo &&
															desafioUpdate.tempoMaximo >= 60
																? desafioUpdate.tempoMaximo / 60
																: 0}
														</StyledTableCell>
														<StyledTableCell align="center">
															{desafioUpdate?.totalQuestoes || 0}
														</StyledTableCell>
														<StyledTableCell align="center">
															{desafioUpdate?.pontuacao}
														</StyledTableCell>
														<StyledTableCell align="center">
															{getDate(desafioUpdate?.pubDate)}
														</StyledTableCell>
														<StyledTableCell align="center">
															{timestampToDate(desafioUpdate?.dataFinal)}
														</StyledTableCell>
													</StyledTableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Grid>
						{updateDesafio?.data?.updateManyDesafio?.desafiosErros?.length >
						0 ? (
							<Grid className={classes.grid}>
								<Typography variant="h6" className={classes.title}>
									Desafios não atualizados
								</Typography>
								<Paper>
									<TableContainer component={Paper}>
										<Table
											style={{ minWidth: 700 }}
											aria-label="customized table"
										>
											<TableHead>
												<TableRow>
													<StyledTableCell align="center">
														ID do desafio
													</StyledTableCell>
													<StyledTableCell align="center">
														Motivo
													</StyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{updateDesafio?.data?.updateManyDesafio?.desafiosErros?.map(
													desafioUpdate => (
														<StyledTableRow key={desafioUpdate.split(' - ')[0]}>
															<StyledTableCell
																align="center"
																style={{ width: '200px' }}
															>
																{desafioUpdate.split(' - ')[0]}
															</StyledTableCell>
															<StyledTableCell align="center">
																{desafioUpdate.split(' - ')[1]}
															</StyledTableCell>
														</StyledTableRow>
													),
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid>
						) : null}
					</>
				) : null}
			</Dialog>
		</div>
	);
};

export default EditarDialog;
