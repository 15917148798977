import React from 'react';
import { Avatar, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
	color: string;
	avatarTitle: string;
	title: string;
	number: number;
}

const Card: React.FC<Props> = ({ color, avatarTitle, title, number }) => {
	const classes = useStyles();
	return (
		<Paper
			style={{
				borderLeft: `6px solid ${color}`,
			}}
			className={classes.cardParceiro}
		>
			<Grid className={classes.cardContent}>
				<Avatar
					variant="rounded"
					style={{
						backgroundColor: color,
					}}
					className={classes.avatar}
				>
					{avatarTitle}
				</Avatar>
				<Divider orientation="vertical" flexItem />
				<Grid className={classes.summary}>
					<Typography className={classes.title}>{title}</Typography>
					<Typography className={classes.number}>{number}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Card;
