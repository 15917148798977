import React, { useContext } from 'react';
import {
	CircularProgress,
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
} from '@material-ui/core';
import { tipo } from 'helpers/options';
import { AdminCadUsersContext } from 'screens/AdminCadUsers/shared/admin-caduser-provider';

interface Props {
	name: string;
	value: string | boolean;
	onChange: any;
	error?: boolean | undefined;
	helperText?: string | false | undefined;
	disabled?: boolean;
	variables?: any;
}

const SelectTipo: React.FC<Props> = ({
	name,
	value,
	onChange,
	error,
	helperText,
	disabled = false,
}) => {
	const { isLoading } = useContext(AdminCadUsersContext);

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<FormControl variant="outlined" error={error} className="mr-2">
			{onChange && (
				<Select
					name={name}
					displayEmpty
					value={value}
					onChange={onChange}
					disabled={disabled}
				>
					<MenuItem value="">Escolha o Tipo</MenuItem>
					{tipo.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			)}

			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default SelectTipo;
