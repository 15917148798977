import React from 'react';
import {
	Grid,
	Typography,
	Chip,
	Box,
	Select,
	MenuItem,
	ListItemText,
	TextField,
	Card,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { NotificationAudienceOptions, NotificationFeedInput } from '../types';
import { useStyles } from '../styles';

const AudienceSection: React.FC<{
	handleOnSetState: (values: Partial<NotificationFeedInput>) => void;
	audience: string | null;
	receiver: string[];
}> = ({ handleOnSetState, receiver, audience }) => {
	const classes = useStyles();

	return (
		<Card className={classes.cardSection}>
			<Box mb={3}>
				<Typography
					style={{ fontWeight: 'bold' }}
					variant="h5"
					component="h5"
					color="initial"
				>
					1. Audience
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} lg="auto">
					<Typography component="p" color="initial">
						Audiências
					</Typography>
					<Select
						name="audience"
						labelId="audience-select"
						variant="outlined"
						displayEmpty
						defaultValue={null}
						value={audience}
						onChange={event => {
							handleOnSetState({
								audience: event.target.value as string,
							});
						}}
						style={{ minWidth: 150 }}
						placeholder="Audiências"
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value="" disabled>
							<em>Selecione um</em>
						</MenuItem>
						{NotificationAudienceOptions.map(option => (
							<MenuItem key={option.value} value={option.value}>
								<ListItemText primary={option.label} />
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} lg="auto">
					<Typography component="p" color="initial">
						Receivers
					</Typography>

					<Autocomplete
						multiple
						id="receiver"
						freeSolo
						sx={{ minWidth: 300 }}
						limitTags={3}
						options={[]}
						value={receiver}
						onChange={(_, value) => {
							handleOnSetState({
								receiver: value,
							});
						}}
						renderTags={(options, getTagProps) =>
							options.map((value: string, index: number) => (
								<Chip
									variant="outlined"
									label={value}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
								label="userIds"
								placeholder="Usuários"
							/>
						)}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};

export default React.memo(AudienceSection);
