import { gql } from 'apollo-boost';

export const LISTAR_DESAFIOS = gql`
	query listarDesafios(
		$desafioFilters: FilterDesafio
		$expired: Boolean
		$limit: Int
		$skip: Int
		$pubDateFilter: String
		$dataFinalFilter: String
		$totalRespFilter: String
	) {
		desafios(
			desafioFilters: $desafioFilters
			expired: $expired
			limit: $limit
			skip: $skip
			pubDateFilter: $pubDateFilter
			dataFinalFilter: $dataFinalFilter
			totalRespFilter: $totalRespFilter
		) {
			_id
			nome
			materia
			tempoMaximo
			totalQuestoes
			totalRespondida
			pontuacao
			vestibular
			disponivel
			pubDate
			dataFinal
			owner
			ownerName
			permissionLevel
			tag
		}
		countDesafios
		desafiosCountFilter(
			desafioFilters: $desafioFilters
			expired: $expired
			limit: $limit
			pubDateFilter: $pubDateFilter
			dataFinalFilter: $dataFinalFilter
		)
	}
`;

export const REMOVE_DESAFIO = gql`
	mutation removeDesafio($idDesafio: String!) {
		removeDesafio(idDesafio: $idDesafio) {
			_id
		}
	}
`;
