import React from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IPlano } from 'types/plano';

interface Props {
	planos?: IPlano[] | null;
	formik: any;
}

const FixedPlans = [
	{
		_id: 'premium',
		name: 'FB Premium',
	},
	{
		_id: 'freemium',
		name: 'FREEMIUM',
	},
];

const ExclusivePlans: React.FC<Props> = ({ planos, formik }) => {
	if (!planos) return null;

	return (
		<Grid item xs={12} md="auto">
			<InputLabel id="exclusivePlans-select">
				Exclusivo para os planos:
			</InputLabel>
			<Select
				name="exclusivePlans"
				labelId="exclusivePlans-select"
				multiple
				error={
					formik.touched.exclusivePlans && Boolean(formik.errors.exclusivePlans)
				}
				onChange={formik.handleChange}
				value={formik.values.exclusivePlans}
				renderValue={selected => {
					const values = (selected as string[])
						// .map((it: number) => AppLevels[it])
						?.join(', ');
					if (!values) return 'Selecione os planos';
					return values;
				}}
				variant="outlined"
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}
			>
				<MenuItem value="" disabled>
					<em>Selecione os planos</em>
				</MenuItem>
				{FixedPlans.concat(planos).map(option => (
					<MenuItem key={option._id} value={option.name}>
						<ListItemIcon>
							<Checkbox
								checked={formik.values.exclusivePlans?.includes(option.name)}
							/>
						</ListItemIcon>
						<ListItemText primary={option.name} />
					</MenuItem>
				))}
			</Select>
			<FormHelperText>
				{formik.touched.exclusivePlans && formik.errors.exclusivePlans}
			</FormHelperText>
		</Grid>
	);
};

export default ExclusivePlans;
