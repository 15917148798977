import React from 'react';
import { Container, Box, CircularProgress } from '@material-ui/core';

const LoadingPrivateRouter: React.FC = () => (
	<Container>
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="90vh"
			flexDirection="column"
		>
			<CircularProgress color="secondary" size={40} />
		</Box>
	</Container>
);

export default LoadingPrivateRouter;
