import React from 'react';
import {
	Grid,
	Typography,
	Box,
	TextField,
	IconButton,
} from '@material-ui/core';
import AddLink from '@material-ui/icons/InsertLinkRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { NotificationFeedAction } from '../types';
import DeeplinkDialogOptions from './deeplink-dialog-options';

const ActionItem: React.FC<{
	action: NotificationFeedAction;
	index: number;
	handleOnChange: (values: {
		index: number;
		name: string;
		value: string;
		action: NotificationFeedAction;
	}) => void;
	onRemove: (index: number) => void;
}> = ({ action, index, handleOnChange, onRemove }) => {
	const [openDeeplinkHelp, setOpenDeeplinkHelp] = React.useState(false);
	const handleOnRemove = () => {
		onRemove(index);
	};
	return (
		<Box marginBottom={1}>
			<Box
				marginBottom={1}
				flexDirection="row"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography variant="body1" component="p" color="textPrimary">
					Action {index + 1}
				</Typography>
				<Box>
					<IconButton
						onClick={() => setOpenDeeplinkHelp(true)}
						title="Adicionar Deeplink"
					>
						<AddLink color="primary" />
					</IconButton>
					<IconButton onClick={handleOnRemove} title="Remover">
						<RemoveCircle color="error" />
					</IconButton>
				</Box>
				<DeeplinkDialogOptions
					id={`deeplink-action-${index}`}
					keepMounted
					open={openDeeplinkHelp}
					onClose={value => {
						setOpenDeeplinkHelp(false);
						if (value) {
							handleOnChange({
								index,
								name: 'link',
								value,
								action,
							});
						}
					}}
					value={action.link}
				/>
			</Box>
			<Grid container spacing={1}>
				<Grid item spacing={3} xs={12} lg={6}>
					<TextField
						id={`action-title${index}`}
						value={action.label}
						name="label"
						fullWidth
						onChange={event =>
							handleOnChange({
								index,
								name: event.currentTarget.name,
								value: event.currentTarget.value,
								action,
							})
						}
						label="Label"
						variant="outlined"
					/>
				</Grid>
				<Grid item spacing={1} xs={12} lg={6}>
					<TextField
						id={`action-link-${index}`}
						value={action.link}
						name="link"
						fullWidth
						onChange={event =>
							handleOnChange({
								index,
								name: event.currentTarget.name,
								value: event.currentTarget.value,
								action,
							})
						}
						label="Deeplink"
						variant="outlined"
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ActionItem;
