import { useContext, useState } from 'react';
import { AdminCadUsersContext } from '../shared/admin-caduser-provider';

export const useAdminCadUsers = () => {
	const [tipo, setTipo] = useState<string>('');
	const context = useContext(AdminCadUsersContext);

	const selectTipo = (event: any) => {
		context.onChangeRole?.(event.target.value);
		setTipo(event.target.value);
	};

	return {
		...context,
		tipo,
		selectTipo,
	};
};
