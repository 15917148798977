import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router-dom';
import { CADASTRAR_USUARIO } from 'constants/routes';
import { NavHeader, SelectTipo, SearchBox } from 'components';
import { ADMIN_CAD_USERS_SCREEN_PARTNER } from '../AdminCadUsersPartner/routes';
import CadUsersTable from './components/CadUsersTable';
import { useAdminCadUsers } from './hooks/useAdminCadUsers';
import { useStyles } from './styles';

const AdminCadUsers: React.FC = () => {
	const styles = useStyles();
	const { onSearch, tipo, selectTipo } = useAdminCadUsers();
	const navigate = useNavigate();
	return (
		<>
			<NavHeader />
			<Container>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Usuários
					</Typography>
				</Box>
				<SearchBox onSubmit={onSearch} />
				<Grid
					container
					className={styles.gridContainer}
					justify="space-between"
				>
					<Grid item xs={10} lg={2} className="text-center">
						<Box my={3} p={2} className="text-right">
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate(ADMIN_CAD_USERS_SCREEN_PARTNER)}
							>
								Editar Parceiros
							</Button>
						</Box>
					</Grid>
					<Grid item className="text-center">
						<Box my={3} p={2} className="text-left">
							<Button
								variant="contained"
								color="default"
								onClick={() => navigate(CADASTRAR_USUARIO)}
								startIcon={<AddIcon />}
							>
								Novo Usuário
							</Button>
						</Box>
					</Grid>
				</Grid>

				<Grid container className="filterHeader">
					<Grid item xs={8} justify="space-between">
						<SelectTipo name="tipo" value={tipo} onChange={selectTipo} />
					</Grid>
				</Grid>
				<Box my={4}>
					<CadUsersTable />
				</Box>
			</Container>
		</>
	);
};

export default AdminCadUsers;
