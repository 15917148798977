import { isEmptyObject } from 'helpers';

const ReviewWrapper = ({ children, questao }) => {
	if (
		!isEmptyObject(questao) &&
		(questao.erros.length > 0 || questao.reviewReason)
	) {
		return children;
	}

	return null;
};

export default ReviewWrapper;
