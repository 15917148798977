import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Button, IconButton } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { CADASTRAR_DESAFIO_ROUTE } from 'constants/routes';
import { CheckQuestionContext } from 'screens/CadastrarDesafio/provider/CheckQuestionProvider';
import { createUseStyles } from 'react-jss';
import { REMOVE_QUESTOES_SIMULADO } from 'screens/RevisarSimulados/api';
import { REMOVE_QUESTAO_DESAFIO } from './mutation';
import TableCollapse from './TableCollapse';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

export const useStyles = createUseStyles({
	scoreBaixo: {
		color: '#f44336',
		fontWeight: 'bold',
	},
	scoreMedio: {
		color: '#ff9800',
		fontWeight: 'bold',
	},
	scoreAlto: {
		color: '#4caf50',
		fontWeight: 'bold',
	},
});

const getDate = (date: any) => {
	// @ts-ignore
	if (moment(date)._d.toString() === 'Invalid Date') {
		return moment(parseInt(date, 10)).fromNow();
	}
	return moment(date, 'YYYY-MM-DD').fromNow();
};

export default function Row(props: any) {
	const {
		questao,
		desafioId,
		refetch,
		deleteCheckbox,
		selectedQuestion,
		prova,
	} = props;
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate();
	const { setSelectedQuestions } = useContext(CheckQuestionContext);

	const handleToggle = (questaoSelected: any) => e => {
		selectedQuestion(curr => {
			const questionFilter = e.target.checked
				? [
						...curr,
						{
							id: questaoSelected._id,
						},
				  ]
				: curr.filter(people => people?.id !== questaoSelected._id);
			return questionFilter;
		});
	};

	const [removeQuestao] = useMutation(REMOVE_QUESTAO_DESAFIO, {
		onCompleted: refetch,
	});

	const [removeQuestaoSimulado] = useMutation(REMOVE_QUESTOES_SIMULADO, {
		onCompleted: refetch,
	});

	let assertividade = 0;
	if (questao.assertividade) {
		assertividade =
			questao.assertividade?.erros + questao.assertividade?.acertos;
		assertividade = questao.assertividade?.acertos / assertividade;
		assertividade = Math.floor(assertividade * 100);
	}

	const classes = useStyles();
	const assertividadeLabel = questao.assertividade
		? `${(assertividade || 0).toString()}%`
		: '-';
	let classAssertividade = classes.scoreBaixo;
	if (assertividade > 69) {
		classAssertividade = classes.scoreAlto;
	} else if (assertividade > 29 && assertividade < 70) {
		classAssertividade = classes.scoreMedio;
	}

	return (
		<React.Fragment key={questao._id}>
			<StyledTableRow>
				<StyledTableCell component="th" scope="row">
					{questao.vestibular}
				</StyledTableCell>
				<StyledTableCell align="center">{questao.ano}</StyledTableCell>
				<StyledTableCell align="center">{questao.fonte}</StyledTableCell>
				<StyledTableCell align="center">{questao.materia}</StyledTableCell>
				<StyledTableCell align="center">
					{questao.numeroQuestao}
				</StyledTableCell>
				<StyledTableCell align="center">
					{questao.areaConhecimento?.nome}
				</StyledTableCell>
				<StyledTableCell align="center">
					<span className={classAssertividade}>{assertividadeLabel}</span>
				</StyledTableCell>
				<StyledTableCell align="center">
					{getDate(questao.createdAt)}
				</StyledTableCell>
				<StyledTableCell align="center">
					<Button
						data-testid="salvar-button"
						variant="outlined"
						color="primary"
						style={{ fontSize: 11, padding: 3 }}
						startIcon={<EditIcon style={{ marginLeft: 3, fontSize: 14 }} />}
						type="submit"
						onClick={() =>
							navigate(
								`${CADASTRAR_DESAFIO_ROUTE}/${desafioId}/questao/${questao._id}`,
							)
						}
					>
						Revisar
					</Button>
				</StyledTableCell>

				<StyledTableCell align="center">
					<Button
						style={{ fontSize: 11, color: 'black' }}
						data-testid="salvar-button"
						variant="outlined"
						size="small"
						type="submit"
						onClick={() => setOpen(!open)}
					>
						Detalhes
					</Button>
				</StyledTableCell>
				<StyledTableCell align="center">
					{deleteCheckbox ? (
						<input
							onChange={handleToggle(questao)}
							type="checkbox"
							style={{ width: 20, height: 20 }}
						/>
					) : (
						<IconButton
							color="secondary"
							onClick={async () => {
								let data;
								if (prova || prova === 0) {
									data = await removeQuestaoSimulado({
										variables: {
											idSimulado: desafioId,
											questoes: [questao._id],
											prova,
										},
									});
								} else {
									data = await removeQuestao({
										variables: {
											idDesafio: desafioId,
											questoes: [questao._id],
										},
									});
								}
								setSelectedQuestions(
									prova || prova === 0
										? data.data.removeQuestoesSimulado.provas[
												prova
										  ]?.questoes?.map(({ _id }) => _id)
										: data.data.desafio.questoes,
								);
							}}
						>
							<DeleteForeverIcon />
						</IconButton>
					)}
				</StyledTableCell>
			</StyledTableRow>
			<TableCollapse
				open={open}
				enunciado={questao?.enunciado ? questao.enunciado : ''}
				alternativas={questao?.alternativas ? questao.alternativas : []}
			/>
		</React.Fragment>
	);
}
