import moment from 'moment';
import React from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import { useFinancialFilters, useUserFilters } from '../hooks';
import {
	FINANCIAL_REPORT_GRAPH,
	REPORT_USERS,
	PRICE_REPORT_GRAPH,
} from '../api';
import { UserDateFilter } from '../types';

interface Context {
	filters: any;
	handleChange: any;
	users: any;
	dataUser: any;
	loadingUsers: boolean;
	loadingGraph: boolean;
	loadingReport: boolean;
	usersError: ApolloError | undefined;
	errorGraph: ApolloError | undefined;
	errorReport: ApolloError | undefined;
	filtersUser: any;
	handleChangeUser: any;
	dataPrice: any;
}

export const UsersContext = React.createContext<Context>({
	filters: {},
	handleChange: () => {},
	users: {},
	dataUser: {},
	loadingUsers: false,
	loadingGraph: false,
	loadingReport: false,
	usersError: undefined,
	errorGraph: undefined,
	errorReport: undefined,
	filtersUser: {},
	dataPrice: {},
	handleChangeUser: () => {},
});

interface Props {
	children: React.ReactElement;
	filtersOut?: UserDateFilter;
	setFilters?: (filters: UserDateFilter) => void;
	filtersOutUser?: { active: boolean };
	setFiltersUser?: (filters: { active: boolean }) => void;
	update?: boolean;
}
let auxFilters: UserDateFilter = {
	inicio: moment().startOf('year').format('YYYY-MM-DD'),
	fim: moment().format('YYYY-MM-DD'),
};
let auxFilters2: { active: boolean } = {
	active: true,
};

const UsersProvider: React.FC<Props> = ({
	children,
	filtersOut,
	setFilters,
	filtersOutUser,
	setFiltersUser,
	update = false,
}) => {
	const { filters, handleChange } = useFinancialFilters();

	const { filtersUser, handleChangeUser } = useUserFilters();

	if (filtersOut && filtersOut !== auxFilters && update) {
		auxFilters = filtersOut;
	}
	if (!update) {
		auxFilters = filters;
		if (setFilters) setFilters(filters || ({} as UserDateFilter));
	}

	if (filtersOutUser && filtersOutUser !== auxFilters2 && update) {
		auxFilters2 = filtersOutUser;
	}
	if (!update) {
		auxFilters2 = filtersUser;
		if (setFiltersUser)
			setFiltersUser(filtersUser || ({} as { active: boolean }));
	}
	const variables = {
		filters: { ...auxFilters },
	};
	const { data, loading, error } = useQuery(FINANCIAL_REPORT_GRAPH, {
		variables,
		fetchPolicy: 'no-cache',
	});

	const {
		data: dataUser,
		loading: loadingReport,
		error: errorReport,
	} = useQuery(REPORT_USERS, {
		variables: { filters: { ...auxFilters2 } },
		fetchPolicy: 'no-cache',
	});

	const {
		data: dataPrice,
		loading: loadingGraph,
		error: errorGraph,
	} = useQuery(PRICE_REPORT_GRAPH, {
		variables,
		fetchPolicy: 'no-cache',
	});

	return (
		<UsersContext.Provider
			value={{
				filters,
				handleChange,
				users: data,
				dataUser,
				loadingUsers: loading,
				usersError: error,
				loadingGraph,
				loadingReport,
				errorReport,
				errorGraph,
				filtersUser,
				dataPrice,
				handleChangeUser,
			}}
		>
			{children}
		</UsersContext.Provider>
	);
};

export default UsersProvider;
