import React, { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import UserRoles from 'types/user';

type SelectType = ChangeEvent<{ name?: string; value: string }>;

interface IDesafiosContext {
	materia: string;
	owner: string;
	disponivel: string;
	vestibular: string;
	permissao: string;
	expired: boolean;
	publicado: string;
	dataFinal: string;
	totalRespondido: string;
	selectMateria: (event: SelectType) => void;
	selectStatus: (event: SelectType) => void;
	selectVestibular: (event: SelectType) => void;
	selectPermissao: (event: SelectType) => void;
	selectPublicado: (event: SelectType) => void;
	selectDataFinal: (event: SelectType) => void;
	selectTotalRespondido: (event: SelectType) => void;
}

const DesafiosContext = React.createContext({} as IDesafiosContext);

export const DesafioProvider = ({ children }) => {
	const [materia, setMateria] = useState('');
	const [disponivel, setDisponivel] = useState<string>('');
	const [expired, setExpired] = useState(false);
	const [vestibular, setVestibular] = useState<string>('');
	const [permissao, setPermissao] = useState<string>('');
	const [publicado, setPublicado] = useState<string>('');
	const [dataFinal, setDataFinal] = useState<string>('');
	const [totalRespondido, setTotalRespondido] = useState<string>('');
	const user = useSelector((state: RootState) => state.auth.user);
	const owner = user.role !== UserRoles.ADMINISTRADOR ? user._id : '';

	const selectMateria = (event: SelectType) => {
		setMateria(event.target.value);
	};

	const selectStatus = (event: SelectType) => {
		if (event.target.value === 'vencido') {
			setDisponivel(event.target.value);
			setExpired(true);
		} else {
			setDisponivel(event.target.value);
			setExpired(false);
		}
	};

	const selectVestibular = (event: SelectType) => {
		setVestibular(event.target.value);
	};

	const selectPermissao = (event: SelectType) => {
		setPermissao(event.target.value);
	};

	const selectPublicado = (event: SelectType) => {
		setPublicado(event.target.value);
	};

	const selectDataFinal = (event: SelectType) => {
		setDataFinal(event.target.value);
	};

	const selectTotalRespondido = (event: SelectType) => {
		setTotalRespondido(event.target.value);
	};

	return (
		<DesafiosContext.Provider
			value={{
				materia,
				selectMateria,
				selectStatus,
				selectVestibular,
				selectPermissao,
				selectPublicado,
				selectDataFinal,
				selectTotalRespondido,
				owner,
				disponivel,
				vestibular,
				permissao,
				expired,
				publicado,
				dataFinal,
				totalRespondido,
			}}
		>
			{children}
		</DesafiosContext.Provider>
	);
};

export default DesafiosContext;
