import moment from 'moment';
import { PriceReport } from '../types';

export const getRows = (data: any) =>
	data?.getReportUsers?.map((user: any, index: number) => ({
		id: index + 1,
		nome: user?.userData[0].user.displayName || '------------------------',
		email: user?.userData[0].user.email,
		plano: user?.userData[0].sku,
		parceiro:
			capitalizeFirstLetter(user?.userData[0].externalParceiro) ||
			'------------------------',
		date: unixToDate(user?.userData[0].startedAt),
		dateExpired: unixToDate(user?.userData[0].expireAt),
		numberSubscriptions: user?.userData.length,
	}));

export const queryInfo: any = {
	label: {
		activeSubscriptions: 'Realizadas',
		subscriptionsActivated: 'Ativas',
		subscriptionsExpired: 'Expiradas',
		subscriptionRenewedGoogle: 'Renewed Google',
		subscriptionRenewedApple: 'Renewed Apple',
		subscriptionCanceledGoogle: 'Canceled Google',
		subscriptionCanceledApple: 'Canceled Apple',
	},
	totalKey: {
		activeSubscriptions: 'totalSubscriptionsActive',
		subscriptionsActivated: 'totalSubscriptionsActivated',
		subscriptionsExpired: 'totalSubscriptionsExpired',
		subscriptionRenewedGoogle: 'totalSubscriptionsRenewedGoogle',
		subscriptionRenewedApple: 'totalSubscriptionsRenewedApple',
		subscriptionCanceledGoogle: 'totalSubscriptionsCanceledGoogle',
		subscriptionCanceledApple: 'totalSubscriptionsCanceledApple',
	},
};

export const month2digits = (month: number) => (month < 10 ? '0' : '') + month;

export const formattedFinancialReport = (financialReport: any) =>
	financialReport?.map((item: { _id: { month: number; year: number } }) => ({
		...item,
		label: `${month2digits(item?._id?.month)}/${item?._id?.year}`,
	}));

export const capitalizeFirstLetter = (stringToCapitalize: string) =>
	stringToCapitalize?.charAt(0)?.toUpperCase() + stringToCapitalize?.slice(1);

export const unixToDate = (unix: number) =>
	moment(new Date(Number(unix))).format('DD/MM/YYYY');

export const sortDate = (
	financialReport: { label: string; _id: { year: number; month: number } }[],
) =>
	financialReport.sort(
		(
			a: { _id: { year: number; month: number } },
			b: { _id: { year: number; month: number } },
		) => {
			if (a._id.year !== b._id.year) return a._id.year - b._id.year;
			return a._id.month - b._id.month;
		},
	);

export const formatArray = (arr: { label: string }[]) =>
	arr.reduce((acc, val) => {
		const index = acc.findIndex(el => el.label === val.label);
		if (index !== -1) {
			const key = Object.keys(val)[1];
			acc[index][key] = val[key];
		} else {
			acc.push(val);
		}
		return acc;
	}, []);

export const formattedPriceReport = (priceReport: PriceReport[]) =>
	priceReport?.map(item => ({
		...item,
		[item.totalPriceApple ? 'totalPriceApple' : 'totalPriceGoogle']: Number(
			(item?.totalPriceApple ?? item?.totalPriceGoogle)?.toFixed(2),
		),
		label: `${month2digits(item?._id?.month)}/${item?._id?.year}`,
	}));

export const formattedPriceReportUSD = (priceReport: PriceReport[]) =>
	priceReport?.map(item => ({
		...item,
		[item.totalPriceAppleUSD
			? 'totalPriceAppleUSD'
			: 'totalPriceGoogleUSD']: Number(
			(item?.totalPriceAppleUSD ?? item?.totalPriceGoogleUSD)?.toFixed(2),
		),
		label: `${month2digits(item?._id?.month)}/${item?._id?.year}`,
	}));
