import React from 'react';
import { createUseStyles } from 'react-jss';
import { Header, Body } from './components/index';

const useStyles = createUseStyles({
	container: {
		backgroundColor: '#F2F2F2',
		minHeight: '100vh',
		height: '100%',
	},
});

function ListarQuestoes() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Header />
			<Body />
		</div>
	);
}

export default ListarQuestoes;
