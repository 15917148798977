import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_FEED_NOTIFICATION } from './mutations';
import { validateNotificationInput } from '../utils/validade-notification';
import { CreateNotificationFeedInput, NotificationFeedInput } from '../types';

const initialState = {
	audience: null,
	title: '',
	receiver: [],
	description: '',
	link: null,
	icon: '',
	iconType: null,
	actions: [],
};

export const useFeedNotification = () => {
	const [createNotificationFeed, { loading, data }] = useMutation<
		{ createNotificationFeed: string | null },
		{ notification: CreateNotificationFeedInput }
	>(MUTATION_CREATE_FEED_NOTIFICATION);

	const [state, setState] = useState<NotificationFeedInput>(initialState);

	const handleOnSendNotification = async () => {
		const { audience, receiver, ...restState } = state;
		const message = Object.entries(restState).reduce((items, [key, value]) => {
			if (value) {
				return {
					...items,
					[key]: value,
				};
			}
			return items;
		}, {}) as CreateNotificationFeedInput['message'];
		const notification = {
			audience: audience || null,
			message,
			receiver,
		};

		try {
			await validateNotificationInput(notification);
			await createNotificationFeed({
				variables: {
					notification,
				},
			});
			toast.success('Notificação enviada com sucesso!');
			// clear the state
			setState(initialState);
		} catch (error) {
			toast.error(error?.message || 'Não foi possível enviar a notificação.');
		}
	};

	const handleOnSetState = useCallback(
		(values: Partial<NotificationFeedInput>) => {
			setState(vals => ({ ...vals, ...values }));
		},
		[],
	);

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setState(vals => ({
				...vals,
				[event.target.name]: event.target.value,
			}));
		},
		[],
	);

	return {
		handleOnChange,
		handleOnSetState,
		handleOnSendNotification,
		state,
		loading,
		data,
	};
};
