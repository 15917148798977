import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	btn: {
		backgroundColor: '#363636',
		color: '#fff',
		'&:hover': {
			cursor: 'pointer',
			color: 'white',
			textDecoration: 'none',
		},
		'&:focus': {
			backgroundColor: '#000',
			outline: 'none',
		},
	},
	icon: {
		color: 'white',
		'&:hover': {
			cursor: 'pointer',
		},
		'&:focus': {
			color: '#C8CCD4',
		},
	},
	body: {
		padding: '42px 10% 10px 10%',
	},
	bodyTab: {
		marginBottom: 20,
		height: 50,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		height: '50vh',
		marginTop: '10%',
	},
	title: {
		display: 'flex',
		alignSelf: 'center',
		color: '#C8CCD4',
	},

	root: {
		flexGrow: 1,
		paddingBottom: 10,
	},
	gridContainer: {
		justifyContent: 'space-between',
		paddingBottom: 10,
		paddingTop: 10,
	},
	form: {
		'& > *': {
			margin: 10,
			width: '10ch',
		},
	},

	buttonLink: {
		outline: 'none',
		fontSize: 12,
		color: 'white',
		fontWeight: '600',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#262626',
			textDecoration: 'none',
		},
		'&:focus': {
			backgroundColor: '#F6F6F6',
			outline: 'none',
		},
	},
});
