import React, { Component } from 'react';
import { Row, Col, Button, ListGroupItem } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiPencil, mdiDelete } from '@mdi/js';
import PropTypes from 'prop-types';

class ListarMateriasItem extends Component {
	handleOnEditItem = () => {
		const { handleOnEdit, item } = this.props;
		handleOnEdit(item);
	};

	render() {
		const {
			item: { materia },
		} = this.props;

		return (
			<ListGroupItem className="align-items-center">
				<Row>
					<Col xs="1" sm="5" md="5">
						{materia}
					</Col>
					<Col xs="12" sm="12" md="10" />
					<Col xs="9" sm="9" md="12">
						<Button color="danger" className="ml-2 float-right align-items">
							<Icon path={mdiDelete} size={0.8} color="white" />
						</Button>
						<Button
							color="primary"
							onClick={this.handleOnEditItem}
							className="float-right"
						>
							<Icon path={mdiPencil} size={0.8} color="white" />
						</Button>
					</Col>
				</Row>
			</ListGroupItem>
		);
	}
}

ListarMateriasItem.propTypes = {
	handleOnEdit: PropTypes.func.isRequired,
	item: PropTypes.shape({
		_id: PropTypes.string,
		materia: PropTypes.string,
	}),
};

export default ListarMateriasItem;
