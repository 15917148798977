import React from 'react';
import { Button, TableBody, Chip, IconButton } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import { IDesafio } from 'types/desafio';
import { getDate, timestampToDate } from 'helpers/data';
import { CADASTRAR_DESAFIO_ROUTE } from 'constants/routes';
import UserWrapper from 'screens/wrappers/UserWrapper';
import UserRoles, { AppLevels } from 'types/user';
import { StyledTableCell } from './StyledTableCell';
import { StyledTableRow } from './StyledTableRow';
import { REMOVE_DESAFIO } from './queries';
import { useStyles } from './styles';

interface Props {
	desafios: IDesafio[];
	refetch: any;
	desafioInfo: Record<string, unknown>[];
	setdesafioInfo: (desafioInfo: Record<string, unknown>[]) => void;
}

const checkDesafio = (desafio: IDesafio) => {
	const today = new Date().getTime();
	return today > Number(desafio.dataFinal) && desafio.disponivel === true;
};

const checkDesafioDisponivel = (
	desafio: IDesafio,
	desafioDisponivel: boolean,
) => {
	if (checkDesafio(desafio)) {
		return 'Vencido';
	}
	if (desafioDisponivel) return 'Disponível';
	return 'Indisponível';
};

const TableRows: React.FC<Props> = ({
	desafios,
	refetch,
	desafioInfo,
	setdesafioInfo,
}) => {
	const classes = useStyles();
	const [removeDesafio] = useMutation(REMOVE_DESAFIO, {
		onCompleted: refetch,
	});

	const handleCheck = (desafio: IDesafio) => e => {
		const peopleFilter = e.target.checked
			? [
					...desafioInfo,
					{
						id: desafio._id,
					},
			  ]
			: desafioInfo.filter(people => people?.id !== desafio._id);

		setdesafioInfo(peopleFilter);
	};

	return (
		<TableBody>
			{desafios?.map(desafio => (
				<StyledTableRow key={desafio?._id}>
					<StyledTableCell align="center">
						<Chip
							size="small"
							variant="outlined"
							label={checkDesafioDisponivel(desafio, desafio?.disponivel)}
							color={desafio?.disponivel ? 'primary' : 'secondary'}
							style={
								checkDesafio(desafio)
									? { border: '1px solid #eed202', color: '#eed202' }
									: {}
							}
						/>
					</StyledTableCell>
					<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
						<StyledTableCell align="center">
							<b>
								{desafio?.permissionLevel
									?.map((it: number) => AppLevels[it])
									.join(', ')}
							</b>
						</StyledTableCell>
					</UserWrapper>
					<StyledTableCell align="center">{desafio?.nome}</StyledTableCell>
					<StyledTableCell align="center">{desafio?.ownerName}</StyledTableCell>
					<StyledTableCell align="left">{desafio?.materia}</StyledTableCell>
					<StyledTableCell align="center">
						{desafio.tempoMaximo && desafio.tempoMaximo >= 60
							? desafio.tempoMaximo / 60
							: 0}
					</StyledTableCell>
					<StyledTableCell align="center">
						{desafio?.totalQuestoes || 0}
					</StyledTableCell>
					<StyledTableCell align="center">{desafio?.pontuacao}</StyledTableCell>
					<StyledTableCell align="center">
						{desafio?.totalRespondida}
					</StyledTableCell>
					<StyledTableCell align="center">
						{getDate(desafio?.pubDate)}
					</StyledTableCell>
					<StyledTableCell align="center">
						{timestampToDate(desafio?.dataFinal)}
					</StyledTableCell>
					<StyledTableCell align="center">
						<Link
							to={`${CADASTRAR_DESAFIO_ROUTE}/${desafio?._id}`}
							className={classes.button}
						>
							<Button
								style={{ padding: 4, fontSize: 10 }}
								data-testid="salvar-button"
								variant="outlined"
								color="primary"
								startIcon={<EditIcon style={{ fontSize: 14 }} />}
							>
								Revisar
							</Button>
						</Link>
					</StyledTableCell>
					<StyledTableCell align="center">
						<IconButton
							onClick={() =>
								removeDesafio({
									variables: {
										idDesafio: desafio?._id,
									},
								})
							}
						>
							<DeleteForeverIcon color="secondary" />
						</IconButton>
					</StyledTableCell>
					<StyledTableCell align="center">
						<input
							onChange={handleCheck(desafio)}
							type="checkbox"
							style={{ width: 20, height: 20 }}
						/>
					</StyledTableCell>
				</StyledTableRow>
			))}
		</TableBody>
	);
};

export default TableRows;
