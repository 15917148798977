import React, { useMemo } from 'react';
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	Paper,
	Typography,
	CircularProgress,
	TablePagination,
} from '@material-ui/core';

import { ICadUser } from '../../types';
import { useAdminCadUsers } from '../../hooks/useAdminCadUsers';
import RowCadUser from './RowUser';

const headCells = ['Nome', 'E-mail', 'Tipo', 'Situação', '', ''];

const CadUsersTable: React.FC = () => {
	const {
		users,
		isLoading,
		refetch,
		error,
		page,
		totalUsers,
		limit,
		onChangePage,
		onChangeLimit,
	} = useAdminCadUsers();

	const renderUsers = (user: ICadUser) => (
		<RowCadUser key={user._id} user={user} />
	);

	const renderComponentState = useMemo(() => {
		if (isLoading) return <CircularProgress />;

		if (error)
			return (
				<>
					<Typography align="center" variant="h6" component="h6">
						Ops, aconteceu alguma coisa errada.
					</Typography>
					<Button onClick={() => refetch()} variant="contained">
						Tentar novamente
					</Button>
				</>
			);

		return (
			<Typography align="center" variant="h5" component="h5">
				Nenhum usuário encontrado
			</Typography>
		);
	}, [error, refetch, isLoading]);

	if (!users?.length || isLoading || error) {
		return (
			<Box p={5} textAlign="center">
				{renderComponentState}
			</Box>
		);
	}
	return (
		<Paper>
			<TableContainer>
				<Table
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
					component={Paper}
				>
					<TableHead>
						<TableRow>
							{headCells.map((item, index) => (
								<TableCell key={`${item}-${index}`} align="left">
									{item}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>{users && users?.map(renderUsers)}</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 20, 30]}
				component="div"
				count={totalUsers || 0}
				rowsPerPage={limit}
				page={page}
				labelRowsPerPage="Usuários por página"
				onChangePage={(_, pagina) => onChangePage?.(pagina)}
				onChangeRowsPerPage={event =>
					onChangeLimit?.(parseInt(event.target.value, 10))
				}
			/>
		</Paper>
	);
};

export default CadUsersTable;
