import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Container,
	Typography,
	Box,
	CircularProgress,
} from '@material-ui/core';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { NavHeader } from 'components';
import { toast } from 'react-toastify';
import { IAppUser } from 'screens/FindUsersApp/types';
import DetailsUserApp from 'screens/FindUsersApp/details-user';
import { Item, ItemLine } from 'screens/FindUsersApp/details-user/utils';
import { ADMIN_FIND_VALIDATION_CODE } from './api/query';
import FindValidationForm from './api/components/form.filters';
import { State } from './types';

const FindValidationCode: React.FC = () => {
	const statusErrorRef = useRef<string>('');
	const [searchOption, setSearchOption] = useState<State>({
		nome: '',
		parceiro: '',
		validationCode: '',
		username: '',
		password: '',
	});

	const onHandleChange = useCallback((partialValues: Partial<State>) => {
		setSearchOption(values => ({ ...values, ...partialValues }));
	}, []);
	const onClear = useCallback(() => {
		onHandleChange({
			validationCode: '',
			username: '',
			password: '',
		});
	}, [onHandleChange]);

	const [
		adminFindValidationCode,
		{ loading, data, error, networkStatus, called, refetch },
	] = useLazyQuery<
		{
			adminFindValidationCode?: {
				existingUser: IAppUser[] | null;
				found?: boolean;
				authorized: boolean;
				isActive: boolean;
			};
		},
		{
			validationCode: string;
			nome: string;
			email: string;
			username: string;
			password: string;
			parceiro: string;
		}
	>(ADMIN_FIND_VALIDATION_CODE);

	const onSearch = (event: React.FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		const inputIsEmpty =
			!searchOption.validationCode &&
			!searchOption.password &&
			!searchOption.username;

		if (!searchOption.nome || inputIsEmpty) return;
		statusErrorRef.current = 'called';

		adminFindValidationCode({
			variables: {
				...searchOption,
				email: searchOption.validationCode,
			},
		});
	};
	const isLoading = useMemo(
		() => loading || networkStatus === NetworkStatus.refetch,
		[loading, networkStatus],
	);

	useEffect(() => {
		if (error && statusErrorRef.current === 'called') {
			toast.error('Error ao tentar buscar ValidationCode!');
			statusErrorRef.current = 'showed';
		} else if (
			statusErrorRef.current === 'called' &&
			called &&
			data &&
			!data?.adminFindValidationCode?.found
		) {
			toast.info('ValidationCode não encontrado');
			statusErrorRef.current = 'showed';
		}
	}, [error, called, data?.adminFindValidationCode?.found, data]);

	return (
		<>
			<NavHeader />
			<Container>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Buscar Validation Code (
						{data?.adminFindValidationCode?.existingUser?.length})
					</Typography>
				</Box>
				<FindValidationForm
					state={searchOption}
					handleChange={onHandleChange}
					onSubmit={onSearch}
					onClear={onClear}
				/>
				<Box my={4}>
					{isLoading ? (
						<h5>
							Carregando
							<CircularProgress />
						</h5>
					) : null}
				</Box>
				{data?.adminFindValidationCode?.found ? (
					<Box mb={3}>
						<Typography variant="h5" style={{ fontWeight: 'bold' }}>
							Informações
						</Typography>
						<Item>
							<ItemLine
								label="Authorized"
								value={data?.adminFindValidationCode.authorized ? 'SIM' : 'NÃO'}
							/>
							<ItemLine
								label="Ativado"
								value={data?.adminFindValidationCode.isActive ? 'SIM' : 'NÃO'}
							/>
						</Item>
					</Box>
				) : null}

				{data?.adminFindValidationCode?.existingUser?.map(user => (
					<Box my={4} key={user.uid}>
						<DetailsUserApp user={user} refetch={refetch} />
					</Box>
				))}
			</Container>
		</>
	);
};

export default FindValidationCode;
