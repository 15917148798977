import React, { useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Navigate } from 'react-router-dom';
import { UsersContext } from 'screens/DashboardFinanceiro/provider';
import { useStyles } from './styles';
import { FinancialCard } from '..';

interface Props {
	loading: boolean;
	error: any;
	cards: any;
}

const CardRow: React.FC<Props> = ({ loading, error, cards }) => {
	const classes = useStyles();
	const { loadingUsers, usersError } = useContext(UsersContext);

	if (loadingUsers) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;
	if (loading) return <LinearProgress />;
	if (error) return <Navigate to="/" />;

	const renderCards = () => {
		const cardsMap = cards.map((card: any) => (
			<Grid item lg={3}>
				<FinancialCard
					avatarTitle={card.label}
					color={card.color}
					title={card.label}
					number={card.total}
				/>
			</Grid>
		));
		return cardsMap;
	};
	return (
		<Grid className={classes.container} container spacing={2}>
			{renderCards()}
		</Grid>
	);
};

export default CardRow;
