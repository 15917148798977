import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography } from '@material-ui/core';
import { CampaignInput } from './types';

const AlertDialog: React.FC<{
	campanha: CampaignInput;
	open: boolean;
	handleClose: () => void;
	handleSubmit: () => void;
}> = ({ handleSubmit, handleClose, open, campanha }) => (
	<div>
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Você tem certeza que deseja enviar essa campanha ?
					{campanha.subject && (
						<Typography variant="body2" component="p">
							<b>Subject:</b> {campanha.subject}
						</Typography>
					)}
					{campanha.url && (
						<Typography variant="body2" component="p">
							<b>Link: </b>
							{campanha.url}
						</Typography>
					)}
					{campanha.displayName && (
						<Typography variant="body2" component="p">
							<b>DisplayName:</b> {campanha.displayName}
						</Typography>
					)}
					{campanha.email && (
						<Typography variant="body2" component="p">
							<b>E-mail</b>: {campanha.email}
						</Typography>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="outlined" color="secondary">
					Cancelar
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					autoFocus
				>
					Enviar
				</Button>
			</DialogActions>
		</Dialog>
	</div>
);

export default AlertDialog;
