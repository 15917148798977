import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import { formatCompetenciasHabilidades } from 'helpers';

const FieldRow = styled(Row)`
	border: 1px solid lightgray;
	border-radius: 5px;
	margin-bottom: 20px;
`;

const FieldHeader = styled.p`
	margin-top: 10px;
	border-bottom: 1px solid lightgray;
	font-weight: bold;
	font-size: 18px;
`;

const FieldText = styled.p`
	font-size: 15px;
`;

const CommonField = ({ label, text }) => (
	<FieldRow className="ml-3 mr-3">
		<Col xs="12">
			<FieldHeader>{label}</FieldHeader>
		</Col>
		<Col xs="12">
			<FieldText>{text}</FieldText>
		</Col>
	</FieldRow>
);

CommonField.propTypes = {
	label: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DetalhesQuestao = ({ isOpen, toggle, questao }) => {
	const competencias = questao.areaConhecimento
		? formatCompetenciasHabilidades(questao.areaConhecimento.competencias, 'C')
		: null;
	const habilidades = questao.areaConhecimento
		? formatCompetenciasHabilidades(questao.areaConhecimento.habilidades, 'H')
		: null;

	return (
		<Modal isOpen={isOpen} toggle={toggle} size="lg">
			<ModalHeader toggle={toggle}>Visualizar Questão</ModalHeader>
			<ModalBody>
				<CommonField label="Vestibular" text={questao.vestibular} />
				<CommonField label="Palavra Chave" text={questao.palavraChave} />
				{questao.codigo && <CommonField label="Código" text={questao.codigo} />}
				<CommonField label="Número da Questão" text={questao.numeroQuestao} />
				<CommonField label="Matéria" text={questao.materia} />
				<FieldRow className="ml-3 mr-3">
					<Col xs="12">
						<FieldHeader>Assuntos</FieldHeader>
					</Col>
					<Col xs="12">
						{questao.assuntos.map(assunto => (
							<li key={assunto._id} className="mb-1">
								{assunto.nome}
							</li>
						))}
					</Col>
				</FieldRow>
				<CommonField label="Fonte" text={questao.fonte} />
				{questao.vestibular === 'ENEM' && (
					<>
						<CommonField label="Competência(s)" text={competencias} />
						<CommonField label="Habilidade(s)" text={habilidades} />
						<CommonField
							label="Área de Conhecimento"
							text={questao.areaConhecimento.nome}
						/>
					</>
				)}
				<FieldRow className="ml-3 mr-3">
					<Col xs="12">
						<FieldHeader>Enunciado</FieldHeader>
					</Col>
					<Col xs="12">
						<div dangerouslySetInnerHTML={{ __html: questao.enunciado }} />
					</Col>
				</FieldRow>
				{questao.video && <CommonField label="Vídeo" text={questao.video} />}
				<FieldRow className="ml-3 mr-3">
					<Col xs="12">
						<FieldHeader>Alternativas</FieldHeader>
					</Col>
					{questao.alternativas.map(item => (
						<Col xs="12" key={item.letra}>
							<Alert color={item.correta ? 'success' : 'danger'}>
								<h5>
									{item.letra} {item.correta && '(Correta)'}
								</h5>
								<div dangerouslySetInnerHTML={{ __html: item.texto }} />
							</Alert>
						</Col>
					))}
				</FieldRow>
				<FieldRow className="ml-3 mr-3">
					<Col xs="12">
						<FieldHeader>Resolução</FieldHeader>
					</Col>
					<Col xs="12">
						<div dangerouslySetInnerHTML={{ __html: questao.resolucao }} />
					</Col>
				</FieldRow>
			</ModalBody>
		</Modal>
	);
};

DetalhesQuestao.defaultProps = {
	isOpen: false,
};

DetalhesQuestao.propTypes = {
	questao: PropTypes.instanceOf(Object),
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
};

export default DetalhesQuestao;
