import moment from 'moment';

export const getDate = date => {
	if (!date) return '-';
	// @ts-ignore
	if (moment(date)._d.toString() === 'Invalid Date') {
		return moment(parseInt(date, 10)).fromNow();
	}
	return moment(date, 'YYYY-MM-DD').fromNow();
};

export const timestampToDate = timestamp =>
	moment(timestamp, 'x').format('DD MMM YYYY');

export const dateToStringDate = date => moment(date, 'x').format('YYYY-MM-DD');

export const alphabet = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

export const VESTIBULARES = [
	{
		_id: '5c48b5d52f753e4bae695ac0',
		vestibular: 'ENEM',
		palavrasChave: [
			{
				palavraChave: 'RESOLVA ENEM',
				ano: 2017,
				codigo: 'I',
			},
			{
				palavraChave: 'RESOLVA ENEM',
				ano: 2017,
				codigo: 'II',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S1',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S2',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S3',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S8',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S7',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S6',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2016,
				codigo: 'S5',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2015,
				codigo: null,
			},
			{
				palavraChave: 'ENEM 2016',
				ano: 2016,
				codigo: null,
			},
			{
				palavraChave: 'ENEM 2015',
				ano: 2015,
				codigo: null,
			},
			{
				palavraChave: 'ENEM 2016 II',
				ano: 2016,
				codigo: 'II',
			},
			{
				palavraChave: 'ENEM 2014',
				ano: 2014,
				codigo: null,
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2017,
				codigo: 'S10',
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2017,
				codigo: 'S9',
			},
			{
				palavraChave: 'ENEM 2017',
				ano: 2017,
				codigo: null,
			},
			{
				palavraChave: 'SIMULADO ENEM',
				ano: 2017,
				codigo: 'S4',
			},
		],
	},
	{
		_id: '5c48b5d52f753e4bae695ac1',
		vestibular: 'ITA',
		palavrasChave: [
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2016,
				codigo: 'S1',
			},
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2016,
				codigo: 'S3',
			},
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2016,
				codigo: 'S4',
			},
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2014,
				codigo: 'S5',
			},
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2016,
				codigo: 'S6',
			},
			{
				palavraChave: 'ITA 2015',
				ano: 2015,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2016',
				ano: 2016,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2008',
				ano: 2008,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2014',
				ano: 2014,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2009',
				ano: 2009,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2010',
				ano: 2010,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2013',
				ano: 2013,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2011',
				ano: 2011,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2012',
				ano: 2012,
				codigo: null,
			},
			{
				palavraChave: 'ITA 2017',
				ano: 2017,
				codigo: null,
			},
			{
				palavraChave: 'SIMULADO ITA',
				ano: 2014,
				codigo: 'S7',
			},
		],
	},
	{
		_id: '5c48b5d52f753e4bae695ac2',
		vestibular: 'UNICAMP',
		palavrasChave: [
			{
				palavraChave: 'UNICAMP 2017',
				ano: 2017,
				codigo: null,
			},
			{
				palavraChave: 'UNICAMP 2018',
				ano: 2018,
				codigo: null,
			},
			{
				palavraChave: 'SIMULADO UNICAMP',
				ano: 2017,
				codigo: 'S1',
			},
			{
				palavraChave: 'SIMULADO UNICAMP',
				ano: 2017,
				codigo: 'S2',
			},
			{
				palavraChave: 'SIMULADO UNICAMP',
				ano: 2017,
				codigo: 'S3',
			},
		],
	},
	{
		_id: '5c48b5d52f753e4bae695ac3',
		vestibular: 'IME',
		palavrasChave: [
			{
				palavraChave: 'SIMULADO IME',
				ano: 2016,
				codigo: 'S2',
			},
			{
				palavraChave: 'SIMULADO IME',
				ano: 2016,
				codigo: 'S5',
			},
			{
				palavraChave: 'SIMULADO IME',
				ano: 2014,
				codigo: 'S4',
			},
			{
				palavraChave: 'IME 2016',
				ano: 2016,
				codigo: null,
			},
			{
				palavraChave: 'IME 2015',
				ano: 2015,
				codigo: null,
			},
			{
				palavraChave: 'IME 2013',
				ano: 2013,
				codigo: null,
			},
			{
				palavraChave: 'IME 2011',
				ano: 2011,
				codigo: null,
			},
			{
				palavraChave: 'IME 2010',
				ano: 2010,
				codigo: null,
			},
			{
				palavraChave: 'IME 2014',
				ano: 2014,
				codigo: null,
			},
			{
				palavraChave: 'IME 2012',
				ano: 2012,
				codigo: null,
			},
			{
				palavraChave: 'SIMULADO IME',
				ano: 2013,
				codigo: 'S6',
			},
			{
				palavraChave: 'SIMULADO IME',
				ano: 2014,
				codigo: 'S3',
			},
		],
	},
	{
		_id: '5c48b5d52f753e4bae695ac4',
		vestibular: 'FUVEST',
		palavrasChave: [
			{
				palavraChave: 'FUVEST 2017',
				ano: 2017,
				codigo: null,
			},
			{
				palavraChave: 'FUVEST 2018',
				ano: 2018,
				codigo: null,
			},
		],
	},
	{
		_id: '5c48b5d52f753e4bae695ac5',
		vestibular: 'USP',
		palavrasChave: [
			{
				palavraChave: 'SIMULADO USP',
				ano: 2016,
				codigo: 'S2',
			},
		],
	},
];

export const MATERIAS_DB = [
	{
		_id: '5c3f30337bff7b7e921f782d',
		materiaId: 11,
		materia: 'Matemática',
	},
	{
		_id: '5c3f30337bff7b7e921f782e',
		materiaId: 12,
		materia: 'Física',
	},
	{
		_id: '5c3f30337bff7b7e921f782f',
		materiaId: 13,
		materia: 'Química',
	},
	{
		_id: '5c3f30337bff7b7e921f7830',
		materiaId: 14,
		materia: 'Biologia',
	},
	{
		_id: '5c3f30337bff7b7e921f7831',
		materiaId: 15,
		materia: 'História',
	},
	{
		_id: '5c3f30337bff7b7e921f7832',
		materiaId: 16,
		materia: 'Geografia',
	},
	{
		_id: '5c3f30337bff7b7e921f7833',
		materiaId: 18,
		materia: 'Inglês',
	},
	{
		_id: '5c3f30337bff7b7e921f7834',
		materiaId: 21,
		materia: 'Língua Portuguesa',
	},
	{
		_id: '5c3f30337bff7b7e921f7835',
		materiaId: 36,
		materia: 'Espanhol',
	},
	{
		_id: '5c3f30337bff7b7e921f7836',
		materiaId: 39,
		materia: 'Português',
	},
	{
		_id: '5c3f30337bff7b7e921f7837',
		materiaId: 43,
		materia: 'Filosofia',
	},
	{
		_id: '5c3f30337bff7b7e921f7838',
		materiaId: 44,
		materia: 'Sociologia',
	},
];

export const AREA_DB = [
	{
		_id: '5c41feb6dca082b4a0350bdf',
		areaConhecimento: 'MATEMÁTICA E SUAS TECNOLOGIAS',
		materias: ['5c3f30337bff7b7e921f782d'],
		vestibulares: ['ENEM'],
	},
	{
		_id: '5c41feb6dca082b4a0350be0',
		areaConhecimento: 'LINGUAGENS CÓDIGOS E SUAS TECNOLOGIAS',
		materias: [
			'5c3f30337bff7b7e921f7833',
			'5c3f30337bff7b7e921f7835',
			'5c3f30337bff7b7e921f7836',
			'5c3f30337bff7b7e921f782d',
			'5c3f30337bff7b7e921f7834',
		],
		vestibulares: ['ENEM'],
	},
	{
		_id: '5c41feb6dca082b4a0350be1',
		areaConhecimento: 'CIÊNCIAS HUMANAS E SUAS TECNOLOGIAS',
		materias: [
			'5c3f30337bff7b7e921f7831',
			'5c3f30337bff7b7e921f7832',
			'5c3f30337bff7b7e921f7838',
			'5c3f30337bff7b7e921f7837',
		],
		vestibulares: ['ENEM'],
	},
	{
		_id: '5c41feb6dca082b4a0350be2',
		areaConhecimento: 'CIÊNCIAS DA NATUREZA E SUAS TECNOLOGIAS',
		materias: [
			'5c3f30337bff7b7e921f782f',
			'5c3f30337bff7b7e921f782e',
			'5c3f30337bff7b7e921f7830',
		],
		vestibulares: ['ENEM'],
	},
	{
		_id: '5c41feb6dca082b4a0350be3',
		areaConhecimento: 'LINGUAGENS, CÓDIGOS E SUAS TECNOLOGIAS/INGLÊS',
		materias: ['5c3f30337bff7b7e921f7836', '5c3f30337bff7b7e921f7833'],
		vestibulares: ['IME', 'ENEM'],
	},
	{
		_id: '5c41feb6dca082b4a0350be4',
		areaConhecimento: 'LINGUAGENS, CÓDIGOS E SUAS TECNOLOGIAS/ESPANHOL',
		materias: ['5c3f30337bff7b7e921f7836', '5c3f30337bff7b7e921f7835'],
		vestibulares: ['IME', 'ENEM'],
	},
];

export const FONTE_DB = [
	{
		_id: '5c3f2c1b8578cda4d3927705',
		fonte: 'VA',
		descricao: 'Vestibular Anteriores',
	},
	{
		_id: '5c3f2c278578cda4d3927706',
		fonte: 'FB',
		descricao: 'Farias Brito',
	},
];
