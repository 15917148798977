import UserRoles from 'types/user';
import {
	CHANGE_PASSWORD,
	LISTAR_SIMULADOS,
	LISTAR_QUESTOES,
} from '../../../constants';

export const getLoginRoute = (role: string, firstLogin: boolean) => {
	const { COLABORADOR } = UserRoles;
	if (firstLogin) {
		return CHANGE_PASSWORD;
	}
	if (role === COLABORADOR) {
		return LISTAR_SIMULADOS;
	}
	return LISTAR_QUESTOES;
};
