import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		height: '100vh',
		alignItems: 'center',
		justifyContent: 'center',
	},
	card: {
		width: '35%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(4),
	},
	title: {
		textAlign: 'center',
		marginBottom: theme.spacing(4),
	},
	oldPassword: {
		marginBottom: theme.spacing(2),
	},
	newPassword: {
		marginBottom: theme.spacing(2),
	},
	confirmPassword: {
		marginBottom: theme.spacing(4),
	},
	button: {
		marginBottom: theme.spacing(2),
	},
}));
