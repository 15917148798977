import React, { useState } from 'react';

import { Container } from '@material-ui/core';

import { NavHeader } from 'components';
import moment from 'moment';
import {
	FINANCIAL_REPORT_MONTH,
	FINANCIAL_REPORT,
} from 'screens/DashboardFinanceiro/api';
import { useQuery } from '@apollo/client';
import { primaryGreen, oraColor, tixColor } from 'constants/colors';
import { queryInfo } from './helpers';
import { useStyles } from './styles';
import {
	CardRow,
	Filters,
	FinancialChart,
	FinancialTable,
	DoubleCardRow,
} from './components';
import UsersProvider from './provider';
import { UserDateFilter } from './types';

const DashboardFinanceiro: React.FC = () => {
	const classes = useStyles();
	const [filtersOut, setFilters] = useState<UserDateFilter>();

	const variablesFinancialReport = {
		filters: {
			inicio: '01-01-1971',
			fim: moment().format('MM-DD-YYYY'),
		},
	};
	const variablesGoogleApple = {
		filters: {
			inicio: moment().subtract(30, 'day').format('MM-DD-YYYY'),
			fim: moment().format('MM-DD-YYYY'),
		},
	};
	const {
		data: dataFinancialReport,
		loading: loadingFinancialReport,
		error: errorFinancialReport,
	} = useQuery(FINANCIAL_REPORT, {
		variables: variablesFinancialReport,
		fetchPolicy: 'no-cache',
	});
	const {
		data: dataGoogleApple,
		loading: loadingGoogleApple,
		error: errorGoogleApple,
	} = useQuery(FINANCIAL_REPORT_MONTH, {
		variables: variablesGoogleApple,
		fetchPolicy: 'no-cache',
	});

	const parseQuery = (dataParse = {}, reportMonth: boolean) => {
		let colors = [oraColor, tixColor];
		const parse = Object.entries(dataParse)
			.filter(([key]) => key !== '__typename')
			.map(([key, value]: any, index) => ({
				color:
					index === 0 &&
					(key !== 'subscriptionRenewedGoogle' ||
						key !== 'subscriptionRenewedApple')
						? primaryGreen
						: colors[(index + 1) % 2],
				label: queryInfo.label[key],
				total: reportMonth
					? value?.[0]?.[queryInfo.totalKey[key]] ?? 0
					: value?.reduce(
							(acc: any, curr: any) => acc + curr[queryInfo.totalKey[key]],
							0,
					  ) ?? 0,
			}));
		return parse;
	};
	const cardsGoogleApple = parseQuery(
		dataGoogleApple?.getFinancialReport,
		true,
	);
	const cardsFinancialReport = parseQuery(
		dataFinancialReport?.getFinancialReport,
		false,
	);

	const [filtersOutUser, setFiltersUser] = useState<{ active: boolean }>();
	return (
		<Container className={classes.container} maxWidth="xl" disableGutters>
			<NavHeader headerStyle={{ paddingRight: '4.7%', paddingLeft: '4.2%' }} />
			<Container className={classes.body}>
				<UsersProvider setFilters={setFilters}>
					<>
						<h3>Total Subscriptions </h3>
						<CardRow
							loading={loadingFinancialReport}
							error={errorFinancialReport}
							cards={cardsFinancialReport}
						/>
						<hr />
						<h3>Assinaturas nos últimos 30 dias </h3>
						<br />
						<DoubleCardRow
							loading={loadingGoogleApple}
							error={errorGoogleApple}
							cards={cardsGoogleApple}
						/>
						<hr />
						<h3>Pesquisa </h3>
						<hr />
						<Filters />
						<hr />
						<h3>Resultados </h3>
						<hr />
						<FinancialChart />
					</>
				</UsersProvider>
				<UsersProvider
					filtersOut={filtersOut}
					setFilters={setFilters}
					update
					filtersOutUser={filtersOutUser}
					setFiltersUser={setFiltersUser}
				>
					<>
						<FinancialTable />
					</>
				</UsersProvider>
			</Container>
		</Container>
	);
};

export default DashboardFinanceiro;
