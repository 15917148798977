import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { RootModel } from '..';
import { IUser } from './types';
import api from '../../../services/api';
import { GET_CAD_USERS } from '../../../services/queries';

const INITIAL_STATE = {
	user: {} as IUser,
	fetchRefreshToken: false,
	invalidToken: false,
};

export const authModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		setUser: (state, props: IUser) => ({
			user: { ...state.user, ...props },
			fetchRefreshToken: false,
			invalidToken: false,
		}),
		setTokenInvalid: state => ({
			...state,
			fetchRefreshToken: false,
			invalidToken: true,
		}),
		finalyFetchRefreshToken: state => ({
			...state,
			fetchRefreshToken: false,
		}),
		setFetchRefreshToken: state => ({
			...state,
			fetchRefreshToken: true,
			invalidToken: false,
		}),
		logout: () => {
			localStorage.removeItem('loginCadUser');
			return INITIAL_STATE;
		},
	},
	effects: dispatch => ({
		async logUser(user: IUser) {
			try {
				const { data } = await api.query({
					query: GET_CAD_USERS,
					variables: {
						email: user.email,
					},
				});
				await dispatch.auth.setUser({
					...user,
					...data.cadUsers[0],
				});
			} catch (error: any) {
				await dispatch.auth.logout();
				if (error.graphQLErrors && error.graphQLErrors.length > 0) {
					toast.error(error.graphQLErrors[0].message);
				} else {
					toast.error('Houve um Erro ao tentar logar o usuário!');
				}
			}
		},
	}),
});
