import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { UsersContext } from 'screens/DashboardParceiros/provider';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from './styles';
import { getRows } from '../../helpers';

const columns = [
	{ field: 'id', headerName: 'ID', width: 75, filterable: false },
	{ field: 'nome', headerName: 'Nome', width: 200, filterable: true },
	{ field: 'email', headerName: 'E-mail', width: 300, filterable: true },
	{ field: 'plano', headerName: 'Plano', width: 150, filterable: true },
	{
		field: 'plataforma',
		headerName: 'Plataforma',
		width: 150,
		filterable: true,
	},
	{
		field: 'provider',
		headerName: 'Forma de Login',
		width: 200,
		filterable: true,
	},
	{
		field: 'open',
		headerName: 'Detalhes',
		width: 100,
		filterable: true,
	},
];

const UserTable: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { users, usersError, loadingUsers, setPage, filters } = useContext(
		UsersContext,
	);
	const page = filters.skip ? filters.skip / 20 + 1 : 1;
	function CustomPagination() {
		const adicionar = getRows(users).length >= 20 ? 1 : 0;
		return (
			<Pagination
				className={classes.root}
				color="primary"
				count={page + adicionar}
				page={page}
				onChange={(event, value) => setPage((value - 1) * 20)}
			/>
		);
	}
	if (loadingUsers) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;
	return (
		<div className={classes.tableContainer}>
			<hr />
			<h3>Lista de Usuários</h3>
			<hr />
			{users ? (
				<DataGrid
					className={classes.datagrid}
					rows={getRows(users)}
					pageSize={20}
					columns={columns}
					loading={loadingUsers}
					checkboxSelection={false}
					onCellClick={params => {
						if (params.field === 'open') {
							navigate(`/buscar-usuario/${params.data.email}`);
						}
					}}
					disableSelectionOnClick
					components={{
						pagination: CustomPagination,
					}}
				/>
			) : null}
		</div>
	);
};

export default UserTable;
