import { NavHeader } from 'components';
import React from 'react';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	Divider,
	Card,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowBack, Send } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FEED_BY_ID_NOTIFICATION } from 'constants/routes';
import { useStyles } from '../../styles';

import NotificationItemExample from '../../components/notification-item-example';
import AudienceSection from '../../components/audience-section';
import MessageInputsSection from '../../components/message-input-section';
import { useFeedNotification } from '../../api/use-feed-notification';

const FeedNotification: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		loading,
		data,
		handleOnChange,
		handleOnSendNotification,
		handleOnSetState,
		state,
	} = useFeedNotification();

	return (
		<>
			<NavHeader />
			<Container
				style={{ backgroundColor: '#F6F7F8', height: '100vh' }}
				maxWidth="xl"
			>
				<Container>
					<Grid container className={classes.headerTitle}>
						<Button
							className={classes.backButton}
							onClick={() => {
								navigate(-1);
							}}
							startIcon={<ArrowBack />}
						>
							Voltar
						</Button>
					</Grid>
					<Box my={3}>
						<Typography
							style={{ fontWeight: 'bold' }}
							variant="h4"
							component="h4"
							color="initial"
						>
							Feed de Notificações
						</Typography>
						<Typography component="p" color="initial">
							Envio de mensagens que serão exibidas no feed de notificações do
							usuário no app.
						</Typography>
					</Box>
					{/* Audiencias e UserIds */}
					<AudienceSection
						audience={state.audience}
						receiver={state.receiver}
						handleOnSetState={handleOnSetState}
					/>
					<Divider className={classes.divider} />
					{/* Mensagem */}
					<Card className={classes.cardSection}>
						<Box mb={3}>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								component="h5"
								color="initial"
							>
								2. Mensagem
							</Typography>
						</Box>
						<Grid container spacing={3}>
							{/* Coluna A */}
							<Grid item spacing={1} xs={12} lg={6}>
								<MessageInputsSection
									state={state}
									handleOnSetState={handleOnSetState}
									handleOnChange={handleOnChange}
								/>
							</Grid>
							{/* Coluna B */}
							<Grid item xs={12} lg={6}>
								<NotificationItemExample state={state} />
							</Grid>
						</Grid>
					</Card>
					<Divider className={classes.divider} />
					{/* Delivery */}
					<Card className={classes.cardSection}>
						<Box pb={2}>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								component="h5"
								color="initial"
							>
								3. Delivery
							</Typography>
						</Box>

						{data?.createNotificationFeed ? (
							<Box pb={2}>
								<Grid container spacing={3}>
									<Grid item>
										<Typography
											style={{ fontWeight: 'bold' }}
											variant="body1"
											component="h5"
											color="initial"
										>
											Última notificação enviada
										</Typography>
									</Grid>
									<Grid item>
										<Link
											to={FEED_BY_ID_NOTIFICATION.replace(
												':notificationId',
												data?.createNotificationFeed,
											)}
										>
											Abrir notificação
										</Link>
									</Grid>
								</Grid>
							</Box>
						) : null}
						<LoadingButton
							variant="contained"
							disableElevation
							color="primary"
							loading={loading}
							onClick={handleOnSendNotification}
							endIcon={<Send />}
						>
							Revisar e Enviar
						</LoadingButton>
					</Card>
				</Container>
			</Container>
		</>
	);
};

export default FeedNotification;
