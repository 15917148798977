import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MenuPopupState from 'screens/ListarQuestoes/components/Header/MenuPopState';
import { useStyles } from './styles';
import GerenciamentoMenu from './components/SimpleMenu';
import QuestaoMenu from './components/QuestoesMenu';
import { LISTAR_SIMULADOS, LISTAR_DESAFIOS_ROUTE } from '../../../constants';
import FBVestMenu from './components/FBVestMenu';

interface Props {
	headerStyle?: any;
}

const NavHeader: React.FC<Props> = ({ headerStyle }) => {
	const styles = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			style={headerStyle}
			className={styles.header}
		>
			<Grid item xs={10} className={styles.headerItens}>
				<div>
					<QuestaoMenu />
					<Link to={LISTAR_SIMULADOS} className={styles.menuIten}>
						SIMULADOS
					</Link>
					<Link to={LISTAR_DESAFIOS_ROUTE} className={styles.menuIten}>
						DESAFIOS
					</Link>
					<GerenciamentoMenu />
					<FBVestMenu />
				</div>
			</Grid>
			<Grid item xs={2}>
				<MenuPopupState />
			</Grid>
		</Grid>
	);
};

export default NavHeader;
