export const tabListData = [
	{
		id: 1,
		title: 'A REVISAR',
		selected: true,
		reported: false,
		review: true,
	},
	{
		id: 2,
		title: 'REVISADAS',
		selected: false,
		reported: false,
		review: false,
	},
];

interface IData {
	countQuestoesReportadas: number;
	countQuestoes: number;
	countQuestoesRevisadas: number;
}

interface ITabItem {
	id: number;
	title: string;
	selected: boolean;
	reported: boolean;
	review: boolean;
}

export const getCountQuestoes = (data: IData, tabItem: ITabItem) => {
	if (tabItem.reported) {
		return data ? data.countQuestoesReportadas : 0;
	}
	if (tabItem.review) {
		return data ? data.countQuestoes : 0;
	}
	return data ? data.countQuestoesRevisadas : 0;
};
