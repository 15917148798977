import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		marginRight: theme.spacing(1),
	},
	select: {
		borderWidth: 5,
		fontSize: theme.typography.pxToRem(13),
	},
}));
