import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router-dom';
import { CADASTRAR_USUARIO } from 'constants/routes';
import { NavHeader } from 'components';
import { ADMIN_CAD_USERS_SCREEN } from 'screens/AdminCadUsers/routes';
import CadUsersTable from './components/CadUsersTable';
import { useStyles } from './styles';

const AdminCadUsersPartner: React.FC = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	return (
		<>
			<NavHeader />
			<Container>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Parceiros
					</Typography>
				</Box>
				<Grid
					container
					className={styles.gridContainer}
					justify="space-between"
				>
					<Grid item xs={10} lg={2} className="text-center">
						<Box my={3} p={2} className="text-center">
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate(ADMIN_CAD_USERS_SCREEN)}
							>
								Voltar
							</Button>
						</Box>
					</Grid>
					<Grid item className="text-center">
						<Box my={3} p={2} className="text-left">
							<Button
								variant="contained"
								color="default"
								onClick={() => navigate(CADASTRAR_USUARIO)}
								startIcon={<AddIcon />}
							>
								Novo Usuário
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Box my={4}>
					<CadUsersTable />
				</Box>
			</Container>
		</>
	);
};

export default AdminCadUsersPartner;
