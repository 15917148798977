import React, { useContext, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import QuestionContext from 'screens/ListarQuestoes/providers/QuestionProvider';
import { CheckQuestionContext } from 'screens/CadastrarDesafio/provider/CheckQuestionProvider';
import {
	ascOptions,
	anoOptions,
	getPalavrasChaveOptions,
	getMateriaOptions,
	getVestibularOptions,
	getFontesOptions,
	orderOptions,
} from './SelectDropDown/options';
import { useStyles } from './styles';

import SelectDropDown from './SelectDropDown';
import ImputTextField from './TextField';
import TablePagination from './TablePagination';

const getCountQuestoes = (review: number, reported: number, data: any) => {
	if (reported) {
		return data.countQuestoesReportadas;
	}
	if (review) {
		return data.countQuestoes;
	}
	return data.countQuestoesRevisadas;
};

export const BancoQuestoes: React.FC = () => {
	const styles = useStyles();
	const {
		data,
		loading,
		error,
		reported,
		review,
		setReview,
		ano,
		setAno,
		palavraChave,
		setPalavraChave,
		codigo,
		especial,
		assunto,
		materia,
		setMateria,
		vestibular,
		setVestibular,
		asc,
		setAsc,
		fonte,
		setFonte,
		assertividadeOrder,
		setAssertividadeOrder,
		setInputValues,
	} = useContext(QuestionContext);

	const { selecionadas } = useContext(CheckQuestionContext);
	useEffect(() => {
		if (review) {
			setReview(false);
		}
	}, [review, setReview]);

	const itemEls = useRef({ especial: null, assunto: null, codigo: null });

	const onKeyDown = useCallback(
		(event: any) => {
			// @ts-ignore
			if (event.key === 'Enter') {
				const values = Object.keys(itemEls.current).reduce(
					(acc, key) => ({
						...acc,
						// @ts-ignore
						[key]: itemEls.current[key]?.value ?? '',
					}),
					{},
				);
				setInputValues(values);
			}
		},
		[itemEls, setInputValues],
	);

	if (loading) {
		return <LinearProgress />;
	}
	if (error)
		return (
			<div>
				Ocorreu um erro interno , logue novamente:
				<Link to="/login" className={styles.btn}>
					Login
				</Link>
			</div>
		);
	const mudarAssertividade = (novoValor: string) => {
		setAssertividadeOrder(novoValor);
	};

	return (
		<div className={styles.body}>
			<div className={styles.root}>
				<Grid
					container
					className={styles.gridContainer}
					justify="space-between"
				>
					<Grid item xs={4}>
						<SelectDropDown
							value={assertividadeOrder}
							onChange={mudarAssertividade}
							options={orderOptions}
						/>
						<SelectDropDown
							value={asc}
							onChange={setAsc}
							options={ascOptions}
						/>

						<SelectDropDown
							value={vestibular}
							onChange={setVestibular}
							options={getVestibularOptions(data)}
						/>

						<SelectDropDown
							value={materia}
							onChange={setMateria}
							options={getMateriaOptions(data)}
						/>

						<SelectDropDown
							value={palavraChave}
							onChange={setPalavraChave}
							options={getPalavrasChaveOptions(data)}
						/>

						<SelectDropDown
							value={ano}
							onChange={(value: string) => setAno(parseInt(value, 10))}
							options={anoOptions}
						/>
						<SelectDropDown
							value={fonte}
							onChange={(value: string) => setFonte(value)}
							options={getFontesOptions(data)}
						/>
					</Grid>

					<Grid item xs={6}>
						<form noValidate autoComplete="off">
							<ImputTextField
								ref={ref => {
									itemEls.current.codigo = ref;
								}}
								placeholder="Código"
								value={codigo}
								name="codigo"
								myWhidth="15ch"
								myMargin="10px"
								onKeyDown={onKeyDown}
							/>
							<ImputTextField
								ref={ref => {
									itemEls.current.assunto = ref;
								}}
								placeholder="Assunto"
								name="assunto"
								value={assunto}
								myWhidth="20ch"
								myMargin="10px"
								onKeyDown={onKeyDown}
							/>
							<ImputTextField
								ref={ref => {
									itemEls.current.especial = ref;
								}}
								placeholder="Enunciado/Alternativas"
								value={especial}
								myWhidth="25ch"
								name="especial"
								myMargin="0"
								onKeyDown={onKeyDown}
							/>
						</form>
					</Grid>
				</Grid>
				<Grid container className={styles.gridContainer}>
					<Grid item xs={10}>
						<Typography variant="h6" component="h6" color="initial">
							Foram encontradas
							<b style={{ marginLeft: 5, marginRight: 5, color: '#8190A7' }}>
								{getCountQuestoes(review, reported, data)}
							</b>
							questões
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<Typography variant="h6" component="h6" color="initial">
							Total de
							<b style={{ marginLeft: 5, marginRight: 5, color: '#8190A7' }}>
								{selecionadas}
							</b>
							questões no Desafio
						</Typography>
					</Grid>
				</Grid>
				<TablePagination
					questoes={data.questoes}
					countQuestoes={getCountQuestoes(review, reported, data)}
				/>
			</div>
		</div>
	);
};
