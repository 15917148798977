import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
	countQuestoes: number;
	fetchMore: any;
}

export const PaginationList: React.FC<Props> = ({
	fetchMore,
	countQuestoes,
}) => {
	const [pages, setPages] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [visiblePages, setVisiblePages] = useState([]);
	const qtdPages = Math.ceil(countQuestoes / 20);

	useEffect(() => {
		const newPages: any = [];
		for (let index = 0; index < qtdPages; index += 1) {
			newPages.push(index);
		}
		setPages(newPages);
		if (qtdPages > 5) {
			setVisiblePages(newPages.slice(1, 4));
		} else {
			setVisiblePages(newPages.slice(1, qtdPages - 1));
		}
	}, [qtdPages]);

	const changeVisiblePages = (page: number) => {
		if (page > 1) {
			if (page < qtdPages - 3) {
				setVisiblePages(pages.slice(page - 1, page + 2));
			} else {
				setVisiblePages(pages.slice(qtdPages - 4, qtdPages - 1));
			}
		} else {
			setVisiblePages(pages.slice(1, 4));
		}
	};

	const fetchMoreQuestoes = async (page: number) => {
		fetchMore({
			variables: {
				skip: page * 20,
			},
		});
		setCurrentPage(page);
		if (qtdPages > 5) {
			changeVisiblePages(page);
		}
	};

	const getVisibleItems = () => (
		<>
			{currentPage >= 3 && qtdPages > 5 ? (
				<PaginationItem disabled>
					<PaginationLink>...</PaginationLink>
				</PaginationItem>
			) : null}
			{visiblePages.map(page => (
				<PaginationItem key={page} active={page === currentPage}>
					<PaginationLink onClick={() => fetchMoreQuestoes(page)}>
						{page + 1}
					</PaginationLink>
				</PaginationItem>
			))}
			{currentPage < qtdPages - 3 && qtdPages > 5 ? (
				<PaginationItem disabled>
					<PaginationLink>...</PaginationLink>
				</PaginationItem>
			) : null}
		</>
	);

	if (countQuestoes <= 20) {
		return <></>;
	}

	return (
		<Pagination>
			<PaginationItem>
				<PaginationLink
					disabled={currentPage - 1 <= 0}
					onClick={() =>
						fetchMoreQuestoes(currentPage - 1 >= 0 ? currentPage - 1 : 0)
					}
				>
					‹
				</PaginationLink>
			</PaginationItem>
			<PaginationItem active={currentPage === 0}>
				<PaginationLink onClick={() => fetchMoreQuestoes(0)}>1</PaginationLink>
			</PaginationItem>
			{getVisibleItems()}
			<PaginationItem active={currentPage === qtdPages - 1}>
				<PaginationLink onClick={() => fetchMoreQuestoes(qtdPages - 1)}>
					{qtdPages}
				</PaginationLink>
			</PaginationItem>
			<PaginationItem>
				<PaginationLink
					disabled={currentPage + 1 >= qtdPages - 1}
					onClick={() => fetchMoreQuestoes(currentPage + 1)}
				>
					{' '}
					›{' '}
				</PaginationLink>
			</PaginationItem>
		</Pagination>
	);
};
