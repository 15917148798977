import { useState } from 'react';
import { UserObjectiveFilter } from '../types';

const INITIAL_FILTERS: UserObjectiveFilter = {
	level: 3, // os dois tipos de usuários (freemium e premium)
	parceiro: '',
	vestibular: '',
};

export const useObjectiveFilters = () => {
	const [filters, setFilters] = useState(INITIAL_FILTERS);
	const handleChange = (event: any) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[`${event.target.name}`]: event.target.value,
		}));
	};
	return {
		filters,
		handleChange,
	};
};
