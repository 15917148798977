import React from 'react';
import { Grid, Typography, Card, Divider } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete } from '@material-ui/icons';
import NotificationItemExample from 'screens/FeedNotification/components/notification-item-example';
import { useMutation } from '@apollo/client';
import {
	NotificationFeedData,
	NotificationFeedInput,
} from 'screens/FeedNotification/types';
import { MUTATION_REMOVE_FEED_NOTIFICATION } from 'screens/FeedNotification/api/mutations';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useStyles } from '../../styles';

const MessageNotificationById: React.FC<{
	data: NotificationFeedData | null;
	onClearStore: () => void;
}> = ({ data, onClearStore }) => {
	const classes = useStyles();
	const [removeNotification, { loading }] = useMutation<
		boolean,
		{ notificationId: string }
	>(MUTATION_REMOVE_FEED_NOTIFICATION);

	const handleDeleteNotification = async () => {
		try {
			await removeNotification({
				variables: {
					notificationId: data._id,
				},
			});
			onClearStore();
			toast.success('Notificação deletada!');
		} catch (error) {
			toast.error('Não foi possível deletar a notificação');
		}
	};

	if (!data) return null;

	return (
		<Card className={classes.cardSection}>
			<Grid container spacing={3}>
				{/* Coluna A */}
				<Grid item spacing={1} xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item spacing={1} xs={12}>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								component="p"
								color="initial"
							>
								Quem recebeu?
							</Typography>
						</Grid>
						<Grid item spacing={1} xs={12} md={6}>
							<strong>Audience:</strong> {data?.audience || 'N/A'}
						</Grid>
						<Grid item spacing={1} xs={12}>
							<strong>Deeplink: </strong>
							{data?.message?.link || 'Sem deeplink'}
						</Grid>
						<Grid item spacing={1} xs={12}>
							<strong>User IDs:</strong>{' '}
							{data?.receiver?.map((it, index) => (
								<>
									<Link to={`/buscar-usuario/${it}`} target="_blank">
										{it}
									</Link>
									{index < data?.receiver?.length - 1 ? ' - ' : ''}
								</>
							)) || 'N/A'}
						</Grid>

						<Grid item spacing={1} xs={6}>
							<strong>Ícone:</strong> {data?.message?.icon || ' - '}
						</Grid>
						<Grid item spacing={1} xs={6}>
							<strong>Tipo do ícone:</strong> {data?.message?.iconType || ' - '}
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item spacing={1} xs={6}>
							<strong>Enviado por:</strong> {data?.sentName ?? 'Usuário admin'}
						</Grid>
						<Grid item spacing={1} xs={6}>
							<strong>Local:</strong> {data?.sentFrom ?? 'N/A'}
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item spacing={1} xs={12}>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="body1"
								component="h5"
								color="initial"
							>
								Estatística de atividades:
							</Typography>
						</Grid>
						<Grid item spacing={1} xs={4}>
							Visualizado: {data?.seen?.length}
						</Grid>
						<Grid item spacing={1} xs={4}>
							Interações: {data?.interact?.length ?? 0}
						</Grid>
						<Grid item spacing={1} xs={4}>
							Removido: {data?.hide?.length}
						</Grid>
					</Grid>
					{!!data?.message?.actions?.length && (
						<Divider className={classes.divider} />
					)}
					{data?.message?.actions?.map((action, index) => (
						<Grid key={`action-${index}`} container spacing={1}>
							<Grid item spacing={1} xs={12}>
								<strong>Action {index + 1}</strong>
							</Grid>
							<Grid item spacing={1} xs={12} md={6}>
								Label: {action.label}
							</Grid>
							<Grid item spacing={1} xs={12} md={6}>
								DeepLink: {action.link}
							</Grid>
						</Grid>
					))}
					<Divider className={classes.divider} />
					<LoadingButton
						variant="contained"
						disableElevation
						color="error"
						loading={loading}
						onClick={handleDeleteNotification}
						endIcon={<Delete />}
					>
						Deletar
					</LoadingButton>
				</Grid>
				{/* Coluna B */}
				<Grid item xs={12} lg={6}>
					<NotificationItemExample
						state={data?.message as NotificationFeedInput}
						createdAt={data?.createdAt.toString()}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};

export default MessageNotificationById;
