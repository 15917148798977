import React from 'react';
import {
	tixColor,
	primaryGreen,
	primaryBlue,
	primaryOrange,
	lighterBlue,
	lightOraColor,
} from 'constants/colors';
import { SortTotal } from '../types';

export const userType = [
	{ label: 'Premium', value: 2 },
	{ label: 'Freemium', value: 1 },
];

export const sortTotalAscending = (vestibulares: SortTotal[]) =>
	vestibulares.sort((a: SortTotal, b: SortTotal) => a.total - b.total);

export const sortTotalDescending = (vestibulares: SortTotal[]) =>
	vestibulares.sort((a: SortTotal, b: SortTotal) => b.total - a.total);

export const colors = [
	tixColor,
	primaryGreen,
	primaryBlue,
	primaryOrange,
	lighterBlue,
	lightOraColor,
	'#672C47',
	'#CC00CC',
	'#1c537a',
];

export const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	total,
}) => {
	const RADIAN = Math.PI / 180;
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 35;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="white"
			textAnchor="middle"
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}% - ${total}`}
		</text>
	);
};

export const renderCustomizedLabelGraph = props => {
	const { x, y, width, height, value } = props;

	const offsetY = 7;
	const offsetX = 15;
	return (
		<text
			x={x + width - offsetX}
			y={y + height - offsetY}
			fill="#fff"
			textAnchor="end"
			fontSize={18}
		>
			{value}
		</text>
	);
};
