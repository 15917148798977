import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 80,
		marginRight: theme.spacing(1),
		marginTop: 10,
	},
	select: {
		borderWidth: 5,
		fontSize: theme.typography.pxToRem(12),
	},
}));
