import React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { IAlternativa } from 'types/alternativas';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: '#53AF50',
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

interface Props {
	enunciado: string;
	alternativas: IAlternativa[];
	open: boolean;
}

const TableCollapse: React.FC<Props> = ({ enunciado, alternativas, open }) => (
	<StyledTableRow>
		<StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Box margin={1}>
					<Typography align="center" variant="h6" gutterBottom component="div">
						Enunciado
					</Typography>
					<Typography gutterBottom component="p">
						<div dangerouslySetInnerHTML={{ __html: enunciado }} />
					</Typography>
					<Typography align="center" variant="h6" gutterBottom component="div">
						Alternativas
					</Typography>
					{alternativas.map((alternativa: any) => (
						<div
							key={alternativa.texto}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<Typography
								style={{ color: alternativa.correta ? 'green' : 'red' }}
							>
								{alternativa.letra}:{' '}
							</Typography>
							<Typography
								style={{ color: alternativa.correta ? 'green' : 'red' }}
							>
								<div dangerouslySetInnerHTML={{ __html: alternativa.texto }} />
							</Typography>
						</div>
					))}
				</Box>
			</Collapse>
		</StyledTableCell>
	</StyledTableRow>
);

export default TableCollapse;
