/* eslint-disable no-restricted-syntax */
import { BlobServiceClient } from '@azure/storage-blob';
import dotenv from 'dotenv';
import { renameFile } from '../helpers/file';

dotenv.config();

const sasToken = process.env.REACT_APP_SAS_TOKEN || ''; // Fill string with your SAS token
const containerName = process.env.REACT_APP_CONTAINER_NAME || '';
const storageAccountName = process.env.REACT_APP_ACCOUNT_NAME || ''; // Fill string with your Storage resource name

export const isStorageConfigured = () => !(!storageAccountName || !sasToken);

const getBlobsInContainer = async containerClient => {
	const returnedBlobUrls = [];

	for await (const blob of containerClient.listBlobsFlat()) {
		returnedBlobUrls.push(
			`https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`,
		);
	}

	return returnedBlobUrls;
};

const getBlobFromFile = async (containerClient, file) => {
	const blobList = await getBlobsInContainer(containerClient);
	return blobList.find(blob => blob.includes(file.name));
};

const createBlobInContainer = async (containerClient, file) => {
	const blobClient = containerClient.getBlockBlobClient(file.name);

	const options = { blobHTTPHeaders: { blobContentType: file.type } };

	await blobClient.uploadBrowserData(file, options);
};

const uploadFileToBlob = async file => {
	const blobService = new BlobServiceClient(
		`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`,
	);
	const normalizedFile = renameFile(file);

	const containerClient = blobService.getContainerClient(containerName);

	await createBlobInContainer(containerClient, normalizedFile);

	const blobUrl = await getBlobFromFile(containerClient, normalizedFile);

	return blobUrl;
};

export const uploadFileToBlobName = async file => {
	const blobService = new BlobServiceClient(
		`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`,
	);
	const normalizedFile = renameFile(file);

	const containerClient = blobService.getContainerClient(containerName);

	await createBlobInContainer(containerClient, normalizedFile);

	const blobUrl = await getBlobFromFile(containerClient, normalizedFile);

	return { url: blobUrl, name: normalizedFile.name };
};

export default uploadFileToBlob;
