import { connect } from 'react-redux';
import { object, string } from 'yup';
import { withFormik } from 'formik';

import AssuntoModal from './AssuntoModal';

const validationSchema = object().shape({
	name: string().required('Nome do assunto é obrigatório'),
	materia: object({
		label: string().required('Você precisa selecionar uma Matéria'),
	})
		.required('Você precisa selecionar uma Matéria')
		.typeError('Você precisa selecionar uma Matéria'),
});

const FormCadAssunto = withFormik({
	mapPropsToValues: props => ({
		name: '',
		_id: null,
		materia: props?.materia,
	}),
	enableReinitialize: true,
	handleSubmit: async (values, { props, resetForm }) => {
		const { postAssunto, toggle, onSave } = props;
		const assunto = await postAssunto(values);

		if (assunto) {
			toggle();

			if (onSave) {
				onSave(assunto);
			}

			// Evitar que a Form suma antes do Modal fechar
			setTimeout(() => resetForm(), 500);
		}
	},
	validationSchema,
	displayName: 'FormAssuntoModal',
})(AssuntoModal);

const mapStateToProps = ({ assuntosForm }) => ({ ...assuntosForm });
const mapDispatchToProps = ({ assuntosForm }) => ({ ...assuntosForm });

export default connect(mapStateToProps, mapDispatchToProps)(FormCadAssunto);
