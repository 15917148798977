import React, { useState, useEffect, useCallback } from 'react';
import {
	Alert,
	CardTitle,
	Input,
	Button,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import Icon from '@mdi/react';
import Select from 'react-select';

import {
	ItemPagination,
	ItemPalavraChave,
	ModalPalavraChave,
} from 'components';
import { usePagination } from 'helpers/hooks';
import { selectStyles, normalizarString } from 'helpers';
import api from 'services/api';

const TableCol = styled(Col)`
	padding-bottom: 5px;
	padding-top: 5px;
`;

const HeaderRow = styled(Row)`
	margin: 1px;
	margin-bottom: 3px;
	font-size: 17px;
	font-weight: bold;
`;

const HeaderCol = styled(TableCol)`
	font-weight: bold;
	padding-bottom: 5px;
	padding-top: 5px;
	font-size: 17px;
	text-justify: center;
`;

const ListaCadPalavrasChave = () => {
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [pcState, setPcState] = useState({
		vestibular: 'ENEM',
		vestibulares: [],
		palavrasChave: [],
		displayPalavrasChave: [],
	});
	const {
		vestibular,
		vestibulares,
		palavrasChave,
		displayPalavrasChave,
	} = pcState;

	const toggleModal = () => setOpenModal(!openModal);

	const handleSearchChange = ({ target: { value } }) => setSearch(value);
	const handleSelectChange = ({ value: vest }) =>
		setPcState(state => ({ ...state, vestibular: vest }));

	const submitSearch = () => {
		/**
		 * Atualizar Palavras-chave vísiveis de acordo com pesquisa
		 */
		setPcState(state => ({
			...state,
			displayPalavrasChave: palavrasChave.filter(pc =>
				normalizarString(pc.palavraChave).includes(normalizarString(search)),
			),
		}));
	};

	const getVestibulares = useCallback(async () => {
		try {
			setLoading(true);
			const { data: vests } = await api.get(`vestibulares`);
			setPcState(state => ({ ...state, vestibulares: vests }));
		} catch (error) {
			toast.error('Erro ao tentar atualizar informações de Palavra Chave');
		} finally {
			setLoading(false);
		}
	}, []);

	/**
	 * Atualiza dados de Vestibulares apenas ao montar o Componente
	 */
	useEffect(() => {
		getVestibulares();
	}, [getVestibulares]);

	useEffect(() => {
		/**
		 * Atualiza as palavras-chaves vísiveis sempre que o Vestibular for alterado
		 * Apenas mostra Palavras-chave do vestibular selecionado
		 */
		const filteredVests = vestibulares.filter(
			vest => vest.vestibular === vestibular,
		);
		const display = [].concat(...filteredVests.map(vest => vest.palavrasChave));

		setPcState(state => ({
			...state,
			palavrasChave: display,
			displayPalavrasChave: display,
		}));
	}, [vestibular, vestibulares]);

	const vestibularesOptions = vestibulares.map(it => ({
		label: it.vestibular,
		value: it.vestibular,
	}));

	const {
		currentPage,
		handlePageChange,
		totalPages,
		paginated,
	} = usePagination({
		data: displayPalavrasChave,
		perPage: 10,
	});

	const handleSearchKeyPress = ({ key }) => {
		if (key === 'Enter') submitSearch();
	};

	return (
		<>
			<ModalPalavraChave
				isOpen={openModal}
				toggle={toggleModal}
				onSave={getVestibulares}
			/>
			<Card className="mb-2">
				<CardHeader className="font-weight-bold">
					Palavras Chave
					<Button
						size="sm"
						color="success"
						className="float-right"
						onClick={toggleModal}
					>
						Nova Palavra Chave
						<Icon className="ml-2" path={mdiPlus} size={0.8} color="white" />
					</Button>
				</CardHeader>
				<CardTitle className="px-5 mt-3">
					<Row className="mb-3">
						<Col xs={12}>
							<p className="font-weight-bold m-0 mb-1">
								Filtrar por Vestibular
							</p>
							<Select
								classNamePrefix="select"
								name="materia"
								isSearchable
								noOptionsMessage={() => 'Nenhum Vestibular encontrado'}
								onChange={handleSelectChange}
								options={vestibularesOptions}
								value={{ value: vestibular, label: vestibular }}
								styles={selectStyles}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="9" lg="10">
							<Input
								className="mb-3"
								placeholder="Pesquisar..."
								name="search"
								value={search}
								onChange={handleSearchChange}
								onKeyPress={handleSearchKeyPress}
							/>
						</Col>
						<Col xs="12" md="3" lg="2">
							<Button block onClick={submitSearch}>
								Buscar
								<Icon
									className="ml-2"
									path={mdiMagnify}
									size={0.8}
									color="white"
								/>
							</Button>
						</Col>
					</Row>
					<Row>
						<Col xs="auto" className="mx-auto mt-3">
							{totalPages > 0 && (
								<ItemPagination
									currentPage={currentPage}
									totalPages={totalPages}
									onChange={handlePageChange}
								/>
							)}
						</Col>
					</Row>
				</CardTitle>
				<CardBody>
					{loading && (
						<ReactLoading className="mx-auto" type="bars" color="blue" />
					)}
					<Row>
						<Col xs={12}>
							{displayPalavrasChave.length === 0 && (
								<Alert color="danger">Nenhuma Palavra Chave encontrada</Alert>
							)}
						</Col>
					</Row>
					{paginated.length > 0 && (
						<>
							<HeaderRow>
								<HeaderCol xs={3} sm={3} md={3} lg={3}>
									Ano
								</HeaderCol>
								<HeaderCol xs={4} sm={4} md={4} lg={4}>
									Nome
								</HeaderCol>
								<HeaderCol xs={3} sm={3} md={4} lg={4}>
									Código
								</HeaderCol>
								<HeaderCol xs={2} sm={2} md={1} lg={1} />
							</HeaderRow>
							{paginated.map(pc => (
								<ItemPalavraChave
									key={pc.palavraChave + pc.ano + pc.codigo}
									palavraChave={pc}
									vestibular={vestibular}
								/>
							))}
						</>
					)}
				</CardBody>
			</Card>
		</>
	);
};

export default ListaCadPalavrasChave;
