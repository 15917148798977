import { gql } from '@apollo/client';

export const FETCH_PLANOS = gql`
	{
		planos {
			_id
			name
		}
	}
`;

export const FINANCIAL_REPORT_MONTH = gql`
	query getFinancialReport($filters: userDateFilter) {
		getFinancialReport(filters: $filters) {
			subscriptionRenewedGoogle {
				_id {
					year
					month
				}
				totalSubscriptionsRenewedGoogle
			}
			subscriptionCanceledGoogle {
				_id {
					year
					month
				}
				totalSubscriptionsCanceledGoogle
			}
			subscriptionRenewedApple {
				_id {
					year
					month
				}
				totalSubscriptionsRenewedApple
			}
			subscriptionCanceledApple {
				_id {
					year
					month
				}
				totalSubscriptionsCanceledApple
			}
		}
	}
`;

export const FINANCIAL_REPORT = gql`
	query getFinancialReport($filters: userDateFilter) {
		getFinancialReport(filters: $filters) {
			subscriptionsActivated {
				_id {
					year
					month
				}
				totalSubscriptionsActivated
			}
			subscriptionsExpired {
				_id {
					year
					month
				}
				totalSubscriptionsExpired
			}
			activeSubscriptions {
				totalSubscriptionsActive
			}
		}
	}
`;

export const FINANCIAL_REPORT_GRAPH = gql`
	query getFinancialReport($filters: userDateFilter) {
		getFinancialReport(filters: $filters) {
			subscriptionsExpired {
				_id {
					year
					month
				}
				totalSubscriptionsExpired
			}
			subscriptionsActivated {
				_id {
					year
					month
				}
				totalSubscriptionsActivated
			}
			activeSubscriptions {
				totalSubscriptionsActive
			}
			subscriptionRenewedGoogle {
				_id {
					year
					month
				}
				totalSubscriptionsRenewedGoogle
			}
			subscriptionRenewedApple {
				_id {
					year
					month
				}
				totalSubscriptionsRenewedApple
			}
			subscriptionCanceledGoogle {
				_id {
					year
					month
				}
				totalSubscriptionsCanceledGoogle
			}
			subscriptionCanceledApple {
				_id {
					year
					month
				}
				totalSubscriptionsCanceledApple
			}
		}
	}
`;

export const REPORT_USERS = gql`
	query getReportUsers($filters: userStatsFilter) {
		getReportUsers(filters: $filters) {
			userData {
				uid
				purchaseStatus
				externalParceiro
				user {
					displayName
					email
				}
				sku
				startedAt
				expireAt
			}
		}
	}
`;

export const LIST_USERS = gql`
	query listUsers($filters: listUsersFilters) {
		listUsers(filters: $filters) {
			_id
			displayName
			email
			phoneNumber
			createdAt
			providers {
				providerId
			}
			customData {
				deviceInfo {
					platform
				}
			}
			customClaims {
				level
				subscriptions {
					parceiro
					nome
				}
			}
		}
		usersStats(filters: $filters) {
			ativos
			premium
			plano {
				nome
				valor
			}
			parceiro {
				nome
				valor
			}
			plataforma {
				nome
				valor
			}
			login {
				nome
				valor
			}
			lastDay {
				ativos
				premium
				plano {
					nome
					valor
				}
				parceiro {
					nome
					valor
				}
				plataforma {
					nome
					valor
				}
				login {
					nome
					valor
				}
			}
		}
	}
`;

export const PRICE_REPORT_GRAPH = gql`
	query getReportPrice($filters: userDateFilter) {
		getReportPrice(filters: $filters) {
			applePriceBRL {
				_id {
					year
					month
					currency
				}
				totalPriceApple
			}
			applePriceUSD {
				_id {
					year
					month
					currency
				}
				totalPriceAppleUSD
			}
			googlePriceBRL {
				_id {
					year
					month
					currency
				}
				totalPriceGoogle
			}
			googlePriceUSD {
				_id {
					year
					month
					currency
				}
				totalPriceGoogleUSD
			}
		}
	}
`;
