import React, { useRef, useCallback } from 'react';
import {
	Box,
	Container,
	Paper,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Typography,
	Grid,
	Button,
	TextField,
	FormHelperText,
	CircularProgress,
	Snackbar,
	Select,
	MenuItem,
} from '@material-ui/core';
import logo from 'assets/logo_fb.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Cached, FileCopy } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from 'services/mutations';
import { NavHeader } from 'components';
import PasswordGenerator from 'generate-password';
import { Alert } from '@material-ui/lab';
import { registerSchema } from './registerSchema';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			background: '#222',
			minHeight: '100vh',
		},
		paper: {
			padding: theme.spacing(1),
			paddingBottom: theme.spacing(3),
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			margin: 'auto',
			maxWidth: 500,
		},
	}),
);

interface State {
	nome: string;
	email: string;
	password: string;
	role: string;
}

const CadastrarUsuario = () => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const emailRef = useRef<HTMLInputElement | null>(null);
	const navigate = useNavigate();

	const [isCopyPassword, setCopyPassowrd] = React.useState(false);
	const classes = useStyles();
	const [registerUser, { loading }] = useMutation(REGISTER_USER);

	const copyToClipboard = () => {
		if (!values.password) return;
		inputRef.current?.select();
		document.execCommand('copy');
		setCopyPassowrd(true);
	};
	const {
		values,
		setValues,
		handleChange,
		handleSubmit,
		resetForm,
		touched,
		errors,
	} = useFormik<State>({
		initialValues: {
			nome: '',
			email: '',
			password: '',
			role: '',
		},
		validationSchema: registerSchema,
		onSubmit: async credentials => {
			try {
				const {
					data: { user },
				} = await registerUser({
					variables: {
						username: credentials.email,
						email: credentials.email,
						nome: credentials.nome,
						role: credentials.role,
						password: credentials.password,
					},
				});

				toast.success(`Usuário ${user.nome} Cadastrado com sucesso`);

				copyToClipboard();
				resetForm();
			} catch (error: any) {
				// console.log(JSON.stringify(error, null, 2));
				if (error?.networkError?.result?.errors) {
					const isDuplicateUser = error?.networkError?.result?.errors.find(
						(it: any) => it.message.includes('duplicate key error collection'),
					);
					const message = isDuplicateUser
						? 'Usuário já existe.'
						: 'Houve um erro inesperado, tente novamente!';
					toast.error(message);
					if (isDuplicateUser) {
						emailRef.current?.focus();
						emailRef.current?.select();
					}
				} else {
					toast.error('Houve um erro inesperado, tente novamente!');
				}
			}
		},
	});

	const generatePassword = useCallback(() => {
		const newPassword = PasswordGenerator.generate({
			length: 10,
			numbers: true,
		});
		setValues({ ...values, password: newPassword });
	}, [setValues, values]);

	const handleCloseSnackCopyPassword = useCallback(() => {
		setCopyPassowrd(false);
	}, []);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	return (
		<>
			<NavHeader />
			<Container>
				<Box pt={8}>
					<Paper
						component="form"
						className={classes.paper}
						onSubmit={event => {
							event.preventDefault();
							if (!loading) handleSubmit();
						}}
					>
						<Box textAlign="center">
							<img src={logo} alt="logo" />
						</Box>
						<Box textAlign="center" my={3}>
							<Typography
								component="h5"
								variant="h5"
								className="font-weight-bold"
							>
								Cadastrar Usuário
							</Typography>
						</Box>
						<Grid container alignItems="center" justify="center" spacing={3}>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="role">Tipo do usuário</InputLabel>
									<Select
										labelId="role"
										id="role"
										value={values.role}
										onChange={handleChange('role')}
										labelWidth={115}
										error={touched?.role && !!errors?.role}
									>
										<MenuItem value="autor">Autor</MenuItem>
										<MenuItem value="revisor">Revisor</MenuItem>
										<MenuItem value="admin">Administrador</MenuItem>
										<MenuItem value="collaborator">Colaborador</MenuItem>
										<MenuItem value="partner">Parceiro</MenuItem>
									</Select>
									<FormHelperText error={touched.role && Boolean(errors.role)}>
										{touched.role && errors.role}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<TextField
										id="nome"
										type="text"
										label="Nome"
										variant="outlined"
										placeholder="Digite o nome"
										value={values.nome}
										onChange={handleChange('nome')}
										error={touched?.nome && !!errors?.nome}
										helperText={touched?.nome && errors?.nome}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<TextField
										inputRef={emailRef}
										id="email"
										type="text"
										label="E-mail"
										variant="outlined"
										placeholder="Digite o e-mail"
										value={values.email}
										onChange={handleChange('email')}
										error={touched?.email && !!errors?.email}
										helperText={touched?.email && errors?.email}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="password">Senha</InputLabel>
									<OutlinedInput
										inputRef={inputRef}
										id="password"
										type="text"
										readOnly
										placeholder="Gere uma senha"
										value={values.password}
										onChange={handleChange('password')}
										error={touched?.password && !!errors?.password}
										labelWidth={50}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="click para gerar password"
													onClick={generatePassword}
													onMouseDown={handleMouseDownPassword}
													color="primary"
												>
													<Cached />
												</IconButton>
												<Box px={0.5} />
												<IconButton
													aria-label="click para copiar password"
													onClick={copyToClipboard}
													onMouseDown={handleMouseDownPassword}
													color="secondary"
												>
													<FileCopy />
												</IconButton>
											</InputAdornment>
										}
									/>
									<FormHelperText
										error={touched.password && Boolean(errors.password)}
									>
										{touched.password && errors.password}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									size="large"
									color="primary"
									className="py-3"
								>
									{!loading ? (
										'Cadastrar'
									) : (
										<CircularProgress disableShrink color="inherit" size={25} />
									)}
								</Button>
							</Grid>
							<Grid item xs={12} md={11} lg={9}>
								<Button
									fullWidth
									size="large"
									color="primary"
									className="py-3"
									onClick={() => navigate(-1)}
								>
									Voltar
								</Button>
							</Grid>
						</Grid>
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							open={isCopyPassword}
							autoHideDuration={3000}
							onClose={handleCloseSnackCopyPassword}
						>
							<Alert onClose={handleCloseSnackCopyPassword} severity="success">
								Senha copiada!
							</Alert>
						</Snackbar>
					</Paper>
				</Box>
			</Container>
		</>
	);
};

export default CadastrarUsuario;
