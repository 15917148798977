import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { PrivateRouteProps } from './types';
import { Dispatch, RootState } from '../../store/store';

import LoadingPrivateRouter from './LoadingRoute';
import { useValidCadUserToken } from '../../store/models/auth/shared/user-valid-caduser-token';
import { CHANGE_PASSWORD } from '../../constants';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
	component: Component,
	roles,
}) => {
	const {
		user,
		fetchRefreshToken,
		isValidToken,
		invalidToken,
		isUnauthorized,
	} = useValidCadUserToken(roles);
	const isFirstUse = useSelector(
		(state: RootState) => state.firstUse.isFirstUse,
	);
	const dispatch = useDispatch<Dispatch>();
	useEffect(() => {
		// Quando o token está invalido e há algum dado de usuário
		if (invalidToken && user.token) dispatch.auth.logout();
	}, [dispatch.auth, invalidToken, user]);

	if (isFirstUse) return <Navigate to={CHANGE_PASSWORD} />;

	// mater na tela o login
	if (fetchRefreshToken) return <LoadingPrivateRouter />;

	if (invalidToken) {
		return (
			<Navigate
				to="/login"
				state={{ invalidToken: invalidToken && !!user.token }}
			/>
		);
	}

	if (isValidToken && isUnauthorized) {
		toast.error('Usuário não tem permissão para esse recurso.');
		return <Navigate to="/unauthorized" />;
	}

	return <Component />;
};

export default PrivateRoute;
