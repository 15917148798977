import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CADASTRAR_DESAFIO_ROUTE } from 'constants/routes';
import { LISTAR_DESAFIOS } from 'components/TabelaDesafios/queries';
import { IDesafio } from 'types/desafio';
import { isEmptyObject } from 'helpers';
import { FETCH_PLANOS } from 'screens/DashboardFinanceiro/api';
import { IPlano } from 'types/plano';
import { IDesafioCredentials } from '../types';
import { desafioSchema } from '../validation';
import {
	CREATE_DESAFIO,
	FETCH_DESAFIO,
	PUBLISH_DESAFIO,
	UNPUBLISH_DESAFIO,
	PUBLISH_MANY_DESAFIO,
	UNPUBLISH_MANY_DESAFIO,
	UPDATE_DESAFIO,
	UPDATE_MANY_DESAFIOS,
	CHECK_DESAFIO_RESPONSE,
} from '../api';

const INITIAL_CREDENTIALS: IDesafioCredentials = {
	nome: '',
	materia: '',
	tempoMaximo: 10,
	totalQuestoes: 1,
	pontuacao: 1000,
	vestibular: '',
	dataFinal: '',
	owner: '',
	tag: '',
	permissionLevel: [2],
	plansSection: [],
	exclusivePlans: [],
};

export const useDesafioForm = (desafio: IDesafio) => {
	const user = useSelector((state: RootState) => state.auth.user);
	const desafioPontuacao = 1000;
	const initialValues = isEmptyObject(desafio)
		? INITIAL_CREDENTIALS
		: {
				nome: desafio.nome,
				materia: desafio.materia,
				tempoMaximo: desafio.tempoMaximo,
				totalQuestoes: desafio.totalQuestoes,
				pontuacao: desafioPontuacao,
				vestibular: desafio.vestibular,
				dataFinal: moment
					.unix(parseInt(desafio.dataFinal, 10) / 1000)
					.format('YYYY-MM-DD'),
				permissionLevel: desafio?.permissionLevel || [2],
				plansSection: desafio?.plansSection || [],
				exclusivePlans: desafio?.exclusivePlans || [],
				owner: desafio.owner,
				tag: desafio?.tag,
		  };
	const navigate = useNavigate();
	const [cadastrarDesafio] = useMutation(CREATE_DESAFIO);
	const [atualizarDesafio] = useMutation(UPDATE_DESAFIO);
	const formik = useFormik({
		initialValues,
		validationSchema: desafioSchema,
		onSubmit: async desafioFields => {
			try {
				const { data } = isEmptyObject(desafio)
					? await cadastrarDesafio({
							variables: {
								desafio: {
									...desafioFields,
									dataFinal: moment(desafioFields.dataFinal).toISOString(),
									owner: user.nome,
									pubDate: Date.now().toString(),
								},
							},
							refetchQueries: [{ query: LISTAR_DESAFIOS }],
					  })
					: await atualizarDesafio({
							variables: {
								_id: desafio._id,
								desafio: {
									...desafioFields,
									dataFinal: moment(desafioFields.dataFinal).toISOString(),
									pubDate: Date.now().toString(),
									totalQuestoes: desafio.questoes.length,
								},
							},
							refetchQueries: [{ query: LISTAR_DESAFIOS }],
					  });

				toast.success(`Rascunho ${data.desafio.nome} salvo com sucesso.`);
				navigate(`${CADASTRAR_DESAFIO_ROUTE}/${data.desafio._id}`);
			} catch (err) {
				toast.error(
					`Verifique se todos os campos foram preenchidos corretamente. Tente novamente.`,
				);
			}
		},
	});

	return {
		formik,
	};
};

export const useLazyDesafio = (desafioId: string) => {
	const [fetchPlanos, { data: dataPlanos }] = useLazyQuery<{
		planos?: null | IPlano[];
	}>(FETCH_PLANOS);

	const [fetchCountDesafios, { data: dataCountdesafio }] = useLazyQuery(
		CHECK_DESAFIO_RESPONSE,
		{
			variables: { _id: desafioId },
		},
	);

	const [
		fetchDesafio,
		{ called, data, loading, error, refetch },
	] = useLazyQuery(FETCH_DESAFIO, {
		variables: {
			_id: desafioId,
		},
		fetchPolicy: 'no-cache',
	});

	const [publishDesafio] = useMutation(PUBLISH_DESAFIO, {
		onCompleted: refetch,
	});
	const [unpublishDesafio] = useMutation(UNPUBLISH_DESAFIO, {
		onCompleted: refetch,
	});

	const handlePublish = useCallback(
		(disponivel: boolean) => {
			disponivel
				? unpublishDesafio({
						variables: { _id: desafioId },
						refetchQueries: [{ query: LISTAR_DESAFIOS }],
				  })
				: publishDesafio({
						variables: { _id: desafioId },
						refetchQueries: [{ query: LISTAR_DESAFIOS }],
				  });
		},
		[desafioId, publishDesafio, unpublishDesafio],
	);

	useEffect(() => {
		if (desafioId) {
			fetchDesafio();
			fetchPlanos();
			fetchCountDesafios();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [desafioId, fetchDesafio]);

	return {
		data,
		loading,
		called,
		error,
		handlePublish,
		refetch,
		planos: dataPlanos?.planos,
		dataCountdesafio,
	};
};

export const useDialog = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return {
		open,
		handleOpen,
		handleClose,
	};
};

export const useCustomDialog = (
	defaultState?: boolean,
): [boolean, () => void, () => void] => {
	const [open, setOpen] = useState(defaultState ?? false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return [open, handleOpen, handleClose];
};

export const desafiosMap = (desafios, disponivel, indisponivel) =>
	desafios.map(desafio =>
		desafio.status === 'success'
			? disponivel.push(desafio.id)
			: indisponivel.push(desafio.id),
	);

export const useDesafioFormUpdate = (
	desafio: IDesafio,
	desafioArrayId: Record<string, unknown>[],
) => {
	const [updateDesafio, setUpdateDesafio] = useState({} as any);
	const desafioPontuacao = 1000;
	const initialValues = {
		nome: desafio.nome,
		tempoMaximo: desafio.tempoMaximo,
		pontuacao: desafioPontuacao,
		dataFinal: moment
			.unix(parseInt(desafio.dataFinal, 10) / 1000)
			.format('YYYY-MM-DD'),
		permissionLevel: desafio?.permissionLevel || [2],
		tag: desafio?.tag,
		publicar: '',
		plansSection: [],
		exclusivePlans: [],
	};
	const [updateMany] = useMutation(UPDATE_MANY_DESAFIOS);
	const [publishManyDesafio] = useMutation(PUBLISH_MANY_DESAFIO);
	const [unpublishManyDesafio] = useMutation(UNPUBLISH_MANY_DESAFIO);

	const formik = useFormik({
		initialValues,
		onSubmit: async fieldsDesafio => {
			const desafioFields = fieldsDesafio;
			const desafioArrayIdFormatted = desafioArrayId.map(
				desafioReturn => desafioReturn.id,
			);
			let disponivel = [];
			let indisponivel = [];
			try {
				const publishResponse =
					desafioFields.publicar === 'publish'
						? await publishManyDesafio({
								variables: { _id: desafioArrayIdFormatted },
								refetchQueries: [{ query: LISTAR_DESAFIOS }],
						  })
						: await unpublishManyDesafio({
								variables: { _id: desafioArrayIdFormatted },
								refetchQueries: [{ query: LISTAR_DESAFIOS }],
						  });
				desafioFields?.publicar === 'publish'
					? desafiosMap(
							publishResponse.data?.publishManyDesafio,
							disponivel,
							indisponivel,
					  )
					: desafiosMap(
							publishResponse.data?.unpublishManyDesafio,
							disponivel,
							indisponivel,
					  );
				delete desafioFields.publicar;
				const updateDesafioQuery = await updateMany({
					variables: {
						_id: desafioArrayIdFormatted,
						desafio: {
							...desafioFields,
							dataFinal: moment(desafioFields.dataFinal).toISOString(),
						},
					},
					refetchQueries: [{ query: LISTAR_DESAFIOS }],
				});
				setUpdateDesafio(updateDesafioQuery);
				toast.success(`Desafios atualizados com sucesso.`);
			} catch (err) {
				toast.error(
					`Verifique se todos os campos foram preenchidos corretamente. Tente novamente.`,
				);
			}
		},
	});

	return {
		formik,
		updateDesafio,
	};
};
