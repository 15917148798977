export interface IDesafioCredentials {
	nome: string;
	materia: string;
	tempoMaximo: number;
	totalQuestoes: number;
	pontuacao: number;
	vestibular: string;
	dataFinal: string;
	owner: string;
	tag: string;
	permissionLevel: number[];
	plansSection: string[];
	exclusivePlans: string[];
}

export enum DesafioTags {
	NOVOS = 'Novos',
	DESTAQUES = 'Destaques',
}
