import React, { useMemo, useState, useCallback } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';

import { AdminCadUsersContextProps, ICadUser } from '../types';
import { GET_ALL_CAD_USERS } from '../api/query';

const DEFAULT_VARIABLES = {
	page: 0,
	skip: 0,
	limit: 10,
	role: 'partner',
};
export const AdminCadUsersContext = React.createContext<AdminCadUsersContextProps>(
	{
		...DEFAULT_VARIABLES,
		refetch: () => {},
		totalUsers: 0,
	},
);

export const AdminCadUsersProviderPartner: React.FC = ({ children }) => {
	const [variables, setVariables] = useState(DEFAULT_VARIABLES);

	const { loading, data, error, refetch, networkStatus } = useQuery<{
		cadUsers: ICadUser[];
		countCadUsers: number;
	}>(GET_ALL_CAD_USERS, { variables });

	const onSearch = (event: React.FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		const input = (event.target as HTMLFormElement).elements?.namedItem(
			'search',
		) as HTMLInputElement;
		const { value } = input;
		const valuesToSearch = {
			nome: value,
			email: value,
			username: value,
			role: 'partner',
		};

		refetch({
			...valuesToSearch,
			skip: DEFAULT_VARIABLES.skip,
			limit: DEFAULT_VARIABLES.limit,
			role: 'partner',
		});
		setVariables(DEFAULT_VARIABLES);
	};

	const isLoading = useMemo(
		() => loading || networkStatus === NetworkStatus.refetch,
		[loading, networkStatus],
	);

	const onChangePage = useCallback(
		(page: number) => {
			const skip = page * variables.limit;
			refetch({ skip, limit: variables.limit });
			setVariables(vals => ({ ...vals, skip, page }));
		},
		[refetch, variables.limit],
	);

	const onChangeLimit = useCallback(
		(limit: number) => {
			const newVariables = {
				...variables,
				skip: DEFAULT_VARIABLES.skip,
				limit,
				role: 'partner',
			};
			refetch(newVariables);
			setVariables(newVariables);
		},
		[refetch, variables],
	);

	return (
		<AdminCadUsersContext.Provider
			value={{
				isLoading,
				users: data?.cadUsers,
				totalUsers: data?.countCadUsers || 0,
				error,
				refetch,
				onChangePage,
				onChangeLimit,
				onSearch,
				...variables,
			}}
		>
			{children}
		</AdminCadUsersContext.Provider>
	);
};
