import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../store/store';

interface Props {
	userRole: string;
	route: string;
	to: string;
}

const UserRouteWrapper: React.FC<Props> = ({ userRole, route, to }) => {
	const user = useSelector((state: RootState) => state.auth.user);

	if (user && user.role && user.role === userRole) {
		return <Navigate replace to={route} />;
	}

	return <Navigate replace to={to} />;
};

export default UserRouteWrapper;
