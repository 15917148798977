import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { selectStyles } from 'helpers';

class FormMateria extends PureComponent {
	render() {
		const { materias, materia, propsValidation, onChange } = this.props;

		return (
			<>
				<p className="font-weight-bold m-0 mb-1">Matéria</p>
				<Select
					className={propsValidation('materia')}
					classNamePrefix="select"
					name="materia"
					isClearable
					isSearchable
					noOptionsMessage={() => 'Sem dados'}
					onChange={onChange}
					options={materias}
					value={materia}
					styles={selectStyles}
				/>
			</>
		);
	}
}

FormMateria.propTypes = {
	materia: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	materias: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	),
	onChange: PropTypes.func.isRequired,
	propsValidation: PropTypes.func.isRequired,
};

export default React.memo(FormMateria);
