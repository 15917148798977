import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { gql } from 'apollo-boost';
import { RootModel } from '..';
import { IVestibular, IFonte, IMateria, IArea } from './types';

import api from '../../../services/api';

export const INITIAL_STATE = {
	loading: false,
	// Opções
	vestibulares: [],
	fontes: [],
	materias: [],
	areas: [],
	palavrasChave: [],
	assuntos: [],
	// Valores
	vestibular: null,
	fonte: null,
	areaConhecimento: null,
	codigo: '',
	palavraChave: null,
	numeroQuestao: '',
	competencia: null,
	habilidade: null,
	acumularQuestao: true,
	materia: null,
	// Valores para limpar
	enunciado: '',
	resolucao: '',
	video: '',
};

export const cadQuestoes = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		changeFormProps: (state, props: Record<string, unknown>) => ({
			...state,
			...props,
		}),
		clearFields: () => ({ ...INITIAL_STATE }),
		startLoading: state => ({ ...state, loading: true }),
		stopLoading: state => ({ ...state, loading: false }),
	},
	effects: dispatch => ({
		async fetchAllValues() {
			try {
				const query = gql`
					{
						vestibulares {
							_id
							nome
						}
						fontes {
							_id
							nome
						}
						materias {
							_id
							nome
						}
						areas {
							_id
							nome
						}
					}
				`;
				const { data } = await api.query({ query });

				dispatch.cadQuestoes.changeFormProps({
					vestibulares: data.vestibulares.map((vestibular: IVestibular) => ({
						label: vestibular.nome,
						value: vestibular._id,
					})),
					fontes: data.fontes.map((fonte: IFonte) => ({
						label: fonte.nome,
						value: fonte._id,
					})),
					materias: data.materias.map((materia: IMateria) => ({
						label: materia.nome,
						value: materia._id,
					})),
					areas: data.areas.map((area: IArea) => ({
						label: area.nome,
						value: area._id,
						area,
					})),
					loading: false,
				});
			} catch (error: any) {
				dispatch.cadQuestoes.stopLoading();
			}
		},
		async submitQuestao(questao) {
			try {
				dispatch.cadQuestoes.changeFormProps({ loading: true });
				const mutation = gql`
					mutation createQuestao($questao: QuestaoInput!) {
						createQuestao(questao: $questao) {
							_id
						}
					}
				`;

				const variables = {
					questao,
				};

				await api.mutate({ mutation, variables });

				toast.success('Questão salva com sucesso!');
			} catch (error: any) {
				if (error.graphQLErrors && error.graphQLErrors.length > 0) {
					toast.error(error.graphQLErrors[0].message);
				} else {
					toast.error('Houve um Erro ao tentar salvar Questão');
				}
			} finally {
				dispatch.cadQuestoes.changeFormProps({ loading: false });
			}
		},
		setNextValues(values) {
			/**
			 * Atualizar valores padrões para o próximo cadastro de questão
			 * Também aumenta +1 no número da próxima questão para continuidade
			 */
			const numeroQuestao = parseInt(values.numeroQuestao, 10) + 1;
			dispatch.cadQuestoes.changeFormProps({
				...values,
				numeroQuestao,
				enunciado: '',
				resolucao: '',
				video: '',
			});
		},
	}),
});
