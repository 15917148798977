import React from 'react';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { REVISAR_SIMULADOS } from 'constants/routes';

export const RevisarSimulado = ({ simuladoId, classes }) => (
	<Link to={REVISAR_SIMULADOS.replace(':simuladoId', simuladoId)}>
		<Button
			variant="contained"
			color="primary"
			className={classes.button}
			endIcon={<EditIcon />}
		>
			Revisar
		</Button>
	</Link>
);

export default RevisarSimulado;
