import React, { useContext } from 'react';
import { Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { createObjectFromArray } from 'helpers';
import { ALL_VESTIBULARES } from 'helpers/vestibular';
import { ObjectiveContext } from '../../provider/index';
import { userType } from '../../helpers';

const parceiros = [
	{ label: 'FB Online', value: 'FB Online' },
	{ label: 'ORA', value: 'ORA' },
	{ label: 'FB VEST', value: 'FB Vest' },
	{ label: 'MOB', value: 'MOB' },
	{ label: 'Farias Brito', value: 'Farias Brito' },
];

const vestibulares = createObjectFromArray(ALL_VESTIBULARES, [
	'label',
	'value',
]);

const Filters: React.FC = () => {
	const { filters, handleChange, loadingObjective } = useContext(
		ObjectiveContext,
	);

	if (loadingObjective) {
		return <LinearProgress />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg={2}>
				<TextField
					fullWidth
					select
					name="level"
					variant="outlined"
					label="Escolha a Permissão"
					value={filters.level}
					onChange={handleChange}
					size="small"
					style={{ width: 200 }}
				>
					<MenuItem value={3}>Escolha a Permissão</MenuItem>
					{userType.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="parceiro"
					variant="outlined"
					label="Parceiros"
					value={filters.parceiro}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Parceiros" value="">
						Parceiros
					</MenuItem>
					{parceiros.map(parceiro => (
						<MenuItem key={parceiro.value} value={parceiro.value}>
							{parceiro.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3} lg={2}>
				<TextField
					fullWidth
					select
					name="vestibular"
					variant="outlined"
					label="Vestibulares"
					value={filters.vestibular}
					onChange={handleChange}
					size="small"
				>
					<MenuItem key="Parceiros" value="">
						Vestibulares
					</MenuItem>
					{vestibulares.map(vestibular => (
						<MenuItem key={vestibular.value} value={vestibular.value}>
							{vestibular.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
		</Grid>
	);
};

export default Filters;
