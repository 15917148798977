import React, { PureComponent, Fragment } from 'react';
import { ListGroup, Button, CardHeader, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import { MateriasModal, ListarMateriasItem } from 'components';

class ListarMaterias extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			isOpen: false,
			materia: [],
			isEdit: false,
			editMateria: null,
			update: '',
		};

		this.toggle = this.toggle.bind(this);
		this.handleOnEdit = this.handleOnEdit.bind(this);
	}

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal,
			isOpen: !prevState.isOpen,
			isEdit: false,
		}));
	}

	handleOnEdit(materia) {
		this.setState({ materia, isOpen: true, isEdit: true });
	}

	render() {
		const { materias, onChangeState } = this.props;
		const { isOpen, materia, isEdit } = this.state;

		return (
			<>
				<Card md="14">
					<MateriasModal
						toggle={this.toggle}
						isOpen={isOpen}
						isEdit={isEdit}
						materia={materia}
						materias={materias}
						onChangeState={onChangeState}
					/>
					<CardHeader className="font-weight-bold">
						Materias
						<Button
							size="sm"
							color="success"
							className="float-right"
							onClick={this.toggle}
						>
							Nova Materia
							<Icon className="ml-2" path={mdiPlus} size={0.8} color="white" />
						</Button>
					</CardHeader>
					<CardBody>
						<ListGroup>
							{materias &&
								materias.map(item => (
									<ListarMateriasItem
										key={item._id}
										item={item}
										handleOnEdit={this.handleOnEdit}
									/>
								))}
						</ListGroup>
					</CardBody>
				</Card>
			</>
		);
	}
}

ListarMaterias.propTypes = {
	onChangeState: PropTypes.func.isRequired,
	materias: PropTypes.arrayOf(
		PropTypes.shape({
			materiaId: PropTypes.number,
			materia: PropTypes.arrayOf(PropTypes.shape({})),
		}),
	),
};

export default ListarMaterias;
