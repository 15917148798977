export const LISTAR_QUESTOES = '/listar';
export const QUESTOES_REPORTADAS = '/reportadas';
export const DASHBOARD_PARCEIROS = '/parceiros';
export const DASHBOARD_FINANCEIRO = '/financeiro';
export const CADASTRAR_QUESTOES = '/cadastrar';
export const EDITAR_QUESTAO = '/editar/:id';
export const LISTAR_SIMULADOS = '/simulados';
export const REVISAR_SIMULADOS = `${LISTAR_SIMULADOS}/:simuladoId`;
export const CADASTRAR_SIMULADO = '/cadastrar-simulado';
export const LOGIN = '/login';
export const UNAUTHORIZED = '/unauthorized';
export const CADASTRAR_USUARIO = '/register';
export const CHANGE_PASSWORD = '/change-password';
export const LISTAR_DESAFIOS_ROUTE = '/desafios';
export const BUSCAR_USUARIO_APP = '/buscar-usuario';
export const BUSCAR_VALIDATION_CODE_APP = '/validation-code';
export const FEED_NOTIFICATION_LIST = '/notifications';
export const FEED_NOTIFICATION = '/notification';
export const FEED_BY_ID_NOTIFICATION = '/notification/:notificationId';
export const OBJETIVOS_ALUNOS = '/objetivos-alunos';
export const CADASTRAR_DESAFIO_ROUTE = '/cadastrar-desafio';
export const CADASTRAR_DESAFIO = `${CADASTRAR_DESAFIO_ROUTE}/:desafioId?`;

export const CADASTRAR_DESAFIO_QUESTAO = `${CADASTRAR_DESAFIO_ROUTE}/:desafioId/questao`;
