import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
	options: Ioption[];
	value: string | boolean | number;
	onChange: any;
	name?: string;
}

interface Ioption {
	label: string;
	value: any;
}

const SelectDropDown: React.FC<Props> = ({
	options,
	value,
	onChange,
	name,
}) => {
	const styles = useStyles();

	return (
		<FormControl variant="outlined" className={styles.formControl}>
			<Select
				className={styles.select}
				value={value}
				name={name}
				onChange={event => {
					onChange(event.target.value);
				}}
			>
				{options.map((option: Ioption) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
export default SelectDropDown;
