import React from 'react';
import { NavHeader } from 'components';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	CircularProgress,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useListNotificationFeed } from 'screens/FeedNotification/provider/notifications.provider';
import { useStyles } from '../../styles';
import NotificationsTable from './notifications-table';

const ListFeedNotifications: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { loading, error, refetch } = useListNotificationFeed();
	return (
		<>
			<NavHeader />
			<Container>
				<Grid container className={classes.headerTitle}>
					<Button
						className={classes.backButton}
						onClick={() => {
							navigate(-1);
						}}
						startIcon={<ArrowBack />}
					>
						Voltar
					</Button>
				</Grid>
				<Box my={3}>
					<Typography
						style={{ fontWeight: 'bold' }}
						variant="h4"
						component="h4"
						color="initial"
					>
						Lista de notificaçãos
					</Typography>
				</Box>
				{loading && (
					<>
						<Typography component="p" color="initial">
							Buscando notificações
						</Typography>
						<CircularProgress size={50} />
					</>
				)}
				{error && (
					<>
						<Typography variant="h6" component="h6">
							Ops, houve um problema ao buscar notificações
						</Typography>
						<Button onClick={() => refetch()} variant="contained">
							Tentar novamente
						</Button>
					</>
				)}
				<Box my={4}>
					<NotificationsTable />
				</Box>
			</Container>
		</>
	);
};

export default ListFeedNotifications;
