import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { selectStyles } from 'helpers';

const StyledOption = styled(components.Option)`
	:hover {
		background-color: #deebff !important;
	}
	background-color: white !important;
`;

// eslint-disable-next-line react/prop-types
const Option = ({ children, innerProps, ...props }) => {
	/**
	 * Fix pra performance ruim no React Select com muitos itens
	 * Ainda acontence de vez em quando (28/05/2019 16:00)
	 * https://github.com/JedWatson/react-select/issues/3128#issuecomment-451936743
	 */
	// eslint-disable-next-line no-unused-vars
	const { ...rest } = innerProps;
	const newProps = Object.assign(props, { innerProps: rest });
	return <StyledOption {...newProps}>{children}</StyledOption>;
};

class FormAssuntos extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			portalTarget: null,
		};
	}

	componentDidMount() {
		this.setState({
			portalTarget: document.getElementById('react-select'),
		});
	}

	render() {
		const { assuntosOptions, assuntos, propsValidation, onChange } = this.props;

		return (
			<>
				<p className="font-weight-bold m-0 mb-1">Assuntos</p>
				<Select
					isMulti
					components={{ Option }}
					className={propsValidation('assuntos')}
					classNamePrefix="select"
					name="assuntos"
					isClearable
					isSearchable
					noOptionsMessage={() => 'Sem dados'}
					onChange={onChange}
					options={assuntosOptions}
					value={assuntos}
					styles={selectStyles}
				/>
			</>
		);
	}
}

FormAssuntos.propTypes = {
	propsValidation: PropTypes.func.isRequired,
	assuntosOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	).isRequired,
	onChange: PropTypes.func.isRequired,
	assuntos: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	),
};

export default React.memo(FormAssuntos);
