import React, { PureComponent, Fragment } from 'react';
import { ListGroup, Button, CardHeader, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import { ListarVestibularItem } from 'components';

class ListarVestibulares extends PureComponent {
	render() {
		const { vestibulares } = this.props;
		return (
			<>
				<Card md="14">
					<CardHeader className="font-weight-bold" color="danger">
						Vestibulares
						<Button
							size="sm"
							color="success"
							className="float-right"
							onClick={this.toggle}
						>
							Novo Vestibular
							<Icon className="ml-2" path={mdiPlus} size={0.8} color="white" />
						</Button>
					</CardHeader>
					<CardBody>
						<ListGroup>
							{vestibulares.map(item => (
								<ListarVestibularItem
									key={item._id}
									item={item.vestibular}
									handleOnEdit={this.handleOnEdit}
								/>
							))}
						</ListGroup>
					</CardBody>
				</Card>
			</>
		);
	}
}

ListarVestibulares.propTypes = {
	vestibulares: PropTypes.string,
};

export default ListarVestibulares;
