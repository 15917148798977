import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(5),
	},
	titleContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	backButton: {
		marginRight: theme.spacing(5),
	},
	backButtonGrid: {
		position: 'absolute',
	},
	container: {
		backgroundColor: '#F2F2F2',
		height: '100%',
		minHeight: '100vh',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		paddingBottom: theme.spacing(5),
	},
	form: {
		padding: theme.spacing(3),
	},
	btnContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
}));
