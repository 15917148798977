import { gql } from '@apollo/client';

export const FETCH_DESAFIO = gql`
	query fetchDesafio($_id: String!) {
		desafio: desafio(_id: $_id) {
			_id
			nome
			materia
			tempoMaximo
			totalQuestoes
			pontuacao
			vestibular
			dataFinal
			disponivel
			owner
			permissionLevel
			plansSection
			exclusivePlans
			tag
			questoes {
				_id
				vestibular
				ano
				fonte
				materia
				numeroQuestao
				palavraChave
				enunciado
				assertividade {
					erros
					acertos
					assertividade
				}
				areaConhecimento {
					nome
				}
				alternativas {
					letra
					texto
					correta
				}
				createdAt
			}
		}
	}
`;

export const FETCH_CAMPANHA_BY_DESAFIO_ID = gql`
	query campanhaByModelId($modelId: String!) {
		historico: campanhaByModelId(modelId: $modelId) {
			_id
			type
			model
			modelId
			subject
			url
			audience
			totalSent
			delivered
			totalErrors
			displayName
			email
			createdAt
			updatedAt
		}
	}
`;

export const CHECK_DESAFIO_RESPONSE = gql`
	query isDesafioUpdateLocked($_id: String!) {
		isDesafioUpdateLocked(_id: $_id)
	}
`;

export const QUESTAO_BY_ID = gql`
	query questao($_id: ID!) {
		questao(_id: $_id) {
			_id
		}
	}
`;
