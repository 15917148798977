import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Row, Col, Input } from 'reactstrap';

import api from 'services/api';
import { selectStyles } from 'helpers';

class FormPalavraChave extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			vestibularesMap: [],
		};

		this.handleOnChangeSelect = this.handleOnChangeSelect.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnAcumaladorClick = this.handleOnAcumaladorClick.bind(this);
		this.updateOpcoes = this.updateOpcoes.bind(this);
	}

	componentDidMount() {
		this.updateOpcoes();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.vestibular && props.vestibular !== state.vestibular) {
			const palavras = state[props.vestibular];
			if (!palavras) {
				props.onChangeState({ palavrasChave: [], palavraChave: null });
				return {
					vestibular: props.vestibular,
					ano: props.ano,
					palavras: [],
				};
			}
			const palavrasChave = palavras.reduce(
				(il, it, index) =>
					!il.filter(it2 => it2.label === it.palavraChave).length
						? [...il, { label: it.palavraChave, value: index.toString() }]
						: il,
				[],
			);

			props.onChangeState({ palavrasChave, palavraChave: palavrasChave[0] });
			return {
				vestibular: props.vestibular,
				ano: props.ano,
				palavras,
			};
		}
		return null;
	}

	updateOpcoes() {
		api.get('vestibulares').then(response => {
			const vestibularesMap = response.data.reduce(
				(il, it) => ({ ...il, [it.vestibular]: it.palavrasChave }),
				{},
			);
			this.setState({ ...vestibularesMap });
		});
	}

	handleOnChangeSelect(palavraChave) {
		const { onChangeState } = this.props;
		onChangeState({ palavraChave });
	}

	handleOnChange(event) {
		const { onChangeState } = this.props;
		const {
			currentTarget: { name, value },
		} = event;
		if (name === 'numeroQuestao')
			onChangeState({ [name]: value.replace(/\D/g, '') });
		else onChangeState({ [name]: value });
	}

	handleOnAcumaladorClick() {
		const { acumularQuestao, onChangeState } = this.props;
		onChangeState({ acumularQuestao: !acumularQuestao });
	}

	render() {
		const {
			numeroQuestao,
			palavrasChave,
			palavraChave,
			codigo,
			acumularQuestao,
			propsValidation,
		} = this.props;

		return (
			<Row className="mt-4 justify-content-center align-items-center">
				<Col xs={12} sm={6} md={4} lg={5}>
					<p className="font-weight-bold m-0 mb-1">Palavras Chave</p>
					<Select
						className={propsValidation('palavraChave')}
						classNamePrefix="select"
						name="palavraChave"
						isClearable
						isSearchable
						noOptionsMessage={() => 'Sem dados'}
						onChange={this.handleOnChangeSelect}
						options={palavrasChave}
						value={palavraChave}
						styles={selectStyles}
					/>
				</Col>
				<Col xs={12} sm={6} md={2} lg={2}>
					<p className="font-weight-bold m-0 mb-1">Código</p>
					<Input
						className={propsValidation('codigo', false)}
						name="codigo"
						value={codigo}
						onChange={this.handleOnChange}
					/>
				</Col>
				<Col xs={12} sm={12} md={6} lg={5}>
					<Row className="align-items-center">
						<Col xs={7} lg={9}>
							<p className="font-weight-bold m-0 mb-1">Número da Questão</p>
						</Col>
						<Col xs={5} lg={3} className="text-right">
							<Input
								name="acumularQuestao"
								type="checkbox"
								checked={acumularQuestao}
								onChange={this.handleOnAcumaladorClick}
							/>
							<span>Acumular</span>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col xs={12}>
							<Input
								name="numeroQuestao"
								className={propsValidation('numeroQuestao', false)}
								value={numeroQuestao}
								onChange={this.handleOnChange}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}

FormPalavraChave.propTypes = {
	codigo: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
	numeroQuestao: PropTypes.oneOfType([PropTypes.any, PropTypes.number]),
	onChangeState: PropTypes.func.isRequired,
	palavrasChave: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	).isRequired,
	palavraChave: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	propsValidation: PropTypes.func.isRequired,
	acumularQuestao: PropTypes.bool.isRequired,
};

export default React.memo(FormPalavraChave);
