import React from 'react';
import {
	Button,
	Card,
	TextField,
	Grid,
	FormHelperText,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import { SelectMateria, SelectVestibular } from 'components';
import { IDesafio } from 'types/desafio';
import UserWrapper from 'screens/wrappers/UserWrapper';
import UserRoles, { AppLevels } from 'types/user';

import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import moment from 'moment';
import { IPlano } from 'types/plano';
import { useStyles } from './styles';
import {
	DATA_PLACEHOLDER,
	PONTUACAO_PLACEHOLDER,
	TEMPO_PLACEHOLDER,
} from '../../constants';
import { useDesafioForm } from '../../hooks';
import { options } from './material-utils';
import SelectTag from '../SelectTag';
import PlansSection from '../PlansSection';
import ExclusivePlans from '../ExclusivePlans';

interface Props {
	desafio: IDesafio;
	onClickCampanha?: () => void;
	checkDesafioResponse: boolean;
	planos?: null | IPlano[];
}

const DesafioForm: React.FC<Props> = ({
	desafio,
	onClickCampanha,
	checkDesafioResponse,
	planos,
}) => {
	const classes = useStyles();
	const { formik } = useDesafioForm(desafio);

	return (
		<Card className={classes.card}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={3} className={classes.container}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<TextField
							fullWidth
							required
							name="nome"
							label="Nome"
							InputLabelProps={{ shrink: true }}
							variant="outlined"
							value={formik.values.nome}
							onChange={formik.handleChange}
							error={formik.touched.nome && Boolean(formik.errors.nome)}
							helperText={formik.touched.nome && formik.errors.nome}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<SelectVestibular
							name="vestibular"
							value={formik.values.vestibular}
							onChange={formik.handleChange}
							error={
								formik.touched.vestibular && Boolean(formik.errors.vestibular)
							}
							helperText={formik.touched.vestibular && formik.errors.vestibular}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<SelectMateria
							name="materia"
							value={formik.values.materia}
							onChange={formik.handleChange}
							error={formik.touched.materia && Boolean(formik.errors.materia)}
							helperText={formik.touched.materia && formik.errors.materia}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<TextField
							fullWidth
							name="dataFinal"
							InputProps={{
								inputProps: { min: moment(new Date()).format('YYYY-MM-DD') },
							}}
							required
							InputLabelProps={{ shrink: true }}
							type="date"
							label={DATA_PLACEHOLDER}
							variant="outlined"
							value={formik.values.dataFinal}
							onChange={formik.handleChange}
							error={
								formik.touched.dataFinal && Boolean(formik.errors.dataFinal)
							}
							helperText={formik.touched.dataFinal && formik.errors.dataFinal}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<TextField
							fullWidth
							required
							name="tempoMaximo"
							InputLabelProps={{ shrink: true }}
							inputProps={{
								step: 1,
								min: 1,
								max: 120,
								'data-testid': TEMPO_PLACEHOLDER,
							}}
							type="number"
							label={TEMPO_PLACEHOLDER}
							variant="outlined"
							value={formik.values.tempoMaximo}
							onChange={formik.handleChange}
							error={
								formik.touched.tempoMaximo && Boolean(formik.errors.tempoMaximo)
							}
							helperText={
								formik.touched.tempoMaximo && formik.errors.tempoMaximo
							}
							disabled={checkDesafioResponse}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6} md={6} lg={3}>
						<TextField
							fullWidth
							InputLabelProps={{ shrink: true }}
							inputProps={{
								min: 1,
								'data-testid': QUANTIDADE_PLACEHOLDER,
							}}
							required
							name="totalQuestoes"
							type="number"
							label="Quantidade de Questões"
							variant="outlined"
							value={formik.values.totalQuestoes}
							onChange={formik.handleChange}
							error={
								formik.touched.totalQuestoes &&
								Boolean(formik.errors.totalQuestoes)
							}
							helperText={
								formik.touched.totalQuestoes && formik.errors.totalQuestoes
							}
						/>
					</Grid> */}

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<TextField
							fullWidth
							name="pontuacao"
							type="number"
							required
							InputLabelProps={{ shrink: true }}
							inputProps={{
								step: 1,
								min: 1000,
								'data-testid': PONTUACAO_PLACEHOLDER,
							}}
							label="Pontuação"
							variant="outlined"
							value={1000}
							onChange={formik.handleChange}
							error={
								formik.touched.pontuacao && Boolean(formik.errors.pontuacao)
							}
							helperText={formik.touched.pontuacao && formik.errors.pontuacao}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<SelectTag
							name="tag"
							value={formik.values.tag}
							onChange={formik.handleChange}
							error={formik.touched.tag && Boolean(formik.errors.tag)}
							helperText={formik.touched.tag && formik.errors.tag}
						/>
					</Grid>
				</Grid>

				<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
					<Grid
						container
						spacing={3}
						className={classes.container}
						alignItems="center"
					>
						<Grid item xs={12} md="auto">
							<InputLabel id="permissionLevel-select">
								Disponível para usuários:
							</InputLabel>
							<Select
								name="permissionLevel"
								labelId="permissionLevel-select"
								multiple
								error={
									formik.touched.permissionLevel &&
									Boolean(formik.errors.permissionLevel)
								}
								onChange={formik.handleChange}
								value={formik.values.permissionLevel}
								renderValue={selected => {
									const values = (selected as number[])
										.map((it: number) => AppLevels[it])
										.join(', ');
									if (!values) return 'Selecione uma opção';
									return values;
								}}
								variant="outlined"
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value="" disabled>
									<em>Selecione as permissões</em>
								</MenuItem>
								{options.map(option => (
									<MenuItem key={option.level} value={option.level}>
										<ListItemIcon>
											<Checkbox
												checked={formik.values.permissionLevel.includes(
													option.level,
												)}
											/>
										</ListItemIcon>
										<ListItemText primary={option.description} />
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.permissionLevel &&
									formik.errors.permissionLevel}
							</FormHelperText>
						</Grid>
						<ExclusivePlans planos={planos} formik={formik} />
						<PlansSection planos={planos} formik={formik} />
						<Grid item xs={12} md={5}>
							<Button
								data-testid="salvar-button"
								variant="contained"
								className={classes.button}
								size="large"
								startIcon={<EmailIcon />}
								onClick={onClickCampanha}
							>
								Campanhas de E-mail
							</Button>
						</Grid>
					</Grid>
				</UserWrapper>
				<Button
					data-testid="salvar-button"
					variant="contained"
					className={classes.button}
					size="large"
					startIcon={<SaveIcon />}
					type="submit"
				>
					SALVAR RASCUNHO
				</Button>
			</form>
		</Card>
	);
};

export default DesafioForm;
