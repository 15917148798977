import { gql } from '@apollo/client';

export const OBJECTIVE_REPORT = gql`
	query getReportObjectives($filters: ObjectiveFilter) {
		getReportObjectives(filters: $filters) {
			materias {
				_id
				total
			}
			vestibulares {
				_id
				total
			}
			vestibularFilter {
				_id
				total
			}
		}
	}
`;
