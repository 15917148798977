import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { NavHeader } from 'components';
import { useStyles } from './styles';
import { Filters, ObjectiveChart } from './components';
import ObjectiveProvider from './provider';
import { UserObjectiveFilter } from './types';

const ObjetivosAlunos: React.FC = () => {
	const classes = useStyles();
	const [, setFilters] = useState<UserObjectiveFilter>();

	return (
		<Container className={classes.container} maxWidth="xl" disableGutters>
			<NavHeader headerStyle={{ paddingRight: '4.7%', paddingLeft: '4.2%' }} />
			<Container className={classes.body}>
				<ObjectiveProvider setFilters={setFilters}>
					<>
						<h3>Pesquisa </h3>
						<hr />
						<Filters />
						<hr />
						<h3>Resultados </h3>
						<hr />
						<ObjectiveChart />
					</>
				</ObjectiveProvider>
			</Container>
		</Container>
	);
};

export default ObjetivosAlunos;
