import { object, string, number, array } from 'yup';
import {
	DATA_ERROR,
	MATERIA_ERROR,
	NOME_ERROR,
	PONTUACAO_ERROR,
	QUANTIDADE_ERROR,
	TEMPO_ERROR,
	VESTIBULAR_ERROR,
	PREMISSAO_LEVEL,
} from './constants';

export const desafioSchema = object({
	nome: string().required(NOME_ERROR),

	materia: string().required(MATERIA_ERROR),

	tempoMaximo: number().required(TEMPO_ERROR),

	totalQuestoes: number().required(QUANTIDADE_ERROR),

	pontuacao: number().required(PONTUACAO_ERROR),

	vestibular: string().required(VESTIBULAR_ERROR),

	dataFinal: string().required(DATA_ERROR),

	permissionLevel: array().min(1, PREMISSAO_LEVEL).required(PREMISSAO_LEVEL),
});
