import React from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import { useObjectiveFilters } from '../hooks';
import { OBJECTIVE_REPORT } from '../api';
import { UserObjectiveFilter } from '../types';

interface Context {
	filters: any;
	handleChange: any;
	objective: any;
	loadingObjective: boolean;
	objectiveError: ApolloError | undefined;
}

export const ObjectiveContext = React.createContext<Context>({
	filters: {},
	handleChange: () => {},
	objective: {},
	loadingObjective: false,
	objectiveError: undefined,
});

interface Props {
	children: React.ReactElement;
	filtersOut?: UserObjectiveFilter;
	setFilters?: (filters: UserObjectiveFilter) => void;
	update?: boolean;
}
let auxFilters: UserObjectiveFilter = {
	level: 3,
	parceiro: '',
	vestibular: '',
};

const ObjectiveProvider: React.FC<Props> = ({
	children,
	filtersOut,
	setFilters,
	update = false,
}) => {
	const { filters, handleChange } = useObjectiveFilters();

	if (filtersOut && filtersOut !== auxFilters && update) {
		auxFilters = filtersOut;
	}
	if (!update) {
		auxFilters = filters;
		if (setFilters) setFilters(filters || ({} as UserObjectiveFilter));
	}

	const variables = {
		filters: { ...auxFilters },
	};

	const { data, loading, error } = useQuery(OBJECTIVE_REPORT, {
		variables,
		fetchPolicy: 'no-cache',
	});
	return (
		<ObjectiveContext.Provider
			value={{
				filters,
				handleChange,
				objective: data,
				loadingObjective: loading,
				objectiveError: error,
			}}
		>
			{children}
		</ObjectiveContext.Provider>
	);
};

export default ObjectiveProvider;
