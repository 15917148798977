import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import Spinner from 'reactstrap/lib/Spinner';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { NavHeader } from 'components';
import { useStyles } from './styles';
import { COUNT_QUESTOES_HEADER } from '../../queries';
import QuestionContext from '../../providers/QuestionProvider';
import { getCountQuestoes, tabListData } from './tabListData';
import { getQueryVariables } from '../../../../helpers/queries';

const materialUiStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

export const Header = () => {
	const [tabList, setTabList] = useState(tabListData);
	const { setReported, setAno, setReview, fonte } = useContext(QuestionContext);
	const { data, loading } = useQuery(COUNT_QUESTOES_HEADER, {
		variables: getQueryVariables({ fonte }),
	});
	const styles = useStyles({ selected: false });
	const classes = materialUiStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
		setValue(newValue);
	};

	const selectTabItem = (id: number) => {
		const selectedTabList = tabList.map(tabItem => {
			const newTabItem = { ...tabItem };
			if (tabItem.id === id) {
				newTabItem.selected = true;
				return newTabItem;
			}
			newTabItem.selected = false;
			return newTabItem;
		});
		setTabList(selectedTabList);
	};

	const setQuestoes = (id: number) => {
		switch (id) {
			case 2:
				setReported(false);
				setReview(false);
				break;
			case 1:
				setReported(false);
				setReview(true);
				break;
			default:
				setAno(0);
				setReported(false);
				setReview(true);
				break;
		}
	};

	if (loading)
		return (
			<div>
				<Spinner size="lg" color="primary" />
			</div>
		);

	return (
		<>
			<NavHeader />
			<div className={styles.header}>
				<div className={styles.headerTabs}>
					<Paper className={classes.root}>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="primary"
							textColor="primary"
							centered
						>
							{tabList.map(tabItem => (
								<Tab
									key={tabItem.id}
									label={`${tabItem.title} (${getCountQuestoes(
										data,
										tabItem,
									)})`}
									onClick={() => {
										selectTabItem(tabItem.id);
										setQuestoes(tabItem.id);
									}}
									selected={tabItem.selected}
								/>
							))}
						</Tabs>
					</Paper>
				</div>
			</div>
		</>
	);
};
