export const AppLevels: { [key: number]: string } = {
	1: 'Freemium',
	2: 'Premium',
};

enum UserRoles {
	ADMINISTRADOR = 'admin',
	COLABORADOR = 'collaborator',
	REVISOR = 'revisor',
	AUTOR = 'autor',
}

export default UserRoles;
