import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import { darken } from 'polished';

export const ButtonColor = withStyles(() => ({
	root: ({
		active,
		bgcolor,
		color = '#ffffff',
	}: {
		color: string;
		active: boolean;
		bgcolor?: string;
	}) => {
		const backgroundColor = active ? red[500] : green[500];
		return {
			color,
			backgroundColor: bgcolor || backgroundColor,
			'&:hover': {
				backgroundColor: darken(0.1, bgcolor || backgroundColor),
			},
		};
	},
}))(Button);
