import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	cardParceiro: {
		borderRadius: 10,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	avatar: {
		width: theme.spacing(8),
		height: theme.spacing(8),
	},
	summary: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		fontSize: 12,
		fontWeight: 500,
	},
	number: {
		fontSize: 24,
		fontWeight: 'bold',
	},
}));
