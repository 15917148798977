import { number, object, string } from 'yup';
import { ISimuladoCredentials } from '../types';

export const parseCredentials = (formCredentials: ISimuladoCredentials) => ({
	nome: {
		label: 'nome',
		value: formCredentials.nome,
	},
	vestibular: {
		label: 'vestibular',
		value: formCredentials.vestibular,
	},
	ano: {
		label: 'ano',
		value: formCredentials.ano,
	},
	fonte: {
		label: 'fonte',
		value: formCredentials.fonte,
	},
});

export const simuladoSchema = object({
	nome: object({
		label: string(),
		value: string().required('Por favor, preencha o nome do simulado.'),
	}),
	vestibular: object({
		label: string(),
		value: string().required('Por favor, escolha o vestibular do simulado.'),
	}),
	ano: object({
		label: string(),
		value: number().positive('Por favor, escolha o ano do simulado.'),
	}),
	fonte: object({
		label: string(),
		value: string().required('Por favor, escolha uma fonte para o simulado.'),
	}),
});
