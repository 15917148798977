import React, { useState } from 'react';
import {
	Alert,
	CardFooter,
	Input,
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
} from 'reactstrap';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import Select from 'react-select';
import ReactLoading from 'react-loading';

import {
	FormAssuntoModal,
	FiltroAssuntos,
	ListaCadAssuntosItem,
} from 'components';

const GET_ASSUNTOS_MATERIAS = gql`
	query getAssuntosMaterias(
		$materia: String
		$nome: String
		$limit: Int
		$skip: Int
	) {
		assuntos(materia: $materia, nome: $nome, limit: $limit, skip: $skip) {
			_id
			nome
			materia
		}
		materias {
			_id
			nome
		}
	}
`;

const ListaCadAssuntos = () => {
	const [assuntoModal, setAssuntoModal] = useState(false);
	const [editAssunto, setEditAssunto] = useState({});
	const [materia, setMateria] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const [assuntoNome, setAssuntoNome] = useState('');

	const [perPage, setPerPage] = useState(20);

	const perPageOptions = [
		{ label: '20', value: 20 },
		{ label: '40', value: 40 },
		{ label: '60', value: 60 },
	];

	const variables = { skip: 0, limit: perPage, materia, nome: assuntoNome };

	const { loading, data, fetchMore } = useQuery(GET_ASSUNTOS_MATERIAS, {
		variables,
	});

	const updatePerPage = value =>
		/**
		 * Atualizar quantidade de Assuntos adicionados ao clicar
		 * no Botão 'Mais Assuntos'
		 */
		setPerPage(value.value);

	const toggleAssuntoModal = assunto => {
		if (assunto) {
			// Atualizar assunto a ser atualizado na próxima vez
			// que o Modal para editar assunto for aberto
			setEditAssunto({
				value: assunto._id,
				label: assunto.nome,
				materia: { value: assunto.materia, label: assunto.materia },
			});
		}
		setAssuntoModal(!assuntoModal);
	};

	const materiasOptions = data
		? data.materias.map(it => ({ label: it.nome, value: it._id }))
		: [];

	const handleSearchChange = event => setSearchInput(event.target.value);

	const submitSearch = () => {
		/**
		 * Atualizar pesquisa apenas quando Usuário
		 * submeter a pesquisa
		 */
		setAssuntoNome(searchInput);
	};

	const carregarMaisAssuntos = () => {
		/**
		 * Carregar mais Assuntos na Lista
		 */

		const updateQuery = (previousData, { fetchMoreResult }) => {
			/**
			 * Atualizar os dados de `data.assuntos` do resultado da Query com
			 * os novos Assuntos carregados, mas também manter as antigas
			 *
			 * https://www.apollographql.com/docs/react/data/pagination/#offset-based
			 */
			if (!fetchMoreResult) return previousData;

			return {
				...previousData,
				assuntos: [...previousData.assuntos, ...fetchMoreResult.assuntos],
			};
		};

		fetchMore({
			variables: { ...variables, skip: data.assuntos.length },
			updateQuery,
		});
	};

	const handleSearchKeyPress = ({ key }) => {
		/**
		 * Realizar a pesquisa com a tecla Enter
		 */
		if (key === 'Enter') submitSearch();
	};

	const handleFilterChange = item => {
		/**
		 * Atualizar o Filtro por Matéria, caso seja diferente
		 * da matéria anterior, e voltar para a Primeira Página
		 */
		if (item.label !== materia) {
			setMateria(item.label);
			// setFirstPage();
		}
	};

	const hasAssuntos = data ? data.assuntos.length > 0 : false;

	return (
		<>
			<FormAssuntoModal
				isOpen={assuntoModal}
				toggle={toggleAssuntoModal}
				assunto={editAssunto}
			/>
			<Card className="mb-2">
				<CardHeader className="font-weight-bold">
					Assuntos
					<Button
						size="sm"
						color="success"
						className="float-right"
						onClick={toggleAssuntoModal}
					>
						Novo Assunto
						<Icon className="ml-2" path={mdiPlus} size={0.8} color="white" />
					</Button>
				</CardHeader>
				<CardTitle className="px-5 mt-3">
					<Row>
						<Col xs="12">
							<FiltroAssuntos
								onChange={handleFilterChange}
								materia={{ value: materia, label: materia }}
								materias={materiasOptions}
							/>
						</Col>
					</Row>
					<Row xs="10">
						<p className="font-weight-bold ml-3 m-0 mb-1 mt-2">
							Pesquisar Assunto
						</p>
					</Row>
					<Row>
						<Col xs="12" md="9" lg="10">
							<Input
								className="mb-3"
								placeholder="Pesquisar..."
								name="search"
								value={searchInput}
								onChange={handleSearchChange}
								onKeyPress={handleSearchKeyPress}
							/>
						</Col>
						<Col xs="12" md="3" lg="2">
							<Button block onClick={submitSearch}>
								Buscar
								<Icon
									className="ml-2"
									path={mdiMagnify}
									size={0.8}
									color="white"
								/>
							</Button>
						</Col>
					</Row>
				</CardTitle>
				<CardBody>
					<Card>
						<CardHeader className="font-weight-bold">{materia}</CardHeader>
						<CardBody>
							{loading && (
								<ReactLoading className="mx-auto" type="bars" color="blue" />
							)}
							{hasAssuntos &&
								data.assuntos.map(assunto => (
									<ListaCadAssuntosItem
										key={assunto._id}
										assunto={assunto}
										onEditClick={() => toggleAssuntoModal(assunto)}
									/>
								))}
							<Row>
								<Col xs={12}>
									{hasAssuntos || (
										<Alert color="danger">Nenhum assunto encontrado</Alert>
									)}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</CardBody>
				{hasAssuntos && (
					<CardFooter>
						<Row>
							<Col lg="10" sm="6">
								<Button
									block
									size="lg"
									color="primary"
									onClick={carregarMaisAssuntos}
								>
									Mais Assuntos ({perPage})
									<Icon
										className="ml-2"
										path={mdiPlus}
										size={1.2}
										color="white"
									/>
								</Button>
							</Col>
							<Col lg="2" sm="6">
								<Select
									className="mt-1"
									menuPlacement="auto"
									options={perPageOptions}
									onChange={updatePerPage}
									value={{ label: `Carregar mais: ${perPage}`, value: perPage }}
								/>
							</Col>
						</Row>
					</CardFooter>
				)}
			</Card>
		</>
	);
};

export default ListaCadAssuntos;
