import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store/store';
import {
	Container,
	Button,
	Card,
	TextField,
	Typography,
	Grid,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { NavHeader } from 'components';
import logo from '../../assets/logo_fb.png';
import { alterarSenhaSchema, firstUseSchema } from './schema';
import { UPDATE_PASSWORD, LOGIN_USER } from '../../services/mutations';
import { LISTAR_QUESTOES, LOGIN } from '../../constants';
import { useStyles } from './styles';

const AlterarSenha: React.FC = () => {
	const classes = useStyles();
	const isFirstUse = useSelector(
		(state: RootState) => state.firstUse.isFirstUse,
	);
	const user = useSelector((state: RootState) => state.auth.user);
	const dispatch = useDispatch<Dispatch>();
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const navigate = useNavigate();
	const [testLogin] = useMutation(LOGIN_USER);
	const [updatePassword] = useMutation(UPDATE_PASSWORD);

	const yupParse = () => ({
		oldPassword: {
			label: 'oldPassword',
			value: oldPassword,
		},
		newPassword: {
			label: 'newPassword',
			value: newPassword,
		},
		confirmPassword: {
			label: 'confirmPassword',
			value: confirmPassword,
		},
	});

	const checkPassword = async (email: string, _id: number) => {
		const {
			data: { loginCadUser },
		} = await testLogin({
			variables: {
				email,
				password: oldPassword,
			},
		});
		if (_id === loginCadUser._id) {
			return true;
		}
		return false;
	};

	const changePassword = async () => {
		try {
			const yupCredentials = yupParse();
			isFirstUse
				? await firstUseSchema.validate({
						newPassword: yupCredentials.newPassword,
						confirmPassword: yupCredentials.confirmPassword,
				  })
				: await alterarSenhaSchema.validate(yupCredentials);

			if (newPassword !== confirmPassword) {
				throw new Error('Verifique se confirmou a nova senha corretamente!');
			}
			const { email, _id } = JSON.parse(
				localStorage.getItem(
					process.env.REACT_APP_USER_LOGIN_STORAGE_KEY as string,
				) as string,
			);

			if (!isFirstUse) {
				const isValid = await checkPassword(email, _id);

				if (!isValid) {
					throw new Error('Verifique se digitou a senha antiga corretamente!');
				}
			}

			updatePassword({
				variables: {
					_id,
					caduser: {
						password: newPassword,
					},
				},
			})
				.then(async () => {
					await dispatch.firstUse.setIsFirstUse({ isFirstUse: false });
					toast.success('Senha atualizada com sucesso!');
					navigate('/');
				})
				.catch(error => toast.error(error.message));
		} catch (error: any) {
			if (error.errors) {
				toast.error(error.errors[0]);
			} else {
				toast.error(error.message);
			}
		}
	};

	if (Object.keys(user).length === 0) {
		return <Navigate to={LOGIN} />;
	}

	return (
		<>
			<NavHeader />
			<Container className={classes.container}>
				<Card className={classes.card}>
					<Grid container justify="center">
						<img src={logo} alt="fb_logo" />
					</Grid>

					<Typography className={classes.title} variant="h5" gutterBottom>
						Alterar Senha
					</Typography>
					{isFirstUse ? null : (
						<TextField
							className={classes.oldPassword}
							id="outlined-required"
							required
							type="password"
							name="oldPassword"
							label="Senha Antiga"
							variant="outlined"
							onChange={({ target: { value } }) => setOldPassword(value)}
							onKeyDown={({ key }) => {
								if (key === 'Enter') {
									changePassword();
								}
							}}
						/>
					)}
					<TextField
						className={classes.newPassword}
						id="outlined-required"
						required
						type="password"
						name="newPassword"
						label="Nova Senha"
						variant="outlined"
						onChange={({ target: { value } }) => setNewPassword(value)}
						onKeyDown={({ key }) => {
							if (key === 'Enter') {
								changePassword();
							}
						}}
					/>
					<TextField
						className={classes.confirmPassword}
						id="outlined-required"
						required
						type="password"
						name="confirmPassword"
						label="Confirmar Nova Senha"
						variant="outlined"
						onChange={({ target: { value } }) => setConfirmPassword(value)}
						onKeyDown={({ key }) => {
							if (key === 'Enter') {
								changePassword();
							}
						}}
					/>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={changePassword}
					>
						Alterar
					</Button>

					{isFirstUse ? null : (
						<Button
							className={classes.button}
							variant="outlined"
							color="primary"
							onClick={() => navigate(LISTAR_QUESTOES)}
						>
							Voltar
						</Button>
					)}
				</Card>
			</Container>
		</>
	);
};

export default AlterarSenha;
