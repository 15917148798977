import { createAnoOptions } from 'helpers/options';

export const anoOptions = [
	{ label: 'ANO', value: 0 },
	...createAnoOptions(2009),
];

export const ascOptions = [
	{ label: 'ORDENAR', value: 0 },
	{ label: 'CRESCENTE', value: true },
	{ label: 'DECRESCENTE', value: false },
];
export const orderOptions = [
	{ label: 'Nº QUESTÃO', value: false },
	{ label: 'ASSERTIVIDADE', value: true },
];

export const getPalavrasChaveOptions = ({ palavrasChave }) => [
	{ label: 'PALAVRA CHAVE', value: 0 },
	...palavrasChave.map(palavraChave => ({
		label: palavraChave.nome,
		value: palavraChave.nome,
	})),
];

export const getMateriaOptions = ({ materias }) => [
	{ label: 'MATERIAS', value: 0 },
	...materias.map(materia => ({
		label: materia.nome,
		value: materia.nome,
	})),
];

export const getVestibularOptions = ({ vestibulares }) => [
	{ label: 'VESTIBULARES', value: 0 },
	...vestibulares.map(vestibular => ({
		label: vestibular.nome,
		value: vestibular.nome,
	})),
];

export const getFontesOptions = ({ fontes }) => [
	{ label: 'FONTES', value: 0 },
	...fontes.map(fonte => ({
		label: fonte.nome,
		value: fonte.nome,
	})),
];
