import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => navigate(-2), 3000);
	});
	return <h1>Não autorizado.Você será redirecionado em instantes...</h1>;
};

export default Unauthorized;
