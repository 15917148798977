import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { LinearProgress, TextField, MenuItem } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { UsersContext } from 'screens/DashboardFinanceiro/provider';
import { useQuery } from '@apollo/client';
import { REPORT_USERS } from '../../api';
import { useStyles } from './styles';
import { getRows } from '../../helpers';

const usuariosAtivos = [
	{ label: 'Ativo', value: 'Ativo' },
	{ label: 'Inativo', value: 'Inativo' },
];

const columns = [
	{ field: 'id', headerName: 'ID', width: 60, filterable: false },
	{ field: 'nome', headerName: 'Nome', width: 200, filterable: true },
	{ field: 'email', headerName: 'E-mail', width: 300, filterable: true },
	{ field: 'plano', headerName: 'Plano', width: 140, filterable: true },
	{
		field: 'parceiro',
		headerName: 'Plataforma',
		width: 105,
		filterable: true,
	},
];

const columnNumberSubscription = [
	{
		field: 'numberSubscriptions',
		headerName: 'Nº Subscriptions',
		width: 150,
		filterable: true,
	},
];

const columnsSubscription = [
	...columns,
	{
		field: 'date',
		headerName: 'Data 1ª subscription',
		width: 165,
		filterable: true,
	},
	{
		field: 'dateExpired',
		headerName: 'Data expiração',
		width: 130,
		filterable: true,
	},
	...columnNumberSubscription,
];

const FinancialTable: React.FC = () => {
	const classes = useStyles();
	const { usersError, loadingUsers } = useContext(UsersContext);
	const [filters, setFilters] = useState({ active: 'Ativo' });

	const handleChange = (event: any) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[`${event.target.name}`]: event.target.value,
		}));
	};

	let auxFilters2: { active: boolean } = {
		active: true,
	};

	filters.active === 'Inativo'
		? (auxFilters2 = { active: false })
		: (auxFilters2 = { active: true });

	const { data: dataUser } = useQuery(REPORT_USERS, {
		variables: { filters: { ...auxFilters2 } },
		fetchPolicy: 'no-cache',
	});

	if (loadingUsers) return <LinearProgress />;
	if (usersError) return <Navigate to="/" />;

	return (
		<div className={classes.tableContainer}>
			<hr />
			<h3>Lista de Usuários</h3>
			<TextField
				fullWidth
				select
				name="active"
				variant="outlined"
				label="Usuários ativos"
				value={filters.active}
				onChange={handleChange}
				size="small"
			>
				<MenuItem key="Usuários Ativos" value="">
					Usuários Ativos
				</MenuItem>
				{usuariosAtivos.map(usuario => (
					<MenuItem key={usuario.value} value={usuario.value}>
						{usuario.label}
					</MenuItem>
				))}
			</TextField>
			<hr />
			{dataUser ? (
				<DataGrid
					className={classes.datagrid}
					rows={getRows(dataUser)}
					pageSize={20}
					columns={columnsSubscription}
					loading={loadingUsers}
					checkboxSelection={false}
				/>
			) : null}
		</div>
	);
};

export default FinancialTable;
