import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	EditorState,
	convertToRaw,
	ContentState,
	Modifier,
	SelectionState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import uploadFileToBlob from 'services/azure';

class HTMLEditorText extends Component {
	constructor(props) {
		super(props);
		const { htmlRaw } = props;
		const contentBlock = htmlToDraft(htmlRaw);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks,
			);
			const editorState = EditorState.createWithContent(contentState);
			this.state = {
				editorState,
			};
		}
		this.onEditorStateChange = this.onEditorStateChange.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { htmlRaw } = this.props;

		if (nextProps.isAlternativas && nextProps.htmlRaw !== htmlRaw) {
			const htmlValue = draftToHtml(
				convertToRaw(nextState.editorState.getCurrentContent()),
			);
			if (nextProps.htmlRaw !== htmlValue) {
				{
					const contentBlock = htmlToDraft(nextProps.htmlRaw);
					const contentState = ContentState.createFromBlockArray(
						contentBlock.contentBlocks,
					);
					const editorState = EditorState.createWithContent(contentState);
					this.setState({ editorState });
				}
				return false;
			}
		}

		if (nextProps.htmlRaw !== htmlRaw && nextProps.htmlRaw === '') {
			/**
			 * Limpar editor caso o novo conteúdo esteja vazio
			 * https://github.com/facebook/draft-js/issues/1630#issue-291845662
			 *
			 * Nota: considerar função 'clearEditorContent' da library draftjs-utils
			 * ```js
			 * import { clearEditorContent } from 'draftjs-utils';
			 *
			 * const { editorState } = this.state;
			 * this.setState({ editorState: clearEditorContent(editorState) });
			 * ```
			 * https://github.com/jpuri/draftjs-utils
			 */
			let { editorState } = this.state;
			let contentState = editorState.getCurrentContent();
			const firstBlock = contentState.getFirstBlock();
			const lastBlock = contentState.getLastBlock();
			const allSelected = new SelectionState({
				anchorKey: firstBlock.getKey(),
				anchorOffset: 0,
				focusKey: lastBlock.getKey(),
				focusOffset: lastBlock.getLength(),
				hasFocus: true,
			});
			contentState = Modifier.removeRange(
				contentState,
				allSelected,
				'backward',
			);
			editorState = EditorState.push(editorState, contentState, 'remove-range');
			editorState = EditorState.forceSelection(
				editorState,
				contentState.getSelectionAfter(),
			);

			this.setState({ editorState });
		}

		return true;
	}

	onEditorStateChange(editorState) {
		const { onChangeState, name } = this.props;
		this.setState({ editorState });
		const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		onChangeState({ [name]: value });
	}

	render() {
		const { editorState } = this.state;
		const { editorStyle, height, name, propsValidation } = this.props;
		return (
			<Editor
				editorState={editorState}
				onEditorStateChange={this.onEditorStateChange}
				wrapperClassName={propsValidation(name, true, 'wrapper-class')}
				editorStyle={{
					minHeight: height,
					...editorStyle,
				}}
				toolbarStyle={{
					...editorStyle,
					backgroundColor: 'rgb(252, 252, 252)',
				}}
				toolbar={{
					image: {
						urlEnabled: true,
						uploadEnabled: true,
						alignmentEnabled: true,
						uploadCallback: async data => {
							const blobUrl = await uploadFileToBlob(data);

							return {
								data: { link: blobUrl },
							};
						},
						previewImage: true,
						inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
						alt: { present: false, mandatory: false },
						defaultSize: {
							height: 'auto',
							width: 'auto',
						},
					},
				}}
			/>
		);
	}
}

HTMLEditorText.defaultProps = {
	editorStyle: {
		backgroundColor: 'white',
		paddingRight: 5,
		paddingLeft: 5,
		border: 1,
		borderStyle: 'solid',
		borderColor: '#F1F1F1',
	},
	height: 270,
	isAlternativas: false,
	propsValidation: () => 'wrapper-class',
};

HTMLEditorText.propTypes = {
	editorStyle: PropTypes.instanceOf(Object),
	height: PropTypes.number,
	isAlternativas: PropTypes.bool,
	propsValidation: PropTypes.func,
	onChangeState: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	htmlRaw: PropTypes.string,
};

export default React.memo(HTMLEditorText);
