import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import { RootModel, models } from './models';

const persistConfig = {
	key: 'root',
	storage,
	version: 2,
	whitelist: ['auth', 'firstUse'],
};

const store = init<RootModel>({
	models,
	plugins: [persistPlugin(persistConfig)],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

export default store;
