import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Grid, ListItem, Button } from '@material-ui/core';
import { getCollor } from './helper';
import { useStyles } from './styles';
import { Iquestao } from '../../Types';

interface Props {
	questao: Iquestao;
	reportadas: boolean;
}

export const ListQuestionItem: React.FC<Props> = ({ questao }) => {
	const {
		vestibular,
		ano,
		fonte,
		materia,
		numeroQuestao,
		areaConhecimento,
		createdAt,
		_id,
	} = questao;

	const color = getCollor(vestibular);
	const classes = useStyles();

	const getDate = (date: string) => {
		let theMoment = moment(date, 'YYYY-MM-DD').fromNow();
		if (theMoment === 'Invalid date') {
			return moment(parseInt(date, 10)).fromNow();
		}
		return theMoment;
	};
	let assertividade = 0;
	if (questao.assertividade) {
		assertividade =
			questao.assertividade?.erros + questao.assertividade?.acertos;
		assertividade = questao.assertividade?.acertos / assertividade;
		assertividade = Math.floor(assertividade * 100);
	}

	const assertividadeLabel = questao.assertividade
		? `Acerto: ${assertividade.toString()}%`
		: '-';
	let classAssertividade = classes.scoreBaixo;
	if (assertividade > 69) {
		classAssertividade = classes.scoreAlto;
	} else if (assertividade > 29 && assertividade < 70) {
		classAssertividade = classes.scoreMedio;
	}
	return (
		<ListItem
			style={{
				borderLeft: `4px solid ${color}`,
				padding: '10px',
			}}
			className={classes.card}
			key={_id}
		>
			<Grid container direction="row" justify="space-between">
				<Grid item xs={1} className={classes.cardData}>
					<Typography style={{ color }} className={classes.vestibular}>
						{vestibular}
					</Typography>
				</Grid>
				<Grid item xs={3} className={classes.cardData}>
					<Typography className={classes.item}>{ano}</Typography>
					<Typography className={classes.item}>{fonte}</Typography>
				</Grid>
				<Grid item xs={3} className={classes.cardData}>
					<Typography className={classes.item}>{materia}</Typography>
					<Typography className={classes.item}>
						Questão {numeroQuestao}
					</Typography>
				</Grid>
				<Grid item xs={1} className={classes.cardData}>
					<Typography className={`${classes.item} ${classAssertividade}`}>
						{assertividadeLabel}
					</Typography>
				</Grid>

				<Grid item xs={2} className={classes.cardData}>
					<Typography className={classes.item}>
						{areaConhecimento ? areaConhecimento.nome : '----'}
					</Typography>
				</Grid>

				<Grid item xs={1} className={classes.cardData}>
					<Typography className={classes.item}>{getDate(createdAt)}</Typography>
				</Grid>
				<Grid item xs={1} className={classes.cardBtn}>
					<Link to={`/editar/${_id}`} className={classes.button}>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
						>
							REVISAR
						</Button>
					</Link>
				</Grid>
			</Grid>
		</ListItem>
	);
};
