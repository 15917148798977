import { gql } from '@apollo/client';

export const LISTAR_SIMULADOS = gql`
	query ListarSimulados($ano: Int, $vestibular: String, $disponivel: Boolean) {
		simuladosAdmin(
			ano: $ano
			vestibular: $vestibular
			disponivel: $disponivel
		) {
			_id
			nome
			titulo
			descricao
			totalQuestoes
			vestibular
			ano
			pubDate
			fonte
			disponivel
			updatedAt
			nomeOriginal
		}
	}
`;
