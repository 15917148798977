import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { FETCH_DESAFIO } from 'screens/CadastrarDesafio/api';
import { RootModel } from '..';
import api from '../../../services/api';
import { UPDATE_DESAFIO, PUBLISH_DESAFIO } from './queries';

const INITIAL_STATE = {
	disableAdding: true,
	desafioId: '',
	dataFinal: '',
};

export const desafioModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		setDisableAdding: (state, payload: { disableAdding: boolean }) => ({
			...state,
			disableAdding: payload.disableAdding,
		}),
		setDesafioId: (state, payload: { desafioId: string }) => ({
			...state,
			desafioId: payload.desafioId,
		}),
		setDataFinal: (state, payload: { dataFinal: string }) => ({
			...state,
			dataFinal: payload.dataFinal,
		}),
	},
	effects: () => ({
		async addQuestao({ desafioId, questaoId }, models) {
			try {
				const novasQuestoes = models.bancoQuestoes.banco.map(
					questao => questao._id,
				);
				novasQuestoes.push(questaoId);
				await api.mutate({
					mutation: UPDATE_DESAFIO,
					variables: {
						_id: desafioId,
						desafio: {
							questoes: novasQuestoes,
						},
					},
					refetchQueries: [
						{
							query: FETCH_DESAFIO,
							variables: {
								_id: desafioId,
							},
						},
					],
				});
			} catch (error: any) {
				console.log(error);
			}
		},
		async publicarDesafio(props, models) {
			try {
				await api.mutate({
					mutation: PUBLISH_DESAFIO,
					variables: {
						_id: models.desafio.desafioId,
						dataFinal: models.desafio.desafioId,
					},
				});
				toast.success('Desafio foi publicado com sucesso.');
			} catch (error: any) {
				console.log(error);
			}
		},
	}),
});
