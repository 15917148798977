import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_QUESTOES_VESTIBULARES } from '../queries';
import { getQueryVariables } from '../../../helpers/queries';

const QuestionContext = React.createContext();

export const QuestionProvider = ({ children }) => {
	const [review, setReview] = useState(true);
	const [ano, setAno] = useState(0);

	// Campos que são usados em input por string
	const [{ codigo, especial, assunto }, setInputValues] = useState({
		codigo: '',
		especial: '', // Enunciado
		assunto: '',
	});

	const setCodigo = useCallback(
		value => setInputValues(vals => ({ ...vals, codigo: value })),
		[],
	);
	const setEspecial = useCallback(
		value => setInputValues(vals => ({ ...vals, especial: value })),
		[],
	);
	const setAssunto = useCallback(
		value => setInputValues(vals => ({ ...vals, assunto: value })),
		[],
	);

	const [palavraChave, setPalavraChave] = useState(0);

	const [reported, setReported] = useState(false);
	const [materia, setMateria] = useState(0);
	const [vestibular, setVestibular] = useState(0);
	const [asc, setAsc] = useState(false);
	const [fonte, setFonte] = useState(0);
	const [assertividadeOrder, setAssertividadeOrder] = useState(false);
	const { loading, error, data, fetchMore, refetch } = useQuery(
		GET_QUESTOES_VESTIBULARES,
		{
			variables: getQueryVariables({
				vestibular,
				ano,
				review,
				palavraChave,
				materia,
				codigo,
				texto: especial,
				asc,
				fonte,
				assertividadeOrder,
				assunto,
			}),
			pollInterval: 3000,
			fetchPolicy: 'no-cache',
		},
	);

	return (
		<QuestionContext.Provider
			value={{
				loading,
				error,
				data,
				fetchMore,
				refetch,
				reported,
				setReported,
				ano,
				setAno,
				review,
				setReview,
				palavraChave,
				setPalavraChave,
				codigo,
				setCodigo,
				especial,
				setEspecial,
				assunto,
				setAssunto,
				materia,
				setMateria,
				vestibular,
				setVestibular,
				asc,
				setAsc,
				fonte,
				setFonte,
				assertividadeOrder,
				setAssertividadeOrder,
				setInputValues,
			}}
		>
			{children}
		</QuestionContext.Provider>
	);
};

QuestionProvider.propTypes = {
	children: PropTypes.node,
};

export default QuestionContext;
