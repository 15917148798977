import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { NotificationFeedData } from 'screens/FeedNotification/types';
import { getDate } from 'helpers/data';
import { Link } from 'react-router-dom';
import { FEED_BY_ID_NOTIFICATION } from 'constants/routes';

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(4n+1)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	}),
)(TableRow);
/**
 * 
 'Title',
	'Description',
	'Deeplink',
	'Enviado por',
	'Local de Envio',
	'Data',
	'', OPEN
 */

const RowNotificationItem: React.FC<{
	item: Partial<NotificationFeedData>;
}> = ({ item }) => (
	<StyledTableRow key={item._id}>
		<StyledTableCell component="th" scope="row">
			{item?.audience || 'Usuário'}
		</StyledTableCell>
		<StyledTableCell align="left">
			{`${item.message?.title} ${item.message?.description}`}
		</StyledTableCell>
		<StyledTableCell align="left">
			{item.message?.link || 'N/A'}
		</StyledTableCell>
		<StyledTableCell align="left">
			{item?.sentBy ? item?.sentName || 'Usuário admin' : ' - '}
		</StyledTableCell>
		<StyledTableCell align="left">{item?.sentFrom || 'N/A'}</StyledTableCell>
		<StyledTableCell align="left">{getDate(item?.createdAt)}</StyledTableCell>
		<StyledTableCell align="center">
			<Link to={FEED_BY_ID_NOTIFICATION.replace(':notificationId', item?._id)}>
				Abrir notificação
			</Link>
		</StyledTableCell>
	</StyledTableRow>
);

export default RowNotificationItem;
