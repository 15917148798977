export enum NotificationFeedAudience {
	All = 'all',
	Premium = 'premium',
	Freemium = 'freemium',
	B2C = 'b2c',
	B2B = 'b2b',
}

export const NotificationAudienceOptions = [
	{
		label: 'Nenhum',
		value: null,
	},
	{
		label: 'Todos',
		value: NotificationFeedAudience.All,
	},
	{
		label: 'Premium',
		value: NotificationFeedAudience.Premium,
	},
	{
		label: 'Freemium',
		value: NotificationFeedAudience.Freemium,
	},
	{
		label: 'Premium - B2C',
		value: NotificationFeedAudience.B2C,
	},
	{
		label: 'Premium - B2B',
		value: NotificationFeedAudience.B2B,
	},
];
export const IconTypes = [
	{
		label: 'Material Community',
		value: null,
	},
	{
		label: 'Material',
		value: 'material',
	},
	{
		label: 'Url',
		value: 'url',
	},
	{
		label: 'Custom',
		value: 'custom',
	},
	{
		label: 'Ionicons',
		value: 'ionicons',
	},
	{
		label: 'Evilicons',
		value: 'evilicons',
	},
];

interface NotificationFeedMessageSender {
	name: string;
	photoURL: string;
}

export interface NotificationFeedAction {
	label: string;
	link: string;
}

export interface NotificationFeedMessage {
	title: string;
	description: string;
	link: string | null;
	icon: string;
	iconType: string | 'material' | 'url' | 'custom';
	actions?: NotificationFeedAction[];
	sender?: NotificationFeedMessageSender;
}

export interface CreateNotificationFeedInput {
	audience: null | string | 'all' | 'premium' | 'freemium' | 'b2c' | 'b2b';
	receiver?: string[];
	message: NotificationFeedMessage;
	sender?: string;
	entity?: number; // desafio, simulado, questões, objetivos
	entityType?: number;
}

export interface NotificationFeedInput extends NotificationFeedMessage {
	audience: null | string | 'all' | 'premium' | 'freemium' | 'b2c' | 'b2b';
	receiver: string[];
}

export interface NotificationFeedActivities {
	userId: string;
	createdAt: string;
}

export interface NotificationFeedData {
	_id: string;
	sender: string | null; // Notification creator
	receiver: string[]; // Ids of the receivers of the notification
	message: NotificationFeedMessage;
	audience: null | string | 'all' | 'premium' | 'freemium' | 'b2c' | 'b2b';
	entity?: number; // desafio, simulado, questões, objetivos
	entityType?: number;
	interact?: NotificationFeedActivities[];
	read?: NotificationFeedActivities[];
	seen?: NotificationFeedActivities[];
	hide?: NotificationFeedActivities[];
	/**
	 * @param sentBy
	 * User ID do Painel de Dashboard
	 */
	sentBy?: string;
	/**
	 * @param sentFrom
	 * User de onde foi enviado por padrão - Admin
	 */
	sentFrom?: string;
	/**
	 * @param sentName
	 * Nome do usuário
	 */
	sentName?: string;
	sentAsPushNotification?: boolean;
	sentPushNotificationAt?: Date;
	pushNotificationDetails?: {
		id: string;
		recipients: number;
		sendAfter?: string;
	};
	createdAt?: Date;
	updatedAt?: Date;
}

export interface ListNotificationsContextProps {
	notifications: null | Partial<NotificationFeedData>[];
	totalNotifications?: null | number;
	loading?: boolean;
	error?: unknown;
	refetch: () => void;
	page: number;
	skip: number;
	sentFrom: string | null;
	limit: number;
	onChangePage?: (skip: number) => void;
	onChangeLimit?: (limit: number) => void;
	onChangeSentFrom?: (role: string) => void;
}

export const Deeplinks = {
	Desafios: 'desafios',
	QuestoesAvulsas: 'questoes',
	SimuladosHome: 'simulados',
	Perfil: 'perfil',
	ConfigHome: 'config',
	EditarDados: 'perfil/editar',
	MeusPlanos: 'perfil/planos',
	MeusObjetivos: 'perfil/objetivos',
	BecomePremium: 'becomePremium',

	NotificationFeed: 'notifications',

	QuestaoReviewById: 'questao/review/questaoId',

	// Desafios
	DetalhesDesafio: 'desafio/detalhes/desafioId',
	ResponderDesafio: 'desafio/quiz/desafioId',

	// Simulado
	SimuladosByVestibular: 'simulados/NOME_VESTIBULAR',
	// Simulado
	WebView: 'webview',
};

export const DeeplinksOptions = [
	{
		title: 'Desafio - Abrir detalhes',
		link: Deeplinks.DetalhesDesafio,
	},
	{
		title: 'Desafio - Iniciar agora (Tela Cronômetro)',
		link: Deeplinks.ResponderDesafio,
	},
	{
		title: 'Desafio - Home',
		link: Deeplinks.Desafios,
	},
	{
		title: 'Webview - (Título e URL)',
		link: `${Deeplinks.WebView}/?title=FB_VEST&url=https://fbvest.com`,
	},
	{
		title: 'Tela Seja Premium',
		link: Deeplinks.BecomePremium,
	},
	{
		title: 'Questões Avulsas',
		link: Deeplinks.QuestoesAvulsas,
	},

	{
		title: 'Simulados - Home',
		link: Deeplinks.SimuladosHome,
	},
	{
		title: 'Simulados - Abrir lista por Vestibular (ENEM,ITA,IME)',
		link: Deeplinks.SimuladosByVestibular,
	},
	{
		title: 'Perfil - Tela de aproveitamento',
		link: Deeplinks.Perfil,
	},
	{
		title: 'Configurações',
		link: Deeplinks.ConfigHome,
	},
	{
		title: 'Config - Meus Dados',
		link: Deeplinks.EditarDados,
	},
	{
		title: 'Config - Meus Objetivos',
		link: Deeplinks.MeusObjetivos,
	},
	{
		title: 'Config - Meus Planos',
		link: Deeplinks.MeusPlanos,
	},
];
