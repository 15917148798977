import { Models } from '@rematch/core';
import { assuntosModel } from './assuntos';
import { assuntosForm } from './assuntosForm';
import { cadQuestoes } from './cadQuestoes';
import { materiasModel } from './materias';
import { questoesModel } from './questoes';
import { authModel } from './auth';
import { firstUseModel } from './firstUse';
import { desafioModel } from './desafios';
import { bancoQuestoes } from './bancoQuestoes';

export interface RootModel extends Models<RootModel> {
	assuntos: typeof assuntosModel;
	assuntosForm: typeof assuntosForm;
	cadQuestoes: typeof cadQuestoes;
	materias: typeof materiasModel;
	questoes: typeof questoesModel;
	auth: typeof authModel;
	firstUse: typeof firstUseModel;
	desafio: typeof desafioModel;
	bancoQuestoes: typeof bancoQuestoes;
}
export const models: RootModel = {
	assuntos: assuntosModel,
	materias: materiasModel,
	questoes: questoesModel,
	assuntosForm,
	cadQuestoes,
	auth: authModel,
	firstUse: firstUseModel,
	desafio: desafioModel,
	bancoQuestoes,
};
