import React from 'react';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { PUBLISH_SIMULADO_FB } from '../components/SimuladoCard/api';

export const UpdateSimulado = ({ simuladoId, classes }) => {
	const [publishSimulado] = useMutation(PUBLISH_SIMULADO_FB);

	const updateSimulado = async () => {
		try {
			await publishSimulado({
				variables: {
					_id: simuladoId,
				},
			});
			toast.success('Simulado atualizado com sucesso!');
		} catch (error) {
			toast.error('Erro ao atualizar o simulado.');
		}
	};
	return (
		<Button
			variant="contained"
			color="primary"
			className={classes.button}
			endIcon={<RefreshIcon>save</RefreshIcon>}
			onClick={updateSimulado}
		>
			Publicar
		</Button>
	);
};

export default UpdateSimulado;
