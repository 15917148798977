import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { IFirstUse } from './types';

const INITIAL_STATE = {
	isFirstUse: false,
};

export const firstUseModel = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		setIsFirstUse: (state, payload: IFirstUse) => ({
			...state,
			isFirstUse: payload.isFirstUse,
		}),
	},
});
