import React, { useContext } from 'react';
import { Grid, LinearProgress, TextField } from '@material-ui/core';
import { UsersContext } from 'screens/DashboardFinanceiro/provider';

const Filters: React.FC = () => {
	const { filters, handleChange, loadingUsers } = useContext(UsersContext);
	if (loadingUsers) {
		return <LinearProgress />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg={2}>
				<TextField
					fullWidth
					name="inicio"
					type="date"
					value={filters.inicio}
					variant="outlined"
					onChange={handleChange}
					size="small"
				/>
			</Grid>
			<Grid item xs={12} lg={2}>
				<TextField
					fullWidth
					name="fim"
					type="date"
					value={filters.fim}
					variant="outlined"
					onChange={handleChange}
					size="small"
				/>
			</Grid>
		</Grid>
	);
};

export default Filters;
