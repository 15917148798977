import React from 'react';
import { Typography, Grid, ListItem, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getCollor } from 'screens/ListarQuestoes/components/ListQuestionItem/helper';
import { timestampToDate } from 'helpers/data';
import { useStyles } from './styles';
import { UpdateSimulado } from '../../helpers/simuladoQuery';
import { RevisarSimulado } from '../../helpers/revisarSimulado';
import { SimuladoDisponivel } from '../../helpers/ativarSimulado';

function SimuladoCard({ simulado }) {
	const color = getCollor(simulado.vestibular);
	const classes = useStyles();
	return (
		<ListItem
			key={simulado._id}
			style={{
				borderLeft: `4px solid ${color}`,
			}}
			className={classes.card}
		>
			<Grid container direction="column">
				<Grid container direction="row" alignItems="center">
					<Typography
						className={classes.nome}
						gutterBottom
						variant="h6"
						component="h4"
					>
						{simulado.nome}
					</Typography>

					<Typography
						className={classes.nomeOriginal}
						variant="h6"
						component="h4"
						style={{ marginLeft: '10rem' }}
					>
						{simulado.nomeOriginal}
					</Typography>
				</Grid>

				<Grid container direction="row" style={{ display: 'flex' }}>
					<Typography style={{ color }} className={classes.vestibular}>
						{simulado.vestibular}
					</Typography>
					<Typography className={classes.vestibular}>{simulado.ano}</Typography>

					<Typography className={classes.vestibular}>
						{simulado.fonte}
					</Typography>
					<Typography className={classes.vestibular}>
						{simulado.totalQuestoes} questoes
					</Typography>

					<Typography className={classes.vestibular}>
						{simulado.descricao}
					</Typography>

					<Chip
						style={{
							backgroundColor: simulado.disponivel ? '#53AF50' : '#E74133',
							color: 'white',
							minWidth: '15%',
							marginLeft: 'auto',
						}}
						size="small"
						label={simulado.disponivel ? 'Disponível' : 'Indisponível'}
					/>
				</Grid>
				<Grid container direction="row">
					<Typography className={classes.vestibular}>
						Update: {timestampToDate(simulado.updatedAt)}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				alignItems="flex-end"
				style={{ justifyContent: 'end' }}
			>
				<RevisarSimulado simuladoId={simulado._id} classes={classes} />
				<UpdateSimulado simuladoId={simulado._id} classes={classes} />
				<SimuladoDisponivel simulado={simulado} classes={classes} />
			</Grid>
		</ListItem>
	);
}

SimuladoCard.propTypes = {
	simulado: PropTypes.shape({
		_id: PropTypes.string,
		vestibular: PropTypes.string,
		fonte: PropTypes.string,
		pubDate: PropTypes.string,
		totalQuestoes: PropTypes.number,
	}),
};

export default SimuladoCard;
