export const getRows = (data: any) =>
	data?.listUsers?.map((user: any, index: number) => ({
		id: index + 1,
		nome: user.displayName || '------------------------',
		email: user.email,
		plano: user.customClaims?.subscriptions?.length
			? user.customClaims?.subscriptions[0]?.nome
			: '------------------------',
		plataforma:
			user.customData?.deviceInfo?.platform?.toUpperCase() ||
			'------------------------',
		provider:
			(user.providers && user.providers[0]?.providerId) ||
			'------------------------',
		open: 'Abrir',
	}));

export const countPlano = (planos: any, nomePlano: any) =>
	planos.find((userPlano: any) => userPlano.nome.includes(nomePlano))?.valor ||
	0;
