import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { selectStyles } from 'helpers';

class FiltroQuestao extends PureComponent {
	render() {
		const {
			label,
			noData,
			isMulti,
			className,
			name,
			onChange,
			options,
			value,
		} = this.props;

		return (
			<>
				<p className="font-weight-bold m-0 mb-1 mt-2">{label}</p>
				<Select
					placeholder={`Selecionar ${label}...`}
					isMulti={isMulti}
					className={className}
					classNamePrefix="select"
					name={name}
					isClearable
					isSearchable
					noOptionsMessage={() => noData || 'Sem dados'}
					onChange={val => onChange(val, name)}
					options={options}
					value={value}
					styles={selectStyles}
				/>
			</>
		);
	}
}

FiltroQuestao.defaultProps = {
	isMulti: false,
};

FiltroQuestao.propTypes = {
	className: PropTypes.string,
	isMulti: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	value: PropTypes.instanceOf(Object),
	noData: PropTypes.string,
};

export default FiltroQuestao;
