import React, { useCallback } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from '@material-ui/core';
import { useListNotificationFeed } from 'screens/FeedNotification/provider/notifications.provider';
import { NotificationFeedData } from 'screens/FeedNotification/types';
import RowNotificationItem from './row-notification';
import SelectSentFromType from './select-sent-from';

const headCells = [
	'Audience',
	'Texto',
	'Deeplink',
	'Enviado por',
	'Local de Envio',
	'Data',
	'',
];

const NotificationsTable: React.FC = () => {
	const {
		notifications,
		totalNotifications,
		limit,
		page,
		onChangeLimit,
		onChangePage,
	} = useListNotificationFeed();

	const renderNotificationRow = useCallback(
		(notification: Partial<NotificationFeedData>) => (
			<RowNotificationItem key={notification?._id} item={notification} />
		),
		[],
	);

	if (!notifications) return null;
	return (
		<>
			<SelectSentFromType />
			<Paper>
				<TableContainer>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
						component={Paper}
					>
						<TableHead>
							<TableRow>
								{headCells.map((item, index) => (
									<TableCell key={`${item}-${index}`} align="left">
										{item}
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{notifications && notifications?.map(renderNotificationRow)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 30]}
					component="div"
					count={totalNotifications}
					rowsPerPage={limit}
					page={page}
					labelRowsPerPage="Notificações por página"
					onChangePage={(_, pagina) => onChangePage?.(pagina)}
					onChangeRowsPerPage={event =>
						onChangeLimit?.(parseInt(event.target.value, 10))
					}
				/>
			</Paper>
		</>
	);
};

export default NotificationsTable;
