import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, CardBody, CardHeader } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AlternativaItem } from 'components';

class FormAlternativas extends Component {
	constructor(props) {
		super(props);
		const { alternativas } = this.props;
		const alternativaCorreta = alternativas.find(
			alternativa => alternativa.correta,
		);

		const corretaSelect = alternativaCorreta
			? { value: alternativaCorreta.numero, label: alternativaCorreta.letra }
			: null;
		this.state = {
			alternativasCorretas: alternativas.map((alternativa, index) => ({
				value: index,
				label: alternativa.letra,
			})),
			alternativaCorreta: corretaSelect,
			collapses: alternativas.map(alternativa => ({
				isOpen: alternativa.correta || false,
			})),
		};
		this.handleOnChangeAlternativa = this.handleOnChangeAlternativa.bind(this);
		this.handleOnRemove = this.handleOnRemove.bind(this);
		this.handleOnCollapse = this.handleOnCollapse.bind(this);
		this.handleOnChangeAlternativaCorreta = this.handleOnChangeAlternativaCorreta.bind(
			this,
		);
	}

	static getDerivedStateFromProps(props, prevState) {
		if (props.alternativas.length > prevState.collapses.length) {
			const collapses = [
				...prevState.collapses.map(() => ({
					isOpen: false,
				})),
				{ isOpen: true },
			];
			if (props.alternativas.length !== prevState.alternativasCorretas.length) {
				const indexCorreta = props.alternativas.findIndex(it => it.correta);
				const alternativaCorreta = props.alternativas.find(
					alternativa => alternativa.correta,
				);

				return {
					collapses,
					alternativasCorretas: props.alternativas.map(
						(alternativa, index) => ({
							value: index,
							label: alternativa.letra,
						}),
					),
					alternativaCorreta:
						indexCorreta > 0
							? { value: alternativaCorreta, label: alternativaCorreta.letra }
							: null,
				};
			}
			return { collapses };
		}
		if (props.alternativas.length !== prevState.alternativasCorretas.length) {
			const indexCorreta = props.alternativas.findIndex(it => it.correta);
			const alternativaCorreta = props.alternativas.find(
				alternativa => alternativa.correta,
			);

			return {
				alternativasCorretas: props.alternativas.map((alternativa, index) => ({
					value: index,
					label: alternativa.letra,
				})),
				alternativaCorreta:
					indexCorreta > 0
						? { value: alternativaCorreta, label: alternativaCorreta.letra }
						: null,
			};
		}

		if (props.alternativas.length === 0 && prevState.collapses.length > 1) {
			if (props.alternativas.length !== prevState.alternativasCorretas.length) {
				const indexCorreta = props.alternativas.findIndex(it => it.correta);
				const alternativaCorreta = props.alternativas.find(
					alternativa => alternativa.correta,
				);

				return {
					collapses: [{ isOpen: true }],
					alternativasCorretas: props.alternativas.map((it, index) => ({
						value: index,
						label: index + 1,
					})),
					alternativaCorreta:
						indexCorreta > 0
							? { value: alternativaCorreta, label: alternativaCorreta.letra }
							: null,
				};
			}
			return { collapses: [{ isOpen: true }] };
		}
		return null;
	}

	handleOnChangeAlternativaCorreta(alternativaCorreta) {
		this.setState({ alternativaCorreta });
		const { onChangeState } = this.props;
		let { alternativas } = this.props;
		alternativas = alternativas.map(it => ({ ...it, correta: false }));
		alternativas[alternativaCorreta.value].correta = true;
		onChangeState({ alternativas });
	}

	handleOnCollapse(index) {
		const { collapses } = this.state;
		collapses[index].isOpen = !collapses[index].isOpen;
		this.setState({ collapses });
	}

	handleOnChangeAlternativa(texto, index) {
		const { alternativas, onChangeState } = this.props;
		const changedAlternativas = alternativas.map((alternativa, ind) => {
			const newAlternativa = {
				...alternativa,
			};
			if (ind === index) {
				newAlternativa.texto = texto;
			}
			return newAlternativa;
		});

		onChangeState({ alternativas: changedAlternativas });
	}

	handleOnRemove(event) {
		event.preventDefault();
		const index = parseInt(event.currentTarget.dataset.index, 10);
		const { alternativas, onChangeState } = this.props;
		const { collapses } = this.state;
		if (alternativas.length <= 4) {
			toast.error('O número mínimo de alternativas a serem cadastradas é 4.');
		} else {
			const newAlternativa = alternativas
				.slice(0, index)
				.concat(alternativas.slice(index + 1, alternativas.length));
			const newcollapses = collapses
				.slice(0, index)
				.concat(collapses.slice(index + 1, collapses.length));
			this.setState({ collapses: newcollapses });
			onChangeState({ alternativas: newAlternativa });
		}
	}

	render() {
		const { alternativas, addAlternativa, removeAllAlternativas } = this.props;
		const { collapses, alternativasCorretas, alternativaCorreta } = this.state;

		return (
			<Card>
				<CardHeader className="font-weight-bold">
					<Row className="d-flex justify-content-between">
						<Col>Alternativas</Col>
						{alternativas.length > 0 && (
							<Col className="text-right">
								<Button
									size="sm"
									color="warning"
									onClick={removeAllAlternativas}
								>
									Limpar Alternativas
								</Button>
							</Col>
						)}
					</Row>
				</CardHeader>
				<CardBody>
					<Row className="d-flex justify-content-between mb-5">
						<Col>
							{alternativas.length > 0 && (
								<>
									<p className="font-weight-bold m-0 mb-1">
										Alternativa correta
									</p>
									<Select
										name="correta"
										classNamePrefix="select"
										noOptionsMessage={this.noOptionsMessage}
										onChange={this.handleOnChangeAlternativaCorreta}
										options={alternativasCorretas}
										value={alternativaCorreta}
									/>
								</>
							)}
						</Col>
						<Col className="text-right">
							<Button size="sm" color="dark" onClick={addAlternativa}>
								Adicionar
							</Button>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							{alternativas.map((al, index) => (
								<AlternativaItem
									key={al.id || al.letra}
									index={index}
									al={al}
									onChangeAlternativa={this.handleOnChangeAlternativa}
									onCollapse={this.handleOnCollapse}
									handleOnRemove={this.handleOnRemove}
									collapses={collapses}
								/>
							))}
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

FormAlternativas.propTypes = {
	onChangeState: PropTypes.func.isRequired,
	addAlternativa: PropTypes.func.isRequired,
	removeAllAlternativas: PropTypes.func.isRequired,
	alternativas: PropTypes.arrayOf(
		PropTypes.shape({
			letra: PropTypes.string,
			texto: PropTypes.string,
			correta: PropTypes.bool,
			ordem: PropTypes.number,
		}),
	),
};

export default FormAlternativas;
