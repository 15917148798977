import React, { useContext } from 'react';
import { Spinner } from 'reactstrap';
import { BsPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import QuestionContext from '../../providers/QuestionProvider';
import {
	ascOptions,
	anoOptions,
	getPalavrasChaveOptions,
	getMateriaOptions,
	getVestibularOptions,
	getFontesOptions,
	orderOptions,
} from '../SelectDropDown/options';
import { useStyles } from './styles';
import { PaginationList } from '../Pagination';
import SelectDropDown from '../SelectDropDown';
import { ListQuestion } from '../ListQuestion';
import ImputTextField from '../TextField';

const getCountQuestoes = (review: number, reported: number, data: any) => {
	if (reported) {
		return data.countQuestoesReportadas;
	}
	if (review) {
		return data.countQuestoes;
	}
	return data.countQuestoesRevisadas;
};

export const Body = () => {
	const styles = useStyles();

	const {
		loading,
		error,
		data,
		fetchMore,
		reported,
		ano,
		setAno,
		review,
		palavraChave,
		setPalavraChave,
		codigo,
		setCodigo,
		especial,
		setEspecial,
		materia,
		setMateria,
		vestibular,
		setVestibular,
		asc,
		setAsc,
		fonte,
		setFonte,
		assertividadeOrder,
		setAssertividadeOrder,
	} = useContext(QuestionContext);

	if (loading) {
		return (
			<div className={styles.spinner}>
				<Spinner size="lg" color="primary" />
			</div>
		);
	}
	if (error) {
		return (
			<div>
				Ocorreu um erro interno , logue novamente:
				<Link to="/login" className={styles.btn}>
					Login
				</Link>
			</div>
		);
	}
	return (
		<div className={styles.body}>
			<div className={styles.root}>
				<Grid
					container
					className={styles.gridContainer}
					justify="space-between"
				>
					<Grid item xs={8}>
						<SelectDropDown
							value={assertividadeOrder}
							onChange={setAssertividadeOrder}
							options={orderOptions}
							name="campo"
						/>
						<SelectDropDown
							value={asc}
							onChange={setAsc}
							options={ascOptions}
							name="sentido"
						/>

						<SelectDropDown
							value={vestibular}
							onChange={setVestibular}
							options={getVestibularOptions(data)}
						/>

						<SelectDropDown
							value={materia}
							onChange={setMateria}
							options={getMateriaOptions(data)}
						/>

						<SelectDropDown
							value={palavraChave}
							onChange={setPalavraChave}
							options={getPalavrasChaveOptions(data)}
						/>

						<SelectDropDown
							value={ano}
							onChange={(value: string) => setAno(parseInt(value, 10))}
							options={anoOptions}
						/>
						<SelectDropDown
							value={fonte}
							onChange={(value: string) => setFonte(value)}
							options={getFontesOptions(data)}
						/>
					</Grid>

					<Grid item xs={4}>
						<form noValidate autoComplete="off">
							<ImputTextField
								placeholder="Código"
								value={codigo}
								myWhidth="15ch"
								myMargin="10px"
								onKeyDown={(event: any, value: string) => {
									if (event.key === 'Enter') {
										setCodigo(value);
									}
								}}
							/>
							<ImputTextField
								placeholder="Enunciado/Alternativas"
								value={especial}
								myWhidth="25ch"
								myMargin="0"
								onKeyDown={(event: any, value: string) => {
									if (event.key === 'Enter') {
										setEspecial(value);
									}
								}}
							/>
						</form>
					</Grid>
				</Grid>
				<Grid container className={styles.gridContainer}>
					<Grid item xs={10}>
						<Typography variant="h6" component="h6" color="initial">
							Foram encontradas
							<b style={{ marginLeft: 5, marginRight: 5, color: '#8190A7' }}>
								{getCountQuestoes(review, reported, data)}
							</b>
							questões
						</Typography>
					</Grid>
					<Grid item>
						<Link to="/cadastrar" className={styles.buttonLink}>
							<Button
								size="medium"
								variant="contained"
								className={styles.btn}
								style={{ background: '#363636', color: '#fff' }}
							>
								<BsPlus size={20} />
								CADASTRAR
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
			<ListQuestion
				reportadas={reported}
				questoes={reported ? data.questoesReportadas : data.questoes}
			/>

			<PaginationList
				countQuestoes={getCountQuestoes(review, reported, data)}
				fetchMore={fetchMore}
			/>
		</div>
	);
};
