import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	chartContainer: {
		marginTop: theme.spacing(5),
	},
	cardContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
