import moment from 'moment';
import { useState } from 'react';
import { UserDateFilter } from '../types';

const INITIAL_FILTERS: UserDateFilter = {
	inicio: moment().startOf('year').format('YYYY-MM-DD'),
	fim: moment().format('YYYY-MM-DD'),
};

export const useFinancialFilters = () => {
	const [filters, setFilters] = useState(INITIAL_FILTERS);

	const handleChange = (event: any) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[`${event.target.name}`]: event.target.value,
		}));
	};
	return {
		filters,
		handleChange,
	};
};

const INITIAL_USER_FILTERS = {
	active: true,
};

export const useUserFilters = () => {
	const [filtersUser, setFilters] = useState(INITIAL_USER_FILTERS);

	const handleChangeUser = (event: any) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[`${event.target.name}`]: event.target.value,
		}));
	};
	return {
		filtersUser,
		handleChangeUser,
	};
};
