import { object, string } from 'yup';

export const registerSchema = object({
	nome: string().required('O campo usuário é obrigatório.'),
	password: string().required('Por Favor, Gere uma senha.'),
	email: string()
		.email('Por favor selecione um e-mail válido')
		.required('O campo e-mail é obrigatório.'),
	role: string().required('Escolha uma função para o Usuário.'),
});
