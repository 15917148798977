/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import SunEditorCore from 'suneditor/src/lib/core';
import { uploadFileToBlobName } from 'services/azure';

interface Props {
	htmlRaw: string;
	name: string;
	height?: string;
	onChangeState: (state: { [key: string]: string }) => void;
}

const EditorHTML: React.FC<Props> = ({
	htmlRaw,
	onChangeState,
	name,
	height,
}) => {
	/**
	 * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
	 */
	const editor = useRef<SunEditorCore>();

	// The sunEditor parameter will be set to the core suneditor instance when this function is called
	const getSunEditorInstance = (sunEditor: SunEditorCore) => {
		editor.current = sunEditor;
	};

	const handleOnChangeState = React.useCallback(
		(content: string) => {
			onChangeState({ [name]: content });
		},
		[name],
	);

	const onImageUploadBefore = React.useCallback(
		async (files: Array<File>, info: any, uploadHandler: any) => {
			const result = await uploadFileToBlobName(files[0]);

			const response = {
				result: [result],
			};
			uploadHandler(response);
		},
		[],
	);

	return (
		<SunEditor
			lang="pt_br"
			setOptions={{
				mode: 'classic',
				height: 'auto',
				katex: 'window.katex',
				minHeight: height || '400px',
				maxHeight: '1200px',
				plugins,
				buttonList: [
					['undo', 'redo'],
					['fontSize', 'formatBlock'],
					['paragraphStyle', 'blockquote'],
					['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
					['fontColor', 'hiliteColor', 'textStyle'],
					['removeFormat'],
					['math'],
					['outdent', 'indent'],
					['align', 'horizontalRule', 'list', 'lineHeight'],
					['table', 'link', 'image' /** 'video', 'audio','math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
					/** ['imageGallery'] */ ['fullScreen', 'showBlocks', 'codeView'],
					['preview', 'print'],
					['save'],
				],
			}}
			onImageUploadBefore={onImageUploadBefore}
			onChange={handleOnChangeState}
			setDefaultStyle="font-size: 14px;"
			getSunEditorInstance={getSunEditorInstance}
			defaultValue={htmlRaw}
		/>
	);
};

export default EditorHTML;
