import React from 'react';

import { Avatar, Grid, Typography } from '@material-ui/core';
import GeneralInfoAppUser from './general-info';
import { DetailsUserProps } from './types';

const DetailsUserApp: React.FC<DetailsUserProps> = ({ user, refetch }) => {
	if (!user) return null;
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={8} direction="row">
					<Avatar alt={user?.displayName} src={user?.photoURL} />
					<Typography variant="h3">{user?.displayName}</Typography>
				</Grid>
			</Grid>

			<GeneralInfoAppUser user={user} refetch={refetch} />
		</>
	);
};

export default DetailsUserApp;
