import { useEffect, useCallback, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
	PUBLISH_SIMULADO_FB,
	UNPUBLISH_SIMULADO_FB,
} from 'screens/ListarSimulados/components/SimuladoCard/api';
import { LISTAR_SIMULADOS } from 'screens/ListarSimulados/queries';
import { FETCH_SIMULADO } from '../api';

export const useLazySimulado = (simuladoId: string) => {
	const [
		fetchSimulado,
		{ called, data, loading, error, refetch },
	] = useLazyQuery(FETCH_SIMULADO, {
		variables: {
			_id: simuladoId,
		},
		fetchPolicy: 'no-cache',
	});

	const [publishSimulado, { loading: loadPublish }] = useMutation(
		PUBLISH_SIMULADO_FB,
		{
			onCompleted: refetch,
		},
	);
	const [unpublishSimulado, { loading: loadUnpublish }] = useMutation(
		UNPUBLISH_SIMULADO_FB,
		{
			onCompleted: refetch,
		},
	);

	const handlePublish = useCallback(
		(disponivel: boolean) => {
			disponivel
				? unpublishSimulado({
						variables: { _id: simuladoId },
						refetchQueries: [{ query: LISTAR_SIMULADOS }],
				  })
				: publishSimulado({
						variables: { _id: simuladoId },
						refetchQueries: [{ query: LISTAR_SIMULADOS }],
				  });
		},
		[simuladoId, publishSimulado, unpublishSimulado],
	);

	useEffect(() => {
		if (simuladoId) {
			fetchSimulado();
		}
	}, [simuladoId, fetchSimulado]);

	return {
		data,
		loading,
		called,
		error,
		refetch,
		handlePublish,
		loadPublish,
		loadUnpublish,
	};
};

export const useDialog = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return {
		open,
		handleOpen,
		handleClose,
	};
};
