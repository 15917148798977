import { object, string } from 'yup';

export const alterarSenhaSchema = object({
	oldPassword: object({
		label: string(),
		value: string().required('O campo de senha antiga deve ser preenchido!'),
	}),
	newPassword: object({
		label: string(),
		value: string().required('O campo de nova senha deve ser preenchido!'),
	}),
	confirmPassword: object({
		label: string(),
		value: string().required('O campo de confirmar senha deve ser preenchido!'),
	}),
});

export const firstUseSchema = object({
	newPassword: object({
		label: string(),
		value: string().required('O campo de nova senha deve ser preenchido!'),
	}),
	confirmPassword: object({
		label: string(),
		value: string().required('O campo de confirmar senha deve ser preenchido!'),
	}),
});
