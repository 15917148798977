import React from 'react';
import { Button, TableHead, TableRow } from '@material-ui/core';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

import UserRoles from 'types/user';
import UserWrapper from 'screens/wrappers/UserWrapper';
import EditarDialog from 'screens/CadastrarDesafio/components/EditarDialog';
import { StyledTableCell } from './StyledTableCell';
import { useCustomDialog } from '../../screens/CadastrarDesafio/hooks';

interface Props {
	checkable?: boolean;
	collapsable?: boolean;
	desafios?: any;
	desafioInfo: Record<string, unknown>[];
}

const TableHeader: React.FC<Props> = ({
	checkable,
	collapsable,
	desafios,
	desafioInfo,
}) => {
	const [isEditarOpen, openEditarDialog, closeEditarDialog] = useCustomDialog();

	return (
		<TableHead>
			<TableRow>
				{checkable ? (
					<StyledTableCell align="center">
						<CheckBoxIcon />
					</StyledTableCell>
				) : null}
				<StyledTableCell align="center">Status</StyledTableCell>
				<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
					<StyledTableCell align="center">Permissões</StyledTableCell>
				</UserWrapper>
				<StyledTableCell align="center">Nome</StyledTableCell>
				<StyledTableCell align="center">Criador</StyledTableCell>
				<StyledTableCell align="left">Materia</StyledTableCell>
				<StyledTableCell align="center">Tempo Máx(min)</StyledTableCell>
				<StyledTableCell align="center">Total Questoes</StyledTableCell>

				<StyledTableCell align="center">Pontos</StyledTableCell>
				<StyledTableCell align="center">Qtde Resp</StyledTableCell>
				<StyledTableCell align="center">Publicado</StyledTableCell>
				<StyledTableCell align="center">Data Final</StyledTableCell>
				<StyledTableCell align="center">
					<EditIcon />
				</StyledTableCell>
				<StyledTableCell align="center">
					<DeleteForeverIcon />
				</StyledTableCell>
				<StyledTableCell align="center">
					<Button style={{ color: '#ffffff' }} onClick={openEditarDialog}>
						Editar
					</Button>
				</StyledTableCell>
				<EditarDialog
					open={isEditarOpen}
					handleClose={closeEditarDialog}
					arrayDesafioId={desafioInfo}
					desafio={desafios?.desafio ? desafios.desafio : {}}
				/>
				{collapsable ? (
					<StyledTableCell align="right">
						<ArrowDropDownCircleIcon />
					</StyledTableCell>
				) : null}
			</TableRow>
		</TableHead>
	);
};

export default TableHeader;
