import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiContentSave, mdiCloseCircle } from '@mdi/js';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';
import { toast } from 'react-toastify';

import api from 'services/api';

class MateriasModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			materia: '',
			isEdit: false,
		};
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnSave = this.handleOnSave.bind(this);
		this.handleOnUpdate = this.handleOnUpdate.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.isOpen && props.isEdit && !state.isEdit) {
			const {
				materia: { materia },
			} = props;
			return {
				materia,
				isEdit: true,
			};
		}
		if (!props.isOpen) {
			return {
				materia: '',
				isEdit: false,
			};
		}
		return null;
	}

	async handleOnUpdate() {
		try {
			const { materia: texto } = this.state;
			const { materias, materia, onChangeState, toggle } = this.props;
			const updateMateria = {
				...materia,
				materia: texto,
			};
			await api.put(`materias/${materia._id}`, updateMateria);
			const index = materias.findIndex(it => it._id === materia._id);
			const newMaterias = [
				...materias.slice(0, index),
				updateMateria,
				...materias.slice(index + 1, materias.length),
			];
			onChangeState({
				materias: newMaterias,
			});
			toast.success('Matéria Cadastrada!');
			toggle();
		} catch (err) {
			toast.error('Não foi possivel cadastrar matéria');
		}
	}

	async handleOnSave() {
		try {
			const { materia } = this.state;
			const { materias, onChangeState, toggle } = this.props;
			const randomId_ = `5c3f30337bff7b7e921f${Math.floor(
				Math.random() * 2256,
			)}`;
			const newMateria = {
				_id: randomId_,
				materia,
			};
			await api.post(`materias/`, newMateria);
			onChangeState({
				materias: [...materias, newMateria],
			});
			toast.success('Matéria Cadastrada!');
			toggle();
		} catch (err) {
			toast.error('Não foi possivel cadastrar matéria');
		}
	}

	handleOnClick() {
		const { isEdit } = this.props;
		if (isEdit) this.handleOnUpdate();
		else this.handleOnSave();
	}

	handleOnChange(event) {
		const { value: materia } = event.currentTarget;
		this.setState({ materia });
	}

	render() {
		const { isOpen, toggle } = this.props;
		const { materia } = this.state;
		return (
			<div>
				<Modal isOpen={isOpen} toggle={toggle} className>
					<ModalHeader toggle={toggle}>Materia</ModalHeader>
					<ModalBody>
						<Input
							onChange={this.handleOnChange}
							value={materia}
							placeholder="Adicionar matéria"
							rows={5}
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.handleOnClick}>
							Salvar matéria
							<Icon
								className="ml-2"
								path={mdiContentSave}
								size={0.8}
								color="white"
							/>
						</Button>
						<Button color="danger" onClick={toggle}>
							Cancelar
							<Icon
								className="ml-2"
								path={mdiCloseCircle}
								size={0.8}
								color="white"
							/>
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

MateriasModal.propTypes = {
	onChangeState: PropTypes.func.isRequired,
	materia: PropTypes.instanceOf(Array),
	materias: PropTypes.instanceOf(Array).isRequired,
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	isEdit: PropTypes.func.isRequired,
};

export default MateriasModal;
