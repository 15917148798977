import { primaryGreen, primaryBlue } from 'constants/colors';

export const getCollor = vestibular => {
	switch (vestibular) {
		case 'ENEM':
			return primaryGreen;
		case 'ITA':
			return primaryBlue;
		case 'IME':
			return '#f5a623';
		case 'UNICAMP':
			return '#E62E2D';
		case 'FUVEST':
			return primaryBlue;
		case 'USP':
			return primaryBlue;
		default:
			return primaryBlue;
	}
};
