import React from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../../styles';
import { LISTAR_QUESTOES, QUESTOES_REPORTADAS } from '../../../../../constants';

export default function QuestaoMenu() {
	const styles = useStyles();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{ color: 'white', fontWeight: 400, padding: '10px' }}
				className={styles.menuIten}
			>
				QUESTÕES
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => navigate(LISTAR_QUESTOES)}>Revisar</MenuItem>

				<MenuItem onClick={() => navigate(QUESTOES_REPORTADAS)}>
					Reportadas
				</MenuItem>
			</Menu>
		</>
	);
}
