import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
	},
	select: {
		borderWidth: 5,
		fontSize: theme.typography.pxToRem(16),
	},
}));
