import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
	children: React.ReactNode;
	roles: string[];
}

const UserWrapper: React.FC<Props> = ({ children, roles }) => {
	const loggedUser = useSelector((state: RootState) => state.auth.user);

	if (roles.includes(loggedUser.role)) {
		return <>{children}</>;
	}

	return null;
};

export default UserWrapper;
