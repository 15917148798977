import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 'bold',
	},
	titleRow: {
		marginBottom: theme.spacing(5),
	},
	titleContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	backButton: {
		marginRight: theme.spacing(5),
	},
	backButtonGrid: {
		position: 'absolute',
	},
	container: {
		backgroundColor: '#F2F2F2',
		minHeight: '100vh',
	},
	body: {
		height: '100%',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		paddingBottom: theme.spacing(5),
	},
	dropdownCard: {
		flexDirection: 'row',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	dropdownRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	simuladoButtonRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	registerCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	registerButton: {
		color: 'white',
		backgroundColor: '#5AB031',
	},
	filterButton: {
		padding: 12,
	},
	list: {
		marginBottom: theme.spacing(1),
	},
}));
