/* eslint-disable consistent-return */
import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	ApolloLink,
	Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import dotenv from 'dotenv';
import { onError } from '@apollo/client/link/error';
import { getLocalStorage } from '../helpers';
import { getRefreshToken } from './utils';

dotenv.config({
	path: process.env.NODE_ENV === 'production' ? '.env.production' : '.env',
});

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_FB_API_URL,
	credentials: 'same-origin',
});
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const loginCadUser = getLocalStorage(
		process.env.REACT_APP_USER_LOGIN_STORAGE_KEY,
	);
	// return the headers to the context so httpLink can read them
	if (!loginCadUser) {
		return {
			headers: {
				...headers,
			},
		};
	}

	return {
		headers: {
			...headers,
			authorization:
				loginCadUser && loginCadUser.token
					? `Bearer ${loginCadUser.token}`
					: '',
		},
	};
});

// Observar error GraphQL
const promiseToObservable = promise =>
	new Observable(subscriber => {
		promise.then(
			value => {
				if (subscriber.closed) return;
				subscriber.next(value);
				subscriber.complete();
			},
			err => subscriber.error(err),
		);
	});

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			const errorToken = graphQLErrors.every(
				it =>
					it.statusCode === 401 ||
					it.message.includes(
						'Você precisa estar autenticado para fazer isso',
					) ||
					it.message.includes('Token de Acesso Inválido'),
			);

			// Refresh Token
			if (errorToken && operation.operationName !== 'RefreshToken') {
				return promiseToObservable(getRefreshToken()).flatMap(token => {
					const oldHeaders = operation.getContext().headers;
					operation.setContext({
						headers: {
							...oldHeaders,
							authorization: `Bearer ${token}`,
						},
					});
					// retorna para graphql e realizar a requisição novamente
					return forward(operation);
				});
			}
		}

		if (networkError) console.log(`[Network error]: ${networkError}`);
	},
);

const client = new ApolloClient({
	link: ApolloLink.from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					questoes: {
						keyArgs: [
							'vestibular',
							'ano',
							'review',
							'materia',
							'palavraChave',
							'codigo',
							'texto',
							'asc',
							'fonte',
							'assunto',
						],
					},
					questoesReportadas: {
						keyArgs: [
							'vestibular',
							'ano',
							'review',
							'materia',
							'palavraChave',
							'codigo',
							'texto',
							'asc',
							'fonte',
							'assunto',
						],
					},
					simulados: {
						keyArgs: ['vestibular', 'ano'],
					},
					desafios: {
						keyArgs: ['desafioFilters'],
					},
				},
			},
		},
	}),
});

export default client;
