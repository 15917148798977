import { gql } from '@apollo/client';

export const GET_QUESTOES_VESTIBULARES = gql`
	query getQuestoesVestibulares(
		$limit: Int = 20
		$skip: Int = 0
		$vestibular: String
		$fonte: String
		$ano: Int
		$review: Boolean
		$palavraChave: String
		$materia: String
		$codigo: String
		$texto: String
		$asc: Boolean
		$assertividadeOrder: Boolean
		$assunto: String
	) {
		questoesReportadas(
			limit: $limit
			skip: $skip
			vestibular: $vestibular
			fonte: $fonte
			ano: $ano
			palavraChave: $palavraChave
			materia: $materia
			codigo: $codigo
			texto: $texto
			asc: $asc
			assertividadeOrder: $assertividadeOrder
			assunto: $assunto
		) {
			_id
			vestibular
			ano
			fonte
			materia
			enunciado
			numeroQuestao
			palavraChave
			assuntos {
				nome
				materia
			}
			areaConhecimento {
				nome
			}
			alternativas {
				letra
				texto
				correta
			}
			createdAt
			assertividade {
				erros
				acertos
			}
			erros {
				tipoSimulado
				categoria
				mensagem
				user
				info {
					os
					version
					versionNumber
					deviceId
				}
				createdAt
			}
		}
		materias {
			_id
			nome
			areaConhecimento
		}

		vestibulares {
			_id
			nome
		}
		fontes {
			_id
			nome
		}
		countQuestoesReportadas(
			vestibular: $vestibular
			fonte: $fonte
			ano: $ano
			review: $review
			palavraChave: $palavraChave
			materia: $materia
			codigo: $codigo
			texto: $texto
			assunto: $assunto
		)
	}
`;

export const COUNT_QUESTOES_HEADER = gql`
	query getQuestoesHeader($fonte: String) {
		countQuestoesRevisadas: countQuestoes(fonte: $fonte, review: false)
		countQuestoes(fonte: $fonte, review: true)
		countQuestoesReportadas(fonte: $fonte, review: true)
	}
`;

export const GET_VESTIBULARES = gql`
	{
		vestibulares {
			_id
			nome
		}
	}
`;
