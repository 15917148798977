import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import { ExcluirPalavraChave } from 'components';

const TableRow = styled(Row)`
	border: 1px lightgray solid;
	border-radius: 5px;
	margin: 1px;
	margin-bottom: 3px;
	font-size: 15px;
`;

const TableCol = styled(Col)`
	padding-bottom: 5px;
	padding-top: 5px;
`;

const HeaderCol = styled(TableCol)`
	font-weight: bold;
	padding-bottom: 5px;
	padding-top: 5px;
	font-size: 17px;
	text-justify: center;
`;

const ItemPalavraChave = ({ palavraChave, vestibular }) => {
	const [excluirModal, setExcluirModal] = useState(false);
	const toggleExcluir = () => setExcluirModal(!excluirModal);

	const excluirPalavra = async () => {
		/**
		 * TODO: Descobrir que Palavra Chave está no processo de ser excluída
		 * Index não é possível porque o array mostrado já está paginado
		 * Comparar objetos?
		 */
		try {
			// const { data: vest } = await api.get(`vestibulares?vestibular=${vestibular}`);
			// const newPalavrasChave = vest[0].palavrasChave;
			// const index = newPalavrasChave.indexOf(palavraChave);
			// newPalavrasChave.splice(index, 1);
			// await api.put(`vestibulares/${vest[0]._id}`, { ...vest, palavrasChave: newPalavrasChave });
			toast.success('Palavra Chave excluída com sucesso!');
		} catch (error) {
			console.error(error);
			toast.error('Erro ao tentar excluir Palavra Chave');
		}
	};

	return (
		<>
			<ExcluirPalavraChave
				isOpen={excluirModal}
				toggle={toggleExcluir}
				palavraChave={palavraChave}
				vestibular={vestibular}
				onExcluir={excluirPalavra}
			/>
			<TableRow
				key={palavraChave.palavraChave + palavraChave.ano + palavraChave.codigo}
			>
				<HeaderCol xs={3} sm={3} md={3} lg={3}>
					{palavraChave.ano}
				</HeaderCol>
				<TableCol xs={4} sm={4} md={4} lg={4}>
					{palavraChave.palavraChave}
				</TableCol>
				<TableCol xs={3} sm={3} md={4} lg={4}>
					{palavraChave.codigo}
				</TableCol>
				{/* <TableCol xs={2} sm={2} md={1} lg={1}>
          <Button size="sm" color="danger" onClick={toggleExcluir}>
            <Icon path={mdiDelete} size={0.8} color="white" />
          </Button>
        </TableCol> */}
			</TableRow>
		</>
	);
};

ItemPalavraChave.propTypes = {
	palavraChave: PropTypes.instanceOf(Object).isRequired,
	vestibular: PropTypes.string.isRequired,
};

export default ItemPalavraChave;
