import React from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ADMIN_CAD_USERS_SCREEN } from 'screens/AdminCadUsers/routes';
import UserRoles from 'types/user';
import { useStyles } from '../../styles';
import {
	CADASTRAR_USUARIO,
	DASHBOARD_PARCEIROS,
	DASHBOARD_FINANCEIRO,
} from '../../../../../constants';
import UserWrapper from '../../../../../screens/wrappers/UserWrapper';

export default function GerenciamentoMenu() {
	const styles = useStyles();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<UserWrapper roles={[UserRoles.ADMINISTRADOR]}>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{ color: 'white', fontWeight: 400, padding: '10px' }}
				className={styles.menuIten}
			>
				GERENCIAMENTO
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => navigate(ADMIN_CAD_USERS_SCREEN)}>
					Usuários
				</MenuItem>

				<MenuItem onClick={() => navigate(CADASTRAR_USUARIO)}>
					Cadastrar Usuário
				</MenuItem>

				<MenuItem onClick={() => navigate(DASHBOARD_PARCEIROS)}>
					Dashboard Parceiros
				</MenuItem>

				<MenuItem onClick={() => navigate(DASHBOARD_FINANCEIRO)}>
					Dashboard Financeiro
				</MenuItem>
			</Menu>
		</UserWrapper>
	);
}
