export const ALL_VESTIBULARES = [
	'ENEM',
	'ITA',
	'IME',
	'UNICAMP',
	'FUVEST',
	'USP',
	'AFA',
	'EFOMM',
	'ESPCEX',
];
export const VESTIBULAR_FREEMIUM = ['ENEM', 'ITA', 'IME'];
export const VESTIBULAR_PREMIUM = [
	'UNICAMP',
	'FUVEST',
	'AFA',
	'EFOMM',
	'ESPCEX',
];

export const MATERIAS_ORDENADAS = [
	'Matemática',
	'Português',
	'História',
	'Física',
	'Biologia',
	'Química',
	'Geografia',
	'Filosofia',
	'Sociologia',
	'Inglês',
	'Espanhol',
];

export const MATERIAS_ORDENADAS_BY_VESTIBULAR = {
	ENEM: [
		'Matemática',
		'Português',
		'História',
		'Física',
		'Biologia',
		'Química',
		'Geografia',
		'Filosofia',
		'Sociologia',
		'Filosofia e Sociologia',
		'Inglês',
		'Espanhol',
	],
	ITA: ['Matemática', 'Português', 'Física', 'Química', 'Inglês'],
	IME: ['Matemática', 'Português', 'Física', 'Química', 'Inglês'],
	FUVEST: [
		'Matemática',
		'Português',
		'História',
		'Física',
		'Biologia',
		'Química',
		'Geografia',
		'Inglês',
	],
	UNICAMP: [
		'Matemática',
		'Português',
		'História',
		'Física',
		'Biologia',
		'Química',
		'Geografia',
		'Inglês',
	],
	AFA: ['Matemática', 'Português', 'Física', 'Inglês'],
	EFOMM: ['Matemática', 'Português', 'Física', 'Inglês'],
	ESPCEX: [
		'Matemática',
		'Português',
		'História',
		'Física',
		'Química',
		'Geografia',
		'Inglês',
	],
};
