import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		height: '50vh',
		marginTop: '10%',
	},
	dropdownContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	dropdownTitle: { marginRight: 15, fontSize: 11, color: 'white' },
	dropdownIcon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	dropdownButton: {
		border: 0,
		fontWeight: 'bold',
		color: '#8190a7',
		fontSize: 12,
		marginBottom: 5,
		'&:hover': {
			color: '#F2F2F2',
			backgroundColor: '#363636',
			cursor: 'pointer',
		},
	},

	dropdown: {
		display: 'flex',
		height: '20vh',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '10vw',
		paddingTop: 10,
		top: 40,
		backgroundColor: '#F2F2F2',
		position: 'absolute',
	},
});
