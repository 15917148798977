import { createUseStyles } from 'react-jss';
import { primaryBlue } from 'constants/colors';

interface ITheme {
	selected: boolean;
}

const styles = () => ({
	header: {
		backgroundColor: primaryBlue,
		height: '35px',
		padding: '10px 10% 10px 10%',
	},
	headerTitle: {
		color: 'white',
		fontSize: 28,
		fontWeight: 300,
		marginBottom: 20,
	},
	headerTabs: {
		display: 'flex',
		flexDirection: 'row',
	},
	headerTab: {
		display: 'flex',
		flexDirection: 'row',
		color: 'white',
		border: 0,
		backgroundColor: '#8190A7',
		opacity: ({ selected }: ITheme) => (selected ? 1 : 0.39),
		fontSize: 12,
		fontWeight: 600,
		marginRight: 20,
		'&:hover': {
			cursor: 'pointer',
			color: 'gray',
		},
		'&:focus': {
			outline: 'none',
		},
	},
});

export const useStyles = createUseStyles(styles);
