import { createObjectFromArray } from 'helpers';
import { ALL_VESTIBULARES } from 'helpers/vestibular';
import { createAnoOptions } from '../../helpers/options';

export const anoOptions = [
	{ label: 'Selecione Ano', value: 0 },
	...createAnoOptions(2012),
];

export const vestibularOptions = [
	{ label: 'Selecione Vestibular', value: 0 },
	...createObjectFromArray(ALL_VESTIBULARES, ['label', 'value']),
];
