import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
	name?: string;
	placeholder: string;
	value: string;
	onKeyDown: any;
	myWhidth: string;
	myMargin: string;
}

const InputTextField = React.forwardRef<any, Props>(
	({ placeholder, value, onKeyDown, myWhidth, myMargin, name }, ref) => {
		const [currentValue, setCurrentValue] = useState(value);

		return (
			<TextField
				inputRef={ref}
				name={name}
				variant="outlined"
				value={currentValue}
				onChange={event => setCurrentValue(event.target.value)}
				onKeyDown={event => onKeyDown(event, currentValue)}
				type="text"
				placeholder={placeholder}
				style={{ width: myWhidth, marginRight: myMargin }}
			/>
		);
	},
);

export default InputTextField;
