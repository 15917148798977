import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { FETCH_DESAFIO } from 'screens/CadastrarDesafio/api';
import api from 'services/api';
import { RootModel } from '..';
import { SetBanco, ChangePage, CheckOneQuestion, State } from './types';
import { UPDATE_DESAFIO } from '../desafios/queries';

export const INITIAL_STATE: State = {
	page: 0,
	banco: [],
};

export const bancoQuestoes = createModel<RootModel>()({
	state: INITIAL_STATE,
	reducers: {
		setPage: (state, props: ChangePage) => ({
			...state,
			page: props.newPage,
		}),
		setBanco: (state, props: SetBanco) => ({
			...state,
			banco: props.questoes,
		}),
		checkOneQuestion: (state, props: CheckOneQuestion) => {
			const hasQuestion = state.banco.find(
				questao => questao._id === props.questaoId,
			);

			const newBanco = hasQuestion
				? state.banco.map(questao => {
						if (props.questaoId === questao._id) {
							return {
								...questao,
								checked: props.checked,
							};
						}
						return questao;
				  })
				: [
						...state.banco,
						{
							_id: props.questaoId,
							checked: props.checked,
						},
				  ];
			return {
				...state,
				banco: newBanco,
			};
		},
	},
	effects: dispatch => ({
		async salvarQuestoes(props, models) {
			try {
				const selectedQuestions = models.bancoQuestoes.banco.filter(
					questao => questao.checked === true,
				);
				const novosIds = selectedQuestions.map(questao => questao._id);
				await api.mutate({
					mutation: UPDATE_DESAFIO,
					variables: {
						_id: models.desafio.desafioId,
						desafio: {
							questoes: novosIds,
						},
					},
					refetchQueries: [
						{
							query: FETCH_DESAFIO,
							variables: {
								_id: models.desafio.desafioId,
							},
						},
					],
				});
				toast.success('Questões adicionadas ao desafio com Sucesso!');
			} catch (error: any) {
				await dispatch.auth.logout();
				if (error.graphQLErrors && error.graphQLErrors.length > 0) {
					toast.error(error.graphQLErrors[0].message);
				} else {
					toast.error('Houve um Erro ao tentar logar o usuário!');
				}
			}
		},
	}),
});
