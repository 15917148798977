import { DesafioTags } from '../types';

export const getTagsOptions = [
	{ label: 'Pacote Pré ENEM 2023', value: 'pack-pre-enem-2023' },
	{ label: 'Pacote Pré ITA/IME 2023', value: 'pack-pre-ita-ime-2023' },
	{ label: 'Destaques', value: DesafioTags.DESTAQUES },
	{ label: 'Novos', value: DesafioTags.NOVOS },
];

export const getPublicarOptions = [
	{ label: 'Disponível', value: 'publish' },
	{ label: 'Indisponível', value: 'unpublish' },
];
