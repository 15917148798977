import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { NavHeader } from 'components';
import { useStyles } from './styles';
import { Form } from './components';

const CadastrarSimulado: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<>
			<NavHeader headerStyle={{ padding: '20px 5.5% 10px 5.5%' }} />

			<Grid className={classes.container}>
				<Grid item className={classes.backButtonGrid}>
					<Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
						Voltar
					</Button>
				</Grid>
				<Grid item className={classes.titleContainer}>
					<Typography className={classes.title} variant="h4">
						Cadastrar Simulado
					</Typography>
				</Grid>

				<Form />
			</Grid>
		</>
	);
};

export default CadastrarSimulado;
